import React, { useContext, useMemo } from "react";
import { Tooltip, Typography } from "antd";
import { ContractContext } from "../../../../context";
export function CodeTag(props: any) {
  const context = useContext(ContractContext);
  const find = useMemo(
    () => context.find((it) => it.viewCode === props.decoratedText),
    [context, props.decoratedText]
  );
  return (
    <Tooltip title={find ? find.name : props.children}>
      <Typography.Text mark>{props.children}</Typography.Text>
    </Tooltip>
  );
}
