import React from "react";
import { Menu, MenuItemProps } from "antd";
import { useIsPermission } from "../../../hooks";

/**
 * 权限菜单项
 * @param props
 * @constructor
 */
export function PermissionMenuItem(props: PermissionMenuItemProps) {
  const isPermission = useIsPermission(props.permissions);
  return <Menu.Item {...props} disabled={!isPermission || props.disabled} />;
}

export interface PermissionMenuItemProps extends MenuItemProps {
  permissions: string[];
}
