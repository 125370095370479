import React, { useMemo } from "react";
import { Select, SelectProps } from "antd";
import { useList } from "../../../hooks";
import { DictionaryEnum } from "../../../constants";
import { API_OTHER } from "../../../api/API_OTHER";

/**
 * 字典下拉
 */
export function DictionarySelect(props: DictionarySelectProps) {
  const { pid, filterValue, valueKey, valueId, ...rest } = props;
  const key = valueKey ? valueKey : "id";
  const id = valueId ? valueId : "id";
  const params = useMemo<
    API_OTHER.v100001.companyDicdata.SelectParams | undefined
  >(() => (pid ? { pid } : undefined), [pid]);
  const { list } = useList(API_OTHER.v100001.companyDicdata.select, params);
  return (
    <Select {...rest} allowClear>
      {list.map((item) =>
        filterValue?.includes(item[key]) ? null : (
          <Select.Option
            key={item[id]}
            value={item[key]}
            children={item.name}
          />
        )
      )}
    </Select>
  );
}

export interface DictionarySelectProps extends SelectProps<any> {
  pid: DictionaryEnum | number | undefined;
  filterValue?: Array<number | string>;
  // 默认用ID作为value值
  valueKey?: "name" | "id" | "remarks";
  // 默认用ID作为key值
  valueId?: "name" | "id" | "remarks";
}
