import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

export function useRouter() {
  const router = useHistory();
  const location = useLocation();

  const [route, setRoute] = useState(location);

  const path = useMemo(() => {
    const list = route.pathname.split("/");
    list.pop();
    return list.join("/");
  }, [route]);

  const paths = useMemo(() => {
    const _paths = route.pathname.split("/");
    const list: string[] = [];
    const deep = (value: string[]) => {
      value.pop();
      list.push(value.join("/"));
      if (value.length > 0) {
        deep(value);
      }
    };
    deep(_paths);
    return list.filter((item) => item);
  }, [route]);

  const query = useMemo(() => {
    return new URLSearchParams(route.search);
  }, [route.search]);

  useEffect(() => {
    const unregister = router.listen((location) => {
      setRoute(location);
    });

    return () => {
      unregister();
    };
  }, [router, location]);

  return useMemo(
    () => ({
      route,
      router,
      path,
      paths,
      search: location.search,
      pathname: location.pathname,
      query,
    }),
    [route, router, path, paths, location.search, location.pathname, query]
  );
}
