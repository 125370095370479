import React, { useState } from "react";
import { Row, Select, SelectProps, Spin, Typography } from "antd";
import { useList } from "../../../hooks";
import { API_OTHER } from "../../../api/API_OTHER";

export function AddressSearchSelect(props: AddressSearchSelectProps) {
  const [params, setParams] =
    useState<API_OTHER.v100001.addr.SuggestionParams>();
  const { list, loading } = useList(API_OTHER.v100001.addr.suggestion, params);

  return (
    <Select
      {...props}
      showSearch
      filterOption={false}
      virtual={false}
      notFoundContent={loading ? <Spin size="small" /> : null}
      onInputKeyDown={(e) => {
        console.log(e);
      }}
      onSearch={(keyword) => {
        if (keyword && keyword.trim().length) {
          setParams({
            keyword
          });
        }
      }}
    >
      {list.map((item) => (
        <Select.Option
          key={item.id}
          {...item}
          value={item.id}
          children={
            <div>
              <Row align="middle">
                <Typography.Text strong>{item.title}</Typography.Text>
              </Row>
              <Row align="middle">
                <Typography.Text type="secondary">
                  {item.address}
                </Typography.Text>
              </Row>
            </div>
          }
        />
      ))}
    </Select>
  );
}

export interface AddressSearchSelectProps extends SelectProps<string> {}
