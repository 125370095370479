import React, { ReactNode } from "react";
import { Button, ButtonProps } from "antd";
import { useIsPermission } from "../../../hooks";
import { notification } from "antd";

/**
 * 权限按钮
 * @param props
 * @constructor
 */
export function PermissionButton(props: PermissionButtonProps) {
  const isPermission = useIsPermission(props.permissions);
  if (isPermission) {
    return <Button type="link" {...props} />;
  }
  return (
    <Button
      type="link"
      {...props}
      onClick={() => {
        notification.error({
          message: "提示",
          description: "暂无权限"
        });
      }}
    />
  );
}

export interface PermissionButtonProps extends ButtonProps {
  permissions: string[];
  children?: ReactNode;
}
