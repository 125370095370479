import React, { useEffect, useMemo } from "react";
import { Redirect } from "react-router-dom";
import loadable from "@loadable/component";
import { Spin } from "antd";
import CacheRoute, { CacheSwitch } from "react-router-cache-route";
import { Route } from "react-router-dom";
import { Route as RouteInfo } from "../../../routes";
import { RouteContext } from "../../../context";
import { MenuType, ReduxActionType } from "../../../constants";
import { store } from "../../../store";
import { ParsedRoute } from "../../../utils";
import { useRouter } from "../../../hooks";

export function MapRoute(props: MapRouteProps) {
  const { routes, redirect, id } = props.route;
  useEffect(() => {}, []);
  const routeNode = useMemo(
    () =>
      routes
        ? routes
            .concat(
              {
                id: id + 98,
                type: MenuType.Page,
                path: "404.html",
                disableKeepAlive: true,
                component: () => import("../../../layout/not-found-layout")
              },
              {
                id: id + 99,
                type: MenuType.Page,
                path: "",
                disableKeepAlive: true,
                redirect:
                  redirect ||
                  (routes && routes[0] && routes[0].path) ||
                  undefined
              }
            )
            .map((_route) => {
              const { component, redirect, path, NotRefresh } = _route;
              const isExact = !Array.isArray(_route.routes);
              if (component) {
                const LoadableComponent = loadable(component, {
                  fallback: (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <Spin />
                    </div>
                  )
                });
                if (NotRefresh) {
                  return (
                    <Route exact={isExact} path={path} key={path}>
                      <Page route={_route}>
                        <RouteContext.Provider value={{ route: _route }}>
                          <LoadableComponent />
                        </RouteContext.Provider>
                      </Page>
                    </Route>
                  );
                } else {
                  return (
                    <CacheRoute
                      cacheKey={path}
                      exact={isExact}
                      key={_route.id}
                      when="forward"
                      path={path}
                      strict={true}
                    >
                      <Page route={_route}>
                        <RouteContext.Provider value={{ route: _route }}>
                          <LoadableComponent />
                        </RouteContext.Provider>
                      </Page>
                    </CacheRoute>
                  );
                }
              } else if (redirect) {
                return <Redirect exact key={path} path={path} to={redirect} />;
              } else {
                return null;
              }
            })
        : null,
    [id, redirect, routes]
  );

  return <CacheSwitch>{routeNode}</CacheSwitch>;
}

function Page<T>(props: PageProps<T>): T {
  const { pathname } = useRouter();
  useEffect(() => {
    if (props.route.path === pathname) {
      const state = store.getState() as any;
      const find = state.system.routeHistory.find(
        (item: ParsedRoute) => item.path === props.route.path
      );
      if (
        !find &&
        props.route.type === MenuType.Page &&
        !props.route.disableKeepAlive
      ) {
        props.route.search = window.location.search;

        store.dispatch({
          type: ReduxActionType.HISTORY,
          history: state.system.routeHistory.concat([props.route])
        });
      } else if (find) {
        find.search = window.location.search;
      }
    }
  }, [props.route, pathname]);

  return props.children;
}

interface PageProps<T> {
  children: T;
  route: ParsedRoute;
}

export interface MapRouteProps {
  route: RouteInfo;
}
