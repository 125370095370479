export enum HousePayType {
  BEFORE = 1,
  AFTER = 2,
  FIXED = 3,
}

export function getHousePayTypeName(type: HousePayType) {
  switch (type) {
    case HousePayType.AFTER:
      return "延后";
    case HousePayType.BEFORE:
      return "提前";
    case HousePayType.FIXED:
      return "固定";
  }
}
