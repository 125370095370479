import {CurrencyUpload, CurrencyUploadProps} from "../../base/currency-upload";
import {AntCloudOutlined} from "@ant-design/icons";
import React, {useMemo, useState} from "react";
import {RandomUtil} from "../../../utils";
import {UploadFile} from "antd/es/upload/interface";
import {Button} from "antd";

export function UploadAudio(props: UploadAudioProps) {
    const {value = "", onChange, ...rest} = props;

    const [fileList, setFileList] = useState<UploadFile[]>();


    const list = useMemo<UploadFile[]>(() => {
        if (!fileList && value) {
            return value.split(",").map(url => ({
                uid: RandomUtil.createRandomKey(5),
                url,
                path: url,
                size: 0,
                type: "audio/*",
            })) as any;
        }
        return fileList;
    }, [fileList, value]);


    return (
        <CurrencyUpload
            fileList={list}
            listType="text"
            accept="audio/*"
            onChange={({file, fileList, event}) => {
                if (onChange && typeof onChange === "function" && file.status === "done") {
                    // @ts-ignore
                    onChange(fileList.filter(item => item.path).map(item => item.path).join(","));
                }
                setFileList(fileList);
            }}
            {...rest}
        >
            <Button type="primary" icon={<AntCloudOutlined/>}>上传</Button>
        </CurrencyUpload>
    );
}

export interface UploadAudioProps extends Omit<CurrencyUploadProps, "onChange"> {
    value?: string;
    onChange?: (value: string) => string;
}
