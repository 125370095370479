import { ContentBlock, ContentState } from "draft-js";
import React, { CSSProperties, useMemo } from "react";

export function Atomic(props: AtomicComponentProps) {
  const { contentState } = props;
  const entity = useMemo(() => {
    try {
      return contentState.getEntity(
        props.contentState.getLastCreatedEntityKey()
      );
    } catch (e) {}
  }, [contentState, props.contentState]);

  const type = useMemo(() => {
    if (entity) {
      return entity.getType();
    }
  }, [entity]);

  if (type === "hr") {
    return <hr />;
  }

  return <div />;
}

export interface AtomicComponentProps {
  contentState: ContentState;
  block: ContentBlock;
  customStyleMap: { [key: string]: CSSProperties };
  offsetKey: string;
}
