export enum DictionaryEnum {
  /**
   * 财务关联类型
   */
  relation_type = 11,
  /**
   * 首页应支(房东)
   */
  home_expend = 12,
  /**
   * 首页应收(租客)
   */
  home_receivable = 13,
  housing_pay_type = 19,
  /**
   * 空置时间
   */
  rent_out_time = 31,
  /**
   * 免租规则
   */
  rent_free_rules = 56,

  /**
   * 租赁性质
   */
  nature_of_lease = 85,
  /**
   * 渠道来源
   */
  channel_source = 88,
  /**
   * 租客能源费
   */
  energy_fee = 92,
  /**
   * 合同性质
   */
  nature_of_contract = 128,
  /**
   * 租客其他
   */
  tenant_other = 131,
  /**
   * 房东其他信息
   */
  housing_user_other_info = 135,
  /**
   * 向房东支付
   */
  housing_user_pay = 137,
  /**
   * 扣减房东支付
   */
  housing_user_pay_reduce = 139,
  /**
   * 维修方案
   */
  maintenance_plan = 142,
  /**
   * 房源类型
   */
  housing_type = 145,
  /**
   * 我的客源-用途
   */
  customers_purpose = 177,
  /**
   * 收款方式
   */
  payment_method = 675,
  /**
   * 提醒类型
   */
  reminder_type = 192,
  /**
   * 款项类型(收入类)
   */
  fee_income_type = 197,
  /**
   *款项类型(支出类)
   */
  fee_expense_type = 198,
  /**
   * 房东退房
   */
  housing_exit_other = 277,

  /**
   * 租客退房
   */
  tenant_exit_other = 283,

  /**
   * 模版消息类型
   */
  template_config_type = 350,

  /**
   * 在线找房类型
   */
  online_house_config_type = 367,

  /**
   * 短信模板类型
   */
  sms_config_type = 341,
}
