export enum MaintenanceCleanHouseType {
  // 整租
  house1 = 1,
  // 合租
  house2 = 2,
  // 集中
  house3 = 3,
}

export enum MaintenanceCleanAuditType {
  // 通过
  adopt = 1,
  // 未通过
  failed = 2,
  // 未审核
  notreviewed = 3,
}

export enum MaintenanceCleanWeChatType {
  // 微信报修
  WeChat = 1,
  // 公司派单
  company = 2,
}
export enum isUserRelatedType {
  // 自己
  isUserRelated1 = 1,
  // 审核
  isUserRelated2 = 2,
}
export enum typehouse {
  // 不限
  type1 = "1",
  // 主卧
  type2 = "2",
  // 主卧带卫
  type3 = "3",
  //不要隔间
  type4 = "4",
}
