import {
  DownOutlined,
  ReloadOutlined,
  SettingOutlined,
  UpOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  CardProps,
  Checkbox,
  Col,
  Dropdown,
  Form,
  FormInstance,
  Row,
  Space,
  Table,
  notification
} from "antd";
import { useQuery } from "../../../hooks/useQuery";
import { FormItemProps } from "antd/es/form";
import { ColumnType, TableProps } from "antd/es/table";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import MD5 from "crypto-js/md5";
import * as localforage from "localforage";
import { RenderedCell } from "rc-table/lib/interface";
import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useDidRecover } from "react-router-cache-route";
import { formColLayout6 } from "../../../constants";
import { RequestFn, usePageList, useRoute, useRouter } from "../../../hooks";
import "./index.less";
import "../../../styles/main.less";

export function CurrencyTable<
  T extends object = any,
  P extends FetchPageParams = FetchPageParams
>(props: CurrencyTableProps<T, P>) {
  const breakpoint = useBreakpoint();
  let {
    isScroll = true,
    useCard = true,
    card,
    search = [],
    tools,
    actions = [],
    columns = [],
    initialSearch,
    filterNameKey = "",
    onSearch,
    requestFn,
    pageSize = 10,
    params,
    form,
    footer,
    filterColumnNames,
    onRow,
    ...rest
  } = props;
  const nowquery = useQuery<{ type: any }>();
  const [rowActiveIndex, setRowActiveIndex] = useState<any>(null);
  const { path } = useRoute();
  const { pathname } = useRouter();
  const [query, setQuery] = useState<P & FetchPageParams>(
    (initialSearch
      ? onSearch
        ? onSearch({ ...initialSearch })
        : initialSearch
      : undefined) as P
  );

  const initParams = useRef({
    pageSize
  });
  useDidRecover(() => {
    console.log(query, "queryqueryqueryqueryquery");
    setQuery({
      ...query
    });
  }, [query]);
  const pageParams = useMemo(() => {
    let newQuery = { ...query };
    // console.log(newQuery);
    if (query) {
      Object.keys(query).map((item) => {
        //@ts-ignore
        if (Array.isArray(query[item])) {
          //@ts-ignore
          newQuery[item] = newQuery[item].length
            ? //@ts-ignore
              newQuery[item].join(",")
            : undefined;
        }
      });
    }
    let newParams = { ...params };
    if (params) {
      Object.keys(params).map((item) => {
        //@ts-ignore
        if (Array.isArray(params[item])) {
          //@ts-ignore
          newParams[item] = newParams[item].length
            ? //@ts-ignore
              newParams[item].join(",")
            : undefined;
        }
      });
    }
    return {
      pageNo: 1,
      ...initParams.current,
      ...newParams,
      ...newQuery
    } as P;
  }, [params, query]);
  useEffect(() => {
    return () => {
      console.log(1);
    };
  }, []);

  const page = usePageList<T, P>(requestFn, pageParams);

  const columnsNameList = useMemo(
    () =>
      columns.filter((item) => {
        // 是否有标题
        const hasTitle = Boolean(item.title);
        // 是否有过滤
        if (typeof filterColumnNames === "function" && item.dataIndex) {
          const filters = filterColumnNames(page.list);
          return !filters.includes(item.dataIndex as string) && hasTitle;
        }
        return hasTitle;
      }),
    [columns, filterColumnNames, page.list]
  );

  // 默认过滤字段
  const [filterNameList, setFilterNameList] = useState<string[]>(
    columnsNameList
      .filter((item) => item.initShow !== false)
      .map((item) => item.title) as string[]
  );

  // 保存缓存的字段过滤参数
  useEffect(() => {
    if (filterNameList) {
      localforage.setItem(
        MD5(
          requestFn.toString() + path + filterNameKey + "filterNameList"
        ).toString(),
        filterNameList
      );
    }
  }, [filterNameKey, filterNameList, path, requestFn]);

  // 取出缓存的字段过滤参数
  useEffect(() => {
    localforage
      .getItem(
        MD5(
          requestFn.toString() + path + filterNameKey + "filterNameList"
        ).toString()
      )
      .then((res) => {
        // console.log(res)
        if (res) {
          setFilterNameList(res as string[]);
        }
      });
  }, [filterNameKey, path, requestFn]);

  // 缓存过滤列
  const filterColumns = useMemo(() => {
    return columns.filter((item, index) => {
      if (item.title) {
        if (typeof filterColumnNames === "function" && item.dataIndex) {
          const filters = filterColumnNames(page.list);

          return (
            !filters.includes(item.dataIndex as string) &&
            filterNameList.includes(item.title as string)
          );
        }

        return filterNameList.includes(item.title as string);
      }
      return true;
    });
  }, [columns, filterColumnNames, filterNameList, page.list]);

  // 是否显示所有查询条件
  const [formShowAll, setFormShowAll] = useState<boolean>(false);
  useEffect(() => {
    if (nowquery && nowquery?.type == 2) {
      let target = document.getElementsByClassName("selfnoclick");
      const selfmap = (nowTarget: HTMLCollectionOf<Element>) => {
        if (nowTarget.length) {
          for (let index = 0; index < nowTarget.length; index++) {
            const element = nowTarget[index];

            if (
              typeof element.className == "string" &&
              element.className.indexOf("ant-btn") != -1 &&
              element.className.indexOf("canclick") == -1
            ) {
              //@ts-ignore
              element.onclick = (e) => {
                e.stopPropagation();
                notification.warn({
                  message: "提示",
                  description: "退房详情,不能操作"
                });
                return;
              };
            }
            selfmap(element.children);
          }
        }
      };
      selfmap(target);
    }
  }, [page.list, nowquery, path, filterColumns]);
  if (useCard) {
    return (
      <div>
        {search.length ? (
          <Card className="currency-table-cardStyle1">
            <Form
              form={form}
              initialValues={initialSearch}
              layout="vertical"
              onFinish={(v) => {
                const data = {
                  ...query,
                  ...v,
                  pageNo: 1
                };
                setQuery(onSearch ? onSearch(data) : data);
              }}
            >
              <Row wrap gutter={26}>
                {search.map((item, index) => {
                  let isHidden: boolean;
                  if (formShowAll) {
                    isHidden = false;
                  } else {
                    isHidden = index > 6;
                  }
                  return (
                    <Col
                      lg={6}
                      md={6}
                      xxl={3}
                      xl={3}
                      sm={8}
                      xs={24}
                      hidden={isHidden}
                    >
                      <Form.Item
                        key={index || (item.name as string)}
                        {...(item as any)}
                      >
                        {React.cloneElement(item.children as any, {
                          style: { width: "100%" }
                        })}
                      </Form.Item>
                    </Col>
                  );
                })}

                <Col lg={6} md={6} xxl={3} xl={3} sm={8} xs={24}>
                  <Form.Item label=" ">
                    <Button type="primary" htmlType="submit" children="搜索" />

                    {search.length > 6 ? (
                      <Button
                        type="link"
                        htmlType="button"
                        onClick={() => setFormShowAll(!formShowAll)}
                        children={
                          formShowAll ? (
                            <>
                              收起
                              <UpOutlined />
                            </>
                          ) : (
                            <>
                              展开
                              <DownOutlined />
                            </>
                          )
                        }
                      />
                    ) : null}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        ) : null}

        <Card {...card} className="currency-table-cardStyle2">
          <Row justify="space-between" style={{ marginBottom: 10 }}>
            <Row>
              {tools
                ? typeof tools === "function"
                  ? tools(pageParams)
                  : tools
                : undefined}
            </Row>
            <Space wrap>
              {actions.filter((it) => it !== "reload" && it !== "setting")}
              {actions.includes("reload") ? (
                <Button
                  icon={<ReloadOutlined />}
                  onClick={() => setQuery({ ...(query as any) })}
                />
              ) : null}
              {actions.includes("setting") ? (
                <Dropdown
                  arrow
                  placement="bottomRight"
                  trigger={["click"]}
                  overlay={
                    <div>
                      <Card
                        onClick={(e) => e.stopPropagation()}
                        style={{ minWidth: 150 }}
                        title={
                          <Row justify="space-between">
                            <Checkbox
                              checked={
                                columnsNameList.length === filterNameList.length
                              }
                              indeterminate={
                                filterNameList.length > 0 &&
                                columnsNameList.length !== filterNameList.length
                              }
                              onChange={(e) =>
                                setFilterNameList(
                                  e.target.checked
                                    ? columnsNameList.map(
                                        (item) => item.title as string
                                      )
                                    : []
                                )
                              }
                              children="全选"
                            />
                          </Row>
                        }
                        size="small"
                        extra={
                          <Button
                            onClick={() =>
                              setFilterNameList(
                                columnsNameList
                                  .filter((item) => item.initShow !== false)
                                  .map((item) => item.title as string)
                              )
                            }
                            type="link"
                            children="重置"
                            size="small"
                          />
                        }
                      >
                        <Checkbox.Group
                          value={filterNameList}
                          onChange={(v) => setFilterNameList(v as string[])}
                        >
                          {columnsNameList.map((item) => (
                            <div key={item.title as string}>
                              <Checkbox
                                value={item.title}
                                children={item.title}
                              />
                            </div>
                          ))}
                        </Checkbox.Group>
                      </Card>
                    </div>
                  }
                >
                  <Button icon={<SettingOutlined />} htmlType="button" />
                </Dropdown>
              ) : null}
            </Space>
          </Row>
          <Table<T>
            rowClassName={(_, index) =>
              rowActiveIndex === index ? "row-active row-normal" : "row-normal"
            }
            onRow={(record, index) => {
              if (onRow) {
                return {
                  //@ts-ignore
                  ...onRow(record, index),
                  onClick: () => {
                    console.log(index);
                    setRowActiveIndex(index);
                  }
                };
              } else {
                return {
                  onClick: () => {
                    console.log(index);
                    setRowActiveIndex(index);
                  }
                };
              }
            }}
            sticky
            pagination={{
              total: page.total,
              current: (query && query.pageNo) || 1,
              onChange: (pageNo, pageSize) => {
                setQuery({
                  ...query,
                  pageNo,
                  pageSize
                } as P);
              },
              onShowSizeChange: (pageNo, pageSize) => {
                setQuery({
                  ...query,
                  pageNo,
                  pageSize
                } as P);
              },
              showQuickJumper: true,
              showSizeChanger: true,
              pageSize: query ? query.pageSize || pageSize : pageSize,
              pageSizeOptions: [
                "5",
                "10",
                "15",
                "20",
                "25",
                "30",
                "40",
                "50",
                "100",
                "500"
              ],
              showTotal: () => <span>共{page.total}条数据</span>
            }}
            columns={filterColumns as any}
            dataSource={page.list}
            loading={page.loading}
            size="small"
            bordered
            scroll={
              isScroll
                ? {
                    x:
                      breakpoint.sm ||
                      breakpoint.xxl ||
                      breakpoint.xl ||
                      breakpoint.xs
                        ? true
                        : undefined
                  }
                : {}
            }
            {...rest}
            // @ts-ignore
            footer={footer ? () => footer(pageParams) : null}
          />
        </Card>
      </div>
    );
  }

  return (
    <>
      {search.length ? (
        <Form
          layout="vertical"
          style={{ marginBottom: 10 }}
          onFinish={(v) =>
            setQuery(
              onSearch
                ? onSearch({
                    ...query,
                    ...v,
                    pageNo: 1
                  })
                : {
                    ...query,
                    ...v,
                    pageNo: 1
                  }
            )
          }
        >
          <Row wrap gutter={32}>
            {search.map((item, index) => {
              let isHidden: boolean;
              if (formShowAll) {
                isHidden = false;
              } else {
                isHidden = index > 3;
              }
              return (
                <Col {...formColLayout6} hidden={isHidden}>
                  <Form.Item
                    name={item.name as string}
                    label={item.label}
                    key={index || (item.name as string)}
                  >
                    {React.cloneElement(item.children as any, {
                      style: { width: "100%" }
                    })}
                  </Form.Item>
                </Col>
              );
            })}

            <Col {...formColLayout6}>
              <Form.Item label=" ">
                <Button type="primary" htmlType="submit" children="搜索" />

                <Button
                  type="link"
                  htmlType="button"
                  onClick={() => setFormShowAll(!formShowAll)}
                  children={
                    formShowAll ? (
                      <>
                        收起
                        <UpOutlined />
                      </>
                    ) : (
                      <>
                        展开
                        <DownOutlined />
                      </>
                    )
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : null}

      <Row justify="space-between" style={{ marginBottom: 10 }}>
        <Row>{tools ? tools : undefined}</Row>
        <Space wrap>
          {actions.filter((it) => it !== "reload" && it !== "setting")}
          {actions.includes("reload") ? (
            <Button
              className="canclick"
              icon={<ReloadOutlined />}
              onClick={() => setQuery({ ...(query as any) })}
            />
          ) : null}
          {actions.includes("setting") ? (
            <Dropdown
              arrow
              className="canclick"
              placement="bottomRight"
              trigger={["click"]}
              overlay={
                <div>
                  <Card
                    onClick={(e) => e.stopPropagation()}
                    style={{ minWidth: 150 }}
                    title={
                      <Row justify="space-between">
                        <Checkbox
                          checked={
                            columnsNameList.length === filterNameList.length
                          }
                          indeterminate={
                            filterNameList.length > 0 &&
                            columnsNameList.length !== filterNameList.length
                          }
                          onChange={(e) =>
                            setFilterNameList(
                              e.target.checked
                                ? columnsNameList.map(
                                    (item) => item.title as string
                                  )
                                : []
                            )
                          }
                          children="全选"
                        />
                      </Row>
                    }
                    size="small"
                    extra={
                      <Button
                        onClick={() =>
                          setFilterNameList(
                            columnsNameList
                              .filter((item) => item.initShow !== false)
                              .map((item) => item.title as string)
                          )
                        }
                        type="link"
                        children="重置"
                        size="small"
                      />
                    }
                  >
                    <Checkbox.Group
                      value={filterNameList}
                      onChange={(v) => setFilterNameList(v as string[])}
                    >
                      {columnsNameList.map((item) => (
                        <div key={item.title as string}>
                          <Checkbox value={item.title} children={item.title} />
                        </div>
                      ))}
                    </Checkbox.Group>
                  </Card>
                </div>
              }
            >
              <Button icon={<SettingOutlined />} htmlType="button" />
            </Dropdown>
          ) : null}
        </Space>
      </Row>

      <Table<T>
        rowClassName={(_, index) =>
          rowActiveIndex === index ? "row-active row-normal" : "row-normal"
        }
        onRow={(record, index) => {
          if (onRow) {
            return {
              //@ts-ignore
              ...onRow(record, index),
              onClick: () => {
                console.log(index);
                setRowActiveIndex(index);
              }
            };
          } else {
            return {
              onClick: () => {
                console.log(index);
                setRowActiveIndex(index);
              }
            };
          }
        }}
        sticky
        pagination={{
          total: page.total,
          current: (query && query.pageNo) || 1,
          onChange: (pageNo, pageSize) => {
            setQuery({
              ...query,
              pageNo,
              pageSize
            } as P);
          },
          onShowSizeChange: (pageNo, pageSize) => {
            setQuery({
              ...query,
              pageNo,
              pageSize
            } as P);
          },
          showQuickJumper: true,
          showSizeChanger: true,
          pageSize: query ? query.pageSize || pageSize : pageSize,
          pageSizeOptions: [
            "5",
            "10",
            "15",
            "20",
            "25",
            "30",
            "40",
            "50",
            "100",
            "500"
          ],
          showTotal: () => <span>共{page.total}条数据</span>
        }}
        columns={filterColumns as any}
        dataSource={page.list}
        loading={page.loading}
        size="small"
        bordered
        scroll={
          isScroll
            ? {
                x:
                  breakpoint.sm ||
                  breakpoint.xxl ||
                  breakpoint.xl ||
                  breakpoint.xs
                    ? true
                    : undefined
              }
            : {}
        }
        {...rest}
        // @ts-ignore
        footer={footer ? () => footer(pageParams) : null}
      />
    </>
  );
}

export interface CurrencyTableProps<T, P extends FetchPageParams>
  extends Omit<TableProps<T>, "columns" | "loading" | "footer"> {
  /**
   * 过滤头工具栏的key
   * 用于同一个页面使用同一个表格区分缓存问题
   */
  filterNameKey?: string;
  /**
   * 计算要过滤的字段
   * @param data
   */
  filterColumnNames?: (data: T[]) => string[];
  /**
   * 是否使用card
   */
  useCard?: boolean;
  /**
   * card参数
   */
  card?: CardProps;
  /**
   * 默认搜索参数
   */
  initialSearch?: Partial<Record<keyof P, any>>;
  /**
   * 搜索表单
   */
  search?: SearchType<P>;
  /**
   * 表格顶部左侧操作栏
   */
  tools?: ((params: Partial<Record<keyof P, any>>) => ReactNode) | ReactNode;
  /**
   * 表格顶部右侧操作栏
   */
  actions?: Array<ReactNode | "reload" | "setting">;
  /**
   * 列结构
   */
  columns?: CurrencyTableColumnType<T>;
  /**
   * 请求函数
   */
  requestFn: RequestFn<P, T>;
  /**
   * 函数默认参数
   */
  params?: Omit<P, "pageNo" | "pageSize">;
  /**
   * 搜索事件
   * @param v
   */
  onSearch?: (v: Partial<Record<keyof P, any>>) => P;
  contextMenu?: ReactNode;
  /**
   * 表单实例
   */
  form?: FormInstance<P>;
  // 初始化页面条数
  pageSize?: number;
  /**
   * 表格底部
   */
  footer?: (v: Partial<Record<keyof P, any>>) => ReactNode;
  /**
   * 表格选中
   */
  selectedRowKeys?: any[];
  /**
   * 是否滚动
   */
  isScroll?: boolean;
}

export type SearchType<P> = Array<
  Omit<FormItemProps, "name"> & { name?: keyof Omit<P, "pageNo" | "pageSize"> }
>;
export type CurrencyTableColumnType<T, RecordType = any> = Array<
  Omit<ColumnType<T>, "dataIndex" | "dataSource" | "render"> & {
    initShow?: boolean;
    dataIndex?: keyof T;
    render?: (
      value: T,
      record: RecordType,
      index: number
    ) => React.ReactNode | RenderedCell<RecordType>;
  }
>;
