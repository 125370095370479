import { Tag, TagProps } from "antd";
import React, { ReactNode } from "react";
import { useIsPermission } from "../../../hooks";
import { notification } from "antd";

//TODO 需要通过权限控制onclick事件
export function PermissionTag(props: PermissionTagProps) {
  const isPermission = useIsPermission(props.permissions);
  const { onClick, ...rest } = props;
  return (
    <Tag
      {...rest}
      onClick={
        isPermission
          ? onClick
          : () => {
              notification.success({
                message: "提示",
                description: "暂无权限"
              });
            }
      }
    />
  );
}

export interface PermissionTagProps extends TagProps {
  permissions: string[];
  children?: ReactNode;
}
