import { ReactNode } from "react";
// import { Breadcrumb, Button, Card, Col, Row, Space } from "antd";
// import { useRoute } from "../../../hooks";
// import { ParsedRoute } from "../../../utils";
// import { SettingOutlined } from "@ant-design/icons";
// import { useHistory } from "react-router-dom";

export function MapBreadcrumb(props: MapBreadcrumbProps) {
  // const { hasBack = false, buttonShow = false, onClick } = props;
  // const route: ParsedRoute = useRoute();
  // const list = useMemo(() => {
  //   const _: Omit<ParsedRoute, "routes">[] = [];
  //
  //   function deep(r: ParsedRoute) {
  //     _.push(r);
  //     if (r.id !== 12000000 && r.parent) {
  //       deep(r.parent);
  //     }
  //   }
  //
  //   deep(route);
  //   return _.reverse();
  // }, [route]);
  // const history = useHistory();

  // return (
  //   <Card
  //     style={{ borderTop: "none" }}
  //     title={
  //       <Row align="middle">
  //         <Col span={12}>
  //           <Space wrap>
  //             {hasBack && (
  //               <Button size="small" onClick={() => history.goBack()}>
  //                 返回
  //               </Button>
  //             )}
  //             <Breadcrumb style={{ padding: 1 }}>
  //               {list.map((item, idx) => (
  //                 <Breadcrumb.Item children={item.name} />
  //               ))}
  //             </Breadcrumb>
  //           </Space>
  //         </Col>
  //         {buttonShow && (
  //           <Col
  //             span={12}
  //             style={{
  //               display: "flex",
  //               justifyContent: "flex-end",
  //               alignItems: "center",
  //             }}
  //           >
  //             <Button
  //               onClick={onClick}
  //               icon={<SettingOutlined />}
  //               children="设置"
  //               type="primary"
  //             />
  //           </Col>
  //         )}
  //       </Row>
  //     }
  //     bodyStyle={{ padding: 0 }}
  //   />
  // );

  return null;
}

export interface MapBreadcrumbProps {
  hasBack?: boolean;
  children?: ReactNode;
  buttonShow?: boolean;
  onClick?: () => void;
}
