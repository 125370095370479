import React, {ReactNode, useEffect, useState} from "react";
import {Button, Input, InputProps} from "antd";

const timer = Number(localStorage.getItem("SMS-CODE-CACHE"));

export function SMSCodeInput(props: SMSCodeInputProps) {
    const [count, setCount] = useState<number>(!isNaN(timer) ? timer : 0);
    const {onSendSmsCode, ...rest} = props;
    useEffect(() => {
        if (count > 0) {
            const timer = setTimeout(() => {
                const v = count - 1;
                setCount(v);
                localStorage.setItem("SMS-CODE-CACHE", v.toString());
            }, 1000);

            return () => {
                clearTimeout(timer);
            };
        } else {
            setCount(0);
        }
    }, [count]);

    return (
        <Input.Group
            compact
        >
            <Input style={{width: "70%"}} {...rest} />
            <Button
                disabled={Boolean(count)}
                style={{width: "30%"}}
                type="primary"
                children={count > 0 ? count : "发送"}
                onClick={async () => {
                    if (onSendSmsCode) await onSendSmsCode();
                    localStorage.setItem("SMS-CODE-CACHE", "59");
                    setCount(59);
                }}
            />
        </Input.Group>
    );
}

export interface SMSCodeInputProps extends InputProps {
    children?: ReactNode;
    onSendSmsCode?: () => Promise<void>;
}
