import {
  CurrencyUpload,
  CurrencyUploadProps
} from "../../base/currency-upload";
import { AntCloudOutlined, DeleteOutlined } from "@ant-design/icons";
import React, { useEffect, useMemo, useState } from "react";
import { RandomUtil } from "../../../utils";
import { UploadFile } from "antd/es/upload/interface";
import { Button } from "antd";

export function UploadVideo(props: UploadVideoProps) {
  const { value = "", onChange, ...rest } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>();

  const list = useMemo<UploadFile[]>(() => {
    if (!fileList && value) {
      return value.split(",").map((url) => ({
        uid: RandomUtil.createRandomKey(5),
        url,
        path: url,
        size: 0,
        type: "video/*"
        // name: url
      })) as any;
    }
    return fileList;
  }, [fileList, value]);

  useEffect(() => {
    console.log(list);
  }, [list]);
  return (
    <CurrencyUpload
      fileList={list}
      listType="text"
      accept="video/*"
      onChange={({ file, fileList, event }) => {
        setLoading(true);

        if (
          onChange &&
          typeof onChange === "function" &&
          file.status === "done"
        ) {
          // @ts-ignore
          onChange(
            fileList
              //@ts-ignore
              .filter((item) => item.path)
              //@ts-ignore
              .map((item) => item.path)
              .join(",")
          );
          setLoading(false);
        }
        setFileList(fileList);
      }}
      {...rest}
      iconRender={() => {
        return <div>点击播放</div>;
      }}
      itemRender={(originNode, file, fileList) => {
        const index = fileList?.indexOf(file);
        return (
          <div style={{ display: "flex", cursor: "pointer" }}>
            <div
              onClick={() => {
                //@ts-ignore
                window.open(file?.path || file.url);
              }}
            >
              点击播放视频{Number(index) + 1}
            </div>
            <DeleteOutlined
              style={{ lineHeight: "25px", marginLeft: "20px" }}
              onClick={() => {
                if (onChange && typeof onChange === "function" && fileList) {
                  // @ts-ignore
                  onChange(
                    fileList
                      .splice(Number(index), 1)
                      //@ts-ignore
                      .map((item) => item.path)
                      .join(",")
                  );
                }
                setFileList(fileList);
              }}
            />
          </div>
        );
      }}
    >
      <Button type="primary" icon={<AntCloudOutlined />} loading={loading}>
        上传
      </Button>
    </CurrencyUpload>
  );
}

export interface UploadVideoProps
  extends Omit<CurrencyUploadProps, "onChange"> {
  value?: string;
  onChange?: (value: string) => string;
}
