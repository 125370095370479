import React from "react";
import { Menu } from "antd";
import { useHistory } from "react-router-dom";

import { Route } from "../../../routes";

export function MapMenu(routes: Route[], hasChildren: boolean = true) {
  const history = useHistory();
  return routes.map((item) => {
    if (item.hideMenu) {
      return null;
    }
    if (Array.isArray(item.routes)) {
      return (
        <Menu.SubMenu
          icon={item.icon}
          key={item.path}
          title={item.name}
          onTitleClick={() =>
            !hasChildren && history.push(item.path || "/admin")
          }
        >
          {hasChildren && MapMenu(item.routes)}
        </Menu.SubMenu>
      );
    }
    return (
      <Menu.Item
        icon={item.icon}
        key={item.path}
        title={item.name}
        onClick={() => {
          history.push(item.path + "");
        }}
      >
        {item.name}
      </Menu.Item>
    );
  });
}
