import {Modal, ModalProps} from "antd";
import React, {ReactNode} from "react";

export function CurrencyModal(props: CurrencyModalProps) {
    return (
        <Modal
            maskClosable={false}
            keyboard={false}
            destroyOnClose
            {...props}
        />
    );
}

export interface CurrencyModalProps extends ModalProps {
    children?: ReactNode;
}
