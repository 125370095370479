import React, { useEffect, useState } from "react";
import { useList } from "../../../hooks";
import { Cascader, CascaderProps } from "antd";
import { CascaderOptionType } from "antd/lib/cascader";
import { API_OTHER } from "../../../api/API_OTHER";

const defaultParams = { pid: 0 };

/**
 * 地址级联选择器
 * @author 冉云龙<549510622@qq.com>
 */
export function AddressCascader(props: AddressCascaderProps) {
  const { list } = useList(API_OTHER.v100001.addr.list, defaultParams);

  const [options, setOptions] = useState<CascaderOptionType[]>([]);

  useEffect(() => {
    setOptions(
      list &&
        list.map((item) => ({
          value: item.id,
          label: item.fullname,
          isLeaf: false,
          ...item
        }))
    );
  }, [list]);

  return (
    <Cascader
      options={options}
      changeOnSelect={true}
      loadData={(selectedOptions) => {
        if (selectedOptions) {
          const targetOption = selectedOptions[selectedOptions.length - 1];
          targetOption.loading = true;
          if (targetOption.value) {
            API_OTHER.v100001.addr
              .list({ pid: Number(targetOption.value) })
              .then((res) => {
                const { data } = res.data;
                targetOption.loading = false;
                targetOption.children = data.map((item) => ({
                  value: item.id,
                  label: item.fullname,
                  isLeaf: selectedOptions.length === 2,
                  ...item
                }));

                setOptions([...options]);
              });
          }
        }
      }}
      {...props}
    />
  );
}

export interface AddressCascaderProps
  extends Omit<CascaderProps, "loadData" | "options"> {}
