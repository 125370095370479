import { request } from "../utils/request";
export namespace API_SYS {
  export namespace v100001 {
    export namespace noLogin {
      export namespace beiAnHao {
        /**
         * @name 获取备案号
         */
        export function getBeiAnHao() {
          return request.get<ResponseResult<string>>(
            "/sys-v100001/noLogin/beiAnHao/getBeiAnHao"
          );
        }
      }
      export namespace out {
        export namespace login {
          /**
           * @name 微信用户报修登陆退出操作
           */
          export function exitOutRepairWxLogin() {
            return request.post<ResponseResult>(
              "/sys-v100001/noLogin/out/login/exitOutRepairWxLogin"
            );
          }
          export interface GetOutRepairInfoParams {
            /**
             * @description  主键
             */
            id?: number;
          }
          /**
           * @name 外部维修详情
           * @param params
           */
          export function getOutRepairInfo(params: GetOutRepairInfoParams) {
            return request.get<ResponseResult<Repair>>(
              "/sys-v100001/noLogin/out/login/getOutRepairInfo",
              { params }
            );
          }
          export interface OutRepairWxLoginParams {
            /**
             * @description  微信code
             */
            code?: string;
            /**
             * @description  公众号菜单配置路径带上的
             */
            companyId?: number;
            /**
             * @description  获取电话的code
             */
            phoneCode?: string;
          }
          /**
           * @name 报修用户微信授权登陆
           * @param data
           */
          export function outRepairWxLogin(data: OutRepairWxLoginParams) {
            return request.post<ResponseResult<LoginVo>>(
              "/sys-v100001/noLogin/out/login/outRepairWxLogin",
              data
            );
          }
        }
      }
      export namespace weixinConfig {
        /**
         * @name 获取城市编码
         */
        export function getCity() {
          return request.get<ResponseResult<Array<CityCodeDto>>>(
            "/sys-v100001/noLogin/weixinConfig/getCity"
          );
        }
        export interface GetWeiXinJsApiAuthorizeParams {
          /**
           * @description  companyId
           */
          companyId?: number;
          /**
           * @description  本次上传图片唯一标识
           */
          key?: string;
          /**
           * @description  window.location.href获取
           */
          url?: string;
        }
        /**
         * @name 通过config接口注入权限验证配置
         * @param params
         */
        export function getWeiXinJsApiAuthorize(
          params: GetWeiXinJsApiAuthorizeParams
        ) {
          return request.get<ResponseResult<JsApiConfigVo>>(
            "/sys-v100001/noLogin/weixinConfig/getWeiXinJsApiAuthorize",
            { params }
          );
        }
        export interface SaveServerIdListParams {
          /**
           * @description  本次上传图片唯一标识
           */
          key?: string;
          /**
           * @description  微信上传图片返回的id,多个以逗号隔开
           */
          serverIdList?: string;
        }
        /**
         * @name 图片上传完成，保存serverId
         * @param params
         */
        export function saveServerIdList(params: SaveServerIdListParams) {
          return request.get<ResponseResult<object>>(
            "/sys-v100001/noLogin/weixinConfig/saveServerIdList",
            { params }
          );
        }
      }
      export namespace wxThree {
        export interface ApiCreatePreauthcodeParams {
          /**
           * @description  companyId
           */
          companyId?: number;
        }
        /**
         * @name 链接快速授权
         * @param params
         */
        export function apiCreatePreauthcode(
          params: ApiCreatePreauthcodeParams
        ) {
          return request.get<ResponseResult<object>>(
            "/sys-v100001/noLogin/wxThree/api_create_preauthcode",
            { params }
          );
        }
        export interface AuthorizationCodeParams {
          /**
           * @description  auth_code
           */
          auth_code?: string;
          /**
           * @description  expires_in
           */
          expires_in?: number;
        }
        /**
         * @name 微信授权回调
         * @param params
         */
        export function authorizationCode(params: AuthorizationCodeParams) {
          return request.get<ResponseResult<object>>(
            "/sys-v100001/noLogin/wxThree/authorization_code",
            { params }
          );
        }
        export interface CallBackParams {
          /**
           * @description  nonce
           */
          nonce?: string;
          /**
           * @description  timestamp
           */
          timestamp?: string;
          /**
           * @description  signature
           */
          signature?: string;
          /**
           * @description  msg_signature
           */
          msg_signature?: string;
          /**
           * @description  encrypt_type
           */
          encrypt_type?: string;
        }
        /**
         * @name 验证票据
         * @param data
         */
        export function callBack(data: CallBackParams) {
          return request.post("/sys-v100001/noLogin/wxThree/callBack", data);
        }
        export interface MsgCallbackParams {
          /**
           * @description  nonce
           */
          nonce?: string;
          /**
           * @description  timestamp
           */
          timestamp?: string;
          /**
           * @description  signature
           */
          signature?: string;
          /**
           * @description  msg_signature
           */
          msg_signature?: string;
        }
        /**
         * @name 微信消息回调
         * @param appid 微信消息回调
         * @param data
         */
        export function msgCallback(
          appid: string | number,
          data: MsgCallbackParams
        ) {
          return request.post<ResponseResult<object>>(
            `/sys-v100001/noLogin/wxThree/msgCallback/${appid}`,
            data
          );
        }
      }
    }
    export namespace company {
      export interface DeleteByIdParams {
        /**
         * @description  主键
         */
        id?: number;
      }
      /**
       * @name 客户表删除
       * @param params
       */
      export function deleteById(params: DeleteByIdParams) {
        return request.delete<ResponseResult<object>>(
          "/sys-v100001/company/deleteById",
          { params }
        );
      }
      /**
       * @name 客户表详情
       * @param id 客户表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<Company>>(
          `/sys-v100001/company/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  区id
         */
        areaId?: number;
        /**
         * @description  市主键
         */
        cityId?: number;
        /**
         * @description  详情地址
         */
        detailName?: string;
        /**
         * @description  客户名称
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  交费结束结束时间
         */
        payEndEndTime?: string;
        /**
         * @description  交费结束开始时间
         */
        payEndStartTime?: string;
        /**
         * @description  所属电话
         */
        phone?: string;
        /**
         * @description  省id
         */
        provinceId?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  1正式 2试用 3冻结
         */
        status?: number;
      }
      /**
       * @name 客户表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<Company>>>(
          "/sys-v100001/company/list",
          { params }
        );
      }
      export interface RegisterParams {
        /**
         * @description  客户名称
         */
        name?: string;
        /**
         * @description  所属电话
         */
        phone: number;
        /**
         * @description  登录密码
         */
        pwd?: string;
        /**
         * @description  1企业 2个人 （单选框，默认企业）
         */
        useType: number;
      }
      /**
       * @name 客户表新增
       * @param data
       */
      export function register(data: RegisterParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/company/register",
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  区id
         */
        areaId: number;
        /**
         * @description  市主键
         */
        cityId: number;
        /**
         * @description  详情地址
         */
        detailName?: string;
        /**
         * @description  企业图片
         */
        images?: string;
        /**
         * @description  客户名称
         */
        name?: string;
        /**
         * @description  所属电话
         */
        phone?: string;
        /**
         * @description  省id
         */
        provinceId: number;
        /**
         * @description  登录密码
         */
        pwd?: string;
        /**
         * @description  公司简称
         */
        simpleName?: string;
        /**
         * @description  1正式 2试用 3冻结
         */
        status: number;
      }
      /**
       * @name 客户表修改
       * @param id 客户表修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/sys-v100001/company/update/${id}`,
          data
        );
      }
      export interface UpdateStatusParams {
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  1正式 2试用 3冻结
         */
        status?: number;
      }
      /**
       * @name 客户状态修改 1正式 2试用 3冻结
       * @param data
       */
      export function updateStatus(data: UpdateStatusParams) {
        return request.put<ResponseResult<object>>(
          "/sys-v100001/company/updateStatus",
          data
        );
      }
    }
    export namespace companyPayRecord {
      export interface ListParams {
        /**
         * @description  客户id(全局标识)
         */
        companyId?: number;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
      }
      /**
       * @name 客户缴费记录表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<CompanyPayRecord>>>(
          "/sys-v100001/companyPayRecord/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  客户id(全局标识)
         */
        companyId: number;
        /**
         * @description  所用房源数量
         */
        house: number;
        /**
         * @description  交费金额
         */
        money: number;
        /**
         * @description  交费结束时间
         */
        payEndTime: string;
        /**
         * @description  交费开始时间
         */
        payStartTime: string;
      }
      /**
       * @name 客户缴费记录表新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/companyPayRecord/save",
          data
        );
      }
    }
    export namespace register {
      export namespace company {
        export interface GetRegisterCodeParams {
          /**
           * @description  电话
           */
          phone?: string;
        }
        /**
         * @name 客户注册获取验证码
         * @param params
         */
        export function getRegisterCode(params: GetRegisterCodeParams) {
          return request.get<ResponseResult>(
            "/sys-v100001/register/company/getRegisterCode",
            { params }
          );
        }
        export interface RegisterParams {
          /**
           * @description  客户名称
           */
          name?: string;
          /**
           * @description  所属电话
           */
          phone: number;
          /**
           * @description  登录密码
           */
          pwd?: string;
          /**
           * @description  短信验证码
           */
          smsCode?: string;
          /**
           * @description  1企业 2个人 （单选框，默认企业）
           */
          useType: number;
        }
        /**
         * @name 客户表新增
         * @param data
         */
        export function register(data: RegisterParams) {
          return request.post<ResponseResult<object>>(
            "/sys-v100001/register/company/register",
            data
          );
        }
      }
    }
    export namespace dept {
      /**
       * @name 删除
       * @param id 删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/sys-v100001/dept/delete/${id}`
        );
      }
      /**
       * @name 根据上级id查询子部门
       * @param id 根据上级id查询子部门
       */
      export function getByPid(id: string | number) {
        return request.get<ResponseResult<Array<SysDept>>>(
          `/sys-v100001/dept/getByPid/${id}`
        );
      }
      /**
       * @name 查询部门下所有员工
       * @param id 查询部门下所有员工
       */
      export function getUserList(id: string | number) {
        return request.post<ResponseResult<Array<User>>>(
          `/sys-v100001/dept/getUserList/${id}`
        );
      }
      /**
       * @name 获取详情
       * @param id 获取详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<SysDept>>(
          `/sys-v100001/dept/info/${id}`
        );
      }
      /**
       * @name 查询所有部门
       */
      export function list() {
        return request.get("/sys-v100001/dept/list");
      }
      /**
       * @name 查询部门树
       */
      export function listAll() {
        return request.get<ResponseResult<部门输出类>>(
          "/sys-v100001/dept/listAll"
        );
      }
      /**
       * @name listDeptIds
       */
      export function listDeptIds() {
        return request.get<ResponseResult<Array<any>>>(
          "/sys-v100001/dept/listDeptIds"
        );
      }
      /**
       * @name 查询大区
       */
      export function listOne() {
        return request.get("/sys-v100001/dept/listOne");
      }
      /**
       * @name 获取第三级别的部门数据
       */
      export function listV3() {
        return request.get<ResponseResult<Array<SysDept>>>(
          "/sys-v100001/dept/listV3"
        );
      }
      export interface PageParams {
        /**
         * @description  部门等级
         */
        deptLev?: number;
        /**
         * @description  部门名称
         */
        deptName?: string;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
      }
      /**
       * @name 分页查询
       * @param params
       */
      export function page(params: PageParams) {
        return request.get<ResponseResult<PageList<SysDept>>>(
          "/sys-v100001/dept/page",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  公司id
         */
        companyId?: number;
        /**
         * @description  公司名称
         */
        companyName?: string;
        /**
         * @description  创建人id
         */
        createId?: number;
        /**
         * @description  创建人姓名
         */
        createName?: string;
        /**
         * @description  创建时间
         */
        createTime?: string;
        /**
         * @description  部门等级
         */
        deptLev?: number;
        /**
         * @description  部门经理id
         */
        deptManagerId?: number;
        /**
         * @description  部门经理name
         */
        deptManagerName?: string;
        /**
         * @description  部门名称
         */
        deptName?: string;
        /**
         * @description  上级部门id
         */
        supDeptId?: number;
        /**
         * @description  上级部门名称
         */
        supDeptName?: string;
      }
      /**
       * @name 添加
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/dept/save",
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  公司id
         */
        companyId?: number;
        /**
         * @description  公司名称
         */
        companyName?: string;
        /**
         * @description  创建人id
         */
        createId?: number;
        /**
         * @description  创建人姓名
         */
        createName?: string;
        /**
         * @description  创建时间
         */
        createTime?: string;
        /**
         * @description  部门等级
         */
        deptLev?: number;
        /**
         * @description  部门经理id
         */
        deptManagerId?: number;
        /**
         * @description  部门经理name
         */
        deptManagerName?: string;
        /**
         * @description  部门名称
         */
        deptName?: string;
        /**
         * @description  上级部门id
         */
        supDeptId?: number;
        /**
         * @description  上级部门名称
         */
        supDeptName?: string;
      }
      /**
       * @name 修改
       * @param id 修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.post<ResponseResult<object>>(
          `/sys-v100001/dept/update/${id}`,
          data
        );
      }
      export interface UpdatePrincipalParams {
        /**
         * @description  部门经理id
         */
        deptManagerId?: number;
        /**
         * @description  部门id
         */
        id?: number;
      }
      /**
       * @name 更换部门负责人
       * @param data
       */
      export function updatePrincipal(data: UpdatePrincipalParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/dept/updatePrincipal",
          data
        );
      }
    }
    export namespace fileManager {
      /**
       * @name 文件管理删除
       * @param id 文件管理删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/sys-v100001/fileManager/delete/${id}`
        );
      }
      /**
       * @name 文件管理详情
       * @param id 文件管理详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<FileManager>>(
          `/sys-v100001/fileManager/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  文件分类(字典：697)
         */
        classifyId?: number;
        /**
         * @description  创建人姓名
         */
        createName?: string;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  上传文件名称
         */
        fileName?: string;
        /**
         * @description  文件名称备注
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
      }
      /**
       * @name 文件管理列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<FileManager>>>(
          "/sys-v100001/fileManager/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  文件分类(字典：697)
         */
        classifyId: number;
        /**
         * @description  文件名称备注
         */
        name: string;
        /**
         * @description  说明
         */
        remark: string;
      }
      /**
       * @name 文件管理新增
       * @param data
       */
      export function save(data: any) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/fileManager/save",
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );
      }
      export interface UpdateParams {
        /**
         * @description  文件分类(字典：697)
         */
        classifyId: number;
        /**
         * @description  文件名称备注
         */
        name: string;
        /**
         * @description  说明
         */
        remark: string;
      }
      /**
       * @name 文件管理修改
       * @param id 文件管理修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/sys-v100001/fileManager/update/${id}`,
          data
        );
      }
      export interface UpdateFileParams {
        /**
         * @description  主键
         */
        id?: number;
      }
      /**
       * @name 替换文件
       * @param data
       */
      export function updateFile(data: any) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/fileManager/updateFile",
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );
      }
    }
    export namespace homeApp {
      /**
       * @name 首页接口
       */
      export function getStatistical() {
        return request.get<ResponseResult<Array<TopVo>>>(
          "/sys-v100001/homeApp/getStatistical"
        );
      }
    }
    export namespace appLogin {
      export namespace house {
        /**
         * @name 房东退出操作
         */
        export function exitHouse() {
          return request.post<ResponseResult>(
            "/sys-v100001/appLogin/house/exitHouse"
          );
        }
        export interface GetHouseListParams {
          /**
           * @description  账号
           */
          housePhone?: string;
        }
        /**
         * @name 通过手机号获取当前房东拥有的房源数据
         * @param params
         */
        export function getHouseList(params: GetHouseListParams) {
          return request.get<ResponseResult<Array<HouseLoginVo>>>(
            "/sys-v100001/appLogin/house/getHouseList",
            { params }
          );
        }
        export interface LoginParams {
          /**
           * @description  微信code
           */
          code?: string;
          /**
           * @description  Id
           */
          id: number;
          /**
           * @description  密码
           */
          pwd?: string;
        }
        /**
         * @name 房东登陆
         * @param data
         */
        export function login(data: LoginParams) {
          return request.post<ResponseResult<LoginVo>>(
            "/sys-v100001/appLogin/house/login",
            data
          );
        }
        export interface LoginWxParams {
          /**
           * @description  微信code
           */
          code?: string;
          /**
           * @description  客户id,授权登陆后才有，前端缓存起，重新登陆后覆盖掉
           */
          companyId?: number;
        }
        /**
         * @name 房东授权登陆
         * @param data
         */
        export function loginWx(data: LoginWxParams) {
          return request.post<ResponseResult<LoginVo>>(
            "/sys-v100001/appLogin/house/loginWx",
            data
          );
        }
      }
      export namespace tenants {
        /**
         * @name 租客退出操作
         */
        export function exitTenants() {
          return request.post<ResponseResult>(
            "/sys-v100001/appLogin/tenants/exitTenants"
          );
        }
        export interface GetRoomListParams {
          /**
           * @description  1 主用户 2同住人
           */
          isCohabit?: number;
          /**
           * @description  账号
           */
          phone?: string;
        }
        /**
         * @name 当用户输完手机号时调用此接口,此接口返回登陆需要的roomId
         * @param params
         */
        export function getRoomList(params: GetRoomListParams) {
          return request.get<ResponseResult<Array<TenantsHouseLoginVo>>>(
            "/sys-v100001/appLogin/tenants/getRoomList",
            { params }
          );
        }
        export interface LoginParams {
          /**
           * @description  微信code
           */
          code?: string;
          /**
           * @description  1 主用户 2同住人
           */
          isCohabit: number;
          /**
           * @description  手机号
           */
          phone?: string;
          /**
           * @description  密码
           */
          pwd?: string;
          /**
           * @description  房间Id
           */
          roomId?: number;
        }
        /**
         * @name 租客登陆
         * @param data
         */
        export function login(data: LoginParams) {
          return request.post<ResponseResult<LoginVo>>(
            "/sys-v100001/appLogin/tenants/login",
            data
          );
        }
        export interface LoginWxParams {
          /**
           * @description  微信code
           */
          code?: string;
          /**
           * @description  1 主用户 2同住人
           */
          isCohabit?: number;
          /**
           * @description  companyId
           */
          companyId?: number;
        }
        /**
         * @name 租客授权登陆,授权登陆不需要传房间id,因为之前登陆过，所以知道默认是那个房间
         * @param data
         */
        export function loginWx(data: LoginWxParams) {
          return request.post<ResponseResult<LoginVo>>(
            "/sys-v100001/appLogin/tenants/loginWx",
            data
          );
        }
      }
    }
    export namespace LouPan {
      export interface PageParams {
        /**
         * @description  换新区id
         */
        hxAreaId?: number;
        /**
         * @description  换新物业地区名称
         */
        hxDetailName?: string;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
      }
      /**
       * @name 楼盘列表
       * @param params
       */
      export function page(params: PageParams) {
        return request.get<ResponseResult<PageList<HLoupan>>>(
          "/sys-v100001/LouPan/page",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  换新区id
         */
        hxAreaId: number;
        /**
         * @description  换新物业地区名称
         */
        hxDetailName: string;
        /**
         * @description  换新楼盘经度
         */
        hxLat: string;
        /**
         * @description  换新楼盘纬度
         */
        hxLng: string;
      }
      /**
       * @name 添加楼盘
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/LouPan/save",
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  换新区id
         */
        hxAreaId: number;
        /**
         * @description  换新物业地区名称
         */
        hxDetailName: string;
        /**
         * @description  换新楼盘经度
         */
        hxLat: string;
        /**
         * @description  换新楼盘纬度
         */
        hxLng: string;
      }
      /**
       * @name 修改楼盘
       * @param id 修改楼盘
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.post<ResponseResult<object>>(
          `/sys-v100001/LouPan/update/${id}`,
          data
        );
      }
      export interface UpdateDetailParams {
        /**
         * @description  新物业地址
         */
        newDetailId?: string;
        /**
         * @description  旧物业地址
         */
        oldDetailId?: string;
      }
      /**
       * @name 修改所有数据的物业id和名称
       * @param params
       */
      export function updateDetail(params: UpdateDetailParams) {
        return request.get<ResponseResult<object>>(
          "/sys-v100001/LouPan/updateDetail",
          { params }
        );
      }
    }
    export namespace menu {
      export interface DeleteByIdParams {
        /**
         * @description  主键
         */
        id?: number;
      }
      /**
       * @name 菜单表删除
       * @param params
       */
      export function deleteById(params: DeleteByIdParams) {
        return request.delete<ResponseResult<object>>(
          "/sys-v100001/menu/deleteById",
          { params }
        );
      }
      /**
       * @name 缓存所有菜单
       */
      export function getCacheMenuList() {
        return request.get<ResponseResult<object>>(
          "/sys-v100001/menu/getCacheMenuList"
        );
      }
      export interface GetMenuListParams {
        /**
         * @description  1表示saas后台菜单，2客户的菜单
         */
        isType?: number;
        /**
         * @description  接口名称
         */
        name?: string;
        /**
         * @description  父菜单主键
         */
        pid?: number;
      }
      /**
       * @name 返回所有菜单，无层级
       * @param params
       */
      export function getMenuList(params: GetMenuListParams) {
        return request.get<ResponseResult<Array<Menu>>>(
          "/sys-v100001/menu/getMenuList",
          { params }
        );
      }
      export interface GetUserMenuListParams {
        /**
         * @description  1表示saas后台菜单，2客户的菜单
         */
        isType?: number;
        /**
         * @description  接口名称
         */
        name?: string;
        /**
         * @description  父菜单主键
         */
        pid?: number;
      }
      /**
       * @name 返回所有菜单，无层级
       * @param params
       */
      export function getUserMenuList(params: GetUserMenuListParams) {
        return request.get<ResponseResult<Array<Menu>>>(
          "/sys-v100001/menu/getUserMenuList",
          { params }
        );
      }
      /**
       * @name 菜单表详情
       * @param id 菜单表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<Menu>>(
          `/sys-v100001/menu/info/${id}`
        );
      }
      export interface SaveParams {
        /**
         * @description  前端权限URL
         */
        beforeUrl?: string;
        /**
         * @description  图标地址URL
         */
        imgUrl?: string;
        /**
         * @description  是否前端展示1，展示 2，不展示
         */
        isBeforeShow: number;
        /**
         * @description  1表示saas后台菜单，2客户的菜单 ，如果是saas 后台就直接写死传1
         */
        isType: number;
        /**
         * @description  1查看权限，2删除，3修改，4新增
         */
        menuType: number;
        /**
         * @description  菜单地址
         */
        menuUrl?: string;
        /**
         * @description  接口名称
         */
        name?: string;
        /**
         * @description  父菜单主键
         */
        pid: number;
        /**
         * @description  排序字段
         */
        sort: number;
        /**
         * @description  1目录，2菜单
         */
        type: number;
      }
      /**
       * @name 菜单表新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/menu/save",
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  前端权限URL
         */
        beforeUrl?: string;
        /**
         * @description  图标地址URL
         */
        imgUrl?: string;
        /**
         * @description  是否前端展示1，展示 2，不展示
         */
        isBeforeShow: number;
        /**
         * @description  1表示saas后台菜单，2客户的菜单 ，如果是saas 后台就直接写死传1
         */
        isType: number;
        /**
         * @description  1查看权限，2删除，3修改，4新增
         */
        menuType: number;
        /**
         * @description  菜单地址
         */
        menuUrl?: string;
        /**
         * @description  接口名称
         */
        name?: string;
        /**
         * @description  父菜单主键
         */
        pid: number;
        /**
         * @description  排序字段
         */
        sort: number;
        /**
         * @description  1目录，2菜单
         */
        type: number;
      }
      /**
       * @name 菜单表修改
       * @param id 菜单表修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/sys-v100001/menu/update/${id}`,
          data
        );
      }
    }
    export namespace miniProgramConfig {
      export interface GetMenuSetParams {
        /**
         * @description  配置类型ID  必须传
         */
        configTypeId?: number;
        /**
         * @description  房屋类型 1-整租 2-合租 3-集中 必须传
         */
        houseType?: number;
        /**
         * @description  关联类型 1-房东 2-租客
         */
        relationType?: number;
      }
      /**
       * @name 小程序菜单设置隐藏菜单
       * @param params
       */
      export function getMenuSet(params: GetMenuSetParams) {
        return request.get<ResponseResult<Array<MiniMenuSet>>>(
          "/sys-v100001/miniProgramConfig/getMenuSet",
          { params }
        );
      }
      export interface HouseMenuSetParams {
        /**
         * @description  主键
         */
        id?: number;
        miniMenuSetList: Array<{
          id: number;
          name: string;
        }>;
      }
      /**
       * @name 房东小程序菜单设置:pid:713
       * @param data
       */
      export function houseMenuSet(data: HouseMenuSetParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/miniProgramConfig/houseMenuSet",
          data
        );
      }
      export interface OutRepairUserCleanParams {
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  用户id多个以逗号隔开
         */
        userIds?: string;
      }
      /**
       * @name 外部保洁提醒人:pid:739
       * @param data
       */
      export function outRepairUserClean(data: OutRepairUserCleanParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/miniProgramConfig/outRepairUserClean",
          data
        );
      }
      export interface OutRepairUserMaintenanceParams {
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  用户id多个以逗号隔开
         */
        userIds?: string;
      }
      /**
       * @name 外部维修提醒人:pid:740
       * @param data
       */
      export function outRepairUserMaintenance(
        data: OutRepairUserMaintenanceParams
      ) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/miniProgramConfig/outRepairUserMaintenance",
          data
        );
      }
      export interface TenantsMenuSetParams {
        /**
         * @description  主键
         */
        id?: number;
        miniMenuSetList: Array<{
          id: number;
          name: string;
        }>;
      }
      /**
       * @name 租客小程序菜单设置：pid 702
       * @param data
       */
      export function tenantsMenuSet(data: TenantsMenuSetParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/miniProgramConfig/tenantsMenuSet",
          data
        );
      }
    }
    export namespace PzCommission {
      export interface GetByHouseIdParams {
        /**
         * @description  房源编号
         */
        houseId?: number;
        /**
         * @description  1,为房东，2为租客
         */
        type?: number;
      }
      /**
       * @name 普租佣金获取
       * @param params
       */
      export function getByHouseId(params: GetByHouseIdParams) {
        return request.get<ResponseResult<object>>(
          "/sys-v100001/PzCommission/getByHouseId",
          { params }
        );
      }
    }
    export namespace PZContract {
      /**
       * @name 撤销合同
       * @param id 撤销合同
       */
      export function cancel(id: string | number) {
        return request.post<ResponseResult<object>>(
          `/sys-v100001/PZContract/cancel/${id}`
        );
      }
      export interface DownloadParams {
        /**
         * @description  id
         */
        id?: number;
      }
      /**
       * @name 下载合同
       * @param params
       */
      export function download(params: DownloadParams) {
        return request.get("/sys-v100001/PZContract/download", { params });
      }
      export interface GenQrcodeParams {
        /**
         * @description  1，为甲方；2，为乙方
         */
        type?: number;
      }
      /**
       * @name 生成二维码
       * @param id 生成二维码
       * @param params
       */
      export function genQrcode(id: string | number, params: GenQrcodeParams) {
        return request.get(`/sys-v100001/PZContract/genQrcode/${id}`, {
          params
        });
      }
      /**
       * @name 预览合同
       * @param id 预览合同
       */
      export function preview(id: string | number) {
        return request.get<ResponseResult<object>>(
          `/sys-v100001/PZContract/preview/${id}`
        );
      }
      export interface SaveContractParams {
        /**
         * @description  房源ID
         */
        pzHouseId?: number;
        /**
         * @description  合同类型 1-电子 2-纸质
         */
        type?: number;
      }
      /**
       * @name 生成电子合同
       * @param data
       */
      export function saveContract(data: SaveContractParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/PZContract/saveContract",
          data
        );
      }
      export interface SaveContractV1Params {
        /**
         * @description  房源ID
         */
        pzHouseId?: number;
        /**
         * @description  合同类型 1-电子 2-纸质
         */
        type?: number;
      }
      /**
       * @name 生成纸质合同
       * @param data
       */
      export function saveContractV1(data: SaveContractV1Params) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/PZContract/saveContractV1",
          data
        );
      }
      export interface SendEmailParams {
        /**
         * @description  邮箱
         */
        email?: string;
        /**
         * @description  1，为甲方；2，为乙方
         */
        type?: number;
      }
      /**
       * @name 发送邮件
       * @param id 发送邮件
       * @param data
       */
      export function sendEmail(id: string | number, data: SendEmailParams) {
        return request.post<ResponseResult<object>>(
          `/sys-v100001/PZContract/sendEmail/${id}`,
          data
        );
      }
      export interface SendSmsParams {
        /**
         * @description  1，为甲方；2，为乙方
         */
        type?: number;
      }
      /**
       * @name 发送合同短信
       * @param id 发送合同短信
       * @param data
       */
      export function sendSms(id: string | number, data: SendSmsParams) {
        return request.post<ResponseResult<object>>(
          `/sys-v100001/PZContract/sendSms/${id}`,
          data
        );
      }
    }
    export namespace PzDeliveryOrder {
      /**
       * @name 删除交割单
       * @param id 删除交割单
       */
      export function deleteById(id: string | number) {
        return request.post<ResponseResult<object>>(
          `/sys-v100001/PzDeliveryOrder/delete/${id}`
        );
      }
      export interface PageParams {
        /**
         * @description  houseId
         */
        houseId?: number;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
      }
      /**
       * @name 交割单列表
       * @param params
       */
      export function page(params: PageParams) {
        return request.get<ResponseResult<PageList<PzDeliveryOrder>>>(
          "/sys-v100001/PzDeliveryOrder/page",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  冰箱件数
         */
        bxJs?: string;
        /**
         * @description  冰箱品牌
         */
        bxPp?: string;
        /**
         * @description  冰箱状态
         */
        bxZt?: string;
        /**
         * @description  茶几件数
         */
        cjJs?: string;
        /**
         * @description  茶几品牌
         */
        cjPp?: string;
        /**
         * @description  茶几状态
         */
        cjZt?: string;
        /**
         * @description  窗帘件数
         */
        clJs?: string;
        /**
         * @description  窗帘品牌
         */
        clPp?: string;
        /**
         * @description  窗帘状态
         */
        clZt?: string;
        /**
         * @description  餐桌件数
         */
        czJs?: string;
        /**
         * @description  餐桌品牌
         */
        czPp?: string;
        /**
         * @description  餐桌状态
         */
        czZt?: string;
        /**
         * @description  电表备注
         */
        dbBz?: string;
        /**
         * @description  电表数字
         */
        dbSz?: string;
        /**
         * @description  电表状态
         */
        dbZt?: string;
        /**
         * @description  电话件数
         */
        dhJs?: string;
        /**
         * @description  电话品牌
         */
        dhPp?: string;
        /**
         * @description  电话状态
         */
        dhZt?: string;
        /**
         * @description  单人床件数
         */
        drcJs?: string;
        /**
         * @description  单人床品牌
         */
        drcPp?: string;
        /**
         * @description  单人床状态
         */
        drcZt?: string;
        /**
         * @description  电视件数
         */
        dsJs?: string;
        /**
         * @description  电视品牌
         */
        dsPp?: string;
        /**
         * @description  电视状态
         */
        dsZt?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  机顶盒件数
         */
        jdhJs?: string;
        /**
         * @description  机顶盒品牌
         */
        jdhPp?: string;
        /**
         * @description  机顶盒状态
         */
        jdhZt?: string;
        /**
         * @description  空调件数
         */
        ktJs?: string;
        /**
         * @description  空调品牌
         */
        ktPp?: string;
        /**
         * @description  空调状态
         */
        ktZt?: string;
        /**
         * @description  其他1
         */
        qtOne?: string;
        /**
         * @description  其他3
         */
        qtThr?: string;
        /**
         * @description  其他2
         */
        qtTwo?: string;
        /**
         * @description  燃气表备注
         */
        rqbBz?: string;
        /**
         * @description  燃气表数字
         */
        rqbSz?: string;
        /**
         * @description  燃气表状态
         */
        rqbZt?: string;
        /**
         * @description  燃气灶件数
         */
        rqzJs?: string;
        /**
         * @description  燃气灶品牌
         */
        rqzPp?: string;
        /**
         * @description  燃气灶状态
         */
        rqzZt?: string;
        /**
         * @description  热水器件数
         */
        rsqJs?: string;
        /**
         * @description  热水器品牌
         */
        rsqPp?: string;
        /**
         * @description  热水器状态
         */
        rsqZt?: string;
        /**
         * @description  水表备注
         */
        sbBz?: string;
        /**
         * @description  水表数字
         */
        sbSz?: string;
        /**
         * @description  水表状态
         */
        sbZt?: string;
        /**
         * @description  沙发件数
         */
        sfJs?: string;
        /**
         * @description  沙发品牌
         */
        sfPp?: string;
        /**
         * @description  沙发状态
         */
        sfZt?: string;
        /**
         * @description  双人床件数
         */
        srcJs?: string;
        /**
         * @description  双人床品牌
         */
        srcPp?: string;
        /**
         * @description  双人床状态
         */
        srcZt?: string;
        /**
         * @description  物管费备注
         */
        wgfBz?: string;
        /**
         * @description  物管费数字
         */
        wgfSz?: string;
        /**
         * @description  物管费状态
         */
        wgfZt?: string;
        /**
         * @description  物业地址
         */
        wydz?: string;
        /**
         * @description  洗衣机件数
         */
        xyjJs?: string;
        /**
         * @description  洗衣机品牌
         */
        xyjPp?: string;
        /**
         * @description  洗衣机状态
         */
        xyjZt?: string;
        /**
         * @description  写字台件数
         */
        xztJs?: string;
        /**
         * @description  写字台品牌
         */
        xztPp?: string;
        /**
         * @description  写字台状态
         */
        xztZt?: string;
        /**
         * @description  钥匙及水电气业主卡等
         */
        ysQtk?: string;
        /**
         * @description  饮水机件数
         */
        ysjJs?: string;
        /**
         * @description  饮水机品牌
         */
        ysjPp?: string;
        /**
         * @description  饮水机状态
         */
        ysjZt?: string;
        /**
         * @description  音响件数
         */
        yxJs?: string;
        /**
         * @description  音响品牌
         */
        yxPp?: string;
        /**
         * @description  音响状态
         */
        yxZt?: string;
        /**
         * @description  油烟机件数
         */
        yyjJs?: string;
        /**
         * @description  油烟机品牌
         */
        yyjPp?: string;
        /**
         * @description  油烟机状态
         */
        yyjZt?: string;
        /**
         * @description  椅子件数
         */
        yzJs?: string;
        /**
         * @description  椅子品牌
         */
        yzPp?: string;
        /**
         * @description  椅子状态
         */
        yzZt?: string;
      }
      /**
       * @name 添加交割单
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/PzDeliveryOrder/save",
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  冰箱件数
         */
        bxJs?: string;
        /**
         * @description  冰箱品牌
         */
        bxPp?: string;
        /**
         * @description  冰箱状态
         */
        bxZt?: string;
        /**
         * @description  茶几件数
         */
        cjJs?: string;
        /**
         * @description  茶几品牌
         */
        cjPp?: string;
        /**
         * @description  茶几状态
         */
        cjZt?: string;
        /**
         * @description  窗帘件数
         */
        clJs?: string;
        /**
         * @description  窗帘品牌
         */
        clPp?: string;
        /**
         * @description  窗帘状态
         */
        clZt?: string;
        /**
         * @description  餐桌件数
         */
        czJs?: string;
        /**
         * @description  餐桌品牌
         */
        czPp?: string;
        /**
         * @description  餐桌状态
         */
        czZt?: string;
        /**
         * @description  电表备注
         */
        dbBz?: string;
        /**
         * @description  电表数字
         */
        dbSz?: string;
        /**
         * @description  电表状态
         */
        dbZt?: string;
        /**
         * @description  电话件数
         */
        dhJs?: string;
        /**
         * @description  电话品牌
         */
        dhPp?: string;
        /**
         * @description  电话状态
         */
        dhZt?: string;
        /**
         * @description  单人床件数
         */
        drcJs?: string;
        /**
         * @description  单人床品牌
         */
        drcPp?: string;
        /**
         * @description  单人床状态
         */
        drcZt?: string;
        /**
         * @description  电视件数
         */
        dsJs?: string;
        /**
         * @description  电视品牌
         */
        dsPp?: string;
        /**
         * @description  电视状态
         */
        dsZt?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  机顶盒件数
         */
        jdhJs?: string;
        /**
         * @description  机顶盒品牌
         */
        jdhPp?: string;
        /**
         * @description  机顶盒状态
         */
        jdhZt?: string;
        /**
         * @description  空调件数
         */
        ktJs?: string;
        /**
         * @description  空调品牌
         */
        ktPp?: string;
        /**
         * @description  空调状态
         */
        ktZt?: string;
        /**
         * @description  其他1
         */
        qtOne?: string;
        /**
         * @description  其他3
         */
        qtThr?: string;
        /**
         * @description  其他2
         */
        qtTwo?: string;
        /**
         * @description  燃气表备注
         */
        rqbBz?: string;
        /**
         * @description  燃气表数字
         */
        rqbSz?: string;
        /**
         * @description  燃气表状态
         */
        rqbZt?: string;
        /**
         * @description  燃气灶件数
         */
        rqzJs?: string;
        /**
         * @description  燃气灶品牌
         */
        rqzPp?: string;
        /**
         * @description  燃气灶状态
         */
        rqzZt?: string;
        /**
         * @description  热水器件数
         */
        rsqJs?: string;
        /**
         * @description  热水器品牌
         */
        rsqPp?: string;
        /**
         * @description  热水器状态
         */
        rsqZt?: string;
        /**
         * @description  水表备注
         */
        sbBz?: string;
        /**
         * @description  水表数字
         */
        sbSz?: string;
        /**
         * @description  水表状态
         */
        sbZt?: string;
        /**
         * @description  沙发件数
         */
        sfJs?: string;
        /**
         * @description  沙发品牌
         */
        sfPp?: string;
        /**
         * @description  沙发状态
         */
        sfZt?: string;
        /**
         * @description  双人床件数
         */
        srcJs?: string;
        /**
         * @description  双人床品牌
         */
        srcPp?: string;
        /**
         * @description  双人床状态
         */
        srcZt?: string;
        /**
         * @description  物管费备注
         */
        wgfBz?: string;
        /**
         * @description  物管费数字
         */
        wgfSz?: string;
        /**
         * @description  物管费状态
         */
        wgfZt?: string;
        /**
         * @description  物业地址
         */
        wydz?: string;
        /**
         * @description  洗衣机件数
         */
        xyjJs?: string;
        /**
         * @description  洗衣机品牌
         */
        xyjPp?: string;
        /**
         * @description  洗衣机状态
         */
        xyjZt?: string;
        /**
         * @description  写字台件数
         */
        xztJs?: string;
        /**
         * @description  写字台品牌
         */
        xztPp?: string;
        /**
         * @description  写字台状态
         */
        xztZt?: string;
        /**
         * @description  钥匙及水电气业主卡等
         */
        ysQtk?: string;
        /**
         * @description  饮水机件数
         */
        ysjJs?: string;
        /**
         * @description  饮水机品牌
         */
        ysjPp?: string;
        /**
         * @description  饮水机状态
         */
        ysjZt?: string;
        /**
         * @description  音响件数
         */
        yxJs?: string;
        /**
         * @description  音响品牌
         */
        yxPp?: string;
        /**
         * @description  音响状态
         */
        yxZt?: string;
        /**
         * @description  油烟机件数
         */
        yyjJs?: string;
        /**
         * @description  油烟机品牌
         */
        yyjPp?: string;
        /**
         * @description  油烟机状态
         */
        yyjZt?: string;
        /**
         * @description  椅子件数
         */
        yzJs?: string;
        /**
         * @description  椅子品牌
         */
        yzPp?: string;
        /**
         * @description  椅子状态
         */
        yzZt?: string;
      }
      /**
       * @name 修改交割单
       * @param id 修改交割单
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.post<ResponseResult<object>>(
          `/sys-v100001/PzDeliveryOrder/update/${id}`,
          data
        );
      }
    }
    export namespace PZHouse {
      /**
       * @name 详情页面
       * @param id 详情页面
       */
      export function get(id: string | number) {
        return request.get<ResponseResult<PzHouse>>(
          `/sys-v100001/PZHouse/get/${id}`
        );
      }
      export interface GetdjlHouseParams {
        /**
         * @description  端口号
         */
        duankou?: string;
        /**
         * @description  房源编号
         */
        houseNo?: string;
      }
      /**
       * @name 获取到家了房源信息
       * @param data
       */
      export function getdjlHouse(data: GetdjlHouseParams) {
        return request.post<ResponseResult<HouseHxDtoOut>>(
          "/sys-v100001/PZHouse/getdjlHouse",
          data
        );
      }
      export interface PageParams {
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
      }
      /**
       * @name 列表页面
       * @param params
       */
      export function page(params: PageParams) {
        return request.get<ResponseResult<PageList<PzHouse>>>(
          "/sys-v100001/PZHouse/page",
          { params }
        );
      }
      export interface SaveHouseParams {
        /**
         * @description  业主联系地址
         */
        address?: string;
        /**
         * @description  代理人身份证号
         */
        agentIdCard?: string;
        /**
         * @description  代理人姓名
         */
        agentName?: string;
        /**
         * @description  区域id
         */
        areaId?: number;
        /**
         * @description  区域
         */
        areaName?: string;
        /**
         * @description  补充约定
         */
        bcyd?: string;
        /**
         * @description  开始时间-日
         */
        beginDay?: string;
        /**
         * @description  开始时间-月
         */
        beginMonth?: string;
        /**
         * @description  开始时间-年
         */
        beginYear?: string;
        /**
         * @description  单元id
         */
        blockCellId?: number;
        /**
         * @description  建筑面积
         */
        buildArea?: number;
        /**
         * @description  楼盘栋座id
         */
        buildBlockId?: number;
        /**
         * @description  栋座
         */
        buildBolck?: string;
        /**
         * @description  楼盘id
         */
        buildId?: number;
        /**
         * @description  楼盘名称
         */
        buildName?: string;
        /**
         * @description  单元
         */
        buildUnit?: string;
        /**
         * @description  证件类型字典(601)
         */
        certificateName?: string;
        /**
         * @description  证件类型字典(601)
         */
        certificateTypeId?: number;
        /**
         * @description  房东佣金
         */
        commission?: number;
        /**
         * @description  门牌
         */
        door?: string;
        /**
         * @description  结束时间-日
         */
        endDay?: string;
        /**
         * @description  结束时间-月
         */
        endMonth?: string;
        /**
         * @description  结束时间-年
         */
        endYear?: string;
        /**
         * @description  楼层
         */
        floor?: string;
        /**
         * @description  房屋地址
         */
        houseAddress?: string;
        /**
         * @description  房产证号码
         */
        houseCardNo?: string;
        /**
         * @description  到家了房源id
         */
        houseId?: number;
        /**
         * @description  到家了房源编号
         */
        houseNo?: string;
        /**
         * @description  房号id
         */
        houseNumberId?: number;
        /**
         * @description  用途：1，住宅；2，办公；3商用
         */
        housePurpose?: number;
        /**
         * @description  装修 (0:毛坯;1:清水;2:简装;3:中装;4:精装;5豪装;)
         */
        houseTrim?: number;
        /**
         * @description  //装修Str (0:毛坯;1:清水;2:简装;3:中装;4:精装;5豪装;)
         */
        houseTrimStr?: string;
        /**
         * @description  身份证号
         */
        idCard?: string;
        /**
         * @description  业主名称
         */
        name?: string;
        /**
         * @description  租金支付方式:1,月；2，季度；3，半年；4，年；5，一次性
         */
        payType?: string;
        /**
         * @description  业主电话
         */
        phone?: string;
        /**
         * @description  凭租期限
         */
        pzQx?: string;
        /**
         * @description  房号
         */
        roomNo?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店名称
         */
        storeName?: string;
        /**
         * @description  阳台
         */
        totalBalcony?: number;
        /**
         * @description  厅
         */
        totalParlour?: number;
        /**
         * @description  房
         */
        totalRoom?: number;
        /**
         * @description  卫
         */
        totalToilet?: number;
        /**
         * @description  提前通知天数
         */
        tqDay?: string;
        /**
         * @description  违约比例
         */
        wyBl?: string;
        /**
         * @description  押金小写
         */
        yjXx?: string;
        /**
         * @description  逾期支付比例
         */
        yqBl?: string;
        /**
         * @description  逾期最大天数
         */
        yqTs?: string;
        /**
         * @description  租金小写
         */
        zjXx?: string;
        /**
         * @description  租金到期提前支付天数
         */
        zjdqDay?: string;
      }
      /**
       * @name 导入房源信息
       * @param data
       */
      export function saveHouse(data: SaveHouseParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/PZHouse/saveHouse",
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  业主联系地址
         */
        address?: string;
        /**
         * @description  代理人身份证号
         */
        agentIdCard?: string;
        /**
         * @description  代理人姓名
         */
        agentName?: string;
        /**
         * @description  区域id
         */
        areaId?: number;
        /**
         * @description  区域
         */
        areaName?: string;
        /**
         * @description  补充约定
         */
        bcyd?: string;
        /**
         * @description  开始时间-日
         */
        beginDay?: string;
        /**
         * @description  开始时间-月
         */
        beginMonth?: string;
        /**
         * @description  开始时间-年
         */
        beginYear?: string;
        /**
         * @description  单元id
         */
        blockCellId?: number;
        /**
         * @description  建筑面积
         */
        buildArea?: number;
        /**
         * @description  楼盘栋座id
         */
        buildBlockId?: number;
        /**
         * @description  栋座
         */
        buildBolck?: string;
        /**
         * @description  楼盘id
         */
        buildId?: number;
        /**
         * @description  楼盘名称
         */
        buildName?: string;
        /**
         * @description  单元
         */
        buildUnit?: string;
        /**
         * @description  证件类型字典(601)
         */
        certificateName?: string;
        /**
         * @description  证件类型字典(601)
         */
        certificateTypeId?: number;
        /**
         * @description  房东佣金
         */
        commission?: number;
        /**
         * @description  门牌
         */
        door?: string;
        /**
         * @description  结束时间-日
         */
        endDay?: string;
        /**
         * @description  结束时间-月
         */
        endMonth?: string;
        /**
         * @description  结束时间-年
         */
        endYear?: string;
        /**
         * @description  楼层
         */
        floor?: string;
        /**
         * @description  房屋地址
         */
        houseAddress?: string;
        /**
         * @description  房产证号码
         */
        houseCardNo?: string;
        /**
         * @description  房号id
         */
        houseNumberId?: number;
        /**
         * @description  用途：1，住宅；2，办公；3商用
         */
        housePurpose?: number;
        /**
         * @description  装修 (0:毛坯;1:清水;2:简装;3:中装;4:精装;5豪装;)
         */
        houseTrim?: number;
        /**
         * @description  //装修Str (0:毛坯;1:清水;2:简装;3:中装;4:精装;5豪装;)
         */
        houseTrimStr?: string;
        /**
         * @description  身份证号
         */
        idCard?: string;
        /**
         * @description  业主名称
         */
        name?: string;
        /**
         * @description  租金支付方式:1,月；2，季度；3，半年；4，年；5，一次性
         */
        payType?: string;
        /**
         * @description  业主电话
         */
        phone?: string;
        /**
         * @description  凭证期限
         */
        pzQx?: string;
        /**
         * @description  房号
         */
        roomNo?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店名称
         */
        storeName?: string;
        /**
         * @description  阳台
         */
        totalBalcony?: number;
        /**
         * @description  厅
         */
        totalParlour?: number;
        /**
         * @description  房
         */
        totalRoom?: number;
        /**
         * @description  卫
         */
        totalToilet?: number;
        /**
         * @description  提前通知天数
         */
        tqDay?: string;
        /**
         * @description  违约比例
         */
        wyBl?: string;
        /**
         * @description  押金大写
         */
        yjDx?: string;
        /**
         * @description  押金小写
         */
        yjXx?: string;
        /**
         * @description  逾期最大天数
         */
        yqTs?: string;
        /**
         * @description  租金大写
         */
        zjDx?: string;
        /**
         * @description  租金小写
         */
        zjXx?: string;
        /**
         * @description  租金到期提前支付天数
         */
        zjdqDay?: string;
      }
      /**
       * @name 普租房源修改
       * @param id 普租房源修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.post<ResponseResult<object>>(
          `/sys-v100001/PZHouse/update/${id}`,
          data
        );
      }
    }
    export namespace PZTenants {
      /**
       * @name 普租客户详情接口
       * @param id 普租客户详情接口
       */
      export function get(id: string | number) {
        return request.get<ResponseResult<PzTenants>>(
          `/sys-v100001/PZTenants/get/${id}`
        );
      }
      export interface SaveTenantsParams {
        TIdCard?: string;
        /**
         * @description  联系地址
         */
        address?: string;
        /**
         * @description  代理人证件号
         */
        agentIdCard?: string;
        /**
         * @description  代理人姓名
         */
        agentName?: string;
        /**
         * @description  证件类型字典
         */
        certificateName?: string;
        /**
         * @description  证件类型字典
         */
        certificateTypeId?: number;
        /**
         * @description  渠道来源id
         */
        channelId?: number;
        /**
         * @description  渠道来源名称
         */
        channelName?: string;
        /**
         * @description  租客佣金
         */
        commission?: number;
        /**
         * @description  普租房源id
         */
        houseId?: number;
        /**
         * @description  入住性质id
         */
        inNatureId?: number;
        /**
         * @description  入住性质名称
         */
        inNatureName?: string;
        /**
         * @description  租客名字
         */
        name?: string;
        /**
         * @description  租客电话
         */
        phone?: string;
      }
      /**
       * @name 普租客户添加接口
       * @param data
       */
      export function saveTenants(data: SaveTenantsParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/PZTenants/saveTenants",
          data
        );
      }
      export interface UpdateParams {
        TIdCard?: string;
        /**
         * @description  联系地址
         */
        address?: string;
        /**
         * @description  代理人证件号
         */
        agentIdCard?: string;
        /**
         * @description  代理人姓名
         */
        agentName?: string;
        /**
         * @description  证件类型字典
         */
        certificateName?: string;
        /**
         * @description  证件类型字典
         */
        certificateTypeId?: number;
        /**
         * @description  渠道来源id
         */
        channelId?: number;
        /**
         * @description  渠道来源名称
         */
        channelName?: string;
        /**
         * @description  租客佣金
         */
        commission?: number;
        /**
         * @description  普租房源id
         */
        houseId?: number;
        /**
         * @description  入住性质id
         */
        inNatureId?: number;
        /**
         * @description  入住性质名称
         */
        inNatureName?: string;
        /**
         * @description  租客名字
         */
        name?: string;
        /**
         * @description  租客电话
         */
        phone?: string;
      }
      /**
       * @name 普租客户修改接口
       * @param id 普租客户修改接口
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.post<ResponseResult<object>>(
          `/sys-v100001/PZTenants/update/${id}`,
          data
        );
      }
    }
    export namespace role {
      export interface DeleteByIdParams {
        /**
         * @description  主键
         */
        id?: number;
      }
      /**
       * @name 角色表删除
       * @param params
       */
      export function deleteById(params: DeleteByIdParams) {
        return request.delete<ResponseResult<object>>(
          "/sys-v100001/role/deleteById",
          { params }
        );
      }
      /**
       * @name 角色表详情
       * @param id 角色表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<Role>>(
          `/sys-v100001/role/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  1后台角色 2客户角色
         */
        isType?: number;
        /**
         * @description  角色名称
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
      }
      /**
       * @name 角色表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<Role>>>(
          "/sys-v100001/role/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  1表示saas后台角色，2客户的角色
         */
        isType: number;
        /**
         * @description  角色拥有的菜单id(通过逗号分隔)
         */
        menuIds?: string;
        /**
         * @description  角色名称
         */
        name?: string;
        /**
         * @description  角色描述
         */
        remarks?: string;
      }
      /**
       * @name 角色表新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/role/save",
          data
        );
      }
      export interface SelectParams {
        /**
         * @description  isType
         */
        isType?: number;
      }
      /**
       * @name 角色下拉框
       * @param params
       */
      export function select(params: SelectParams) {
        return request.get<ResponseResult<Array<SelectVo>>>(
          "/sys-v100001/role/select",
          { params }
        );
      }
      export interface UpdateParams {
        /**
         * @description  1表示saas后台角色，2客户的角色
         */
        isType: number;
        /**
         * @description  角色拥有的菜单id(通过逗号分隔)
         */
        menuIds?: string;
        /**
         * @description  角色名称
         */
        name?: string;
        /**
         * @description  角色描述
         */
        remarks?: string;
      }
      /**
       * @name 角色表修改
       * @param id 角色表修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/sys-v100001/role/update/${id}`,
          data
        );
      }
    }
    export namespace saasUser {
      export interface DeleteByIdParams {
        /**
         * @description  主键
         */
        id?: number;
      }
      /**
       * @name saas后用户表删除
       * @param params
       */
      export function deleteById(params: DeleteByIdParams) {
        return request.delete<ResponseResult<object>>(
          "/sys-v100001/saasUser/deleteById",
          { params }
        );
      }
      /**
       * @name saas后用户表详情
       * @param id saas后用户表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<SaasUser>>(
          `/sys-v100001/saasUser/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  1 超管理员  2普通用户
         */
        isAdmin?: number;
        /**
         * @description  姓名
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  登录账号
         */
        phone?: number;
        /**
         * @description  角色主键
         */
        roleId?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  状态 1可用，2禁用
         */
        status?: number;
      }
      /**
       * @name saas后用户表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<SaasUser>>>(
          "/sys-v100001/saasUser/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  姓名
         */
        name?: string;
        /**
         * @description  登录账号
         */
        phone: number;
        /**
         * @description  登录密码,修改时不填
         */
        pwd?: string;
        /**
         * @description  角色主键
         */
        roleId: number;
      }
      /**
       * @name saas后用户表新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/saasUser/save",
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  姓名
         */
        name?: string;
        /**
         * @description  登录账号
         */
        phone: number;
        /**
         * @description  登录密码,修改时不填
         */
        pwd?: string;
        /**
         * @description  角色主键
         */
        roleId: number;
      }
      /**
       * @name saas后用户表修改
       * @param id saas后用户表修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/sys-v100001/saasUser/update/${id}`,
          data
        );
      }
      export interface UpdatePwdParams {
        /**
         * @description  新密码密码
         */
        newPwd?: string;
      }
      /**
       * @name 管理员修改用户密码
       * @param data
       */
      export function updatePwd(data: UpdatePwdParams) {
        return request.post<ResponseResult>(
          "/sys-v100001/saasUser/updatePwd",
          data
        );
      }
      export interface UpdateUserPwdParams {
        /**
         * @description  新密码密码
         */
        newPwd?: string;
        /**
         * @description  原密码
         */
        oldPwd?: string;
      }
      /**
       * @name 用户自己修改密码
       * @param data
       */
      export function updateUserPwd(data: UpdateUserPwdParams) {
        return request.post<ResponseResult>(
          "/sys-v100001/saasUser/updateUserPwd",
          data
        );
      }
      /**
       * @name PC端退出操作
       */
      export function exitPc() {
        return request.post<ResponseResult>("/sys-v100001/saasUser/exitPc");
      }
      export interface LoginParams {
        /**
         * @description  账号
         */
        phone?: string;
        /**
         * @description  密码
         */
        pwd?: string;
      }
      /**
       * @name saal后用户登陆
       * @param data
       */
      export function login(data: LoginParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/saasUser/login",
          data
        );
      }
    }
    export namespace stewardManager {
      /**
       * @name 换新管家提层表删除
       * @param id 换新管家提层表删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/sys-v100001/stewardManager/delete/${id}`
        );
      }
      export interface ExportStewardManagerParams {
        /**
         * @description  合同性质
         */
        contractId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  报表录入时间查询
         */
        reportTime?: string;
        /**
         * @description  托管开始时间结束
         */
        startTimeEnd?: string;
        /**
         * @description  托管开始时间开始
         */
        startTimeStart?: string;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  管家id
         */
        userId?: number;
        /**
         * @description  按年查询管理费
         */
        yearTime?: number;
      }
      /**
       * @name 换新管家提层表导出
       * @param params
       */
      export function exportStewardManager(params: ExportStewardManagerParams) {
        return request.get("/sys-v100001/stewardManager/exportStewardManager", {
          params
        });
      }
      /**
       * @name 换新管家提层表详情
       * @param id 换新管家提层表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<StewardManager>>(
          `/sys-v100001/stewardManager/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  合同性质
         */
        contractId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  报表录入时间查询
         */
        reportTime?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  托管开始时间结束
         */
        startTimeEnd?: string;
        /**
         * @description  托管开始时间开始
         */
        startTimeStart?: string;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  管家id
         */
        userId?: number;
        /**
         * @description  按年查询管理费
         */
        yearTime?: number;
      }
      /**
       * @name 换新管家提层表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<StewardManager>>>(
          "/sys-v100001/stewardManager/list",
          { params }
        );
      }
      export interface MyUserSalaryListParams {
        /**
         * @description  合同性质
         */
        contractId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  报表录入时间查询
         */
        reportTime?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  托管开始时间结束
         */
        startTimeEnd?: string;
        /**
         * @description  托管开始时间开始
         */
        startTimeStart?: string;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  管家id
         */
        userId?: number;
        /**
         * @description  按年查询管理费
         */
        yearTime?: number;
      }
      /**
       * @name 我的管家提层,小程序使用
       * @param params
       */
      export function myUserSalaryList(params: MyUserSalaryListParams) {
        return request.get<ResponseResult<PageList<StewardManager>>>(
          "/sys-v100001/stewardManager/myUserSalaryList",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
      }
      /**
       * @name 换新管家提层表重新计算调用
       * @param params
       */
      export function save(params: SaveParams) {
        return request.get<ResponseResult<object>>(
          "/sys-v100001/stewardManager/save",
          { params }
        );
      }
      export interface StoreGroupListParams {
        /**
         * @description  合同性质
         */
        contractId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  报表录入时间查询
         */
        reportTime?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  托管开始时间结束
         */
        startTimeEnd?: string;
        /**
         * @description  托管开始时间开始
         */
        startTimeStart?: string;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  管家id
         */
        userId?: number;
        /**
         * @description  按年查询管理费
         */
        yearTime?: number;
      }
      /**
       * @name 换新管家提层表列表按店面分组
       * @param params
       */
      export function storeGroupList(params: StoreGroupListParams) {
        return request.get<
          ResponseResult<PageList<StewardManagerStoreAmountVo>>
        >("/sys-v100001/stewardManager/storeGroupList", { params });
      }
      export interface UpdateParams {
        /**
         * @description  本月结束时间
         */
        managerEndTime: string;
        /**
         * @description  本月开始时间
         */
        managerStartTime: string;
      }
      /**
       * @name 换新管家提层表修改
       * @param id 换新管家提层表修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/sys-v100001/stewardManager/update/${id}`,
          data
        );
      }
    }
    export namespace storeAudit {
      export interface AuditParams {
        /**
         * @description  是否审核 1已审核  2未通过  3未审核
         */
        auditId?: number;
      }
      /**
       * @name 审核
       * @param id 审核
       * @param data
       */
      export function audit(id: string | number, data: AuditParams) {
        return request.post<ResponseResult<object>>(
          `/sys-v100001/storeAudit/audit/${id}`,
          data
        );
      }
      /**
       * @name 删除
       * @param id 删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/sys-v100001/storeAudit/delete/${id}`
        );
      }
      /**
       * @name 获取详情
       * @param id 获取详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<SysStoreAudit>>(
          `/sys-v100001/storeAudit/info/${id}`
        );
      }
      export interface ListParams {
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
      }
      /**
       * @name 店面列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<SysStoreAudit>>>(
          "/sys-v100001/storeAudit/list",
          { params }
        );
      }
      export interface UpdateParams {
        /**
         * @description   区id
         */
        areaId: number;
        /**
         * @description  市主键
         */
        cityId: number;
        /**
         * @description  联系方式
         */
        contactPhone?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  部门名称
         */
        deptName?: string;
        /**
         * @description  详情地址
         */
        detailName?: string;
        /**
         * @description  店面名称
         */
        name?: string;
        /**
         * @description  省id
         */
        provinceId: number;
        /**
         * @description  店长id
         */
        storeManagerId?: number;
        /**
         * @description  门店编号
         */
        storeNo?: string;
      }
      /**
       * @name 修改
       * @param id 修改
       * @param params
       */
      export function update(id: string | number, params: UpdateParams) {
        return request.get<ResponseResult<object>>(
          `/sys-v100001/storeAudit/update/${id}`,
          { params }
        );
      }
    }
    export namespace store {
      export interface DataMigrationParams {
        /**
         * @description  新门店id
         */
        newStoreId?: string;
        /**
         * @description  旧门店id
         */
        oldStoreId?: number;
      }
      /**
       * @name 门店数据迁移接口
       * @param data
       */
      export function dataMigration(data: DataMigrationParams) {
        return request.put<ResponseResult<object>>(
          "/sys-v100001/store/dataMigration",
          data
        );
      }
      export interface DeleteByIdParams {
        /**
         * @description  主键
         */
        id?: number;
      }
      /**
       * @name 店面删除
       * @param params
       */
      export function deleteById(params: DeleteByIdParams) {
        return request.delete<ResponseResult<object>>(
          "/sys-v100001/store/deleteById",
          { params }
        );
      }
      /**
       * @name 店面下拉框,查询当前公司所有店面（添加用户时使用）
       */
      export function getSelectAll() {
        return request.get<ResponseResult<Array<SelectVo>>>(
          "/sys-v100001/store/getSelectAll"
        );
      }
      /**
       * @name 店面下拉框,查询当前用户可看店面（添加搜索房源使用）
       */
      export function getUserSelect() {
        return request.get<ResponseResult<Array<SelectVo>>>(
          "/sys-v100001/store/getUserSelect"
        );
      }
      /**
       * @name 店面详情
       * @param id 店面详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<Store>>(
          `/sys-v100001/store/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description   区id
         */
        areaId?: number;
        /**
         * @description  市主键
         */
        cityId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  店面名称
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  省id
         */
        provinceId?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店编号
         */
        storeNo?: string;
      }
      /**
       * @name 店面列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<Store>>>(
          "/sys-v100001/store/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description   区id
         */
        areaId: number;
        /**
         * @description  市主键
         */
        cityId: number;
        /**
         * @description  联系方式
         */
        contactPhone?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  部门名称
         */
        deptName?: string;
        /**
         * @description  详情地址
         */
        detailName?: string;
        /**
         * @description  店面名称
         */
        name?: string;
        /**
         * @description  省id
         */
        provinceId: number;
        /**
         * @description  店长id
         */
        storeManagerId?: number;
        /**
         * @description  门店编号
         */
        storeNo?: string;
      }
      /**
       * @name 店面新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/store/save",
          data
        );
      }
      export interface SetAreaManagerParams {
        /**
         * @description  员工id
         */
        areaManagerId?: number;
        /**
         * @description  主键
         */
        id?: number;
      }
      /**
       * @name 设置区域经理
       * @param data
       */
      export function setAreaManager(data: SetAreaManagerParams) {
        return request.put<ResponseResult<object>>(
          "/sys-v100001/store/setAreaManager",
          data
        );
      }
      export interface StoreExportParams {
        /**
         * @description   区id
         */
        areaId?: number;
        /**
         * @description  市主键
         */
        cityId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  店面名称
         */
        name?: string;
        /**
         * @description  省id
         */
        provinceId?: number;
        /**
         * @description  门店编号
         */
        storeNo?: string;
      }
      /**
       * @name 店面管理导出
       * @param params
       */
      export function storeExport(params: StoreExportParams) {
        return request.get("/sys-v100001/store/storeExport", { params });
      }
      export interface UpdateParams {
        /**
         * @description   区id
         */
        areaId: number;
        /**
         * @description  市主键
         */
        cityId: number;
        /**
         * @description  联系方式
         */
        contactPhone?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  部门名称
         */
        deptName?: string;
        /**
         * @description  详情地址
         */
        detailName?: string;
        /**
         * @description  店面名称
         */
        name?: string;
        /**
         * @description  省id
         */
        provinceId: number;
        /**
         * @description  店长id
         */
        storeManagerId?: number;
        /**
         * @description  门店编号
         */
        storeNo?: string;
      }
      /**
       * @name 店面修改
       * @param id 店面修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/sys-v100001/store/update/${id}`,
          data
        );
      }
    }
    export namespace storeGroup {
      export interface DeleteByIdParams {
        /**
         * @description  主键
         */
        id?: number;
      }
      /**
       * @name 店面分组删除
       * @param params
       */
      export function deleteById(params: DeleteByIdParams) {
        return request.delete<ResponseResult<object>>(
          "/sys-v100001/storeGroup/deleteById",
          { params }
        );
      }
      export interface GetSelectAllParams {
        /**
         * @description  storeIds
         */
        storeIds?: string;
      }
      /**
       * @name 店面分组下拉框,查询当前公司所有页面（添加用户时使用）
       * @param params
       */
      export function getSelectAll(params: GetSelectAllParams) {
        return request.get<ResponseResult<Array<SelectVo>>>(
          "/sys-v100001/storeGroup/getSelectAll",
          { params }
        );
      }
      export interface GetUserSelectParams {
        /**
         * @description  storeId
         */
        storeId?: number;
      }
      /**
       * @name 店面分组下拉框,查询当前用户可看分组数据（添加搜索房源使用）
       * @param params
       */
      export function getUserSelect(params: GetUserSelectParams) {
        return request.get<ResponseResult<Array<SelectVo>>>(
          "/sys-v100001/storeGroup/getUserSelect",
          { params }
        );
      }
      /**
       * @name 店面分组详情
       * @param id 店面分组详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<StoreGroup>>(
          `/sys-v100001/storeGroup/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  客户id(全局标识)
         */
        companyId?: number;
        /**
         * @description  创建人主键
         */
        createId?: number;
        /**
         * @description  创建人姓名
         */
        createName?: string;
        /**
         * @description  创建时间
         */
        createTime?: string;
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  组名称
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
      }
      /**
       * @name 店面分组列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<StoreGroup>>>(
          "/sys-v100001/storeGroup/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  组名称
         */
        name?: string;
        /**
         * @description  门店id
         */
        storeId: number;
      }
      /**
       * @name 店面分组新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/storeGroup/save",
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  组名称
         */
        name?: string;
        /**
         * @description  门店id
         */
        storeId: number;
      }
      /**
       * @name 店面分组修改
       * @param id 店面分组修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/sys-v100001/storeGroup/update/${id}`,
          data
        );
      }
    }
    export namespace sysFinance {
      /**
       * @name 系统后台财务表删除
       * @param id 系统后台财务表删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/sys-v100001/sysFinance/delete/${id}`
        );
      }
      /**
       * @name 系统后台财务表详情
       * @param id 系统后台财务表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<SysFinance>>(
          `/sys-v100001/sysFinance/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  客户id
         */
        companyId?: number;
        /**
         * @description  1已出纳  2未出纳
         */
        isCashier?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  账户
         */
        payAccount?: string;
        /**
         * @description  1收入  2支出
         */
        payType?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  支出人或者收入人
         */
        userName?: string;
      }
      /**
       * @name 系统后台财务表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<SysFinance>>>(
          "/sys-v100001/sysFinance/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  图片
         */
        images?: string;
        /**
         * @description  账户
         */
        payAccount?: string;
        /**
         * @description  日期
         */
        payDate: string;
        /**
         * @description  金额
         */
        payFee: number;
        /**
         * @description  1收入  2支出
         */
        payType: number;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  支出人或者收入人
         */
        userName?: string;
      }
      /**
       * @name 系统后台财务表新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/sysFinance/save",
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  图片
         */
        images?: string;
        /**
         * @description  账户
         */
        payAccount?: string;
        /**
         * @description  日期
         */
        payDate: string;
        /**
         * @description  金额
         */
        payFee: number;
        /**
         * @description  1收入  2支出
         */
        payType: number;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  支出人或者收入人
         */
        userName?: string;
      }
      /**
       * @name 系统后台财务表修改
       * @param id 系统后台财务表修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/sys-v100001/sysFinance/update/${id}`,
          data
        );
      }
      export interface UpdateIsCashierParams {
        /**
         * @description  主键
         */
        id?: number;
      }
      /**
       * @name 出纳
       * @param data
       */
      export function updateIsCashier(data: UpdateIsCashierParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/sysFinance/updateIsCashier",
          data
        );
      }
    }
    export namespace Sys {
      export namespace process {
        export interface AuditParams {
          /**
           * @description  审批意见
           */
          approvalComments?: string;
          /**
           * @description  流程id
           */
          id?: number;
          /**
           * @description  审核状态:0待审核；1审核中；2已通过；3已拒绝
           */
          processType?: number;
          userId?: number;
        }
        /**
         * @name 流程审核
         * @param data
         */
        export function audit(data: AuditParams) {
          return request.post<ResponseResult<object>>(
            "/sys-v100001/Sys/process/audit",
            data
          );
        }
        export interface MyAuditProcessListParams {
          pageNo?: number;
          pageSize?: number;
          sortField?: string;
          sortWay?: string;
        }
        /**
         * @name 该我审批的审批流程列表
         * @param data
         */
        export function myAuditProcessList(data: MyAuditProcessListParams) {
          return request.post<ResponseResult<PageList<流程输出类>>>(
            "/sys-v100001/Sys/process/myAuditProcessList",
            data
          );
        }
        /**
         * @name pdfZipDownload
         */
        export function pdfZipDownload() {
          return request.get<ResponseResult<object>>(
            "/sys-v100001/Sys/process/pdfZipDownload"
          );
        }
        export interface ProcessPageParams {
          pageNo?: number;
          pageSize?: number;
          /**
           * @description  流程名称
           */
          processName?: string;
          sortField?: string;
          sortWay?: string;
        }
        /**
         * @name 流程列表查询
         * @param data
         */
        export function processPage(data: ProcessPageParams) {
          return request.post<ResponseResult<PageList<流程输出类>>>(
            "/sys-v100001/Sys/process/processPage",
            data
          );
        }
        export interface SaveProcessParams {
          /**
           * @description  业务id
           */
          businessId?: number;
          /**
           * @description  流程名称
           */
          processName?: string;
          /**
           * @description  流程类型id
           */
          processTypeId?: number;
        }
        /**
         * @name 添加流程测试接口
         * @param data
         */
        export function saveProcess(data: SaveProcessParams) {
          return request.post<ResponseResult<object>>(
            "/sys-v100001/Sys/process/saveProcess",
            data
          );
        }
        export interface SaveProcessV1Params {
          /**
           * @description  业务id
           */
          businessId?: number;
          /**
           * @description  流程名称
           */
          processName?: string;
          /**
           * @description  流程类型id
           */
          processTypeId?: number;
          approveSaveDtos: Array<{
            approverId: number;
            approverName: string;
            processTypeId: number;
            sequence: number;
          }>;
        }
        /**
         * @name 添加流程测试接口V1
         * @param data
         */
        export function saveProcessV1(data: SaveProcessV1Params) {
          return request.post<ResponseResult<object>>(
            "/sys-v100001/Sys/process/saveProcessV1",
            data
          );
        }
        export interface UpdatePdfParams {
          /**
           * @description  业务id
           */
          businessId?: number;
          /**
           * @description  流程类型id
           */
          processTypeId?: number;
        }
        /**
         * @name 重新生成pdf
         * @param data
         */
        export function updatePdf(data: UpdatePdfParams) {
          return request.post<ResponseResult<object>>(
            "/sys-v100001/Sys/process/updatePdf",
            data
          );
        }
      }
      export namespace processType {
        /**
         * @name 物理删除接口
         * @param id 物理删除接口
         */
        export function deleteById(id: string | number) {
          return request.post<ResponseResult<object>>(
            `/sys-v100001/Sys/processType/delete/${id}`
          );
        }
        /**
         * @name 获取详情
         * @param id 获取详情
         */
        export function info(id: string | number) {
          return request.get<ResponseResult<流程类型输出类>>(
            `/sys-v100001/Sys/processType/info/${id}`
          );
        }
        export interface ListParams {
          /**
           * @description  申请名称
           */
          approvalName?: string;
          /**
           * @description  创建人名称
           */
          createName?: string;
          /**
           * @description  创建时间结束
           */
          createTimeEnd?: string;
          /**
           * @description  创建时间开始
           */
          createTimeStart?: string;
          /**
           * @description  审核状态:0待审核；1审核中；2已通过；3已拒绝
           */
          processType?: number;
          /**
           * @description  流程类型id
           */
          processTypeId?: number;
          /**
           * @description  门店id
           */
          storeId?: number;
          /**
           * @description  门店id多选
           */
          storeIdList?: string;
          /**
           * @description  类型名称
           */
          typeName?: string;
        }
        /**
         * @name 流程类型列表
         * @param params
         */
        export function list(params: ListParams) {
          return request.get<ResponseResult<Array<SysProcessType>>>(
            "/sys-v100001/Sys/processType/list",
            { params }
          );
        }
        export interface PageParams {
          /**
           * @description  申请名称
           */
          approvalName?: string;
          /**
           * @description  创建人名称
           */
          createName?: string;
          /**
           * @description  创建时间结束
           */
          createTimeEnd?: string;
          /**
           * @description  创建时间开始
           */
          createTimeStart?: string;
          pageNo?: number;
          pageSize?: number;
          /**
           * @description  审核状态:0待审核；1审核中；2已通过；3已拒绝
           */
          processType?: number;
          /**
           * @description  流程类型id
           */
          processTypeId?: number;
          sortField?: string;
          sortWay?: string;
          /**
           * @description  门店id
           */
          storeId?: number;
          /**
           * @description  门店id多选
           */
          storeIdList?: string;
          /**
           * @description  类型名称
           */
          typeName?: string;
        }
        /**
         * @name 流程类型列表
         * @param params
         */
        export function page(params: PageParams) {
          return request.get<ResponseResult<PageList<SysProcessType>>>(
            "/sys-v100001/Sys/processType/page",
            { params }
          );
        }
        export interface PageV1Params {
          /**
           * @description  申请名称
           */
          approvalName?: string;
          /**
           * @description  创建人名称
           */
          createName?: string;
          /**
           * @description  创建时间结束
           */
          createTimeEnd?: string;
          /**
           * @description  创建时间开始
           */
          createTimeStart?: string;
          pageNo?: number;
          pageSize?: number;
          /**
           * @description  审核状态:0待审核；1审核中；2已通过；3已拒绝
           */
          processType?: number;
          /**
           * @description  流程类型id
           */
          processTypeId?: number;
          sortField?: string;
          sortWay?: string;
          /**
           * @description  门店id
           */
          storeId?: number;
          /**
           * @description  门店id多选
           */
          storeIdList?: string;
          /**
           * @description  类型名称
           */
          typeName?: string;
        }
        /**
         * @name 流程类型列表
         * @param params
         */
        export function pageV1(params: PageV1Params) {
          return request.get<ResponseResult<PageList<SysProcessType>>>(
            "/sys-v100001/Sys/processType/pageV1",
            { params }
          );
        }
        /**
         * @name 逻辑删除接口
         * @param id 逻辑删除接口
         */
        export function remove(id: string | number) {
          return request.post<ResponseResult<object>>(
            `/sys-v100001/Sys/processType/remove/${id}`
          );
        }
        export interface SaveParams {
          /**
           * @description  类型名称
           */
          typeName?: string;
          approveSaveDtoV1s: Array<{
            noticeId: number;
            noticeName: string;
            processTypeId: number;
          }>;
          approveSaveDtos: Array<{
            approverId: number;
            approverName: string;
            processTypeId: number;
            sequence: number;
          }>;
        }
        /**
         * @name 添加流程类型接口
         * @param data
         */
        export function save(data: SaveParams) {
          return request.post<ResponseResult<object>>(
            "/sys-v100001/Sys/processType/save",
            data
          );
        }
        export interface UpdateParams {
          /**
           * @description  id
           */
          id: number;
          /**
           * @description  类型名称
           */
          typeName?: string;
          approveSaveDtoV1s: Array<{
            noticeId: number;
            noticeName: string;
            processTypeId: number;
          }>;
          approveSaveDtos: Array<{
            approverId: number;
            approverName: string;
            processTypeId: number;
            sequence: number;
          }>;
        }
        /**
         * @name 修改数据
         * @param data
         */
        export function update(data: UpdateParams) {
          return request.post<ResponseResult<object>>(
            "/sys-v100001/Sys/processType/update/${id}",
            data
          );
        }
      }
    }
    export namespace tableConfig {
      export interface DeleteBizIdParams {
        /**
         * @description  bizId
         */
        bizId?: number;
      }
      /**
       * @name 表头设置表删除
       * @param params
       */
      export function deleteBizId(params: DeleteBizIdParams) {
        return request.delete<ResponseResult<object>>(
          "/sys-v100001/tableConfig/deleteBizId",
          { params }
        );
      }
      export interface InfoBizTableParams {
        /**
         * @description  bizId
         */
        bizId?: number;
      }
      /**
       * @name 表头设置表详情
       * @param params
       */
      export function infoBizTable(params: InfoBizTableParams) {
        return request.get<ResponseResult<TableConfig>>(
          "/sys-v100001/tableConfig/infoBizTable",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  业务id(字典Pid:699)
         */
        bizId: string;
        /**
         * @description  审核人id
         */
        childTableConfigAuditDtoauditId?: number;
        /**
         * @description  审核人姓名
         */
        childTableConfigAuditDtoauditName?: string;
        /**
         * @description  顺序id
         */
        childTableConfigAuditDtoid?: number;
        /**
         * @description  角色id
         */
        childTableConfigAuditDtoroleId?: number;
        /**
         * @description  非两个数字之间的条件
         */
        childTableConfigAuditDtotableConfigAuditIfDtoListamount?: number;
        /**
         * @description  6介于两个数之间结束
         */
        childTableConfigAuditDtotableConfigAuditIfDtoListamountEnd?: number;
        /**
         * @description  6介于两个数之间开始
         */
        childTableConfigAuditDtotableConfigAuditIfDtoListamountStart?: number;
        /**
         * @description  判断条件价格 1:大于等于  2大于  3小于 4小于等于  5等于 6介于两个数之间
         */
        childTableConfigAuditDtotableConfigAuditIfDtoListamountType?: number;
        /**
         * @description  下一级id 必须传
         */
        childTableConfigAuditDtotableConfigAuditIfDtoListdownId?: number;
        /**
         * @description  类型:1审核  2抄送
         */
        childTableConfigAuditDtotype?: number;
        /**
         * @description  类型:1指定成员，2发起人自选  3当前店面经理  4当前店面区域经理  5角色
         */
        childTableConfigAuditDtouserType?: number;
        /**
         * @description  类型：1金额类，2非金额
         */
        isTypeAmount: number;
        /**
         * @description  类型:1关联房源 2关联租客  3不关联
         */
        isTypeHouse: number;
        /**
         * @description  门店:1需要 2不需要
         */
        isTypeStore: number;
        /**
         * @description  横向展示列数
         */
        rowNum?: number;
        tableConfigFormDtoList: Array<{
          id: number;
          initialValue: string;
          isTypeAmount: number;
          label: string;
          mode: string;
          picker: string;
          placeholder: string;
          precision: number;
          required: string;
          rules: string;
          selectList: string;
          type: string;
        }>;
      }
      /**
       * @name 表头设置表新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/tableConfig/save",
          data
        );
      }
      export interface UpdateSearchConfigParams {
        /**
         * @description  业务id(字典Pid:699)
         */
        bizId: string;
        /**
         * @description  搜索字段json
         */
        searchJson?: string;
      }
      /**
       * @name 设置搜索条件
       * @param data
       */
      export function updateSearchConfig(data: UpdateSearchConfigParams) {
        return request.put<ResponseResult<object>>(
          "/sys-v100001/tableConfig/updateSearchConfig",
          data
        );
      }
      export interface UpdateTableConfigParams {
        /**
         * @description  业务id(字典Pid:699)
         */
        bizId: string;
        /**
         * @description  审核人id
         */
        childTableConfigAuditDtoauditId?: number;
        /**
         * @description  审核人姓名
         */
        childTableConfigAuditDtoauditName?: string;
        /**
         * @description  顺序id
         */
        childTableConfigAuditDtoid?: number;
        /**
         * @description  角色id
         */
        childTableConfigAuditDtoroleId?: number;
        /**
         * @description  非两个数字之间的条件
         */
        childTableConfigAuditDtotableConfigAuditIfDtoListamount?: number;
        /**
         * @description  6介于两个数之间结束
         */
        childTableConfigAuditDtotableConfigAuditIfDtoListamountEnd?: number;
        /**
         * @description  6介于两个数之间开始
         */
        childTableConfigAuditDtotableConfigAuditIfDtoListamountStart?: number;
        /**
         * @description  判断条件价格 1:大于等于  2大于  3小于 4小于等于  5等于 6介于两个数之间
         */
        childTableConfigAuditDtotableConfigAuditIfDtoListamountType?: number;
        /**
         * @description  下一级id 必须传
         */
        childTableConfigAuditDtotableConfigAuditIfDtoListdownId?: number;
        /**
         * @description  类型:1审核  2抄送
         */
        childTableConfigAuditDtotype?: number;
        /**
         * @description  类型:1指定成员，2发起人自选  3当前店面经理  4当前店面区域经理  5角色
         */
        childTableConfigAuditDtouserType?: number;
        /**
         * @description  类型：1金额类，2非金额
         */
        isTypeAmount: number;
        /**
         * @description  类型:1关联房源 2关联租客  3不关联
         */
        isTypeHouse: number;
        /**
         * @description  门店:1需要 2不需要
         */
        isTypeStore: number;
        /**
         * @description  横向展示列数
         */
        rowNum?: number;
        tableConfigFormDtoList: Array<{
          id: number;
          initialValue: string;
          isTypeAmount: number;
          label: string;
          mode: string;
          picker: string;
          placeholder: string;
          precision: number;
          required: string;
          rules: string;
          selectList: string;
          type: string;
        }>;
      }
      /**
       * @name 表头设置表修改
       * @param data
       */
      export function updateTableConfig(data: UpdateTableConfigParams) {
        return request.put<ResponseResult<object>>(
          "/sys-v100001/tableConfig/updateTableConfig",
          data
        );
      }
    }
    export namespace tableData {
      /**
       * @name 动态表数据删除
       * @param id 动态表数据删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/sys-v100001/tableData/delete/${id}`
        );
      }
      /**
       * @name 动态表数据详情
       * @param id 动态表数据详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<TableData>>(
          `/sys-v100001/tableData/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  金额判断字段
         */
        amount?: number;
        /**
         * @description  审核字段json
         */
        auditJson?: string;
        /**
         * @description  业务id(字典Pid:699)
         */
        bizId?: string;
        /**
         * @description  业务名称
         */
        bizName?: string;
        /**
         * @description  客户id(全局标识)
         */
        companyId?: number;
        /**
         * @description  创建人主键
         */
        createId?: number;
        /**
         * @description  创建人姓名
         */
        createName?: string;
        /**
         * @description  创建时间
         */
        createTime?: string;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  物业地区名称
         */
        detailName?: string;
        /**
         * @description  表单字段json
         */
        formJson?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号、（集中几层或者几栋）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  类型：1金额类，2非金额
         */
        isTypeAmount?: number;
        /**
         * @description  类型:1关联房源 2不关联
         */
        isTypeHouse?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间号 (合租)/集中 门牌号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店名称
         */
        storeName?: string;
        /**
         * @description  租客id
         */
        tenantsId?: number;
      }
      /**
       * @name 动态表数据列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<TableData>>>(
          "/sys-v100001/tableData/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  金额判断字段
         */
        amount?: number;
        /**
         * @description  业务id(字典Pid:699)
         */
        bizId: string;
        /**
         * @description  表单字段json
         */
        formJson: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
      }
      /**
       * @name 动态表数据新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/tableData/save",
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  金额判断字段
         */
        amount?: number;
        /**
         * @description  业务id(字典Pid:699)
         */
        bizId: string;
        /**
         * @description  表单字段json
         */
        formJson: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
      }
      /**
       * @name 动态表数据修改
       * @param id 动态表数据修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/sys-v100001/tableData/update/${id}`,
          data
        );
      }
    }
    export namespace templateConfig {
      export interface ListParams {
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  短信模版id
         */
        smsId?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  微信模版id
         */
        templateId?: string;
        /**
         * @description  1表示催租 2表示缴费成功
         */
        type?: number;
      }
      /**
       * @name 微信短信模版表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<TemplateConfig>>>(
          "/sys-v100001/templateConfig/list",
          { params }
        );
      }
      /**
       * @name 微信短信模版表新增
       */
      export function save() {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/templateConfig/save"
        );
      }
      /**
       * @name 模版类型下拉框
       */
      export function select() {
        return request.get<ResponseResult<Array<SelectVo>>>(
          "/sys-v100001/templateConfig/select"
        );
      }
    }
    export namespace userChangeStore {
      export interface AuditParams {
        /**
         * @description  审批状态：1.已审核；2，未通过；3，未审核
         */
        auditId?: number;
      }
      /**
       * @name 人员调店审批
       * @param id 人员调店审批
       * @param data
       */
      export function audit(id: string | number, data: AuditParams) {
        return request.post<ResponseResult<object>>(
          `/sys-v100001/userChangeStore/audit/${id}`,
          data
        );
      }
      /**
       * @name delete
       * @param id delete
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/sys-v100001/userChangeStore/delete/${id}`
        );
      }
      /**
       * @name 获取人员调店详情
       * @param id 获取人员调店详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<SysUserChangeStore>>(
          `/sys-v100001/userChangeStore/info/${id}`
        );
      }
      export interface PageParams {
        /**
         * @description  审批时间
         */
        auditTimeEnd?: string;
        /**
         * @description  审批时间
         */
        auditTimeStart?: string;
        /**
         * @description  创建时间结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建时间开始
         */
        createTimeStart?: string;
        /**
         * @description  新门店id
         */
        newStoreId?: number;
        /**
         * @description  原门店id
         */
        oldStoreId?: number;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  人员id
         */
        userId?: number;
      }
      /**
       * @name 人员调店列表
       * @param params
       */
      export function page(params: PageParams) {
        return request.get<ResponseResult<PageList<SysUserChangeStore>>>(
          "/sys-v100001/userChangeStore/page",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  调店交接人id
         */
        changeAcceptUserId: number;
        /**
         * @description  调店交接人名称
         */
        changeAcceptUserName?: string;
        /**
         * @description  新门店id
         */
        newStoreId: number;
        /**
         * @description  新门店名称
         */
        newStoreName?: string;
        /**
         * @description  调店备注
         */
        remark?: string;
        /**
         * @description  人员id
         */
        userId: number;
        /**
         * @description  人员名称
         */
        userName?: string;
      }
      /**
       * @name 人员调店新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/userChangeStore/save",
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  调店交接人id
         */
        changeAcceptUserId: number;
        /**
         * @description  调店交接人名称
         */
        changeAcceptUserName?: string;
        /**
         * @description  新门店id
         */
        newStoreId: number;
        /**
         * @description  新门店名称
         */
        newStoreName?: string;
        /**
         * @description  调店备注
         */
        remark?: string;
        /**
         * @description  人员id
         */
        userId: number;
        /**
         * @description  人员名称
         */
        userName?: string;
      }
      /**
       * @name 修改人员调店
       * @param id 修改人员调店
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.post<ResponseResult<object>>(
          `/sys-v100001/userChangeStore/update/${id}`,
          data
        );
      }
    }
    export namespace user {
      export interface BookListParams {
        /**
         * @description  身份证
         */
        idCard?: string;
        /**
         * @description  入职开始时间
         */
        inductionTimeBegin?: string;
        /**
         * @description  入职结束时间
         */
        inductionTimeEnd?: string;
        /**
         * @description  1 表示按店面看  2表示按分组看  3看所有
         */
        lookType?: number;
        /**
         * @description  姓名
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  登录账号
         */
        phone?: number;
        /**
         * @description  角色主键
         */
        roleId?: number;
        /**
         * @description  角色名称，例如：维修
         */
        roleName?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  状态 1可用，2禁用 3离职
         */
        status?: string;
        /**
         * @description  员工所在门店id
         */
        storeId?: number;
      }
      /**
       * @name 管理端小程序：通讯录(就一个搜索条件，姓名)
       * @param params
       */
      export function bookList(params: BookListParams) {
        return request.get<ResponseResult<Array<BookVo>>>(
          "/sys-v100001/user/bookList",
          { params }
        );
      }
      export interface BookUserListParams {
        /**
         * @description  身份证
         */
        idCard?: string;
        /**
         * @description  入职开始时间
         */
        inductionTimeBegin?: string;
        /**
         * @description  入职结束时间
         */
        inductionTimeEnd?: string;
        /**
         * @description  1 表示按店面看  2表示按分组看  3看所有
         */
        lookType?: number;
        /**
         * @description  姓名
         */
        name?: string;
        /**
         * @description  登录账号
         */
        phone?: number;
        /**
         * @description  角色主键
         */
        roleId?: number;
        /**
         * @description  角色名称，例如：维修
         */
        roleName?: string;
        /**
         * @description  状态 1可用，2禁用 3离职
         */
        status?: string;
        /**
         * @description  员工所在门店id
         */
        storeId?: number;
      }
      /**
       * @name 管理端小程序：通讯录 用户
       * @param params
       */
      export function bookUserList(params: BookUserListParams) {
        return request.get<ResponseResult<Array<BookUserVo>>>(
          "/sys-v100001/user/bookUserList",
          { params }
        );
      }
      export interface DeleteByIdParams {
        /**
         * @description  主键
         */
        id?: number;
      }
      /**
       * @name 用户删除
       * @param params
       */
      export function deleteById(params: DeleteByIdParams) {
        return request.delete<ResponseResult<object>>(
          "/sys-v100001/user/deleteById",
          { params }
        );
      }
      export interface DepartureParams {
        /**
         * @description  离职接交人id:选用户就行
         */
        departureAcceptUserId?: number;
        /**
         * @description  离职时间
         */
        departureTime: string;
        /**
         * @description  主键不能为空
         */
        id: number;
        /**
         * @description  备注
         */
        remark?: string;
      }
      /**
       * @name 用户离职
       * @param data
       */
      export function departure(data: DepartureParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/user/departure",
          data
        );
      }
      /**
       * @name 根据用户token获取用户信息
       */
      export function getUserInfo() {
        return request.get<ResponseResult<User>>(
          "/sys-v100001/user/getUserInfo"
        );
      }
      /**
       * @name 用户详情
       * @param id 用户详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<User>>(
          `/sys-v100001/user/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  身份证
         */
        idCard?: string;
        /**
         * @description  入职开始时间
         */
        inductionTimeBegin?: string;
        /**
         * @description  入职结束时间
         */
        inductionTimeEnd?: string;
        /**
         * @description  1 表示按店面看  2表示按分组看  3看所有
         */
        lookType?: number;
        /**
         * @description  姓名
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  登录账号
         */
        phone?: number;
        /**
         * @description  角色主键
         */
        roleId?: number;
        /**
         * @description  角色名称，例如：维修
         */
        roleName?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  状态 1可用，2禁用 3离职
         */
        status?: string;
        /**
         * @description  员工所在门店id
         */
        storeId?: number;
      }
      /**
       * @name 用户列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<User>>>(
          "/sys-v100001/user/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  银行卡号
         */
        bankCardNo?: string;
        /**
         * @description  银行名称
         */
        bankName?: string;
        /**
         * @description  开户行
         */
        bankOfDeposit?: string;
        /**
         * @description  所属部门id
         */
        deptId?: number;
        /**
         * @description  所属部门名称
         */
        deptName?: string;
        /**
         * @description  身份证
         */
        idCard?: string;
        /**
         * @description  入职时间
         */
        inductionTime?: string;
        /**
         * @description  员工查看分组id逗号隔开
         */
        lookStoreGroupId?: string;
        /**
         * @description  员工查看门店id逗号隔开
         */
        lookStoreId?: string;
        /**
         * @description  1 表示按店面看  2表示按分组看  3看所有
         */
        lookType: number;
        /**
         * @description  姓名
         */
        name?: string;
        /**
         * @description  登录账号
         */
        phone: number;
        /**
         * @description  登录密码不能为空,新增才传，修改不传
         */
        pwd?: string;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  角色主键
         */
        roleId: number;
        /**
         * @description  员工所在门店id
         */
        storeId: number;
      }
      /**
       * @name 用户新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/user/save",
          data
        );
      }
      export interface SelectParams {
        /**
         * @description  身份证
         */
        idCard?: string;
        /**
         * @description  入职开始时间
         */
        inductionTimeBegin?: string;
        /**
         * @description  入职结束时间
         */
        inductionTimeEnd?: string;
        /**
         * @description  1 表示按店面看  2表示按分组看  3看所有
         */
        lookType?: number;
        /**
         * @description  姓名
         */
        name?: string;
        /**
         * @description  登录账号
         */
        phone?: number;
        /**
         * @description  角色主键
         */
        roleId?: number;
        /**
         * @description  角色名称，例如：维修
         */
        roleName?: string;
        /**
         * @description  状态 1可用，2禁用 3离职
         */
        status?: string;
        /**
         * @description  员工所在门店id
         */
        storeId?: number;
      }
      /**
       * @name 用户下拉框
       * @param params
       */
      export function select(params: SelectParams) {
        return request.get<ResponseResult<Array<SelectVo>>>(
          "/sys-v100001/user/select",
          { params }
        );
      }
      export interface UpdateParams {
        /**
         * @description  银行卡号
         */
        bankCardNo?: string;
        /**
         * @description  银行名称
         */
        bankName?: string;
        /**
         * @description  开户行
         */
        bankOfDeposit?: string;
        /**
         * @description  所属部门id
         */
        deptId?: number;
        /**
         * @description  所属部门名称
         */
        deptName?: string;
        /**
         * @description  身份证
         */
        idCard?: string;
        /**
         * @description  入职时间
         */
        inductionTime?: string;
        /**
         * @description  员工查看分组id逗号隔开
         */
        lookStoreGroupId?: string;
        /**
         * @description  员工查看门店id逗号隔开
         */
        lookStoreId?: string;
        /**
         * @description  1 表示按店面看  2表示按分组看  3看所有
         */
        lookType: number;
        /**
         * @description  姓名
         */
        name?: string;
        /**
         * @description  登录账号
         */
        phone: number;
        /**
         * @description  登录密码不能为空,新增才传，修改不传
         */
        pwd?: string;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  角色主键
         */
        roleId: number;
        /**
         * @description  员工所在门店id
         */
        storeId: number;
      }
      /**
       * @name 用户修改，不修改密码，密码修改单独接口
       * @param id 用户修改，不修改密码，密码修改单独接口
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/sys-v100001/user/update/${id}`,
          data
        );
      }
      export interface UpdatePwdParams {
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  新密码密码
         */
        newPwd?: string;
      }
      /**
       * @name 管理员修改用户密码
       * @param data
       */
      export function updatePwd(data: UpdatePwdParams) {
        return request.post<ResponseResult>(
          "/sys-v100001/user/updatePwd",
          data
        );
      }
      export interface UpdateStatusParams {
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  状态 1可用，2禁用
         */
        status?: number;
      }
      /**
       * @name 管理员禁用用户
       * @param data
       */
      export function updateStatus(data: UpdateStatusParams) {
        return request.put<ResponseResult>(
          "/sys-v100001/user/updateStatus",
          data
        );
      }
      export interface UpdateUserPwdParams {
        /**
         * @description  新密码密码
         */
        newPwd?: string;
        /**
         * @description  原密码
         */
        oldPwd?: string;
      }
      /**
       * @name 用户自己修改密码
       * @param data
       */
      export function updateUserPwd(data: UpdateUserPwdParams) {
        return request.post<ResponseResult>(
          "/sys-v100001/user/updateUserPwd",
          data
        );
      }
      export interface UpdateUserUnbindParams {
        /**
         * @description  主键
         */
        id?: number;
      }
      /**
       * @name 清空openId
       * @param data
       */
      export function updateUserUnbind(data: UpdateUserUnbindParams) {
        return request.put<ResponseResult>(
          "/sys-v100001/user/updateUserUnbind",
          data
        );
      }
      export interface UserExportParams {
        /**
         * @description  身份证
         */
        idCard?: string;
        /**
         * @description  入职开始时间
         */
        inductionTimeBegin?: string;
        /**
         * @description  入职结束时间
         */
        inductionTimeEnd?: string;
        /**
         * @description  1 表示按店面看  2表示按分组看  3看所有
         */
        lookType?: number;
        /**
         * @description  姓名
         */
        name?: string;
        /**
         * @description  登录账号
         */
        phone?: number;
        /**
         * @description  角色主键
         */
        roleId?: number;
        /**
         * @description  角色名称，例如：维修
         */
        roleName?: string;
        /**
         * @description  状态 1可用，2禁用 3离职
         */
        status?: string;
        /**
         * @description  员工所在门店id
         */
        storeId?: number;
      }
      /**
       * @name 员工管理导出
       * @param params
       */
      export function userExport(params: UserExportParams) {
        return request.get("/sys-v100001/user/userExport", { params });
      }
      /**
       * @name PC端退出操作
       */
      export function exitPc() {
        return request.post<ResponseResult>("/sys-v100001/user/exitPc");
      }
      export interface LoginParams {
        /**
         * @description  微信code
         */
        code?: string;
        /**
         * @description  账号
         */
        phone?: string;
        /**
         * @description  密码
         */
        pwd?: string;
      }
      /**
       * @name 客户用户登陆
       * @param data
       */
      export function login(data: LoginParams) {
        return request.post<ResponseResult<LoginVo>>(
          "/sys-v100001/user/login",
          data
        );
      }
      export interface LoginWxParams {
        /**
         * @description  微信code
         */
        code?: string;
        /**
         * @description  公众号菜单配置路径带上的
         */
        companyId?: number;
      }
      /**
       * @name 客户用户微信授权登陆
       * @param data
       */
      export function loginWx(data: LoginWxParams) {
        return request.post<ResponseResult<LoginVo>>(
          "/sys-v100001/user/loginWx",
          data
        );
      }
    }
    export namespace userPerformance {
      /**
       * @name 换新房源业绩表删除
       * @param id 换新房源业绩表删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/sys-v100001/userPerformance/delete/${id}`
        );
      }
      export interface ExportUserPerformanceParams {
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  租赁结束时间结束
         */
        endTimeEnd?: string;
        /**
         * @description  租赁结束开始
         */
        endTimeStart?: string;
        /**
         * @description  支出状态：1确定支出  2不出支
         */
        financeStatus?: number;
        /**
         * @description  业绩支出时间查询
         */
        financeTime?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  房东姓名或者租客姓名
         */
        houseTenantsName?: number;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  其他状态：0,正常状态；1，换租还原状态标记；2，转租还原标记；3，房东续约还原标记
         */
        otherStatus?: number;
        /**
         * @description  房东或租客创建时间结束
         */
        performanceCreateTimeEnd?: string;
        /**
         * @description  房东或租客创建时间开始
         */
        performanceCreateTimeStart?: string;
        /**
         * @description  12房东，13租客
         */
        relationTypeId?: number;
        /**
         * @description  报表录入时间查询
         */
        reportTime?: string;
        /**
         * @description  租赁时间开始结束
         */
        startTimeTimeEnd?: string;
        /**
         * @description  租赁时间开始
         */
        startTimeTimeStart?: string;
        /**
         * @description  状态：1新签 2已续约 3已退房 4已换房  5转租
         */
        statusHisId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店id
         */
        storeIdList?: string;
        /**
         * @description  业绩类型：1租房新签，2收房新签,3收房续约
         */
        typeId?: number;
        /**
         * @description  业绩类型：1租房新签，2收房新签,3收房续约
         */
        typeName?: string;
        /**
         * @description  员工id
         */
        userId?: number;
        /**
         * @description  角色：1业务员 2客源人 3业务员与客源人 4管家
         */
        userTypeId?: number;
      }
      /**
       * @name 换新房源业绩表导出
       * @param params
       */
      export function exportUserPerformance(
        params: ExportUserPerformanceParams
      ) {
        return request.get(
          "/sys-v100001/userPerformance/exportUserPerformance",
          { params }
        );
      }
      export interface HandleUserPerformanceParams {
        /**
         * @description  报表查询时间
         */
        reportTime?: string;
      }
      /**
       * @name 业绩重新计算调用
       * @param params
       */
      export function handleUserPerformance(
        params: HandleUserPerformanceParams
      ) {
        return request.get<ResponseResult<object>>(
          "/sys-v100001/userPerformance/handleUserPerformance",
          { params }
        );
      }
      export interface HandleUserPerformanceOnlyV1Params {
        /**
         * @description  roomId
         */
        roomId?: number;
      }
      /**
       * @name 重新生成单个租客业绩
       * @param data
       */
      export function handleUserPerformanceOnlyV1(
        data: HandleUserPerformanceOnlyV1Params
      ) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/userPerformance/handleUserPerformanceOnlyV1",
          data
        );
      }
      export interface HousePerformanceTenantsIdParams {
        /**
         * @description  房间id
         */
        id?: number;
      }
      /**
       * @name 标记被出房已被计算
       * @param params
       */
      export function housePerformanceTenantsId(
        params: HousePerformanceTenantsIdParams
      ) {
        return request.get<ResponseResult<object>>(
          "/sys-v100001/userPerformance/housePerformanceTenantsId",
          { params }
        );
      }
      /**
       * @name 换新房源业绩表详情
       * @param id 换新房源业绩表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<UserPerformance>>(
          `/sys-v100001/userPerformance/info/${id}`
        );
      }
      export interface IsMarkParams {
        /**
         * @description  id
         */
        id?: number;
        /**
         * @description  标记状态：0，未标记；1，已标记
         */
        isMark?: number;
        /**
         * @description  标记备注
         */
        isMarkRemark?: string;
      }
      /**
       * @name 标记或取消标记下个月完善再核算
       * @param data
       */
      export function isMark(data: IsMarkParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/userPerformance/isMark",
          data
        );
      }
      export interface ListParams {
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  租赁结束时间结束
         */
        endTimeEnd?: string;
        /**
         * @description  租赁结束开始
         */
        endTimeStart?: string;
        /**
         * @description  支出状态：1确定支出  2不出支
         */
        financeStatus?: number;
        /**
         * @description  业绩支出时间查询
         */
        financeTime?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  房东姓名或者租客姓名
         */
        houseTenantsName?: number;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  其他状态：0,正常状态；1，换租还原状态标记；2，转租还原标记；3，房东续约还原标记
         */
        otherStatus?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  房东或租客创建时间结束
         */
        performanceCreateTimeEnd?: string;
        /**
         * @description  房东或租客创建时间开始
         */
        performanceCreateTimeStart?: string;
        /**
         * @description  12房东，13租客
         */
        relationTypeId?: number;
        /**
         * @description  报表录入时间查询
         */
        reportTime?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  租赁时间开始结束
         */
        startTimeTimeEnd?: string;
        /**
         * @description  租赁时间开始
         */
        startTimeTimeStart?: string;
        /**
         * @description  状态：1新签 2已续约 3已退房 4已换房  5转租
         */
        statusHisId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店id
         */
        storeIdList?: string;
        /**
         * @description  业绩类型：1租房新签，2收房新签,3收房续约
         */
        typeId?: number;
        /**
         * @description  业绩类型：1租房新签，2收房新签,3收房续约
         */
        typeName?: string;
        /**
         * @description  员工id
         */
        userId?: number;
        /**
         * @description  角色：1业务员 2客源人 3业务员与客源人 4管家
         */
        userTypeId?: number;
      }
      /**
       * @name 换新房源业绩表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<UserPerformance>>>(
          "/sys-v100001/userPerformance/list",
          { params }
        );
      }
      /**
       * @name 换新房源业绩表列表-个人
       */
      export function myList() {
        return request.get<ResponseResult<Array<UserPerformance>>>(
          "/sys-v100001/userPerformance/myList"
        );
      }
      export interface MyUserPerformanceListParams {
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  租赁结束时间结束
         */
        endTimeEnd?: string;
        /**
         * @description  租赁结束开始
         */
        endTimeStart?: string;
        /**
         * @description  支出状态：1确定支出  2不出支
         */
        financeStatus?: number;
        /**
         * @description  业绩支出时间查询
         */
        financeTime?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  房东姓名或者租客姓名
         */
        houseTenantsName?: number;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  其他状态：0,正常状态；1，换租还原状态标记；2，转租还原标记；3，房东续约还原标记
         */
        otherStatus?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  房东或租客创建时间结束
         */
        performanceCreateTimeEnd?: string;
        /**
         * @description  房东或租客创建时间开始
         */
        performanceCreateTimeStart?: string;
        /**
         * @description  12房东，13租客
         */
        relationTypeId?: number;
        /**
         * @description  报表录入时间查询
         */
        reportTime?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  租赁时间开始结束
         */
        startTimeTimeEnd?: string;
        /**
         * @description  租赁时间开始
         */
        startTimeTimeStart?: string;
        /**
         * @description  状态：1新签 2已续约 3已退房 4已换房  5转租
         */
        statusHisId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店id
         */
        storeIdList?: string;
        /**
         * @description  业绩类型：1租房新签，2收房新签,3收房续约
         */
        typeId?: number;
        /**
         * @description  业绩类型：1租房新签，2收房新签,3收房续约
         */
        typeName?: string;
        /**
         * @description  员工id
         */
        userId?: number;
        /**
         * @description  角色：1业务员 2客源人 3业务员与客源人 4管家
         */
        userTypeId?: number;
      }
      /**
       * @name 我的业绩数据,小程序使用
       * @param params
       */
      export function myUserPerformanceList(
        params: MyUserPerformanceListParams
      ) {
        return request.get<ResponseResult<PageList<UserPerformance>>>(
          "/sys-v100001/userPerformance/myUserPerformanceList",
          { params }
        );
      }
      export interface UpdateParams {
        /**
         * @description  被出房提层
         */
        outAmount?: number;
        /**
         * @description  积分
         */
        performance?: number;
        /**
         * @description  奖励金额
         */
        rewardAmount?: number;
      }
      /**
       * @name 换新房源业绩表发放金额修改
       * @param id 换新房源业绩表发放金额修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/sys-v100001/userPerformance/update/${id}`,
          data
        );
      }
      export interface UpdateFinanceStatusParams {
        /**
         * @description  本月不支出原因
         */
        financeDesc?: string;
        /**
         * @description  支出状态：1确定支出  2不出支
         */
        financeStatus?: number;
        /**
         * @description  支出时间 2022-05
         */
        financeTime?: string;
        /**
         * @description  主键
         */
        id?: number;
      }
      /**
       * @name 业绩财务出纳修改
       * @param data
       */
      export function updateFinanceStatus(data: UpdateFinanceStatusParams) {
        return request.put<ResponseResult<object>>(
          "/sys-v100001/userPerformance/updateFinanceStatus",
          data
        );
      }
      export interface UpdateIsMarkRemarkParams {
        /**
         * @description  id
         */
        id?: number;
        /**
         * @description  标记备注
         */
        isMarkRemark?: string;
      }
      /**
       * @name 单独修改标记备注
       * @param data
       */
      export function updateIsMarkRemark(data: UpdateIsMarkRemarkParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/userPerformance/updateIsMarkRemark",
          data
        );
      }
      export interface UpdatePerformanceReportTimeParams {
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  时间改变原因
         */
        intoDesc?: string;
        /**
         * @description  业绩计算时间 2022-05
         */
        reportTime?: string;
      }
      /**
       * @name 业绩计算时间修改
       * @param data
       */
      export function updatePerformanceReportTime(
        data: UpdatePerformanceReportTimeParams
      ) {
        return request.put<ResponseResult<object>>(
          "/sys-v100001/userPerformance/updatePerformanceReportTime",
          data
        );
      }
      export interface UserPerformanceSumParams {
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  租赁结束时间结束
         */
        endTimeEnd?: string;
        /**
         * @description  租赁结束开始
         */
        endTimeStart?: string;
        /**
         * @description  支出状态：1确定支出  2不出支
         */
        financeStatus?: number;
        /**
         * @description  业绩支出时间查询
         */
        financeTime?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  房东姓名或者租客姓名
         */
        houseTenantsName?: number;
        /**
         * @description  类型：1整租,2合租，3集中
         */
        houseType?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  租前是否审核 1已审核  2未通过  3未审核
         */
        isBeforeAudit?: number;
        /**
         * @description  其他状态：0,正常状态；1，换租还原状态标记；2，转租还原标记；3，房东续约还原标记
         */
        otherStatus?: number;
        /**
         * @description  房东或租客创建时间结束
         */
        performanceCreateTimeEnd?: string;
        /**
         * @description  房东或租客创建时间开始
         */
        performanceCreateTimeStart?: string;
        /**
         * @description  12房东，13租客
         */
        relationTypeId?: number;
        /**
         * @description  报表录入时间查询
         */
        reportTime?: string;
        /**
         * @description  租赁时间开始结束
         */
        startTimeTimeEnd?: string;
        /**
         * @description  租赁时间开始
         */
        startTimeTimeStart?: string;
        /**
         * @description  状态：1新签 2已续约 3已退房 4已换房  5转租
         */
        statusHisId?: number;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店id
         */
        storeIdList?: string;
        /**
         * @description  业绩类型：1租房新签，2收房新签,3收房续约
         */
        typeId?: number;
        /**
         * @description  业绩类型：1租房新签，2收房新签,3收房续约
         */
        typeName?: string;
        /**
         * @description  员工id
         */
        userId?: number;
        /**
         * @description  角色：1业务员 2客源人 3业务员与客源人 4管家
         */
        userTypeId?: number;
      }
      /**
       * @name 换新房源业绩提成统计
       * @param params
       */
      export function userPerformanceSum(params: UserPerformanceSumParams) {
        return request.get<ResponseResult<UserPerformanceSumVo>>(
          "/sys-v100001/userPerformance/userPerformanceSum",
          { params }
        );
      }
      /**
       * @name 换新房源业绩提成统计-个人
       */
      export function userPerformanceSumV1() {
        return request.get<ResponseResult<UserPerformanceSumVo>>(
          "/sys-v100001/userPerformance/userPerformanceSumV1"
        );
      }
    }
    export namespace userSalary {
      export interface ExportUserSalaryParams {
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  报表录入时间查询
         */
        reportTime?: string;
        /**
         * @description  门店id
         */
        storeIdList?: string;
        /**
         * @description  用户id
         */
        userId?: number;
        /**
         * @description  用户确定：1已确定  2未确定  3有误
         */
        userStatusId?: number;
      }
      /**
       * @name 换新工资表导出
       * @param params
       */
      export function exportUserSalary(params: ExportUserSalaryParams) {
        return request.get("/sys-v100001/userSalary/exportUserSalary", {
          params
        });
      }
      export interface FirstCrownAmountParams {
        /**
         * @description  冠军奖励金额
         */
        crownAmount?: number;
        /**
         * @description  主键
         */
        id?: number;
      }
      /**
       * @name 换新添加冠军奖励
       * @param data
       */
      export function firstCrownAmount(data: FirstCrownAmountParams) {
        return request.put<ResponseResult<object>>(
          "/sys-v100001/userSalary/firstCrownAmount",
          data
        );
      }
      export interface FirstRecruitAmountParams {
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  招聘提成
         */
        recruitAmount?: number;
      }
      /**
       * @name 换新添加招聘提成
       * @param data
       */
      export function firstRecruitAmount(data: FirstRecruitAmountParams) {
        return request.put<ResponseResult<object>>(
          "/sys-v100001/userSalary/firstRecruitAmount",
          data
        );
      }
      export interface FirstShimizuAmountParams {
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  清水房推荐奖励
         */
        shimizuAmount?: number;
      }
      /**
       * @name 换新添加清水房推荐奖励
       * @param data
       */
      export function firstShimizuAmount(data: FirstShimizuAmountParams) {
        return request.put<ResponseResult<object>>(
          "/sys-v100001/userSalary/firstShimizuAmount",
          data
        );
      }
      /**
       * @name 换新工资表详情
       * @param id 换新工资表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<UserSalary>>(
          `/sys-v100001/userSalary/info/${id}`
        );
      }
      export interface MyUserSalaryConfirmParams {
        /**
         * @description  确定有误原因
         */
        confirmDesc?: string;
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  员工确认：1已确定     3有误
         */
        userStatusId?: number;
      }
      /**
       * @name 我的业绩数据确认
       * @param data
       */
      export function myUserSalaryConfirm(data: MyUserSalaryConfirmParams) {
        return request.put<ResponseResult<object>>(
          "/sys-v100001/userSalary/myUserSalaryConfirm",
          data
        );
      }
      export interface MyUserSalaryListParams {
        /**
         * @description  部门id
         */
        deptId?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  报表录入时间查询
         */
        reportTime?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeIdList?: string;
        /**
         * @description  用户id
         */
        userId?: number;
        /**
         * @description  用户确定：1已确定  2未确定  3有误
         */
        userStatusId?: number;
      }
      /**
       * @name 我的工资数据,小程序使用
       * @param params
       */
      export function myUserSalaryList(params: MyUserSalaryListParams) {
        return request.get<ResponseResult<PageList<UserSalary>>>(
          "/sys-v100001/userSalary/myUserSalaryList",
          { params }
        );
      }
      export interface ReportListParams {
        /**
         * @description  部门id
         */
        deptId?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  报表录入时间查询
         */
        reportTime?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeIdList?: string;
        /**
         * @description  用户id
         */
        userId?: number;
        /**
         * @description  用户确定：1已确定  2未确定  3有误
         */
        userStatusId?: number;
      }
      /**
       * @name 换新工资表列表
       * @param params
       */
      export function reportList(params: ReportListParams) {
        return request.get<ResponseResult<PageList<UserSalary>>>(
          "/sys-v100001/userSalary/reportList",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  reportTime
         */
        reportTime?: string;
      }
      /**
       * @name 换新工资表重新生成
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/userSalary/save",
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  代退房提层
         */
        exitAmount?: number;
        /**
         * @description  代房东退房数量
         */
        exitHouseCount?: number;
        /**
         * @description  代租客退房数量
         */
        exitTenantsCount?: number;
        /**
         * @description  被出房提层
         */
        outAmount?: number;
        /**
         * @description  出房绩点分
         */
        performanceIn?: number;
        /**
         * @description  被出房绩点分
         */
        performanceOut?: number;
        /**
         * @description  业绩金额
         */
        rewardAmount?: number;
        /**
         * @description  管家提层
         */
        stewardAmount?: number;
        /**
         * @description  管理房源数量
         */
        stewardHouseCount?: number;
        /**
         * @description  完成任务奖励
         */
        taskAmount?: number;
        /**
         * @description  代回访提层
         */
        visitAmount?: number;
        /**
         * @description  代回访数量
         */
        visitCount?: number;
      }
      /**
       * @name 换新工资表修改
       * @param id 换新工资表修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/sys-v100001/userSalary/update/${id}`,
          data
        );
      }
    }
    export namespace weixinConfig {
      /**
       * @name 微信配置表删除
       * @param id 微信配置表删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/sys-v100001/weixinConfig/delete/${id}`
        );
      }
      /**
       * @name 微信配置表详情
       * @param id 微信配置表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<WeixinConfig>>(
          `/sys-v100001/weixinConfig/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  微信appid
         */
        appId?: string;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  1公众号 2客户 3租客 4房东 5在线找房
         */
        type?: number;
      }
      /**
       * @name 微信配置表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<WeixinConfig>>>(
          "/sys-v100001/weixinConfig/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  微信appid
         */
        appId?: string;
        /**
         * @description  微信appsecret
         */
        appSecret?: string;
        /**
         * @description  下拉接口
         */
        type: number;
      }
      /**
       * @name 微信配置表新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/sys-v100001/weixinConfig/save",
          data
        );
      }
      /**
       * @name 微信配置类型下拉框
       */
      export function select() {
        return request.get<ResponseResult<Array<SelectVo>>>(
          "/sys-v100001/weixinConfig/select"
        );
      }
      export interface UpdateParams {
        /**
         * @description  微信appid
         */
        appId?: string;
        /**
         * @description  微信appsecret
         */
        appSecret?: string;
        /**
         * @description  下拉接口
         */
        type: number;
      }
      /**
       * @name 微信配置表修改
       * @param id 微信配置表修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/sys-v100001/weixinConfig/update/${id}`,
          data
        );
      }
      export interface GetUploadImgQrParams {
        /**
         * @description  本次上传图片唯一标识，前端生成一个字符串 长一点的随机数
         */
        key?: string;
        /**
         * @description  前端页面跳转路径
         */
        url?: string;
      }
      /**
       * @name 生成图片上传二维码
       * @param params
       */
      export function getUploadImgQr(params: GetUploadImgQrParams) {
        return request.get("/sys-v100001/weixinConfig/getUploadImgQr", {
          params
        });
      }
      export interface GetWeiXinServerIdImgUrlParams {
        /**
         * @description  本次上传图片唯一标识
         */
        key?: string;
      }
      /**
       * @name 通过config接口注入权限验证配置
       * @param params
       */
      export function getWeiXinServerIdImgUrl(
        params: GetWeiXinServerIdImgUrlParams
      ) {
        return request.get<ResponseResult<object>>(
          "/sys-v100001/weixinConfig/getWeiXinServerIdImgUrl",
          { params }
        );
      }
    }
    export namespace wxThree {
      export namespace code {
        export interface AddTemplateParams {
          /**
           * @description  草稿ID
           */
          draft_id: string;
        }
        /**
         * @name 将草稿添加到模板库
         * @param data
         */
        export function addTemplate(data: AddTemplateParams) {
          return request.post<ResponseResult<object>>(
            "/sys-v100001/wxThree/code/addTemplate",
            data
          );
        }
        export interface AppIdListParams {
          /**
           * @description  微信appid
           */
          appId?: string;
          pageNo?: number;
          pageSize?: number;
          sortField?: string;
          sortWay?: string;
          /**
           * @description  1公众号 2客户 3租客 4房东 5在线找房
           */
          type?: number;
        }
        /**
         * @name 微信小程序配置表列表
         * @param params
         */
        export function appIdList(params: AppIdListParams) {
          return request.get<ResponseResult<PageList<WeixinConfig>>>(
            "/sys-v100001/wxThree/code/appIdList",
            { params }
          );
        }
        export interface DelTemplateParams {
          /**
           * @description  模板ID
           */
          template_id: string;
        }
        /**
         * @name 删除模板
         * @param data
         */
        export function delTemplate(data: DelTemplateParams) {
          return request.post<ResponseResult<object>>(
            "/sys-v100001/wxThree/code/delTemplate",
            data
          );
        }
        /**
         * @name 代码草稿列表
         */
        export function draftList() {
          return request.get<ResponseResult<object>>(
            "/sys-v100001/wxThree/code/draftList"
          );
        }
        export interface GetAuditstatusParams {
          /**
           * @description  列表主键
           */
          id?: number;
          /**
           * @description  2客户端 3租客   5在线找房
           */
          type: number;
        }
        /**
         * @name 查询指定发布审核单的审核状态
         * @param data
         */
        export function getAuditstatus(data: GetAuditstatusParams) {
          return request.post<ResponseResult<object>>(
            "/sys-v100001/wxThree/code/getAuditstatus",
            data
          );
        }
        export interface ReleaseParams {
          /**
           * @description  列表主键
           */
          id?: number;
          /**
           * @description  2客户端 3租客   5在线找房
           */
          type: number;
        }
        /**
         * @name 发布已审核的小程序
         * @param data
         */
        export function release(data: ReleaseParams) {
          return request.post<ResponseResult<object>>(
            "/sys-v100001/wxThree/code/release",
            data
          );
        }
        export interface RevertcodereleaseParams {
          /**
           * @description  列表主键
           */
          id?: number;
          /**
           * @description  2客户端 3租客   5在线找房
           */
          type: number;
        }
        /**
         * @name 小程序版本回退
         * @param data
         */
        export function revertcoderelease(data: RevertcodereleaseParams) {
          return request.post<ResponseResult<object>>(
            "/sys-v100001/wxThree/code/revertcoderelease",
            data
          );
        }
        export interface SetprivacysettingParams {
          /**
           * @description  列表主键
           */
          id?: number;
          /**
           * @description  2客户端 3租客   5在线找房
           */
          type: number;
        }
        /**
         * @name 配置小程序用户隐私保护指引
         * @param data
         */
        export function setprivacysetting(data: SetprivacysettingParams) {
          return request.post<ResponseResult<object>>(
            "/sys-v100001/wxThree/code/setprivacysetting",
            data
          );
        }
        export interface SpeedupauditParams {
          /**
           * @description  列表主键
           */
          id?: number;
          /**
           * @description  2客户端 3租客   5在线找房
           */
          type: number;
        }
        /**
         * @name 加急审核申请 每年只有3次机会
         * @param data
         */
        export function speedupaudit(data: SpeedupauditParams) {
          return request.post<ResponseResult<object>>(
            "/sys-v100001/wxThree/code/speedupaudit",
            data
          );
        }
        export interface SubmitAuditParams {
          /**
           * @description  列表主键
           */
          id?: number;
          /**
           * @description  2客户端 3租客  5在线找房
           */
          type: number;
          /**
           * @description  小程序版本说明和功能解释
           */
          version_desc?: string;
        }
        /**
         * @name 提交审核
         * @param data
         */
        export function submitAudit(data: SubmitAuditParams) {
          return request.post<ResponseResult<object>>(
            "/sys-v100001/wxThree/code/submitAudit",
            data
          );
        }
        /**
         * @name 模板列表
         */
        export function templateList() {
          return request.get<ResponseResult<object>>(
            "/sys-v100001/wxThree/code/templateList"
          );
        }
        export interface UndocodeauditParams {
          /**
           * @description  列表主键
           */
          id?: number;
          /**
           * @description  2客户端 3租客   5在线找房
           */
          type: number;
        }
        /**
         * @name 审核撤回
         * @param data
         */
        export function undocodeaudit(data: UndocodeauditParams) {
          return request.post<ResponseResult<object>>(
            "/sys-v100001/wxThree/code/undocodeaudit",
            data
          );
        }
        export interface UploadCodeParams {
          /**
           * @description  自定义的配置
           */
          ext_json: string;
          /**
           * @description  模板ID
           */
          template_id: string;
          /**
           * @description  2客户端 3租客   5在线找房
           */
          type: number;
          /**
           * @description  代码描述
           */
          user_desc: string;
          /**
           * @description  代码版本号
           */
          user_version: string;
        }
        /**
         * @name 上传代码
         * @param data
         */
        export function uploadCode(data: UploadCodeParams) {
          return request.post<ResponseResult<object>>(
            "/sys-v100001/wxThree/code/uploadCode",
            data
          );
        }
      }
    }
  }
}
