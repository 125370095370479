import {ContentBlock, ContentState, EditorState} from "draft-js";
import {Atomic} from "../components/atomic";
import {CODE_TAG_REGEX} from "../constants/code-tag";
import {compositeDecorator} from "../constants/compositeDecorator";
import {stateToHTML} from "draft-js-export-html";
import {OrderedSet} from "immutable";
import {CSSProperties} from "react";
import {DEFAULT_CUSTOM_STYLES} from "../constants/default-custom-styles";
import {RandomUtil} from "../../../../utils";

/**
 * 块渲染函数
 * @param blockContent
 */
export function blockRendererFn(blockContent: ContentBlock) {
    if (blockContent.getType() === "atomic") {
        return {
            component: Atomic,
            editable: false,
        };
    }
}

/**
 * code-tag处理策略
 * @param contentBlock
 * @param callback
 * @param contentState
 */
export function codeTagStrategy(contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState) {
    findWithRegex(new RegExp(CODE_TAG_REGEX), contentBlock, callback);
}

/**
 * 使用正则表达式查找
 * @param regex
 * @param contentBlock
 * @param callback
 */
export function findWithRegex(regex: RegExp, contentBlock: ContentBlock, callback: (start: number, end: number) => void) {
    const text = contentBlock.getText();
    let matchArr,
        start;
    while ((matchArr = regex.exec(text)) !== null) {
        start = matchArr.index;
        callback(start, start + matchArr[0].length);
    }
}

/**
 * 获取当前的code
 * @param state
 */
export function getCurrentCode(state: EditorState): string | undefined {
    const content = state.getCurrentContent();
    const selection = state.getSelection();
    const block = content.getBlockForKey(selection.getStartKey());
    const text = block.getText();
    let matchArr,
        start;
    const regex = new RegExp(CODE_TAG_REGEX);
    while ((matchArr = regex.exec(text)) !== null) {
        start = matchArr.index;
        if (selection.getStartOffset() > start && selection.getEndOffset() < start + matchArr[0].length) {
            return matchArr[0];
        }
    }
    return undefined;
}

/**
 * 获取当前插入code范围
 * @param state
 */
export function getCurrentCodeRange(state: EditorState): {start: number; end: number; text: string} | undefined {
    const content = state.getCurrentContent();
    const selection = state.getSelection();
    const block = content.getBlockForKey(selection.getStartKey());
    const text = block.getText();
    let matchArr,
        start;
    const regex = new RegExp(CODE_TAG_REGEX);
    while ((matchArr = regex.exec(text)) !== null) {
        start = matchArr.index;
        if (selection.getStartOffset() > start && selection.getEndOffset() < start + matchArr[0].length) {
            return {
                start,
                end: start + matchArr[0].length,
                text: matchArr[0]
            };
        }
    }
    return undefined;
}

/**
 * 按换行符分割分本
 * @param text
 */
export function splitText(text: string) {
    const r = /\n/;
    const rn = /\r\n/;
    if (rn.test(text)) {
        return text.split(rn);
    }
    return text.split(r);
}

/**
 * 块样式处理函数
 * @param blockContent
 */
export function blockStyleFn(blockContent: ContentBlock) {
    const style =  blockContent.getInlineStyleAt(0);
    const isCenter = style.includes("TEXT_ALIGN_CENTER");
    const isLeft = style.includes("TEXT_ALIGN_LEFT");
    const isRight = style.includes("TEXT_ALIGN_RIGHT");

    if (isCenter) {
        return "text-align-center";
    }

    if(isLeft) {
        return "text-align-left";
    }

    if (isRight) {
        return "text-align-right";
    }

    return "";
}

/**
 * 转换为html
 * @param state
 */
export function toHtml(state: EditorState) {
    return stateToHTML(state.getCurrentContent(), {
        // @ts-ignore
        inlineStyleFn: (styles: OrderedSet) => {
            let style: CSSProperties = {};
            styles.forEach((name: string) => {
                const find = DEFAULT_CUSTOM_STYLES[name];
                style = Object.assign(style, find);
            });
            if (Object.keys(style).length) {
                return {style};
            }
            return {};
        },
        blockStyleFn: (block) => {
            const style = block.getInlineStyleAt(0);
            const isCenter = style.includes("TEXT_ALIGN_CENTER");
            const isLeft = style.includes("TEXT_ALIGN_LEFT");
            const isRight = style.includes("TEXT_ALIGN_RIGHT");
            if (isCenter) {
                return {
                    style: {
                        textAlign: "center",
                    }
                };
            }
            if (isLeft) {
                return {
                    style: {
                        textAlign: "left",
                    }
                };
            }
            if (isRight) {
                return {
                    style: {
                        textAlign: "right",
                    }
                };
            }
            return undefined;
        },
        blockRenderers: {
            atomic: (block) => {
                const currentContent = state.getCurrentContent();
                const entity = currentContent.getEntity(currentContent.getLastCreatedEntityKey());
                switch (entity.getType()) {
                    case "hr":
                        return "<hr />";
                }
                return "";
            }
        },
    });
}

/**
 * 格式化行内样式
 * @param style
 */
export function formatInlineStyleToMap(style: string = "") {
    const styles: {[key: string]: string} = {};
    style.split(";").forEach(item => {
        const [styleName, styleValue] = item.split(":");
        if (styleName && styleValue) {
            styles[styleName.trim()] = styleValue.trim();
        }
    });
    return styles;
}

/**
 * 创建空state
 */
export function createState() {
    return EditorState.createEmpty(compositeDecorator);
}

/**
 * 创建block key
 * @param contentState
 */
export function createRandomBlockKey(contentState: ContentState): string {
    const key = RandomUtil.createRandomKey(3);
    if (contentState.getBlockMap().has(key)) {
        return createRandomBlockKey(contentState);
    }
    return key;
}

export function toPdf(state: ContentState) {

}