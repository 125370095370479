import React, { CSSProperties } from "react";
import { Button, Input, Tooltip } from "antd";
import { ButtonType } from "../constants";
import { MediaType, TooBarButton } from "../types";
import { EditorState, RichUtils } from "draft-js";
import { StyleMap } from "../constants/default-custom-styles";

export function ToolBar(props: ToolBarProps) {
  const { options = [], style, className, state } = props;
  return (
    <div className={className} style={style}>
      <Input.Group compact>
        {options.map((item) => {
          switch (item.type) {
            case ButtonType.INLINE_STYLE_BUTTON:
              return (
                <Tooltip placement="top" {...item.tooltip}>
                  <Button
                    icon={item.icon}
                    type={
                      state.getCurrentInlineStyle().includes(item.style)
                        ? "primary"
                        : "default"
                    }
                    onClick={() => props.toggleInlineStyle(item.style)}
                  />
                </Tooltip>
              );
            case ButtonType.BLOCK_TYPE_BUTTON:
              return (
                <Tooltip placement="top" {...item.tooltip}>
                  <Button
                    icon={item.icon}
                    type={
                      RichUtils.getCurrentBlockType(state) === item.style
                        ? "primary"
                        : "default"
                    }
                    onClick={() => props.toggleBlockType(item.style)}
                  />
                </Tooltip>
              );
            case ButtonType.BLOCK_TYPE_CUSTOM:
              return (
                <Tooltip placement="top" {...item.tooltip}>
                  {item.render(props.state, {
                    hasFullScreen: props.hasFullScreen,
                    toggleBlockType: props.toggleBlockType,
                    toggleInlineStyle: props.toggleInlineStyle,
                    toggleTextAlign: props.toggleTextAlign,
                    insertMedia: props.insertMedia,
                    toggleFullScreen: props.toggleFullScreen,
                    insertText: props.insertText,
                    clearInlineStyle: props.clearInlineStyle,
                    redo: props.redo,
                    undo: props.undo,
                  })}
                </Tooltip>
              );
            default:
              return null;
          }
        })}
      </Input.Group>
    </div>
  );
}

export interface ToolBarProps {
  state: EditorState;
  className?: string;
  hasFullScreen?: boolean;
  style?: CSSProperties;
  options: TooBarButton[];
  toggleBlockType: (blockType: string) => void;
  toggleInlineStyle: (inlineStyle: string) => void;
  toggleTextAlign: (inlineStyle: string, map: StyleMap) => void;
  toggleFullScreen: () => void;
  insertText: (text: string) => void;
  insertMedia: (media: MediaType) => void;
  clearInlineStyle: () => void;
  undo: () => void;
  redo: () => void;
}
