import React from "react";

export const HEADING_BLOCK = [
    {
        value: "unstyled",
        element: React.createElement("span", {
            children: "常规"
        })
    },
    {
        value: "header-one",
        element: React.createElement("h1", {
            children: "H1",
            style: {
                margin: 0,
            }
        })
    },
    {
        value: "header-two",
        element: React.createElement("h2", {
            children: "H2",
            style: {
                margin: 0,
            }
        })
    },
    {
        value: "header-three",
        element: React.createElement("h3", {
            children: "H3",
            style: {
                margin: 0,
            }
        })
    },
    {
        value: "header-four",
        element: React.createElement("h4", {
            children: "H4",
            style: {
                margin: 0,
            }
        })
    },
    {
        value: "header-five",
        element: React.createElement("h5", {
            children: "H5",
            style: {
                margin: 0,
            }
        })
    },
    {
        value: "header-six",
        element: React.createElement("h6", {
            children: "H6",
            style: {
                margin: 0,
            }
        })
    },
];