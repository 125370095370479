import { ColProps } from "antd";

export const formColLayout: ColProps = {
  lg: 8,
  md: 8,
  xxl: 8,
  sm: 8,
  xl: 8,
  xs: 24
};

export const formColLayout6: ColProps = {
  lg: 6,
  md: 6,
  xxl: 4,
  xl: 4,
  sm: 8,
  xs: 24
};
