import {CSSProperties} from "react";
import {colors} from "./colors";
import {lineHeightList} from "./line-height-list";
import {fontSizeList} from "./font-size-list";
import {letterSpacingList} from "./letter-spacing-list";

export const DEFAULT_CUSTOM_COLOR_STYLE: StyleMap = {};
export const DEFAULT_CUSTOM_BACKGROUND_COLOR_STYLE: StyleMap = {};
export const DEFAULT_TEXT_ALIGN_STYLE: StyleMap = {
    TEXT_ALIGN_LEFT: {
        textAlign: "left",
    },
    TEXT_ALIGN_CENTER: {
        textAlign: "center",
    },
    TEXT_ALIGN_RIGHT: {
        textAlign: "right",
    }
};
export const DEFAULT_LINE_HEIGHT_STYLE: StyleMap = {};
export const DEFAULT_FONT_SIZE_STYLE: StyleMap = {};
export const DEFAULT_LETTER_SPACING_STYLE: StyleMap = {};

colors.forEach(color => {
    DEFAULT_CUSTOM_COLOR_STYLE[`COLOR${color}`.toUpperCase()] = {color};
    DEFAULT_CUSTOM_BACKGROUND_COLOR_STYLE[`BACKGROUND-COLOR${color}`.toUpperCase()] = {backgroundColor: color};
});
lineHeightList.forEach(lineHeight => {
    DEFAULT_LINE_HEIGHT_STYLE[`LINE_HEIGHT${lineHeight}`.toUpperCase()] = {lineHeight};
});
fontSizeList.forEach(fontSize => {
    DEFAULT_FONT_SIZE_STYLE[`FONT_SIZE${fontSize}`.toUpperCase()] = {fontSize};
});
letterSpacingList.forEach(letterSpacing => {
    DEFAULT_FONT_SIZE_STYLE[`LETTER_SPACING${letterSpacing}`.toUpperCase()] = {letterSpacing};
});


export const DEFAULT_CUSTOM_STYLES: StyleMap = Object.assign(
    {},
    DEFAULT_FONT_SIZE_STYLE,
    DEFAULT_TEXT_ALIGN_STYLE,
    DEFAULT_LINE_HEIGHT_STYLE,
    DEFAULT_CUSTOM_COLOR_STYLE,
    DEFAULT_LETTER_SPACING_STYLE,
    DEFAULT_CUSTOM_BACKGROUND_COLOR_STYLE,
);

export interface StyleMap {
    [key: string]: CSSProperties;
}
