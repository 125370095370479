/**
 * 可查看房源权限
 */
export enum UserLookType {
  BY_STORE = 1,
  BY_STORE_GROUP,
  BY_ALL,
  look_type_4,
  look_type_5,
}
