import { useContext } from "react";
import { RouteContext } from "../context";
import { Route } from "../routes";
import { Exception } from "../lib/exception";

export function useRoute(): Route {
  const context = useContext(RouteContext);
  if (!context.route) {
    throw new Exception("Cannot find route in this context.");
  }
  return context.route;
}
