import { useEffect, useMemo, useState } from "react";
import { useRoute } from "./useRoute";
import { useRouter } from "./useRouter";
import { ClassConstructor, plainToClass } from "class-transformer";
import qs from "qs";

export function useQuery<T>(classType?: ClassConstructor<T>) {
  const [path] = useState(useRoute().path);
  const { pathname, search } = useRouter();
  const plain = useMemo<T>(
    () => qs.parse(search.replace(/^\?/, "")) as any,
    [search]
  );
  const [state, setState] = useState<T>(
    classType ? plainToClass(classType, plain) : plain
  );
  useEffect(() => {
    if (path === pathname) {
      if (classType) {
        setState(plainToClass(classType, plain));
      } else {
        setState(plain);
        console.log(plain);
      }
    }
  }, [classType, path, pathname, plain, search]);

  return state;
}
