import React, {
  createRef,
  CSSProperties,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  AtomicBlockUtils,
  BlockMapBuilder,
  ContentBlock,
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
  Modifier,
  RichUtils,
} from "draft-js";
import { ToolBar } from "./components/tool-bar";
import { MediaType, TooBarButton } from "./types";
import { DEFAULT_TOOLS } from "./constants/default-tools";
import "./styles/index.less";
import {
  DEFAULT_CUSTOM_STYLES,
  StyleMap,
} from "./constants/default-custom-styles";
import {
  blockRendererFn,
  blockStyleFn,
  createRandomBlockKey,
  getCurrentCodeRange,
  splitText,
  toHtml,
} from "./utils";
import { compositeDecorator } from "./constants/compositeDecorator";
import { stateFromHTML } from "draft-js-import-html";

export function RichTextEditor(props: RichTextEditorProps) {
  const {
    tools = DEFAULT_TOOLS,
    customStyleMap = {},
    style,
    editorStyle,
    extendsTools = [],
  } = props;
  const { state = EditorState.createEmpty() } = props;
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const onChange = useCallback(
    (s: EditorState) => {
      if (props.onChange) {
        props.onChange(EditorState.set(s, { decorator: compositeDecorator }));
      }
    },
    [props]
  );

  const toggleInlineStyle = useCallback(
    (inlineStyle: string, filterMap?: StyleMap) => {
      if (filterMap) {
        const selection = state.getSelection();
        const nextContentState = Object.keys(filterMap).reduce(
          (contentState, style) => {
            return Modifier.removeInlineStyle(contentState, selection, style);
          },
          state.getCurrentContent()
        );

        let nextEditorState = EditorState.push(
          state,
          nextContentState,
          "change-inline-style"
        );
        onChange(RichUtils.toggleInlineStyle(nextEditorState, inlineStyle));
      } else {
        onChange(RichUtils.toggleInlineStyle(state, inlineStyle));
      }
    },
    [onChange, state]
  );
  const toggleTextAlign = useCallback(
    (inlineStyle: string, filterMap: StyleMap) => {
      let selection = state.getSelection();
      const text = state
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getText();
      selection = selection.merge({
        anchorOffset: 0,
        focusOffset: text.length,
      });
      const nextContentState = Object.keys(filterMap).reduce(
        (contentState, style) => {
          return Modifier.removeInlineStyle(contentState, selection, style);
        },
        state.getCurrentContent()
      );

      onChange(
        EditorState.push(
          state,
          Modifier.applyInlineStyle(nextContentState, selection, inlineStyle),
          "change-inline-style"
        )
      );
    },
    [onChange, state]
  );
  const toggleBlockType = useCallback(
    (blockType: string) => {
      onChange(RichUtils.toggleBlockType(state, blockType));
    },
    [onChange, state]
  );
  const insertText = useCallback(
    (text: string) => {
      const selection = state.getSelection();
      const currentCode = getCurrentCodeRange(state);

      if (currentCode) {
        const selectionState = selection.merge({
          anchorOffset: currentCode.start,
          focusOffset: currentCode.end,
        });
        return onChange(
          EditorState.push(
            state,
            Modifier.replaceText(
              state.getCurrentContent(),
              selectionState,
              text
            ),
            "insert-characters"
          )
        );
      }

      if (selection.isCollapsed()) {
        onChange(
          EditorState.push(
            state,
            Modifier.insertText(state.getCurrentContent(), selection, text),
            "insert-characters"
          )
        );
      } else {
        onChange(
          EditorState.push(
            state,
            Modifier.replaceText(state.getCurrentContent(), selection, text),
            "insert-characters"
          )
        );
      }
    },
    [onChange, state]
  );

  const insertMedia = useCallback(
    (media: MediaType) => {
      const contentState = state.getCurrentContent();
      const currentContent = contentState.createEntity(
        media.type,
        "IMMUTABLE",
        {
          src: media.src,
        }
      );
      const entityKey = currentContent.getLastCreatedEntityKey();
      const editorState = EditorState.set(state, { currentContent });
      onChange(AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ""));
    },
    [onChange, state]
  );
  const redo = useCallback(() => {
    onChange(EditorState.redo(state));
  }, [onChange, state]);
  const undo = useCallback(() => {
    onChange(EditorState.undo(state));
  }, [onChange, state]);
  const toggleFullScreen = useCallback(
    () => setFullScreen(!fullScreen),
    [fullScreen, setFullScreen]
  );

  const clearInlineStyle = useCallback(() => {
    const selection = state.getSelection();
    const nextContentState = Object.keys(DEFAULT_CUSTOM_STYLES).reduce(
      (contentState, color) => {
        return Modifier.removeInlineStyle(contentState, selection, color);
      },
      state.getCurrentContent()
    );

    onChange(
      RichUtils.toggleBlockType(
        EditorState.push(state, nextContentState, "change-inline-style"),
        "unstyled"
      )
    );
  }, [onChange, state]);

  const mergeTools = useMemo(() => {
    return [...tools, ...extendsTools];
  }, [tools, extendsTools]);
  const styleMap = useMemo<StyleMap>(() => {
    return Object.assign(DEFAULT_CUSTOM_STYLES, customStyleMap);
  }, [customStyleMap]);
  const editorRef = createRef<Editor>();

  return (
    <div
      className="rich-text-editor"
      style={
        fullScreen
          ? {
              position: "fixed",
              left: 0,
              top: 0,
              width: "100vw",
              height: "100vh",
            }
          : style
      }
    >
      <ToolBar
        hasFullScreen={fullScreen}
        className="rich-text-editor-toolbar"
        state={state}
        options={mergeTools}
        toggleBlockType={toggleBlockType}
        toggleInlineStyle={toggleInlineStyle}
        toggleTextAlign={toggleTextAlign}
        insertText={insertText}
        insertMedia={insertMedia}
        toggleFullScreen={toggleFullScreen}
        clearInlineStyle={clearInlineStyle}
        undo={undo}
        redo={redo}
      />

      <div
        className="rich-text-editor-editor"
        style={editorStyle}
        onClick={() => {
          if (editorRef.current) {
            editorRef.current.focus();
          }
        }}
      >
        <Editor
          ref={editorRef}
          editorState={state}
          customStyleMap={styleMap}
          onChange={(editorState) => {
            if (onChange) {
              onChange(editorState);
            }
          }}
          blockStyleFn={blockStyleFn}
          blockRendererFn={blockRendererFn}
          onTab={(e) => {
            RichUtils.onTab(e, state, 0);
            e.preventDefault();
            const selection = state.getSelection();
            if (selection.getStartOffset() === selection.getEndOffset()) {
              onChange(
                EditorState.forceSelection(
                  EditorState.push(
                    state,
                    Modifier.insertText(
                      state.getCurrentContent(),
                      selection,
                      "\t"
                    ),
                    "insert-characters"
                  ),
                  selection.merge({
                    focusOffset: selection.getFocusOffset() + 1,
                    anchorOffset: selection.getFocusOffset() + 1,
                  })
                )
              );
            } else {
              onChange(
                EditorState.push(
                  state,
                  Modifier.replaceText(
                    state.getCurrentContent(),
                    selection,
                    "\t"
                  ),
                  "insert-characters"
                )
              );
            }
          }}
          handlePastedText={(text, html, editorState) => {
            const content = editorState.getCurrentContent();
            onChange(
              EditorState.push(
                editorState,
                Modifier.replaceWithFragment(
                  content,
                  state.getSelection(),
                  BlockMapBuilder.createFromArray(
                    splitText(text).map((text) => {
                      return new ContentBlock({
                        key: createRandomBlockKey(content),
                        text,
                        type: "unstyled",
                      });
                    })
                  )
                ),
                "insert-fragment"
              )
            );
            return "handled";
          }}
        />
      </div>
    </div>
  );
}

RichTextEditor.toHtml = toHtml;
RichTextEditor.fromHTMl = (html: string): EditorState => {
  // const blocksFromHTML = convertFromHTML(html);
  // const state = ContentState.createFromBlockArray(
  //   blocksFromHTML.contentBlocks,
  //   blocksFromHTML.entityMap
  // );
  return EditorState.createWithContent(stateFromHTML(html), compositeDecorator);
};
RichTextEditor.toRaw = (state: EditorState) =>
  convertToRaw(state.getCurrentContent());
RichTextEditor.fromRow = (rowState: string) =>
  EditorState.createWithContent(
    convertFromRaw(JSON.parse(rowState)),
    compositeDecorator
  );

export interface RichTextEditorProps {
  style?: CSSProperties;
  editorStyle?: CSSProperties;
  state?: EditorState;
  onChange?: (state: EditorState) => void;
  tools?: TooBarButton[];
  extendsTools?: TooBarButton[];
  customStyleMap?: StyleMap;
}
