import {Form, FormProps} from "antd";
import React, {ReactNode} from "react";

export function CurrencyForm<T>(props: CurrencyFormProps) {
    return (
        <Form
            requiredMark="optional"
            layout="vertical"
            {...props}
        />
    );
}

export interface CurrencyFormProps extends FormProps {
    children?: ReactNode;
}
