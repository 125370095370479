import React from "react";
import {TooBarButton} from "../types";
import {ButtonType} from "./button-type";
import {Button, Dropdown, Select} from "antd";
import {
    AlignCenterOutlined,
    AlignLeftOutlined,
    AlignRightOutlined,
    BgColorsOutlined,
    BoldOutlined,
    FontColorsOutlined,
    FormatPainterOutlined,
    FullscreenExitOutlined,
    FullscreenOutlined,
    ItalicOutlined,
    OrderedListOutlined,
    RedoOutlined,
    StrikethroughOutlined,
    UnderlineOutlined,
    UndoOutlined,
    UnorderedListOutlined
} from "@ant-design/icons";
import {CompactPicker} from "react-color";
import {colors} from "./colors";
import {
    DEFAULT_CUSTOM_BACKGROUND_COLOR_STYLE,
    DEFAULT_CUSTOM_COLOR_STYLE,
    DEFAULT_FONT_SIZE_STYLE,
    DEFAULT_LETTER_SPACING_STYLE,
    DEFAULT_LINE_HEIGHT_STYLE,
    DEFAULT_TEXT_ALIGN_STYLE
} from "./default-custom-styles";
import {HEADING_BLOCK} from "./heading-block";
import {RichUtils} from "draft-js";
import {fontSizeList} from "./font-size-list";
import {letterSpacingList} from "./letter-spacing-list";

export const DEFAULT_TOOLS: TooBarButton[] = [
    {
        type: ButtonType.BLOCK_TYPE_CUSTOM,
        tooltip: {title: "全屏"},
        render: (state, option) => (
            <Button
                onClick={() => option.toggleFullScreen()}
                icon={option.hasFullScreen ? <FullscreenExitOutlined/> : <FullscreenOutlined/>}
            />
        )
    },
    {
        type: ButtonType.BLOCK_TYPE_CUSTOM,
        tooltip: {
            title: "撤销",
        },
        render: (state, option) => (
            <Button
                icon={<UndoOutlined/>}
                type="default"
                onClick={() => option.undo()}
                disabled={!(state.getUndoStack().size > 0)}
            />
        )
    },
    {
        type: ButtonType.BLOCK_TYPE_CUSTOM,
        tooltip: {
            title: "恢复",
        },
        render: (state, option) => (
            <Button
                icon={<RedoOutlined/>}
                type="default"
                onClick={() => option.redo()}
                disabled={!(state.getRedoStack().size > 0)}
            />
        )
    },

    {
        type: ButtonType.INLINE_STYLE_BUTTON,
        tooltip: {
            title: "加粗"
        },
        style: "BOLD",
        label: "加粗",
        icon: <BoldOutlined/>,
    },
    {
        type: ButtonType.INLINE_STYLE_BUTTON,
        tooltip: {
            title: "斜体"
        },
        style: "ITALIC",
        label: "斜体",
        icon: <ItalicOutlined/>,
    },
    {
        type: ButtonType.INLINE_STYLE_BUTTON,
        tooltip: {
            title: "下划线"
        },
        style: "UNDERLINE",
        label: "下划线",
        icon: <UnderlineOutlined/>,
    },
    {
        type: ButtonType.INLINE_STYLE_BUTTON,
        tooltip: {
            title: "删除线"
        },
        style: "STRIKETHROUGH",
        label: "删除线",
        icon: <StrikethroughOutlined/>
    },

    {
        type: ButtonType.BLOCK_TYPE_CUSTOM,
        tooltip: {
            title: "左对齐"
        },
        render: (state, option) => (
            <Button
                type={state.getCurrentInlineStyle().includes("TEXT_ALIGN_LEFT") ? "primary" : "default"}
                icon={<AlignLeftOutlined/>}
                onClick={() => option.toggleTextAlign("TEXT_ALIGN_LEFT", DEFAULT_TEXT_ALIGN_STYLE)}
            />
        ),
    },
    {
        type: ButtonType.BLOCK_TYPE_CUSTOM,
        tooltip: {
            title: "居中对齐"
        },
        render: (state, option) => (
            <Button
                type={state.getCurrentInlineStyle().includes("TEXT_ALIGN_CENTER") ? "primary" : "default"}
                icon={<AlignCenterOutlined/>}
                onClick={() => option.toggleTextAlign("TEXT_ALIGN_CENTER", DEFAULT_TEXT_ALIGN_STYLE)}
            />
        ),
    },
    {
        type: ButtonType.BLOCK_TYPE_CUSTOM,
        tooltip: {
            title: "右对齐"
        },
        render: (state, option) => (
            <Button
                type={state.getCurrentInlineStyle().includes("TEXT_ALIGN_RIGHT") ? "primary" : "default"}
                icon={<AlignRightOutlined/>}
                onClick={() => option.toggleTextAlign("TEXT_ALIGN_RIGHT", DEFAULT_TEXT_ALIGN_STYLE)}
            />
        ),
    },

    {
        type: ButtonType.BLOCK_TYPE_CUSTOM,
        tooltip: {
            title: "字体颜色",
        },
        render: (state, option) => {
            const styles = state.getCurrentInlineStyle();
            const find = styles.find(item => /^COLOR/.test(item || ""));
            return (
                <Dropdown
                    trigger={["click"]}
                    overlay={
                        <CompactPicker
                            color={find ? find.replace("COLOR", "") : undefined}
                            colors={colors}
                            onChange={(_) => {
                                option.toggleInlineStyle(`COLOR${_.hex}`.toUpperCase(), DEFAULT_CUSTOM_COLOR_STYLE);
                            }}
                        />
                    }>
                    <Button
                        icon={
                            <FontColorsOutlined
                                style={{
                                    color: find ? find.replace("COLOR", "") : undefined,
                                }}
                            />
                        }
                    />
                </Dropdown>
            );
        }
    },
    {
        type: ButtonType.BLOCK_TYPE_CUSTOM,
        tooltip: {
            title: "背景颜色",
        },
        render: (state, option) => {
            const styles = state.getCurrentInlineStyle();
            const find = styles.find(item => /^BACKGROUND-COLOR/.test(item || ""));
            return (
                <Dropdown
                    trigger={["click"]}
                    overlay={
                        <CompactPicker
                            color={find ? find.replace("BACKGROUND-COLOR", "") : undefined}
                            colors={colors}
                            onChange={(_) => {
                                option.toggleInlineStyle(`BACKGROUND-COLOR${_.hex}`.toUpperCase(), DEFAULT_CUSTOM_BACKGROUND_COLOR_STYLE);
                            }}
                        />
                    }>
                    <Button
                        icon={
                            <BgColorsOutlined
                                style={{
                                    color: find ? find.replace("BACKGROUND-COLOR", "") : undefined,
                                }}
                            />
                        }
                    />
                </Dropdown>
            );
        }
    },
    {
        type: ButtonType.BLOCK_TYPE_CUSTOM,
        tooltip: {title: "标题"},
        render: (state, option) => {
            const blockType = RichUtils.getCurrentBlockType(state);
            const find = HEADING_BLOCK.find(it => it.value === blockType);
            return (
                <Select<string>
                    value={find ? find.value : undefined}
                    style={{width: 100}}
                    placeholder="标题"
                    onChange={value => option.toggleBlockType(value)}
                >
                    {HEADING_BLOCK.map(item => (
                        <Select.Option
                            key={item.value}
                            value={item.value}
                            children={item.element}
                        />
                    ))}
                </Select>
            );
        }
    },
    {
        type: ButtonType.BLOCK_TYPE_CUSTOM,
        tooltip: {title: "字号"},
        render: (state, option) => {
            const styles = state.getCurrentInlineStyle();
            const find = styles.find(item => /^FONT_SIZE/.test(item || ""));
            return (
                <Select<string>
                    value={find}
                    style={{width: 120}}
                    placeholder="字号"
                    onChange={value => option.toggleInlineStyle(value, DEFAULT_FONT_SIZE_STYLE)}
                >
                    {fontSizeList.map(item => (
                        <Select.Option
                            key={item}
                            value={`FONT_SIZE${item}`}
                            children={`字号 ${item}px`}
                        />
                    ))}
                </Select>
            );
        }
    },
    {
        type: ButtonType.BLOCK_TYPE_CUSTOM,
        tooltip: {title: "行间距"},
        render: (state, option) => {
            const styles = state.getCurrentInlineStyle();
            const find = styles.find(item => /^LINE_HEIGHT/.test(item || ""));
            return (
                <Select<string>
                    value={find}
                    style={{width: 120}}
                    placeholder="行间距"
                    onChange={value => option.toggleInlineStyle(value, DEFAULT_LINE_HEIGHT_STYLE)}
                >
                    {letterSpacingList.map(item => (
                        <Select.Option
                            key={item}
                            value={`LINE_HEIGHT${item}`}
                            children={`行间距 ${item}px`}
                        />
                    ))}
                </Select>
            );
        }
    },
    {
        type: ButtonType.BLOCK_TYPE_CUSTOM,
        tooltip: {title: "字间距"},
        render: (state, option) => {
            const styles = state.getCurrentInlineStyle();
            const find = styles.find(item => /^LETTER_SPACING/.test(item || ""));
            return (
                <Select<string>
                    value={find}
                    style={{width: 120}}
                    placeholder="字间距"
                    onChange={value => option.toggleInlineStyle(value, DEFAULT_LETTER_SPACING_STYLE)}
                >
                    {letterSpacingList.map(item => (
                        <Select.Option
                            key={item}
                            value={`LETTER_SPACING${item}`}
                            children={`字间距 ${item}px`}
                        />
                    ))}
                </Select>
            );
        }
    },

    {
        type: ButtonType.BLOCK_TYPE_CUSTOM,
        tooltip: {title: "格式化"},
        render: (state, option) => (
            <Button
                icon={<FormatPainterOutlined/>}
                type="default"
                onClick={() => option.clearInlineStyle()}
            />
        )
    },

    // {
    //   type: ButtonType.BLOCK_TYPE_CUSTOM,
    //   tooltip: {title: "分割线"},
    //   render: (state, option) => (
    //       <Button
    //           onClick={() => option.insertMedia({type: "hr"})}
    //           icon={<LineOutlined/>}
    //       />
    //   )
    // },

    {
        type: ButtonType.BLOCK_TYPE_BUTTON,
        tooltip: {
            title: "有序列表"
        },
        style: "ordered-list-item",
        label: "有序列表",
        icon: <OrderedListOutlined/>,
    },
    {
        type: ButtonType.BLOCK_TYPE_BUTTON,
        tooltip: {
            title: "无序列表"
        },
        style: "unordered-list-item",
        label: "无序列表",
        icon: <UnorderedListOutlined/>,
    },
];