export * from "./random";
export * from "./create-route";
export * from "./companyDicdata";
export * from "./compute";
export * from "./logger";
export * from "./menu";
export * from "./request";

/**
 * 是否为开发模式
 */
export function isDevMode(): boolean {
  return process.env.NODE_ENV === "development";
}

export function createNumberRange(option: CreateNumberRangeOption): number[] {
  const data: number[] = [];
  const { min = 0, max = 0 } = option;
  if (min === -Infinity) {
    throw new Error("Invalid option param `min`.");
  }
  if (max === Infinity) {
    throw new Error("Invalid option param `max`.");
  }

  let i = min;
  for (; i < max; i++) {
    data.push(i);
  }
  return data;
}

export interface CreateNumberRangeOption {
  min?: number;
  max?: number;
}
