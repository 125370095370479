import { notification } from "antd";
import Axios, { AxiosError } from "axios";
import qs from "qs";
import { GlobalConfig, ReduxActionType } from "../constants";
// import { GlobalConfigs } from "../constants";
import { GlobalConfigs } from "../constants";
import { Exception } from "../lib/exception";
import { store } from "../store";
import { WebStorageUtil } from "./webStorage";
import { log } from "console";
// import { useSelector } from "react-redux";
// import { ISystemState } from "../reducers/system";
// import { IStoreState } from "../store";
// import { useEdges } from "react-flow-renderer";
// import { useEffect } from "react";
export const request = Axios.create({
  baseURL: "/web",
  transformRequest: [
    (data) => {
      /**
       * @Author 韩家辉
       * 此处修改了参数判断 解决无法传输json问题
       */
      if (
        data instanceof FormData ||
        Object.prototype.toString.call(data) === "[object FormData]" ||
        Object.prototype.toString.call(data) === "[object String]"
      ) {
        return data;
      }

      return qs.stringify(data, {
        allowDots: true
      });
    }
  ],
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/x-www-form-urlencoded",
    token: WebStorageUtil.getItem(`TOKEN-${ReduxActionType.LOGIN}`),
    cityId:
      Number(localStorage.getItem("cityId")) == 510100
        ? Number(localStorage.getItem("cityId"))
        : undefined
  }
});

// 下载的路由添加请求响应为 arraybuffer
const DOWNLOAD_URLS = [
  `${GlobalConfig.HOUSE_API_VERSION}/roomTenants/exportRoom`,
  `${GlobalConfig.HOUSE_API_VERSION}/house/createExcelTemplate`,
  `${GlobalConfig.HOUSE_API_VERSION}/house/exportHouse`,
  `${GlobalConfig.HOUSE_API_VERSION}/contractTemplate/word`,
  `${GlobalConfig.HOUSE_API_VERSION}/contractTemplate/pdf/preview`,
  `${GlobalConfig.HOUSE_API_VERSION}/contractTemplate/preview`,
  `${GlobalConfig.HOUSE_API_VERSION}/hcontractRecord/download`,
  `${GlobalConfig.HOUSE_API_VERSION}/maintenanceClean/exportExcel`,
  `${GlobalConfig.HOUSE_API_VERSION}/roomTenants/houseAndTenantsExcel`,
  `${GlobalConfig.HOUSE_API_VERSION}/tenantsExit/isMarkListExport`,

  //财务导出
  `${GlobalConfig.FINANCE_API_VERSION}/finance/exportExcel/finance`,
  `${GlobalConfig.FINANCE_API_VERSION}/finance/exportExcel/debt`,
  `${GlobalConfig.FINANCE_API_VERSION}/finance/exportExcel/houseRent`,
  `${GlobalConfig.FINANCE_API_VERSION}/finance/exportExcel/incomeExpenseDetail`,
  `${GlobalConfig.FINANCE_API_VERSION}/finance/exportExcel/incomeExpensePlan`,
  `${GlobalConfig.FINANCE_API_VERSION}/finance/exportExcel/exportFinanceBank`,
  `${GlobalConfig.FINANCE_API_VERSION}/home/houseRent/appHouseRentExport`,
  `${GlobalConfig.FINANCE_API_VERSION}/finance/createExcelTemplate`,
  `${GlobalConfig.FINANCE_API_VERSION}/houseInsurance/createExcelTemplate`,

  //系统导出
  `${GlobalConfig.SYS_API_VERSION}/userPerformance/exportUserPerformance`,
  `${GlobalConfig.SYS_API_VERSION}/userSalary/exportUserSalary`,
  `${GlobalConfig.SYS_API_VERSION}/stewardManager/exportStewardManager`,
  `${GlobalConfig.SYS_API_VERSION}/store/storeExport`,
  `${GlobalConfig.SYS_API_VERSION}/user/userExport`,

  //其它导出
  `${GlobalConfig.OTHER_API_VERSION}/opinion/exportExcel`,
  `${GlobalConfig.OTHER_API_VERSION}/complaint/exportExcel`,
  `${GlobalConfig.OTHER_API_VERSION}/payRent/exportExcel`,
  `${GlobalConfig.OTHER_API_VERSION}/preLookHouse/exportExcel`,
  `${GlobalConfig.OTHER_API_VERSION}/opinion/exportExcel`,
  `${GlobalConfig.OTHER_API_VERSION}/reserveHouse/reportExport`,
  `${GlobalConfig.OTHER_API_VERSION}/reserveCustomers/reportExport`,
  `${GlobalConfig.OTHER_API_VERSION}/noLogin/preLookHouse/downloadFile`,
  `${GlobalConfig.OTHER_API_VERSION}/broadband/createExcelTemplate`,
  `${GlobalConfig.OTHER_API_VERSION}/warehouseMaterial/createExcelTemplate`,
  `${GlobalConfig.OTHER_API_VERSION}/out/repair/exportExcel`,
  `${GlobalConfig.OTHER_API_VERSION}/excelErrorMsg/errorMsgExport`,
  `${GlobalConfig.OTHER_API_VERSION}/auditRecodeTenants/export`,
  `${GlobalConfig.OTHER_API_VERSION}/auditRecodeHouse/export`,
  `${GlobalConfig.OTHER_API_VERSION}/warehouseMaterial/createExcelTemplateByMaterialNo`,
  `${GlobalConfig.OTHER_API_VERSION}/stewardChange/exportStewardChange`,

  //报表导出
  `${GlobalConfig.REPORT_API_VERSION}/bargain/reportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/contractRecord/batchCopyDownloadUrl`,
  `${GlobalConfig.REPORT_API_VERSION}/broadband/reportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/houseContract/reportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/pricing/reportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/houseHis/exportHouseReport`,
  `${GlobalConfig.REPORT_API_VERSION}/houseHis/exportHouseHisBackReport`,
  `${GlobalConfig.REPORT_API_VERSION}/house/houseEndReportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/house/houseWalkReportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/house/rentPoorCentralizeReportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/house/rentPoorReportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/goodsIncrease/reportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/decorate/reportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/tenantsContract/reportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/tenantsExit/reportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/emptyTime/companyReportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/emptyTime/storeReportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/maintenanceClean/reportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/finance/statistics/houseRentExportV1`,

  `${GlobalConfig.REPORT_API_VERSION}/tenantsEnergy/reportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/followUp/reportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/sublet/reportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/roomTenants/tenantsReportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/roomTenants/tenantsSubletOrChangeRoomReportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/takeLook/reportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/houseExit/reportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/roomTenants/tenantsEndReportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/tenantsHis/tenantsReportExport`,
  // `${GlobalConfig.REPORT_API_VERSION}/backUp/houseBackUp`,
  // `${GlobalConfig.REPORT_API_VERSION}/backUp/tenantsBackUp`,
  `${GlobalConfig.REPORT_API_VERSION}/houseContract/batchContractDownload`,
  `${GlobalConfig.REPORT_API_VERSION}/tenantsContract/batchContractDownload`,
  `${GlobalConfig.REPORT_API_VERSION}/otherChangeRoom/tenantsChangeRoomReportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/reserveHouse/exportStoreReport`,
  `${GlobalConfig.REPORT_API_VERSION}/reserveHouse/exportUserReport`,
  `${GlobalConfig.REPORT_API_VERSION}/reserveCustomers/exportStoreReport`,
  `${GlobalConfig.REPORT_API_VERSION}/reserveCustomers/exportUserReport`,

  `${GlobalConfig.REPORT_API_VERSION}/tenantsHis/exportTenantsHisBack`,
  `${GlobalConfig.REPORT_API_VERSION}/tenantsContract/exportTenantsContractBack`,
  `${GlobalConfig.REPORT_API_VERSION}/houseContract/exportContractBackReport`,
  `${GlobalConfig.REPORT_API_VERSION}/tenantsExit/reportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/rentPoor/rentPoorReportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/houseInsurance/reportExport`,
  `${GlobalConfig.REPORT_API_VERSION}/otherChangeRoom/exportOtherChangeBack`,
  `${GlobalConfig.REPORT_API_VERSION}/sublet/exportSubletBack`,
  `${GlobalConfig.REPORT_API_VERSION}/sublet/tenantsSubletBackUp`,
  `${GlobalConfig.REPORT_API_VERSION}/otherChangeRoom/tenantsChangeRoomBackUp`,
  `${GlobalConfig.REPORT_API_VERSION}/house/houseWalkReportExportV1`,
  `${GlobalConfig.REPORT_API_VERSION}/rentCollection/export`,
  `${GlobalConfig.REPORT_API_VERSION}/tenantsHis/exportTenantsReportV1`,
  `${GlobalConfig.REPORT_API_VERSION}/houseHis/exportHouseReportV1`,
  `${GlobalConfig.REPORT_API_VERSION}/broadbandSub/export`,
  `${GlobalConfig.REPORT_API_VERSION}/broadbandSub/exportV1`,
  `${GlobalConfigs.HOUSE_API_VERSION}/roomTenants/exportRoom`,
  `${GlobalConfigs.HOUSE_API_VERSION}/house/createExcelTemplate`,
  `${GlobalConfigs.HOUSE_API_VERSION}/house/exportHouse`,
  `${GlobalConfigs.HOUSE_API_VERSION}/contractTemplate/word`,
  `${GlobalConfigs.HOUSE_API_VERSION}/contractTemplate/pdf/preview`,
  `${GlobalConfigs.HOUSE_API_VERSION}/contractTemplate/preview`,
  `${GlobalConfigs.HOUSE_API_VERSION}/hcontractRecord/download`,
  `${GlobalConfigs.HOUSE_API_VERSION}/maintenanceClean/exportExcel`,
  `${GlobalConfigs.HOUSE_API_VERSION}/roomTenants/houseAndTenantsExcel`,
  `${GlobalConfigs.HOUSE_API_VERSION}/tenantsExit/isMarkListExport`,

  //财务导出
  `${GlobalConfigs.FINANCE_API_VERSION}/finance/exportExcel/finance`,
  `${GlobalConfigs.FINANCE_API_VERSION}/finance/exportExcel/debt`,
  `${GlobalConfigs.FINANCE_API_VERSION}/finance/exportExcel/houseRent`,
  `${GlobalConfigs.FINANCE_API_VERSION}/finance/exportExcel/incomeExpenseDetail`,
  `${GlobalConfigs.FINANCE_API_VERSION}/finance/exportExcel/incomeExpensePlan`,
  `${GlobalConfigs.FINANCE_API_VERSION}/finance/exportExcel/exportFinanceBank`,
  `${GlobalConfigs.FINANCE_API_VERSION}/home/houseRent/appHouseRentExport`,
  `${GlobalConfigs.FINANCE_API_VERSION}/finance/createExcelTemplate`,
  `${GlobalConfigs.FINANCE_API_VERSION}/houseInsurance/createExcelTemplate`,

  //系统导出
  `${GlobalConfigs.SYS_API_VERSION}/userPerformance/exportUserPerformance`,
  `${GlobalConfigs.SYS_API_VERSION}/userSalary/exportUserSalary`,
  `${GlobalConfigs.SYS_API_VERSION}/stewardManager/exportStewardManager`,
  `${GlobalConfigs.SYS_API_VERSION}/store/storeExport`,
  `${GlobalConfigs.SYS_API_VERSION}/user/userExport`,

  //其它导出
  `${GlobalConfigs.OTHER_API_VERSION}/opinion/exportExcel`,
  `${GlobalConfigs.OTHER_API_VERSION}/complaint/exportExcel`,
  `${GlobalConfigs.OTHER_API_VERSION}/payRent/exportExcel`,
  `${GlobalConfigs.OTHER_API_VERSION}/preLookHouse/exportExcel`,
  `${GlobalConfigs.OTHER_API_VERSION}/opinion/exportExcel`,
  `${GlobalConfigs.OTHER_API_VERSION}/reserveHouse/reportExport`,
  `${GlobalConfigs.OTHER_API_VERSION}/reserveCustomers/reportExport`,
  `${GlobalConfigs.OTHER_API_VERSION}/noLogin/preLookHouse/downloadFile`,
  `${GlobalConfigs.OTHER_API_VERSION}/broadband/createExcelTemplate`,
  `${GlobalConfigs.OTHER_API_VERSION}/warehouseMaterial/createExcelTemplate`,
  `${GlobalConfigs.OTHER_API_VERSION}/out/repair/exportExcel`,
  `${GlobalConfigs.OTHER_API_VERSION}/excelErrorMsg/errorMsgExport`,
  `${GlobalConfigs.OTHER_API_VERSION}/auditRecodeTenants/export`,
  `${GlobalConfigs.OTHER_API_VERSION}/auditRecodeHouse/export`,
  `${GlobalConfigs.OTHER_API_VERSION}/warehouseMaterial/createExcelTemplateByMaterialNo`,
  `${GlobalConfigs.OTHER_API_VERSION}/stewardChange/exportStewardChange`,

  //报表导出
  `${GlobalConfigs.REPORT_API_VERSION}/bargain/reportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/contractRecord/batchCopyDownloadUrl`,
  `${GlobalConfigs.REPORT_API_VERSION}/broadband/reportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/houseContract/reportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/pricing/reportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/houseHis/exportHouseReport`,
  `${GlobalConfigs.REPORT_API_VERSION}/houseHis/exportHouseHisBackReport`,
  `${GlobalConfigs.REPORT_API_VERSION}/house/houseEndReportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/house/houseWalkReportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/house/rentPoorCentralizeReportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/house/rentPoorReportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/goodsIncrease/reportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/decorate/reportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/finance/statistics/houseRentExportV1`,

  `${GlobalConfigs.REPORT_API_VERSION}/tenantsContract/reportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/tenantsExit/reportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/emptyTime/companyReportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/emptyTime/storeReportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/maintenanceClean/reportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/tenantsEnergy/reportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/followUp/reportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/sublet/reportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/roomTenants/tenantsReportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/roomTenants/tenantsSubletOrChangeRoomReportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/takeLook/reportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/houseExit/reportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/roomTenants/tenantsEndReportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/tenantsHis/tenantsReportExport`,
  // `${GlobalConfig.REPORT_API_VERSION}/backUp/houseBackUp`,
  // `${GlobalConfig.REPORT_API_VERSION}/backUp/tenantsBackUp`,
  `${GlobalConfigs.REPORT_API_VERSION}/houseContract/batchContractDownload`,
  `${GlobalConfigs.REPORT_API_VERSION}/tenantsContract/batchContractDownload`,
  `${GlobalConfigs.REPORT_API_VERSION}/otherChangeRoom/tenantsChangeRoomReportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/reserveHouse/exportStoreReport`,
  `${GlobalConfigs.REPORT_API_VERSION}/reserveHouse/exportUserReport`,
  `${GlobalConfigs.REPORT_API_VERSION}/reserveCustomers/exportStoreReport`,
  `${GlobalConfigs.REPORT_API_VERSION}/reserveCustomers/exportUserReport`,
  `${GlobalConfigs.REPORT_API_VERSION}/tenantsHis/exportTenantsHisBack`,
  `${GlobalConfigs.REPORT_API_VERSION}/tenantsContract/exportTenantsContractBack`,
  `${GlobalConfigs.REPORT_API_VERSION}/houseContract/exportContractBackReport`,
  `${GlobalConfigs.REPORT_API_VERSION}/tenantsExit/reportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/rentPoor/rentPoorReportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/houseInsurance/reportExport`,
  `${GlobalConfigs.REPORT_API_VERSION}/otherChangeRoom/exportOtherChangeBack`,
  `${GlobalConfigs.REPORT_API_VERSION}/sublet/exportSubletBack`,
  `${GlobalConfigs.REPORT_API_VERSION}/sublet/tenantsSubletBackUp`,
  `${GlobalConfigs.REPORT_API_VERSION}/otherChangeRoom/tenantsChangeRoomBackUp`,
  `${GlobalConfigs.REPORT_API_VERSION}/house/houseWalkReportExportV1`,
  `${GlobalConfigs.REPORT_API_VERSION}/rentCollection/export`,
  `${GlobalConfigs.REPORT_API_VERSION}/tenantsHis/exportTenantsReportV1`,
  `${GlobalConfigs.REPORT_API_VERSION}/houseHis/exportHouseReportV1`,
  `${GlobalConfigs.REPORT_API_VERSION}/broadbandSub/export`,
  `${GlobalConfigs.REPORT_API_VERSION}/broadbandSub/exportV1`
];

request.interceptors.request.use((config) => {
  if (config.url && DOWNLOAD_URLS.includes(config.url)) {
    config.responseType = "arraybuffer";
  }

  return config;
});

request.interceptors.request.use((use) => {
  console.log(use?.url);
  console.log(use?.data?.cityId);
  console.log(localStorage.getItem("cityId"));
  if (localStorage.getItem("cityId")) {
    let tempUrl = use.url?.replaceAll(
      "v100001",
      `${localStorage.getItem("cityId")}`
    );
    console.log("Url", tempUrl);
    use.url = tempUrl;
  } else {
    use.url = use.url;
  }

  // if (Number(localStorage.getItem("cityId")) == 510100) {
  //   console.log(1);
  //   console.log(use.url);
  //   use.url = use.url + `?cityId=${Number(localStorage.getItem("cityId"))}`;
  //   console.log(123232);
  // } else {
  //   console.log(222);
  //   use.url = use.url;
  // }
  return use;
});

request.interceptors.response.use(
  (response) => {
    console.log(response.data, "response.data");
    console.log(response, "response.data");

    if (response.data && response.data.code) {
      notification.error({
        message: "系统提示",
        description: response.data.msg || "请求数据失败，请刷新重试！",
        duration: 5
      });
      console.log(response.data.code);
      throw new Exception("请求数据失败，请刷新重试！");
    }
    return response;
  },
  (err: AxiosError) => {
    if (err.isAxiosError && err.response) {
      if (err.response.status >= 400) {
        switch (err.response.status) {
          case 401:
            store.dispatch({
              type: ReduxActionType.LOGIN,
              token: null,
              user: null
            });
            if (localStorage.getItem(ReduxActionType.LOGIN.toString())) {
              notification.error({
                message: "系统提示",
                description: "登录过期，请重新登录"
              });
            }
            break;
          default:
            notification.error({
              message: "系统提示",
              description: err.message || err.response.data.msg
            });
        }
      }
    }
    return Promise.reject(err);
  }
);
