import { Exception } from "../lib/exception";

export class FormatUtil {
  /**
   * 格式化时间方法
   * @param option 日期对象
   * 可自定义格式化 例如 :
   *
   * ```
   * YYYY/MM/DD HH:ss:mm
   * YYYY-MM-DD HH:ss:mm
   * YYYY || yyyy 2019
   * YYY || yyy 019
   * YY  || yy  19
   * MM 月份
   * DD 日期
   * HH 小时24小时制
   * hh 小时12小时制
   * ss 分
   * mm 秒
   * ```
   */
  static date(option: { date?: Date; format?: string }): string {
    const { date = new Date(), format = "YYYY-MM-DD HH:mm:ss" } = option;
    const YYYY = date.getFullYear();
    const MM = FormatUtil.padding(date.getMonth() + 1);
    const DD = FormatUtil.padding(date.getDate());

    const hh = date.getHours();
    const HH = FormatUtil.padding(hh);

    const mm = FormatUtil.padding(date.getMinutes());

    const ss = FormatUtil.padding(date.getSeconds());

    return format
      .replace(/YYYY/g, YYYY.toString())
      .replace(/YYY/g, YYYY.toString().substr(3))
      .replace(/YY/g, YYYY.toString().substr(2))
      .replace(/yyyy/g, YYYY.toString())
      .replace(/yyy/g, YYYY.toString().substr(3))
      .replace(/yy/g, YYYY.toString().substr(2))
      .replace(/MM/g, MM)
      .replace(/DD/g, DD)
      .replace(/HH/g, HH)
      .replace(/ss/g, ss)
      .replace(/mm/g, mm)
      .replace(
        /hh/g,
        hh > 12 ? FormatUtil.padding(hh - 12) : FormatUtil.padding(hh)
      );
  }

  /**
   * 格式化星期方法
   * @param prefix
   * @param week
   */
  static week(
    prefix: string = "星期",
    week: number = new Date().getDay()
  ): string {
    switch (week) {
      case 1:
        return prefix + "一";
      case 2:
        return prefix + "二";
      case 3:
        return prefix + "三";
      case 4:
        return prefix + "四";
      case 5:
        return prefix + "五";
      case 6:
        return prefix + "六";
      default:
        return prefix + "日";
    }
  }

  /**
   * 格式化价格
   */
  static price(price: number) {
    if (!price) {
      return "0.00";
    }
    return (price / 100).toFixed(2);
  }

  /**
   * 价格乘以100
   * @param price
   */
  static priceX100(price: number) {
    return isNaN(price) ? 0 : price * 100;
  }

  /**
   * images ，号处理
   * */
  static images(images: string = "") {
    return images.split(",");
  }

  /**
   * 小于10的数字前面 补0
   * @param num
   */
  static padding(num: number): string {
    if (num < 10) {
      return "0" + num;
    }
    return num + "";
  }

  /**
   * 数字用逗号隔开的使用此方法转换成数字数组
   * @param value
   */
  static byCommaSplitToArrayNumber(value: string = ""): number[] {
    return value
      .split(",")
      .map((item) => Number(item))
      .filter((item) => !isNaN(item));
  }

  /**
   * 格式化字符串为数字
   * @param val
   */
  static number(val?: NullLike<NumberLike>): number | undefined {
    const value = Number(val);
    return !val && isNaN(value) ? undefined : value;
  }
  /**
   * 把数字用字符串逗号分隔
   * @param arrNum
   */
  static arrayNumberJoinCommaToString(arrNum: number[] = []): string {
    if (Array.isArray(arrNum)) {
      return arrNum.join(",");
    }
    return "";
  }

  static createNumberRangeArray(max: number, min: number = 0): number[] {
    if (min === -Infinity || max === Infinity) {
      throw Exception.create("Invalid number min or max.");
    }
    const list: number[] = [];

    for (; min < max; min++) {
      list.push(min);
    }

    return list;
  }
  static MsgSort(list: any) {
    list.sort(function (a: any, b: any) {
      return b.auditTime < a.auditTime ? 1 : -1;
    });
  }

  /**
   * 判断缴费类型
   * @param tenantsChildvo
   */
  static tenantsPayTypeToName(tenantsChildvo: TenantsChildVo): string {
    const { payType, payTypeDay } = tenantsChildvo;
    switch (payType) {
      case 1:
        return `提前${payTypeDay}天`;
      case 2:
        return `延后${payTypeDay}天`;
      case 3:
        return `固定${payTypeDay}天`;
      default:
        return "";
    }
  }

  /**
   * 判断房源类型显示
   * @param houseTypeToName
   */
  static houseTypeToName(houseType: number): string {
    switch (houseType) {
      case 1:
        return "整租";
      case 2:
        return "合租";
      case 3:
        return "集中";
      default:
        return "";
    }
  }

  /**
   * 拼接年月日
   * @param yearAndMonthAndDay
   */
  static yearAndMonthAndDay(year: number, month: number, day: number): string {
    return `${year || 0}年${month || 0}月${day || 0}日`;
  }
}
