import { FormatUtil } from "../utils/format";
import { DictionaryEnum } from "./dictionary";

export * from "./dictionary";
export * from "./form-col-layout";
export * from "./global-config";
export * from "./house.pay.type";
export * from "./maintenanceClean-type";
export * from "./menu-type";
export * from "./notice-type";
export * from "./outdoor-type";
export * from "./redux-action-type";
export * from "./roleType";
export * from "./time";
export * from "./user-look-type";
export * from "./validators";
export * from "./warehouseInventory-type";
export * from "./global-configs";

export const numberList = FormatUtil.createNumberRangeArray(10);
export const year10List = FormatUtil.createNumberRangeArray(11);
export const month12List = FormatUtil.createNumberRangeArray(12);
export const month36List = FormatUtil.createNumberRangeArray(36);
export const dayList = FormatUtil.createNumberRangeArray(31);
export const newdayList = FormatUtil.createNumberRangeArray(30);
export const tenant_exit_other_param = {
  pid: DictionaryEnum.tenant_exit_other
};
export const nature_of_contract = {
  pid: DictionaryEnum.nature_of_contract
};
export const housing_exit_other_param = {
  pid: DictionaryEnum.housing_exit_other
};
export const housing_user_other_info_param = {
  pid: DictionaryEnum.housing_user_other_info
};

export const housing_user_pay_param = { pid: DictionaryEnum.housing_user_pay };
export const housing_tenant_pay_param = { pid: DictionaryEnum.energy_fee };
export const tenant_other_param = { pid: DictionaryEnum.tenant_other };
export const PAY_TYPE_SELECT = { pid: DictionaryEnum.housing_pay_type };
export const housing_user_pay_reduce_param = {
  pid: DictionaryEnum.housing_user_pay_reduce
};
export const online_house_config_type_param = {
  pid: DictionaryEnum.online_house_config_type
};
export const sms_config_type_param = {
  pid: DictionaryEnum.sms_config_type
};
export const send_sms_type_param = {
  type: 1
};
export const send_tenan_sms_type_param = {
  type: 2
};
export const EMPTY_PARAMS: Readonly<any> = {};
