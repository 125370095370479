export enum OutDoorType {
  // 出门中
  out = 1,
  // 签到成功
  sign = 2,
  // 已返回
  back = 3,
}

export function getOutDoorTypeName(type: OutDoorType) {
  switch (type) {
    case OutDoorType.out:
      return "出门中";
    case OutDoorType.sign:
      return "签到成功";
    case OutDoorType.back:
      return "已返回";
  }
}
