import { Upload, UploadProps } from "antd";
import React, { ReactNode, useState } from "react";
import { API_OTHER } from "../../../api/API_OTHER";

export function CurrencyUpload(props: CurrencyUploadProps) {
  const { onRemove } = props;
  const [params, setParams] = useState<OssPolicyResult>();
  return (
    <Upload
      accept="image/*"
      action={params ? params.uploadHost : ""}
      beforeUpload={async (file: any) => {
        const res = await API_OTHER.v100001.oss.osspolicy();
        console.log(res);
        const suffix = file.name.slice(file.name.lastIndexOf("."));
        const filename = Date.now() + suffix;
        const { data } = res.data;
        setParams({
          ...data,
          dir: data.dir + filename
        });
        file.path = data.host + "/" + data.dir + filename;
        let fileSize = file.size / 1024 / 1024;
        if (props.nowType == "image") {
          if (fileSize > 2) {
            try {
              return new Promise((resolve) => {
                //声明FileReader文件读取对象
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                  // 生成canvas画布
                  const canvas = document.createElement("canvas");
                  // 生成img
                  const img = document.createElement("img");
                  //@ts-ignore
                  img.src = reader.result;
                  img.onload = () => {
                    const ctx = canvas.getContext("2d");
                    //原始图片宽度、高度
                    let originImageWidth = img.width,
                      originImageHeight = img.height;
                    //默认最大尺度的尺寸限制在（1920 * 1080）
                    let maxWidth = 1920,
                      maxHeight = 1080,
                      ratio = maxWidth / maxHeight;
                    //目标尺寸
                    let targetWidth = originImageWidth,
                      targetHeight = originImageHeight;
                    //当图片的宽度或者高度大于指定的最大宽度或者最大高度时,进行缩放图片
                    // if (
                    //   originImageWidth > maxWidth ||
                    //   originImageHeight > maxHeight
                    // ) {
                    //   //超过最大宽高比例
                    //   if (originImageWidth / originImageHeight > ratio) {
                    //     //宽度取最大宽度值maxWidth,缩放高度
                    //     targetWidth = maxWidth;
                    //     targetHeight = Math.round(
                    //       maxWidth * (originImageHeight / originImageWidth)
                    //     );
                    //   } else {
                    //     //高度取最大高度值maxHeight,缩放宽度
                    //     targetHeight = maxHeight;
                    //     targetWidth = Math.round(
                    //       maxHeight * (originImageWidth / originImageHeight)
                    //     );
                    //   }
                    // }
                    // canvas对图片进行缩放
                    canvas.width = targetWidth;
                    canvas.height = targetHeight;
                    // 清除画布
                    //@ts-ignore
                    ctx.clearRect(0, 0, targetWidth, targetHeight);
                    // 绘制图片
                    //@ts-ignore
                    ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
                    // quality值越小,图像越模糊,默认图片质量为0.92
                    const imageDataURL = canvas.toDataURL(
                      file.type || "image/jpeg",
                      0.85
                    );
                    // 去掉URL的头,并转换为byte
                    const imageBytes = window.atob(imageDataURL.split(",")[1]);
                    // 处理异常,将ascii码小于0的转换为大于0
                    const arrayBuffer = new ArrayBuffer(imageBytes.length);
                    const uint8Array = new Uint8Array(arrayBuffer);
                    for (let i = 0; i < imageBytes.length; i++) {
                      uint8Array[i] = imageBytes.charCodeAt(i);
                    }
                    //@ts-ignore
                    let mimeType = imageDataURL
                      .split(",")[0]
                      .match(/:(.*?);/)[1];
                    let newFile = new File([uint8Array], file.name, {
                      type: mimeType || "image/jpeg"
                    });
                    // console.log('after compress, the file size is : ', (newFile.size / 1024 / 1024) + "M");
                    //@ts-ignore

                    resolve(newFile);
                  };
                };
                reader.onerror = () => {
                  return file;
                };
              });
            } catch (e) {
              //压缩出错,直接返回原file对象
              return file;
            }
          }
        }
        return file;
      }}
      data={
        params
          ? {
              OSSAccessKeyId: params.accessid,
              policy: params.policy,
              key: params.dir,
              expire: params.expire,
              success_action_status: "200",
              signature: params.signature,
              callback: params.callback
            }
          : undefined
      }
      onRemove={(info) => {
        onRemove && onRemove(info);
      }}
      {...props}
    />
  );
}

export interface CurrencyUploadProps extends UploadProps {
  children?: ReactNode;
  nowType?: string;
}
