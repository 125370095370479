import { AxiosResponse } from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

type RequestFn<P, R> = (params: P) => Promise<AxiosResponse<ResponseResult<R>>>;

export function useDetail<R, P>(fn: RequestFn<P, R>, params?: P) {
  const [detail, setDetail] = useState<R>();
  const [loading, setLoading] = useState<boolean>(false);
  const cache = useRef({
    detail,
    loading
  });
  useEffect(() => {
    cache.current.loading = loading;
    cache.current.detail = detail;
  }, [cache, detail, loading]);
  const getDetail = useCallback(async () => {
    try {
      if (!params || cache.current.loading) {
        setDetail(undefined);
        return;
      }
      setLoading(true);
      const res = await fn(params);
      setDetail(res.data.data);
    } catch (e) {}
    setLoading(false);
  }, [fn, params]);

  useEffect(() => {
    getDetail().catch((err) => err);
  }, [getDetail]);

  return useMemo(
    () => ({
      detail,
      getDetail,
      loading
    }),
    [detail, getDetail, loading]
  );
}
