import validator from "validator";

/**
 * 是否为手机号码
 */
export const isMobilePhone = {
  validator: async (rule: any, value: any) => {
    if (value && !validator.isMobilePhone(value + "", "zh-CN")) {
      return Promise.reject("手机号码格式不正确");
    }
    return Promise.resolve();
  },
};
