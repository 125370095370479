import { request } from "../utils/request";
export namespace API_FINANCE {
  export namespace v100001 {
    export namespace debt {
      export interface BatchUpdateStatusParams {
        /**
         * @description  主键
         */
        ids?: string;
        /**
         * @description  3表示未付改成已付完，1已付完改未付
         */
        status?: number;
      }
      /**
       * @name 批量修改状态
       * @param data
       */
      export function batchUpdateStatus(data: BatchUpdateStatusParams) {
        return request.put<ResponseResult<object>>(
          "/finance-v100001/debt/batchUpdateStatus",
          data
        );
      }
      export interface DebtMountParams {
        /**
         * @description  欠款主键
         */
        id?: number;
        /**
         * @description  房租主键，取消挂载不传
         */
        rentId?: number;
        /**
         * @description  类型：1挂载  2取消挂载
         */
        type?: number;
      }
      /**
       * @name 欠款挂载功能:1挂载  2取消挂载
       * @param data
       */
      export function debtMount(data: DebtMountParams) {
        return request.put<ResponseResult<object>>(
          "/finance-v100001/debt/debtMount",
          data
        );
      }
      export interface DeductionDebtListParams {
        /**
         * @description  房源ID
         */
        houseId?: number;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  房间ID
         */
        tenantsId?: number;
      }
      /**
       * @name 抵扣欠款列表 tenantsId 或 房源ID 二选其一
       * @param params
       */
      export function deductionDebtList(params: DeductionDebtListParams) {
        return request.get<ResponseResult<PageList<Debt>>>(
          "/finance-v100001/debt/deductionDebtList",
          { params }
        );
      }
      /**
       * @name 欠款表删除
       * @param id 欠款表删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/finance-v100001/debt/delete/${id}`
        );
      }
      /**
       * @name 欠款表详情
       * @param id 欠款表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<Debt>>(
          `/finance-v100001/debt/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  区域ID
         */
        areaId?: number;
        /**
         * @description  欠款时间结束
         */
        dateEnd?: string;
        /**
         * @description  日期筛选月 格式:2020-01 与时间段互斥
         */
        dateMonth?: string;
        /**
         * @description  欠款时间开始
         */
        dateStart?: string;
        /**
         * @description  日期筛选类型 1-欠款时间 2-还款期限
         */
        dateType?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区ID
         */
        detailId?: string;
        /**
         * @description  房源ID 如不为空则查询房东欠款
         */
        houseId?: number;
        /**
         * @description  房源编号
         */
        houseNo?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1-整租 2-合租 3-集中
         */
        houseType?: number;
        /**
         * @description  收入类型 1-他人欠公司 2-公司欠他人
         */
        inoutType?: number;
        /**
         * @description  是否挂载：1已挂载 2未挂载
         */
        isMount?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  还款状态 1-未付 2-已还部分 3-已还完 4-坏账
         */
        payStatus?: number;
        /**
         * @description  关联人
         */
        relationName?: string;
        /**
         * @description  关联人电话
         */
        relationPhone?: string;
        /**
         * @description  关联类型ID
         */
        relationTypeId?: number;
        /**
         * @description  还款期限结束
         */
        repayPeriodEnd?: string;
        /**
         * @description  还款期限开始
         */
        repayPeriodStart?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间编号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店ID
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  租客ID 如不为空则查询租客欠款
         */
        tenantsId?: number;
      }
      /**
       * @name 欠款列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<Debt>>>(
          "/finance-v100001/debt/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  业务id
         */
        businessId?: number;
        /**
         * @description  欠款时间
         */
        debtDate: string;
        /**
         * @description  欠款金额
         */
        debtFee: number;
        /**
         * @description  房源ID
         */
        houseId?: number;
        /**
         * @description  1-整租 2-合租 3-集中
         */
        houseType?: number;
        /**
         * @description  收入类型 1-他人欠公司 2-公司欠他人
         */
        inoutType: number;
        relationName?: string;
        /**
         * @description  关联人电话 如非房源相关此项必填
         */
        relationPhone?: string;
        /**
         * @description  关联类型ID 如非房源相关此项必填
         */
        relationTypeId: number;
        /**
         * @description  备注
         */
        remark: string;
        /**
         * @description  房租id
         */
        rentId?: number;
        /**
         * @description  还款期限
         */
        repayPeriod: string;
        /**
         * @description  门店ID
         */
        storeId?: number;
        /**
         * @description  租客ID
         */
        tenantsId?: number;
        /**
         * @description  类型：1，水电气；2，物业费；3，税点；4，租金；5，维修费用；6，保洁费用
         */
        typeId?: number;
        /**
         * @description  类型：1，水电气；2，物业费；3，税点；4，租金；5，维修费用；6，保洁费用
         */
        typeName?: string;
      }
      /**
       * @name 欠款表新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/finance-v100001/debt/save",
          data
        );
      }
      export interface TotalParams {
        /**
         * @description  区域ID
         */
        areaId?: number;
        /**
         * @description  欠款时间结束
         */
        dateEnd?: string;
        /**
         * @description  日期筛选月 格式:2020-01 与时间段互斥
         */
        dateMonth?: string;
        /**
         * @description  欠款时间开始
         */
        dateStart?: string;
        /**
         * @description  日期筛选类型 1-欠款时间 2-还款期限
         */
        dateType?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区ID
         */
        detailId?: string;
        /**
         * @description  房源ID 如不为空则查询房东欠款
         */
        houseId?: number;
        /**
         * @description  房源编号
         */
        houseNo?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1-整租 2-合租 3-集中
         */
        houseType?: number;
        /**
         * @description  收入类型 1-他人欠公司 2-公司欠他人
         */
        inoutType?: number;
        /**
         * @description  是否挂载：1已挂载 2未挂载
         */
        isMount?: number;
        /**
         * @description  还款状态 1-未付 2-已还部分 3-已还完 4-坏账
         */
        payStatus?: number;
        /**
         * @description  关联人
         */
        relationName?: string;
        /**
         * @description  关联人电话
         */
        relationPhone?: string;
        /**
         * @description  关联类型ID
         */
        relationTypeId?: number;
        /**
         * @description  还款期限结束
         */
        repayPeriodEnd?: string;
        /**
         * @description  还款期限开始
         */
        repayPeriodStart?: string;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间编号
         */
        roomNo?: string;
        /**
         * @description  门店ID
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  租客ID 如不为空则查询租客欠款
         */
        tenantsId?: number;
      }
      /**
       * @name 欠款总计
       * @param params
       */
      export function total(params: TotalParams) {
        return request.get<ResponseResult<DebtTotal>>(
          "/finance-v100001/debt/total",
          { params }
        );
      }
      export interface UpdateParams {
        /**
         * @description  业务id
         */
        businessId?: number;
        /**
         * @description  欠款时间
         */
        debtDate: string;
        /**
         * @description  欠款金额
         */
        debtFee: number;
        /**
         * @description  房源ID
         */
        houseId?: number;
        /**
         * @description  1-整租 2-合租 3-集中
         */
        houseType?: number;
        /**
         * @description  收入类型 1-他人欠公司 2-公司欠他人
         */
        inoutType: number;
        relationName?: string;
        /**
         * @description  关联人电话 如非房源相关此项必填
         */
        relationPhone?: string;
        /**
         * @description  关联类型ID 如非房源相关此项必填
         */
        relationTypeId: number;
        /**
         * @description  备注
         */
        remark: string;
        /**
         * @description  房租id
         */
        rentId?: number;
        /**
         * @description  还款期限
         */
        repayPeriod: string;
        /**
         * @description  门店ID
         */
        storeId?: number;
        /**
         * @description  租客ID
         */
        tenantsId?: number;
        /**
         * @description  类型：1，水电气；2，物业费；3，税点；4，租金；5，维修费用；6，保洁费用
         */
        typeId?: number;
        /**
         * @description  类型：1，水电气；2，物业费；3，税点；4，租金；5，维修费用；6，保洁费用
         */
        typeName?: string;
      }
      /**
       * @name 欠款表修改
       * @param id 欠款表修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/finance-v100001/debt/update/${id}`,
          data
        );
      }
      export namespace list {
        export interface DeductionParams {
          /**
           * @description  房源ID
           */
          houseId?: number;
          pageNo?: number;
          pageSize?: number;
          sortField?: string;
          sortWay?: string;
          /**
           * @description  房间ID
           */
          roomId?: number;
        }
        /**
         * @name 抵扣欠款列表 只能传房间ID 或 房源ID 二选其一
         * @param params
         */
        export function deduction(params: DeductionParams) {
          return request.get<ResponseResult<PageList<Debt>>>(
            "/finance-v100001/debt/list/deduction",
            { params }
          );
        }
      }
    }
    export namespace finance {
      export interface AuditParams {
        /**
         * @description  审核状态 2-通过 3-驳回
         */
        audit: number;
        /**
         * @description  审核备注
         */
        auditRemark?: string;
        /**
         * @description  主键ID
         */
        id: number;
      }
      /**
       * @name 财务审核
       * @param data
       */
      export function audit(data: AuditParams) {
        return request.put<ResponseResult<object>>(
          "/finance-v100001/finance/audit",
          data
        );
      }
      export interface CashierParams {
        /**
         * @description  出纳状态 1-未出纳 2-已出纳
         */
        cashier: number;
        /**
         * @description  主键ID
         */
        id: number;
      }
      /**
       * @name 财务出纳
       * @param data
       */
      export function cashier(data: CashierParams) {
        return request.put<ResponseResult<object>>(
          "/finance-v100001/finance/cashier",
          data
        );
      }
      /**
       * @name 财务表删除
       * @param id 财务表删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/finance-v100001/finance/delete/${id}`
        );
      }
      /**
       * @name 财务表详情
       * @param id 财务表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<Finance>>(
          `/finance-v100001/finance/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  区域ID
         */
        areaId?: number;
        /**
         * @description  是否审核 1-未审核 2-已审核 3-驳回
         */
        audit?: number;
        /**
         * @description  财务来源类型 1-手动记账 2-业务关联
         */
        billType?: number;
        /**
         * @description  出纳状态 1-未出纳 2-已出纳
         */
        cashier?: number;
        /**
         * @description  创建人ID
         */
        createId?: number;
        /**
         * @description  创建结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建开始
         */
        createTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区ID
         */
        detailId?: string;
        /**
         * @description  款项原因ID
         */
        feeReasonId?: number;
        /**
         * @description  款项类型ID
         */
        feeTypeId?: number;
        /**
         * @description  房源ID
         */
        houseId?: number;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1-整租 2-合租 3-集中
         */
        houseType?: number;
        /**
         * @description  收入类型 1-收入 2-支出
         */
        inoutType?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  收款日期结束
         */
        payDateEnd?: string;
        /**
         * @description  收款日期统计月 格式:2020-01
         */
        payDateMonth?: string;
        /**
         * @description  收款日期开始
         */
        payDateStart?: string;
        /**
         * @description  关联人身份证号码
         */
        relationIdcard?: string;
        /**
         * @description  关联人
         */
        relationName?: string;
        /**
         * @description  关联人电话
         */
        relationPhone?: string;
        /**
         * @description  关联类型ID
         */
        relationTypeId?: number;
        /**
         * @description  复核状态 1-未复核 2-已复核 3-驳回
         */
        review?: number;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间编号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店ID
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  租客ID
         */
        tenantsId?: number;
      }
      /**
       * @name 财务列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<Finance>>>(
          "/finance-v100001/finance/list",
          { params }
        );
      }
      export interface RepayParams {
        /**
         * @description  坏账原因
         */
        badDeptReason?: string;
        /**
         * @description  定金IDS 多个逗号分割
         */
        bargainIds?: string;
        /**
         * @description  业务关联ID
         */
        businessId: number;
        /**
         * @description  业务关联类型ID   租客退房 462, 定金退还 412
         */
        businessTypeId: number;
        /**
         * @description  欠款IDS 多个逗号分割
         */
        debtIds?: string;
        /**
         * @description  款项原因ID 需要传给后端
         */
        feeReasonId: number;
        /**
         * @description  款项类型ID 需要传给后端
         */
        feeTypeId: number;
        /**
         * @description  支付图片多张逗号隔开
         */
        images?: string;
        /**
         * @description  收入类型 1-收入 2-支出 需要传给后端
         */
        inoutType: number;
        /**
         * @description  是否生成流水 0-否 1-是
         */
        isWater: number;
        /**
         * @description  滞纳金
         */
        lateFee?: number;
        /**
         * @description  收支账号ID
         */
        payAccountId?: number;
        /**
         * @description  入账日期
         */
        payDate?: string;
        /**
         * @description  本次付款
         */
        payFee?: number;
        /**
         * @description  支付方式ID
         */
        payMethodId?: number;
        /**
         * @description  还款状态 1-未付 2-已还部分 3-已还完 4-坏账
         */
        payStatus: number;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  剩余还款日期
         */
        surplusPayDate?: string;
      }
      /**
       * @name 还款
       * @param data
       */
      export function repay(data: RepayParams) {
        return request.put<ResponseResult<object>>(
          "/finance-v100001/finance/repay",
          data
        );
      }
      export interface ReviewParams {
        /**
         * @description  审核状态 2-通过 3-驳回
         */
        audit: number;
        /**
         * @description  审核备注
         */
        auditRemark?: string;
        /**
         * @description  主键ID
         */
        id: number;
      }
      /**
       * @name 财务复核
       * @param data
       */
      export function review(data: ReviewParams) {
        return request.put<ResponseResult<object>>(
          "/finance-v100001/finance/review",
          data
        );
      }
      export interface SaveParams {
        /**
         * @description  款项原因ID
         */
        feeReasonId: number;
        /**
         * @description  款项类型ID
         */
        feeTypeId: number;
        /**
         * @description  房源ID
         */
        houseId?: number;
        /**
         * @description  收款图片
         */
        images?: string;
        /**
         * @description  收入类型 1-收入 2-支出
         */
        inoutType: number;
        /**
         * @description  收款账户ID
         */
        payAccountId?: number;
        /**
         * @description  收款日期
         */
        payDate: string;
        /**
         * @description  收款金额
         */
        payFee: number;
        /**
         * @description  收款方式ID
         */
        payMethodId?: number;
        /**
         * @description  关联人身份证号码
         */
        relationIdcard?: string;
        /**
         * @description  关联人
         */
        relationName?: string;
        /**
         * @description  关联人电话
         */
        relationPhone?: string;
        /**
         * @description  关联类型ID
         */
        relationTypeId?: number;
        /**
         * @description  收款备注
         */
        remark?: string;
        /**
         * @description  房间ID
         */
        roomId?: number;
        /**
         * @description  门店ID
         */
        storeId: number;
      }
      /**
       * @name 财务表新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/finance-v100001/finance/save",
          data
        );
      }
      export interface SendPaperParams {
        /**
         * @description  电子邮箱
         */
        email?: string;
        /**
         * @description  主键
         */
        id?: number;
      }
      /**
       * @name 发送电子票据
       * @param params
       */
      export function sendPaper(params: SendPaperParams) {
        return request.get<ResponseResult<object>>(
          "/finance-v100001/finance/sendPaper",
          { params }
        );
      }
      export interface TotalParams {
        /**
         * @description  区域ID
         */
        areaId?: number;
        /**
         * @description  是否审核 1-未审核 2-已审核 3-驳回
         */
        audit?: number;
        /**
         * @description  财务来源类型 1-手动记账 2-业务关联
         */
        billType?: number;
        /**
         * @description  出纳状态 1-未出纳 2-已出纳
         */
        cashier?: number;
        /**
         * @description  创建人ID
         */
        createId?: number;
        /**
         * @description  创建结束
         */
        createTimeEnd?: string;
        /**
         * @description  创建开始
         */
        createTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区ID
         */
        detailId?: string;
        /**
         * @description  款项原因ID
         */
        feeReasonId?: number;
        /**
         * @description  款项类型ID
         */
        feeTypeId?: number;
        /**
         * @description  房源ID
         */
        houseId?: number;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1-整租 2-合租 3-集中
         */
        houseType?: number;
        /**
         * @description  收入类型 1-收入 2-支出
         */
        inoutType?: number;
        /**
         * @description  收款日期结束
         */
        payDateEnd?: string;
        /**
         * @description  收款日期统计月 格式:2020-01
         */
        payDateMonth?: string;
        /**
         * @description  收款日期开始
         */
        payDateStart?: string;
        /**
         * @description  关联人身份证号码
         */
        relationIdcard?: string;
        /**
         * @description  关联人
         */
        relationName?: string;
        /**
         * @description  关联人电话
         */
        relationPhone?: string;
        /**
         * @description  关联类型ID
         */
        relationTypeId?: number;
        /**
         * @description  复核状态 1-未复核 2-已复核 3-驳回
         */
        review?: number;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间编号
         */
        roomNo?: string;
        /**
         * @description  门店ID
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  租客ID
         */
        tenantsId?: number;
      }
      /**
       * @name 财务总计
       * @param params
       */
      export function total(params: TotalParams) {
        return request.get<ResponseResult<FinanceTotal>>(
          "/finance-v100001/finance/total",
          { params }
        );
      }
      export interface UpdateParams {
        /**
         * @description  款项原因ID
         */
        feeReasonId: number;
        /**
         * @description  款项类型ID
         */
        feeTypeId: number;
        /**
         * @description  房源ID
         */
        houseId?: number;
        /**
         * @description  收款图片
         */
        images?: string;
        /**
         * @description  收入类型 1-收入 2-支出
         */
        inoutType: number;
        /**
         * @description  收款账户ID
         */
        payAccountId?: number;
        /**
         * @description  收款日期
         */
        payDate: string;
        /**
         * @description  收款金额
         */
        payFee: number;
        /**
         * @description  收款方式ID
         */
        payMethodId?: number;
        /**
         * @description  关联人身份证号码
         */
        relationIdcard?: string;
        /**
         * @description  关联人
         */
        relationName?: string;
        /**
         * @description  关联人电话
         */
        relationPhone?: string;
        /**
         * @description  关联类型ID
         */
        relationTypeId?: number;
        /**
         * @description  收款备注
         */
        remark?: string;
        /**
         * @description  房间ID
         */
        roomId?: number;
        /**
         * @description  门店ID
         */
        storeId: number;
      }
      /**
       * @name 财务表修改
       * @param id 财务表修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/finance-v100001/finance/update/${id}`,
          data
        );
      }
      export interface UpdatePayStatusNoParams {
        /**
         * @description  id
         */
        id?: number;
      }
      /**
       * @name 修改已付为未付
       * @param data
       */
      export function updatePayStatusNo(data: UpdatePayStatusNoParams) {
        return request.put<ResponseResult<object>>(
          "/finance-v100001/finance/updatePayStatusNo",
          data
        );
      }
      /**
       * @name 财务导入模版下载
       */
      export function createExcelTemplate() {
        return request.get<ResponseEntity>(
          "/finance-v100001/finance/createExcelTemplate"
        );
      }
      /**
       * @name 财务数据导入
       */
      export function insertImplFinance(data: any) {
        return request.post<ResponseResult<object>>(
          "/finance-v100001/finance/insertImplFinance",
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );
      }
      export namespace exportExcel {
        export interface DebtParams {
          /**
           * @description  区域ID
           */
          areaId?: number;
          /**
           * @description  欠款时间结束
           */
          dateEnd?: string;
          /**
           * @description  日期筛选月 格式:2020-01 与时间段互斥
           */
          dateMonth?: string;
          /**
           * @description  欠款时间开始
           */
          dateStart?: string;
          /**
           * @description  日期筛选类型 1-欠款时间 2-还款期限
           */
          dateType?: number;
          /**
           * @description  部门id
           */
          deptId?: number;
          /**
           * @description  物业地区ID
           */
          detailId?: string;
          /**
           * @description  房源ID 如不为空则查询房东欠款
           */
          houseId?: number;
          /**
           * @description  房源编号
           */
          houseNo?: string;
          /**
           * @description  门牌号
           */
          houseNum?: string;
          /**
           * @description  1-整租 2-合租 3-集中
           */
          houseType?: number;
          /**
           * @description  收入类型 1-他人欠公司 2-公司欠他人
           */
          inoutType?: number;
          /**
           * @description  是否挂载：1已挂载 2未挂载
           */
          isMount?: number;
          /**
           * @description  还款状态 1-未付 2-已还部分 3-已还完 4-坏账
           */
          payStatus?: number;
          /**
           * @description  关联人
           */
          relationName?: string;
          /**
           * @description  关联人电话
           */
          relationPhone?: string;
          /**
           * @description  关联类型ID
           */
          relationTypeId?: number;
          /**
           * @description  还款期限结束
           */
          repayPeriodEnd?: string;
          /**
           * @description  还款期限开始
           */
          repayPeriodStart?: string;
          /**
           * @description  房间id
           */
          roomId?: number;
          /**
           * @description  房间编号
           */
          roomNo?: string;
          /**
           * @description  门店ID
           */
          storeId?: number;
          /**
           * @description  门店id多选
           */
          storeIdList?: string;
          /**
           * @description  租客ID 如不为空则查询租客欠款
           */
          tenantsId?: number;
        }
        /**
         * @name 欠款导出
         * @param params
         */
        export function debt(params: DebtParams) {
          return request.get("/finance-v100001/finance/exportExcel/debt", {
            params
          });
        }
        export interface ExportFinanceBankParams {
          /**
           * @description  区域ID
           */
          areaId?: number;
          /**
           * @description  是否审核 1-未审核 2-已审核 3-驳回
           */
          audit?: number;
          /**
           * @description  财务来源类型 1-手动记账 2-业务关联
           */
          billType?: number;
          /**
           * @description  出纳状态 1-未出纳 2-已出纳
           */
          cashier?: number;
          /**
           * @description  创建人ID
           */
          createId?: number;
          /**
           * @description  创建结束
           */
          createTimeEnd?: string;
          /**
           * @description  创建开始
           */
          createTimeStart?: string;
          /**
           * @description  部门id
           */
          deptId?: number;
          /**
           * @description  物业地区ID
           */
          detailId?: string;
          /**
           * @description  款项原因ID
           */
          feeReasonId?: number;
          /**
           * @description  款项类型ID
           */
          feeTypeId?: number;
          /**
           * @description  房源ID
           */
          houseId?: number;
          /**
           * @description  门牌号
           */
          houseNum?: string;
          /**
           * @description  1-整租 2-合租 3-集中
           */
          houseType?: number;
          /**
           * @description  收入类型 1-收入 2-支出
           */
          inoutType?: number;
          /**
           * @description  收款日期结束
           */
          payDateEnd?: string;
          /**
           * @description  收款日期统计月 格式:2020-01
           */
          payDateMonth?: string;
          /**
           * @description  收款日期开始
           */
          payDateStart?: string;
          /**
           * @description  关联人身份证号码
           */
          relationIdcard?: string;
          /**
           * @description  关联人
           */
          relationName?: string;
          /**
           * @description  关联人电话
           */
          relationPhone?: string;
          /**
           * @description  关联类型ID
           */
          relationTypeId?: number;
          /**
           * @description  复核状态 1-未复核 2-已复核 3-驳回
           */
          review?: number;
          /**
           * @description  房间id
           */
          roomId?: number;
          /**
           * @description  房间编号
           */
          roomNo?: string;
          /**
           * @description  门店ID
           */
          storeId?: number;
          /**
           * @description  门店id多选
           */
          storeIdList?: string;
          /**
           * @description  租客ID
           */
          tenantsId?: number;
        }
        /**
         * @name  导出支出流水银行卡信息
         * @param params
         */
        export function exportFinanceBank(params: ExportFinanceBankParams) {
          return request.get(
            "/finance-v100001/finance/exportExcel/exportFinanceBank",
            { params }
          );
        }
        export interface FinanceParams {
          /**
           * @description  区域ID
           */
          areaId?: number;
          /**
           * @description  是否审核 1-未审核 2-已审核 3-驳回
           */
          audit?: number;
          /**
           * @description  财务来源类型 1-手动记账 2-业务关联
           */
          billType?: number;
          /**
           * @description  出纳状态 1-未出纳 2-已出纳
           */
          cashier?: number;
          /**
           * @description  创建人ID
           */
          createId?: number;
          /**
           * @description  创建结束
           */
          createTimeEnd?: string;
          /**
           * @description  创建开始
           */
          createTimeStart?: string;
          /**
           * @description  部门id
           */
          deptId?: number;
          /**
           * @description  物业地区ID
           */
          detailId?: string;
          /**
           * @description  款项原因ID
           */
          feeReasonId?: number;
          /**
           * @description  款项类型ID
           */
          feeTypeId?: number;
          /**
           * @description  房源ID
           */
          houseId?: number;
          /**
           * @description  门牌号
           */
          houseNum?: string;
          /**
           * @description  1-整租 2-合租 3-集中
           */
          houseType?: number;
          /**
           * @description  收入类型 1-收入 2-支出
           */
          inoutType?: number;
          /**
           * @description  收款日期结束
           */
          payDateEnd?: string;
          /**
           * @description  收款日期统计月 格式:2020-01
           */
          payDateMonth?: string;
          /**
           * @description  收款日期开始
           */
          payDateStart?: string;
          /**
           * @description  关联人身份证号码
           */
          relationIdcard?: string;
          /**
           * @description  关联人
           */
          relationName?: string;
          /**
           * @description  关联人电话
           */
          relationPhone?: string;
          /**
           * @description  关联类型ID
           */
          relationTypeId?: number;
          /**
           * @description  复核状态 1-未复核 2-已复核 3-驳回
           */
          review?: number;
          /**
           * @description  房间id
           */
          roomId?: number;
          /**
           * @description  房间编号
           */
          roomNo?: string;
          /**
           * @description  门店ID
           */
          storeId?: number;
          /**
           * @description  门店id多选
           */
          storeIdList?: string;
          /**
           * @description  租客ID
           */
          tenantsId?: number;
        }
        /**
         * @name 财务导出
         * @param params
         */
        export function finance(params: FinanceParams) {
          return request.get("/finance-v100001/finance/exportExcel/finance", {
            params
          });
        }
        export interface HouseRentParams {
          /**
           * @description  区域ID
           */
          areaId?: number;
          /**
           * @description  业务人员ID
           */
          businessId?: number;
          /**
           * @description  联系人
           */
          contactName?: string;
          /**
           * @description  联系电话
           */
          contactPhone?: string;
          /**
           * @description  合同性质id
           */
          contractId?: number;
          /**
           * @description  统计月份 格式:2020-02
           */
          dateMonth?: string;
          /**
           * @description  统计日期类型 1-按应收 2-有效期开始 3-有效期结束
           */
          dateType?: number;
          /**
           * @description  部门id
           */
          deptId?: number;
          /**
           * @description  物业地区ID
           */
          detailId?: string;
          /**
           * @description  房源ID(续约ID-租客ID) 选其一
           */
          houseId?: number;
          /**
           * @description  房源编号
           */
          houseNo?: string;
          /**
           * @description  门牌号
           */
          houseNum?: string;
          /**
           * @description  费用结算单签名 1-未签名 2-已签名
           */
          houseSettlementIsSignature?: number;
          /**
           * @description  1-整租 2-合租 3-集中
           */
          houseType?: number;
          /**
           * @description  主键
           */
          id?: number;
          /**
           * @description  租后是否审核 1已审核  2未通过  3未审核
           */
          isAfterAudit?: number;
          /**
           * @description  只看自己的
           */
          isMy?: number;
          /**
           * @description  是否续约 0-否 1-是
           */
          isRenewal?: number;
          /**
           * @description  缴费日期(结束)
           */
          payDateEnd?: string;
          /**
           * @description  缴费日期(开始)
           */
          payDateStart?: string;
          /**
           * @description  0-押金 非0-表示房租
           */
          payNum?: number;
          /**
           * @description  还款状态 1-未付 2-已还部分 3-已还完 4-坏账
           */
          payStatus?: number;
          /**
           * @description  付款类型ID
           */
          payTypeId?: number;
          /**
           * @description  租金类型 房东=应支 租客=应收
           */
          relationTypeId?: number;
          /**
           * @description  续约ID(房源ID-租客ID) 选其一
           */
          renewalId?: number;
          /**
           * @description  房间编号
           */
          roomNo?: string;
          /**
           * @description  状态：1正常 2待退房 3冻结
           */
          status?: number;
          /**
           * @description  管家ID
           */
          stewardId?: number;
          /**
           * @description  第二管家ID
           */
          stewardId2?: number;
          /**
           * @description  员工分组id
           */
          storeGroupId?: number;
          /**
           * @description  门店ID
           */
          storeId?: number;
          /**
           * @description  门店id多选
           */
          storeIdList?: string;
          /**
           * @description  租客ID(续约ID-房源ID) 选其一
           */
          tenantsId?: number;
        }
        /**
         * @name 房租导出
         * @param params
         */
        export function houseRent(params: HouseRentParams) {
          return request.get("/finance-v100001/finance/exportExcel/houseRent", {
            params
          });
        }
        export interface IncomeExpenseDetailParams {
          /**
           * @description  区域ID
           */
          areaId?: number;
          /**
           * @description  计费方式(1-均费 2-自定义)
           */
          billType?: number;
          /**
           * @description  部门id
           */
          deptId?: number;
          /**
           * @description  物业地区ID
           */
          detailId?: string;
          /**
           * @description  款项原因ID
           */
          feeReasonId?: number;
          /**
           * @description  款项类型ID
           */
          feeTypeId?: number;
          /**
           * @description  房源ID 与收支计划ID和租客ID互斥
           */
          houseId?: number;
          /**
           * @description  房源编号
           */
          houseNum?: string;
          /**
           * @description  1-整租 2-合租 3-集中
           */
          houseType?: number;
          /**
           * @description  收支类型(1-收入 2-支出)
           */
          inoutType?: number;
          /**
           * @description  支付时间
           */
          payDate?: string;
          /**
           * @description  缴费日期筛选结束时间
           */
          payDateEnd?: string;
          /**
           * @description  支付时间统计月 格式:2020-01
           */
          payDateMonth?: string;
          /**
           * @description  缴费日期筛选开始时间
           */
          payDateStart?: string;
          /**
           * @description  付款方式ID
           */
          payMethodId?: number;
          /**
           * @description  还款状态 1-未付 2-已还部分 3-已还完 4-坏账
           */
          payStatus?: number;
          /**
           * @description  收支计划ID 与房源ID和租客ID互斥
           */
          planId?: number;
          /**
           * @description  计划类型(1-房屋 2-其他)
           */
          planType?: number;
          /**
           * @description  关联人身份证号码
           */
          relationIdcard?: string;
          /**
           * @description  关联人
           */
          relationName?: string;
          /**
           * @description  关联人电话
           */
          relationPhone?: string;
          /**
           * @description  关联类型ID
           */
          relationTypeId?: number;
          /**
           * @description  房间编号
           */
          roomNo?: string;
          /**
           * @description  门店ID
           */
          storeId?: number;
          /**
           * @description  门店id多选
           */
          storeIdList?: string;
          /**
           * @description  租客ID 与收支计划ID和房源ID互斥
           */
          tenantsId?: number;
        }
        /**
         * @name 收支明细导出
         * @param params
         */
        export function incomeExpenseDetail(params: IncomeExpenseDetailParams) {
          return request.get(
            "/finance-v100001/finance/exportExcel/incomeExpenseDetail",
            { params }
          );
        }
        export interface IncomeExpensePlanParams {
          /**
           * @description  区域ID
           */
          areaId?: number;
          /**
           * @description  部门id
           */
          deptId?: number;
          /**
           * @description  物业地区ID
           */
          detailId?: string;
          /**
           * @description  款项原因ID
           */
          feeReasonId?: number;
          /**
           * @description  款项类型ID
           */
          feeTypeId?: number;
          /**
           * @description  首次付款时间(止)
           */
          firstPayDateEnd?: string;
          /**
           * @description  开始时间统计月 格式 2020-01
           */
          firstPayDateMonth?: string;
          /**
           * @description  首次付款时间(起)
           */
          firstPayDateStart?: string;
          /**
           * @description  房源ID
           */
          hosueId?: number;
          /**
           * @description  房源编号
           */
          houseNo?: string;
          /**
           * @description  门牌号
           */
          houseNum?: string;
          /**
           * @description  1-整租 2-合租 3-集中
           */
          houseType?: number;
          /**
           * @description  收入类型 1-收入 2-支出
           */
          inoutType?: number;
          /**
           * @description  付款方式ID
           */
          payMethodId?: number;
          /**
           * @description  计划类型(1-房屋 2-其他)
           */
          planType?: number;
          /**
           * @description  关联人身份证号码
           */
          relationIdcard?: string;
          /**
           * @description  关联人
           */
          relationName?: string;
          /**
           * @description  关联人电话
           */
          relationPhone?: string;
          /**
           * @description  关联类型ID
           */
          relationTypeId?: number;
          /**
           * @description  房间编号
           */
          roomNo?: string;
          /**
           * @description  门店ID
           */
          storeId?: number;
          /**
           * @description  门店id多选
           */
          storeIdList?: string;
          /**
           * @description  租客ID
           */
          tenantsId?: number;
        }
        /**
         * @name 收支计划导出
         * @param params
         */
        export function incomeExpensePlan(params: IncomeExpensePlanParams) {
          return request.get(
            "/finance-v100001/finance/exportExcel/incomeExpensePlan",
            { params }
          );
        }
      }
      export namespace audit {
        export interface BatchParams {
          /**
           * @description  主键IDS 逗号分割 只能选择未审核的数据
           */
          ids: string;
        }
        /**
         * @name 财务批量审核
         * @param data
         */
        export function batch(data: BatchParams) {
          return request.put<ResponseResult<object>>(
            "/finance-v100001/finance/audit/batch",
            data
          );
        }
      }
      export namespace cashier {
        export interface BatchParams {
          /**
           * @description  主键IDS 逗号分割 只能选择已复核的数据
           */
          ids: string;
        }
        /**
         * @name 财务批量出纳
         * @param data
         */
        export function batch(data: BatchParams) {
          return request.put<ResponseResult<object>>(
            "/finance-v100001/finance/cashier/batch",
            data
          );
        }
      }
      export namespace repay {
        export interface BatchParams {
          /**
           * @description  主键id，多个
           */
          ids?: string;
          /**
           * @description  收支账号ID
           */
          payAccountId?: number;
          /**
           * @description  付款时间(结束) 若为空 则为开始时间之前（包含）
           */
          payDateEnd?: string;
          /**
           * @description  付款时间(开始)
           */
          payDateStart: string;
          /**
           * @description  支付方式ID
           */
          payMethodId?: number;
          /**
           * @description  收入类型 13-收入（租客） 12-支出（房东）
           */
          relationTypeId: number;
          /**
           * @description  门店ID 多个
           */
          storeId?: string;
          /**
           * @description  是否生成财务流水 1是 2否，默认为否
           */
          type: number;
        }
        /**
         * @name 批量修改租金状态
         * @param data
         */
        export function batch(data: BatchParams) {
          return request.put<ResponseResult<object>>(
            "/finance-v100001/finance/repay/batch",
            data
          );
        }
        export interface BatchV1Params {
          /**
           * @description  主键id，多个
           */
          ids?: string;
          /**
           * @description  收支账号ID
           */
          payAccountId?: number;
          /**
           * @description  付款时间(结束) 若为空 则为开始时间之前（包含）
           */
          payDateEnd?: string;
          /**
           * @description  付款时间(开始)
           */
          payDateStart: string;
          /**
           * @description  支付方式ID
           */
          payMethodId?: number;
          /**
           * @description  收入类型 13-收入（租客） 12-支出（房东）
           */
          relationTypeId: number;
          /**
           * @description  门店ID 多个
           */
          storeId?: string;
          /**
           * @description  是否生成财务流水 1是 2否，默认为否
           */
          type: number;
        }
        /**
         * @name 选中后批量修改租金状态
         * @param data
         */
        export function batchV1(data: BatchV1Params) {
          return request.put<ResponseResult<object>>(
            "/finance-v100001/finance/repay/batchV1",
            data
          );
        }
      }
      export namespace review {
        export interface BatchParams {
          /**
           * @description  主键IDS 逗号分割 只能选择已审核的数据
           */
          ids: string;
        }
        /**
         * @name 财务批量复核
         * @param data
         */
        export function batch(data: BatchParams) {
          return request.put<ResponseResult<object>>(
            "/finance-v100001/finance/review/batch",
            data
          );
        }
      }
    }
    export namespace financeAccount {
      /**
       * @name 财务账号表删除
       * @param id 财务账号表删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/finance-v100001/financeAccount/delete/${id}`
        );
      }
      /**
       * @name 财务账号表详情
       * @param id 财务账号表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<FinanceAccount>>(
          `/finance-v100001/financeAccount/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  账号名称
         */
        accountName?: string;
        /**
         * @description  主键
         */
        id?: number;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店ID
         */
        storeId?: number;
      }
      /**
       * @name 财务账号表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<FinanceAccount>>>(
          "/finance-v100001/financeAccount/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  银行账号
         */
        account?: number;
        /**
         * @description  账号名称
         */
        name?: string;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  门店ID
         */
        storeId: number;
      }
      /**
       * @name 财务账号表新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/finance-v100001/financeAccount/save",
          data
        );
      }
      /**
       * @name 财务账号表下拉
       */
      export function select() {
        return request.get<ResponseResult<Array<FinanceAccount>>>(
          "/finance-v100001/financeAccount/select"
        );
      }
      export interface UpdateParams {
        /**
         * @description  银行账号
         */
        account?: number;
        /**
         * @description  账号名称
         */
        name?: string;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  门店ID
         */
        storeId: number;
      }
      /**
       * @name 财务账号表修改
       * @param id 财务账号表修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/finance-v100001/financeAccount/update/${id}`,
          data
        );
      }
    }
    export namespace financePayStatus {
      export interface GetFinanceParameterParams {
        /**
         * @description  坏账原因
         */
        badDeptReason?: string;
        /**
         * @description  定金IDS 多个逗号分割,支付房租才会些参数其它情况没有
         */
        bargainIds?: string;
        /**
         * @description  164欠款表,165收支计划，166房租表，462租客退房，609房东退房,412定金退还
         */
        businessTypeId: number;
        /**
         * @description  欠款IDS 多个逗号分割,支付房租才会些参数其它情况没有
         */
        debtIds?: string;
        /**
         * @description  当前数据主键
         */
        id: number;
        /**
         * @description  滞纳金(支付房租才有)
         */
        lateFee?: number;
        /**
         * @description  本次付款
         */
        payFee: number;
        /**
         * @description  还款状态 2-已还部分 3-已还完 4-坏账
         */
        payStatus: number;
        /**
         * @description  剩余还款日期（只有欠款已还部分才有这个字段）
         */
        surplusPayDate?: string;
      }
      /**
       * @name 各个业务表生成财务的参数
       * @param params
       */
      export function getFinanceParameter(params: GetFinanceParameterParams) {
        return request.get<ResponseResult<RepayVo>>(
          "/finance-v100001/financePayStatus/getFinanceParameter",
          { params }
        );
      }
    }
    export namespace houseExit {
      export interface MyHouseListParams {
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
      }
      /**
       * @name 主要用于给房东打钱
       * @param params
       */
      export function myHouseList(params: MyHouseListParams) {
        return request.get<ResponseResult<PageList<HouseExit>>>(
          "/finance-v100001/houseExit/myHouseList",
          { params }
        );
      }
      export interface PayHouseExitParams {
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  前端计算出来的支付金额
         */
        payMoneyShow?: number;
      }
      /**
       * @name 房东退房交还押金
       * @param data
       */
      export function payHouseExit(data: PayHouseExitParams) {
        return request.post<ResponseResult<JHPayResult>>(
          "/finance-v100001/houseExit/payHouseExit",
          data
        );
      }
      /**
       * @name 发送管家确认消息
       * @param id 发送管家确认消息
       */
      export function sendHouseKeepConfirm(id: string | number) {
        return request.get<ResponseResult<object>>(
          `/finance-v100001/houseExit/sendHouseKeepConfirm/${id}`
        );
      }
      /**
       * @name setStewardConfirm
       */
      export function setStewardConfirm() {
        return request.post<ResponseResult<object>>(
          "/finance-v100001/houseExit/setStewardConfirm"
        );
      }
      export interface UpdateConfirmParams {
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  管家确认无误：1,无误；2，未确认;3,有误
         */
        stewardConfirm?: number;
        /**
         * @description  管家确认有误备注
         */
        stewardConfirmNotes?: string;
      }
      /**
       * @name 退租管家确认无误接口
       * @param data
       */
      export function updateConfirm(data: UpdateConfirmParams) {
        return request.post<ResponseResult<object>>(
          "/finance-v100001/houseExit/updateConfirm",
          data
        );
      }
    }
    export namespace houseInsuranceClaims {
      /**
       * @name 家财险理赔表删除
       * @param id 家财险理赔表删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/finance-v100001/houseInsuranceClaims/delete/${id}`
        );
      }
      /**
       * @name 家财险理赔表详情
       * @param id 家财险理赔表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<HouseInsuranceClaims>>(
          `/finance-v100001/houseInsuranceClaims/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  报案报表查询时间
         */
        caseReportTime?: string;
        /**
         * @description  报案时间结束
         */
        caseTimeEnd?: string;
        /**
         * @description  报案时间开始
         */
        caseTimeStart?: string;
        /**
         * @description  理赔报表查询时间
         */
        claimsReportTime?: string;
        /**
         * @description  理赔时间结束
         */
        claimsTimeEnd?: string;
        /**
         * @description  理赔时间开始
         */
        claimsTimeStart?: string;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  家财险表主键
         */
        insuranceId?: number;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 家财险理赔表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<HouseInsuranceClaims>>>(
          "/finance-v100001/houseInsuranceClaims/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  报案时间
         */
        caseTime: string;
        /**
         * @description  理赔金额
         */
        claimsAmount: number;
        /**
         * @description  事故原因
         */
        claimsDesc: string;
        /**
         * @description  理赔时间
         */
        claimsTime: string;
        /**
         * @description  家财险表主键
         */
        insuranceId: number;
        /**
         * @description  备注
         */
        remark?: string;
      }
      /**
       * @name 家财险理赔表新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/finance-v100001/houseInsuranceClaims/save",
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  报案时间
         */
        caseTime: string;
        /**
         * @description  理赔金额
         */
        claimsAmount: number;
        /**
         * @description  事故原因
         */
        claimsDesc: string;
        /**
         * @description  理赔时间
         */
        claimsTime: string;
        /**
         * @description  家财险表主键
         */
        insuranceId: number;
        /**
         * @description  备注
         */
        remark?: string;
      }
      /**
       * @name 家财险理赔表修改
       * @param id 家财险理赔表修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/finance-v100001/houseInsuranceClaims/update/${id}`,
          data
        );
      }
    }
    export namespace houseInsurance {
      /**
       * @name 家财险删除
       * @param id 家财险删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/finance-v100001/houseInsurance/delete/${id}`
        );
      }
      /**
       * @name 家财险详情
       * @param id 家财险详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<HouseInsurance>>(
          `/finance-v100001/houseInsurance/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  购买日期报表查询时间
         */
        buyReportTime?: string;
        /**
         * @description  购买时间结束
         */
        buyTimeEnd?: string;
        /**
         * @description  购买时间开始
         */
        buyTimeStart?: string;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  房东姓名
         */
        houseName?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  身份证
         */
        idCard?: string;
        /**
         * @description  到时日期报表查询时间 (默认当前这个时间为时间查询条件)
         */
        insuranceEndReportTime?: string;
        /**
         * @description  终保日期结束
         */
        insuranceEndTimeEnd?: string;
        /**
         * @description  终保日期开始
         */
        insuranceEndTimeStart?: string;
        /**
         * @description  起保日期报表查询时间
         */
        insuranceStartReportTime?: string;
        /**
         * @description  起保日期结束
         */
        insuranceStartTimeEnd?: string;
        /**
         * @description  起保日期开始
         */
        insuranceStartTimeStart?: string;
        /**
         * @description  保险类型：1新签购买、2脱保购买、3续保购买
         */
        insuranceType?: number;
        /**
         * @description  保单号
         */
        orderNo?: string;
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  托管开始时间结束
         */
        startTimeEnd?: string;
        /**
         * @description  托管开始时间开始
         */
        startTimeStart?: string;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
      }
      /**
       * @name 家财险列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<HouseInsurance>>>(
          "/finance-v100001/houseInsurance/list",
          { params }
        );
      }
      export interface SaveParams {
        /**
         * @description  购买时间
         */
        buyTime: string;
        /**
         * @description  房源id
         */
        houseId: number;
        /**
         * @description  终保日期
         */
        insuranceEndTime: string;
        /**
         * @description  起保日期
         */
        insuranceStartTime: string;
        /**
         * @description  保险类型：1新签购买、2脱保购买、3续保购买
         */
        insuranceType: number;
        /**
         * @description  保单号
         */
        orderNo: string;
      }
      /**
       * @name 家财险新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/finance-v100001/houseInsurance/save",
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  购买时间
         */
        buyTime: string;
        /**
         * @description  房源id
         */
        houseId: number;
        /**
         * @description  终保日期
         */
        insuranceEndTime: string;
        /**
         * @description  起保日期
         */
        insuranceStartTime: string;
        /**
         * @description  保险类型：1新签购买、2脱保购买、3续保购买
         */
        insuranceType: number;
        /**
         * @description  保单号
         */
        orderNo: string;
      }
      /**
       * @name 家财险修改
       * @param id 家财险修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/finance-v100001/houseInsurance/update/${id}`,
          data
        );
      }
      /**
       * @name 家财险导入模版下载
       */
      export function createExcelTemplate() {
        return request.get<ResponseEntity>(
          "/finance-v100001/houseInsurance/createExcelTemplate"
        );
      }
      /**
       * @name 家财险数据导入
       */
      export function insertImplInsurance(data: any) {
        return request.post<ResponseResult<object>>(
          "/finance-v100001/houseInsurance/insertImplInsurance",
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );
      }
    }
    export namespace houseRentDetail {
      export interface CoverageParams {
        /**
         * @description  房屋ID 与租客ID互斥
         */
        houseId?: number;
        /**
         * @description  租客ID 与房屋ID互斥
         */
        tenantId?: number;
        /**
         * @description  2表示修改后生成账单
         */
        type?: number;
      }
      /**
       * @name 房屋租金表删除重新生成
       * @param data
       */
      export function coverage(data: CoverageParams) {
        return request.put<ResponseResult<object>>(
          "/finance-v100001/houseRentDetail/coverage",
          data
        );
      }
      /**
       * @name 房屋租金明细表删除
       * @param id 房屋租金明细表删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/finance-v100001/houseRentDetail/delete/${id}`
        );
      }
      /**
       * @name 房屋租金明细表详情
       * @param id 房屋租金明细表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<HouseRent>>(
          `/finance-v100001/houseRentDetail/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  区域ID
         */
        areaId?: number;
        /**
         * @description  业务人员ID
         */
        businessId?: number;
        /**
         * @description  联系人
         */
        contactName?: string;
        /**
         * @description  联系电话
         */
        contactPhone?: string;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  统计月份 格式:2020-02
         */
        dateMonth?: string;
        /**
         * @description  统计日期类型 1-按应收 2-有效期开始 3-有效期结束
         */
        dateType?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区ID
         */
        detailId?: string;
        /**
         * @description  房源ID(续约ID-租客ID) 选其一
         */
        houseId?: number;
        /**
         * @description  房源编号
         */
        houseNo?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  费用结算单签名 1-未签名 2-已签名
         */
        houseSettlementIsSignature?: number;
        /**
         * @description  1-整租 2-合租 3-集中
         */
        houseType?: number;
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  只看自己的
         */
        isMy?: number;
        /**
         * @description  是否续约 0-否 1-是
         */
        isRenewal?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  缴费日期(结束)
         */
        payDateEnd?: string;
        /**
         * @description  缴费日期(开始)
         */
        payDateStart?: string;
        /**
         * @description  0-押金 非0-表示房租
         */
        payNum?: number;
        /**
         * @description  还款状态 1-未付 2-已还部分 3-已还完 4-坏账
         */
        payStatus?: number;
        /**
         * @description  付款类型ID
         */
        payTypeId?: number;
        /**
         * @description  租金类型 房东=应支 租客=应收
         */
        relationTypeId?: number;
        /**
         * @description  续约ID(房源ID-租客ID) 选其一
         */
        renewalId?: number;
        /**
         * @description  房间编号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  状态：1正常 2待退房 3冻结
         */
        status?: number;
        /**
         * @description  管家ID
         */
        stewardId?: number;
        /**
         * @description  第二管家ID
         */
        stewardId2?: number;
        /**
         * @description  员工分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店ID
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  租客ID(续约ID-房源ID) 选其一
         */
        tenantsId?: number;
      }
      /**
       * @name 房屋租金明细分页列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<HouseRent>>>(
          "/finance-v100001/houseRentDetail/list",
          { params }
        );
      }
      export interface ListAndAllParams {
        /**
         * @description  房源ID
         */
        houseId?: number;
        /**
         * @description  续约ID
         */
        renewalId?: number;
        /**
         * @description  租客ID
         */
        tenantsId?: number;
      }
      /**
       * @name 根据条件查询列表包含总数
       * @param params
       */
      export function listAndAll(params: ListAndAllParams) {
        return request.get<ResponseResult<HouseRentVo>>(
          "/finance-v100001/houseRentDetail/listAndAll",
          { params }
        );
      }
      export interface RegenerateParams {
        /**
         * @description  房源ID
         */
        houseId: number;
        /**
         * @description  租客ID 如租客ID不为空则代表重新生成租客租金表
         */
        tenantsId?: number;
        houseRentList: Array<{
          freeDay: number;
          freeFee: number;
          houseFee: number;
          id: number;
          incomeHouseFee: number;
          intervalDay: number;
          intervalMonth: number;
          intervalYear: number;
          minusOther: Array<{
            code: number;
            name: string;
            remarks: string;
            val: string;
            val1: string;
          }>;
          payDate: string;
          payNum: number;
          periodEnd: string;
          periodStart: string;
          plusOther: Array<{
            code: number;
            name: string;
            remarks: string;
            val: string;
            val1: string;
          }>;
          shouldFee: number;
        }>;
      }
      /**
       * @name 房屋租金表修改数据
       * @param data
       */
      export function regenerate(data: RegenerateParams) {
        return request.put<ResponseResult<object>>(
          "/finance-v100001/houseRentDetail/regenerate",
          data
        );
      }
      export interface TotalParams {
        /**
         * @description  区域ID
         */
        areaId?: number;
        /**
         * @description  业务人员ID
         */
        businessId?: number;
        /**
         * @description  联系人
         */
        contactName?: string;
        /**
         * @description  联系电话
         */
        contactPhone?: string;
        /**
         * @description  合同性质id
         */
        contractId?: number;
        /**
         * @description  统计月份 格式:2020-02
         */
        dateMonth?: string;
        /**
         * @description  统计日期类型 1-按应收 2-有效期开始 3-有效期结束
         */
        dateType?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区ID
         */
        detailId?: string;
        /**
         * @description  房源ID(续约ID-租客ID) 选其一
         */
        houseId?: number;
        /**
         * @description  房源编号
         */
        houseNo?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  费用结算单签名 1-未签名 2-已签名
         */
        houseSettlementIsSignature?: number;
        /**
         * @description  1-整租 2-合租 3-集中
         */
        houseType?: number;
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  租后是否审核 1已审核  2未通过  3未审核
         */
        isAfterAudit?: number;
        /**
         * @description  只看自己的
         */
        isMy?: number;
        /**
         * @description  是否续约 0-否 1-是
         */
        isRenewal?: number;
        /**
         * @description  缴费日期(结束)
         */
        payDateEnd?: string;
        /**
         * @description  缴费日期(开始)
         */
        payDateStart?: string;
        /**
         * @description  0-押金 非0-表示房租
         */
        payNum?: number;
        /**
         * @description  还款状态 1-未付 2-已还部分 3-已还完 4-坏账
         */
        payStatus?: number;
        /**
         * @description  付款类型ID
         */
        payTypeId?: number;
        /**
         * @description  租金类型 房东=应支 租客=应收
         */
        relationTypeId?: number;
        /**
         * @description  续约ID(房源ID-租客ID) 选其一
         */
        renewalId?: number;
        /**
         * @description  房间编号
         */
        roomNo?: string;
        /**
         * @description  状态：1正常 2待退房 3冻结
         */
        status?: number;
        /**
         * @description  管家ID
         */
        stewardId?: number;
        /**
         * @description  第二管家ID
         */
        stewardId2?: number;
        /**
         * @description  员工分组id
         */
        storeGroupId?: number;
        /**
         * @description  门店ID
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  租客ID(续约ID-房源ID) 选其一
         */
        tenantsId?: number;
      }
      /**
       * @name 房屋租金明细分页总计
       * @param params
       */
      export function total(params: TotalParams) {
        return request.get<ResponseResult<HouseRentTotal>>(
          "/finance-v100001/houseRentDetail/total",
          { params }
        );
      }
      export interface UpdateHouseFeeParams {
        /**
         * @description  房租金额
         */
        houseFee: number;
        /**
         * @description  主键
         */
        id: number;
        /**
         * @description  缴费日期
         */
        payDate: string;
      }
      /**
       * @name 房屋租金明细表修改
       * @param data
       */
      export function updateHouseFee(data: UpdateHouseFeeParams) {
        return request.put<ResponseResult<object>>(
          "/finance-v100001/houseRentDetail/updateHouseFee",
          data
        );
      }
      export interface UpdateOtherAmountParams {
        /**
         * @description  id
         */
        id?: number;
        /**
         * @description  plusOther
         */
        plusOther?: string;
        /**
         * @description  minusOther
         */
        minusOther?: string;
      }
      /**
       * @name 其它费修改
       * @param data
       */
      export function updateOtherAmount(data: UpdateOtherAmountParams) {
        return request.put<ResponseResult<object>>(
          "/finance-v100001/houseRentDetail/updateOtherAmount",
          data
        );
      }
      export interface UpdateRemarkParams {
        /**
         * @description  备注
         */
        remark?: string;
      }
      /**
       * @name 应收应支添加备注
       * @param id 应收应支添加备注
       * @param data
       */
      export function updateRemark(
        id: string | number,
        data: UpdateRemarkParams
      ) {
        return request.put<ResponseResult<object>>(
          `/finance-v100001/houseRentDetail/updateRemark/${id}`,
          data
        );
      }
      export namespace end {
        export interface TimeParams {
          /**
           * @description  日
           */
          day?: number;
          /**
           * @description  月
           */
          month?: number;
          /**
           * @description  开始日期 格式 2020-01-01
           */
          startDate?: string;
          /**
           * @description  年
           */
          year?: number;
        }
        /**
         * @name 获取结束时间
         * @param params
         */
        export function time(params: TimeParams) {
          return request.get<ResponseResult<object>>(
            "/finance-v100001/houseRentDetail/end/time",
            { params }
          );
        }
      }
      export namespace late {
        export interface ReductionParams {
          /**
           * @description  减免金额
           */
          amount?: string;
          /**
           * @description  租金ID
           */
          id?: number;
        }
        /**
         * @name 滞纳金减免 必须选择有滞纳金的数据
         * @param data
         */
        export function reduction(data: ReductionParams) {
          return request.put<ResponseResult<object>>(
            "/finance-v100001/houseRentDetail/late/reduction",
            data
          );
        }
      }
    }
    export namespace houseRentExit {
      /**
       * @name 退房房屋租金明细表详情
       * @param id 退房房屋租金明细表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<HouseRentExit>>(
          `/finance-v100001/houseRentExit/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  物业地区ID
         */
        detailId?: string;
        /**
         * @description  房源ID
         */
        houseId?: number;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  房屋类型 1-整租 2-合租 3-集中
         */
        houseType?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  付款日期
         */
        payDate?: string;
        /**
         * @description  第几次付款
         */
        payNum?: number;
        /**
         * @description  房间ID
         */
        roomId?: number;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  管家id
         */
        stewardId?: number;
        /**
         * @description  门店ID
         */
        storeId?: number;
        /**
         * @description  租客ID
         */
        tenantsId?: number;
      }
      /**
       * @name 退房房屋租金明细表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<HouseRent>>>(
          "/finance-v100001/houseRentExit/list",
          { params }
        );
      }
    }
    export namespace houseRentFinance {
      export interface FindPdfUrlParams {
        /**
         * @description  164 欠款，166 房租表
         */
        businessTypeId?: number;
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  身份类型id
         */
        relationTypeId?: number;
      }
      /**
       * @name 查看电子票据
       * @param params
       */
      export function findPdfUrl(params: FindPdfUrlParams) {
        return request.get<ResponseResult<object>>(
          "/finance-v100001/houseRentFinance/findPdfUrl",
          { params }
        );
      }
      export interface FindPdfUrlV1Params {
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  身份类型id
         */
        relationTypeId?: number;
      }
      /**
       * @name 查看电子票据
       * @param params
       */
      export function findPdfUrlV1(params: FindPdfUrlV1Params) {
        return request.get<ResponseResult<object>>(
          "/finance-v100001/houseRentFinance/findPdfUrlV1",
          { params }
        );
      }
    }
    export namespace home {
      export namespace houseRent {
        export interface AppHouseRentExportParams {
          /**
           * @description  物业地区ID
           */
          detailId?: string;
          /**
           * @description  门牌号
           */
          houseNum?: string;
          /**
           * @description  还款状态 1-未付 2-已还部分 3-已还完 4-坏账
           */
          payStatus?: number;
          /**
           * @description  租金类型 房东=12 租客=13
           */
          relationTypeId?: number;
          /**
           * @description  管家ID
           */
          stewardId?: number;
          /**
           * @description  门店ID
           */
          storeId?: number;
          /**
           * @description  1所有房租， 2表示超期房租  3表示今日   4表示未来5天
           */
          type?: number;
        }
        /**
         * @name 首页查询账单数据导出
         * @param params
         */
        export function appHouseRentExport(params: AppHouseRentExportParams) {
          return request.get(
            "/finance-v100001/home/houseRent/appHouseRentExport",
            { params }
          );
        }
        export interface AppHouseRentListParams {
          /**
           * @description  物业地区ID
           */
          detailId?: string;
          /**
           * @description  门牌号
           */
          houseNum?: string;
          pageNo?: number;
          pageSize?: number;
          /**
           * @description  还款状态 1-未付 2-已还部分 3-已还完 4-坏账
           */
          payStatus?: number;
          /**
           * @description  租金类型 房东=12 租客=13
           */
          relationTypeId?: number;
          sortField?: string;
          sortWay?: string;
          /**
           * @description  管家ID
           */
          stewardId?: number;
          /**
           * @description  门店ID
           */
          storeId?: number;
          /**
           * @description  1所有房租， 2表示超期房租  3表示今日   4表示未来5天
           */
          type?: number;
        }
        /**
         * @name 首页查询账单数据
         * @param params
         */
        export function appHouseRentList(params: AppHouseRentListParams) {
          return request.get<ResponseResult<PageList<HouseRent>>>(
            "/finance-v100001/home/houseRent/appHouseRentList",
            { params }
          );
        }
      }
    }
    export namespace houseSettlement {
      /**
       * @name 费用结算单表删除
       * @param id 费用结算单表删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/finance-v100001/houseSettlement/delete/${id}`
        );
      }
      export interface DeleteListParams {
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  姓名
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  电话
         */
        phone?: string;
        /**
         * @description  类型 12房东 13租客
         */
        relationTypeId?: number;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  租客id
         */
        tenantsId?: number;
      }
      /**
       * @name 结算单列表：回收站
       * @param params
       */
      export function deleteList(params: DeleteListParams) {
        return request.get<ResponseResult<PageList<HouseSettlement>>>(
          "/finance-v100001/houseSettlement/deleteList",
          { params }
        );
      }
      /**
       * @name 费用结算单废弃
       * @param id 费用结算单废弃
       */
      export function discard(id: string | number) {
        return request.post<ResponseResult<object>>(
          `/finance-v100001/houseSettlement/discard/${id}`
        );
      }
      /**
       * @name 费用结算单返回默认选中值
       */
      export function getDefaultCodeList() {
        return request.get<ResponseResult<HouseSettlementAddVo>>(
          "/finance-v100001/houseSettlement/getDefaultCodeList"
        );
      }
      export interface GetPdfUrlParams {
        /**
         * @description  邮件地址
         */
        email?: string;
        /**
         * @description  结算单ID
         */
        id?: number;
        /**
         * @description  类型1表示查看pdf,2表示发送邮件
         */
        type?: number;
      }
      /**
       * @name 费用结算单发送邮件或者查看
       * @param params
       */
      export function getPdfUrl(params: GetPdfUrlParams) {
        return request.get<ResponseResult<object>>(
          "/finance-v100001/houseSettlement/getPdfUrl",
          { params }
        );
      }
      /**
       * @name 费用结算单表详情
       * @param id 费用结算单表详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<HouseSettlement>>(
          `/finance-v100001/houseSettlement/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区id
         */
        detailId?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  门牌号）
         */
        houseNum?: string;
        /**
         * @description  1整租 2合租 3集中
         */
        houseType?: number;
        /**
         * @description  姓名
         */
        name?: string;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  电话
         */
        phone?: string;
        /**
         * @description  类型 12房东 13租客
         */
        relationTypeId?: number;
        /**
         * @description  房间id
         */
        roomId?: number;
        /**
         * @description  房间号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店id
         */
        storeId?: number;
        /**
         * @description  租客id
         */
        tenantsId?: number;
      }
      /**
       * @name 费用结算单表列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<HouseSettlement>>>(
          "/finance-v100001/houseSettlement/list",
          { params }
        );
      }
      export interface ReductionParams {
        /**
         * @description  id
         */
        id?: number;
      }
      /**
       * @name 费用结算单还原
       * @param data
       */
      export function reduction(data: ReductionParams) {
        return request.post<ResponseResult<object>>(
          "/finance-v100001/houseSettlement/reduction",
          data
        );
      }
      export interface SaveParams {
        /**
         * @description  清节费用
         */
        clearAmount?: number;
        /**
         * @description  清节时间
         */
        clearTime?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  能源图片
         */
        imgs?: string;
        /**
         * @description  维修金额
         */
        maintenanceAmount?: number;
        /**
         * @description  维修描述
         */
        maintenanceDetail?: string;
        /**
         * @description  应付时间
         */
        payDate: string;
        /**
         * @description  类型 12房东 13租客
         */
        relationTypeId: number;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  总费用:欠费金额+清节+维修费 - 预交金额
         */
        sumAmount: number;
        /**
         * @description  租客id
         */
        tenantsId?: number;
        /**
         * @description  结算方式：1线下现金  2表示线上欠款
         */
        type: number;
        otherAmountList: Array<{
          debtAmount: number;
          endTime: string;
          preAmount: number;
          price: number;
          projectCode: number;
          projectName: string;
          remark: string;
          startTime: string;
        }>;
        utilityList: Array<{
          debtAmount: number;
          endNum: number;
          preAmount: number;
          price: number;
          projectCode: number;
          projectName: string;
          remark: string;
          startNum: number;
        }>;
      }
      /**
       * @name 手机端：费用结算单表新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/finance-v100001/houseSettlement/save",
          data
        );
      }
      export interface SignatureUserParams {
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  签名图片url
         */
        signUserImg?: string;
      }
      /**
       * @name 结算单签字，租客与房东端 需要 判断，如果已签字，那不能在签了
       * @param data
       */
      export function signatureUser(data: SignatureUserParams) {
        return request.post<ResponseResult<object>>(
          "/finance-v100001/houseSettlement/signatureUser",
          data
        );
      }
      export interface UpdateParams {
        /**
         * @description  清节费用
         */
        clearAmount?: number;
        /**
         * @description  清节时间
         */
        clearTime?: string;
        /**
         * @description  房源id
         */
        houseId?: number;
        /**
         * @description  能源图片
         */
        imgs?: string;
        /**
         * @description  维修金额
         */
        maintenanceAmount?: number;
        /**
         * @description  维修描述
         */
        maintenanceDetail?: string;
        /**
         * @description  应付时间
         */
        payDate: string;
        /**
         * @description  类型 12房东 13租客
         */
        relationTypeId: number;
        /**
         * @description  备注
         */
        remark?: string;
        /**
         * @description  总费用:欠费金额+清节+维修费 - 预交金额
         */
        sumAmount: number;
        /**
         * @description  租客id
         */
        tenantsId?: number;
        /**
         * @description  结算方式：1线下现金  2表示线上欠款
         */
        type: number;
        otherAmountList: Array<{
          debtAmount: number;
          endTime: string;
          preAmount: number;
          price: number;
          projectCode: number;
          projectName: string;
          remark: string;
          startTime: string;
        }>;
        utilityList: Array<{
          debtAmount: number;
          endNum: number;
          preAmount: number;
          price: number;
          projectCode: number;
          projectName: string;
          remark: string;
          startNum: number;
        }>;
      }
      /**
       * @name 费用结算单表修改
       * @param id 费用结算单表修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/finance-v100001/houseSettlement/update/${id}`,
          data
        );
      }
    }
    export namespace incomeExpenseDetail {
      /**
       * @name 收支明细删除
       * @param id 收支明细删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/finance-v100001/incomeExpenseDetail/delete/${id}`
        );
      }
      /**
       * @name 收支明细详情
       * @param id 收支明细详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<IncomeExpenseDetail>>(
          `/finance-v100001/incomeExpenseDetail/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  区域ID
         */
        areaId?: number;
        /**
         * @description  计费方式(1-均费 2-自定义)
         */
        billType?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区ID
         */
        detailId?: string;
        /**
         * @description  款项原因ID
         */
        feeReasonId?: number;
        /**
         * @description  款项类型ID
         */
        feeTypeId?: number;
        /**
         * @description  房源ID 与收支计划ID和租客ID互斥
         */
        houseId?: number;
        /**
         * @description  房源编号
         */
        houseNum?: string;
        /**
         * @description  1-整租 2-合租 3-集中
         */
        houseType?: number;
        /**
         * @description  收支类型(1-收入 2-支出)
         */
        inoutType?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  支付时间
         */
        payDate?: string;
        /**
         * @description  缴费日期筛选结束时间
         */
        payDateEnd?: string;
        /**
         * @description  支付时间统计月 格式:2020-01
         */
        payDateMonth?: string;
        /**
         * @description  缴费日期筛选开始时间
         */
        payDateStart?: string;
        /**
         * @description  付款方式ID
         */
        payMethodId?: number;
        /**
         * @description  还款状态 1-未付 2-已还部分 3-已还完 4-坏账
         */
        payStatus?: number;
        /**
         * @description  收支计划ID 与房源ID和租客ID互斥
         */
        planId?: number;
        /**
         * @description  计划类型(1-房屋 2-其他)
         */
        planType?: number;
        /**
         * @description  关联人身份证号码
         */
        relationIdcard?: string;
        /**
         * @description  关联人
         */
        relationName?: string;
        /**
         * @description  关联人电话
         */
        relationPhone?: string;
        /**
         * @description  关联类型ID
         */
        relationTypeId?: number;
        /**
         * @description  房间编号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店ID
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  租客ID 与收支计划ID和房源ID互斥
         */
        tenantsId?: number;
      }
      /**
       * @name 收支明细列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<IncomeExpenseDetail>>>(
          "/finance-v100001/incomeExpenseDetail/list",
          { params }
        );
      }
      export interface SelcetParams {
        /**
         * @description  区域ID
         */
        areaId?: number;
        /**
         * @description  计费方式(1-均费 2-自定义)
         */
        billType?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区ID
         */
        detailId?: string;
        /**
         * @description  款项原因ID
         */
        feeReasonId?: number;
        /**
         * @description  款项类型ID
         */
        feeTypeId?: number;
        /**
         * @description  房源ID 与收支计划ID和租客ID互斥
         */
        houseId?: number;
        /**
         * @description  房源编号
         */
        houseNum?: string;
        /**
         * @description  1-整租 2-合租 3-集中
         */
        houseType?: number;
        /**
         * @description  收支类型(1-收入 2-支出)
         */
        inoutType?: number;
        /**
         * @description  支付时间
         */
        payDate?: string;
        /**
         * @description  缴费日期筛选结束时间
         */
        payDateEnd?: string;
        /**
         * @description  支付时间统计月 格式:2020-01
         */
        payDateMonth?: string;
        /**
         * @description  缴费日期筛选开始时间
         */
        payDateStart?: string;
        /**
         * @description  付款方式ID
         */
        payMethodId?: number;
        /**
         * @description  还款状态 1-未付 2-已还部分 3-已还完 4-坏账
         */
        payStatus?: number;
        /**
         * @description  收支计划ID 与房源ID和租客ID互斥
         */
        planId?: number;
        /**
         * @description  计划类型(1-房屋 2-其他)
         */
        planType?: number;
        /**
         * @description  关联人身份证号码
         */
        relationIdcard?: string;
        /**
         * @description  关联人
         */
        relationName?: string;
        /**
         * @description  关联人电话
         */
        relationPhone?: string;
        /**
         * @description  关联类型ID
         */
        relationTypeId?: number;
        /**
         * @description  房间编号
         */
        roomNo?: string;
        /**
         * @description  门店ID
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  租客ID 与收支计划ID和房源ID互斥
         */
        tenantsId?: number;
      }
      /**
       * @name 按条件查询所有
       * @param params
       */
      export function selcet(params: SelcetParams) {
        return request.get<ResponseResult<Array<IncomeExpenseDetail>>>(
          "/finance-v100001/incomeExpenseDetail/selcet",
          { params }
        );
      }
      export interface UpdateParams {
        /**
         * @description  缴费日期
         */
        payDate?: string;
        /**
         * @description  房租
         */
        shouldFee?: number;
      }
      /**
       * @name 收支明细修改
       * @param id 收支明细修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/finance-v100001/incomeExpenseDetail/update/${id}`,
          data
        );
      }
      export namespace update {
        export interface BatchParams {
          /**
           * @description  dtos
           */
          dtos?: string;
        }
        /**
         * @name 收支明细批量修改
         * @param data
         */
        export function batch(data: BatchParams) {
          return request.put<ResponseResult<object>>(
            "/finance-v100001/incomeExpenseDetail/update/batch",
            data
          );
        }
      }
    }
    export namespace incomeExpensePlan {
      export interface AuditParams {
        /**
         * @description  审核状态 2-通过 3-驳回
         */
        audit: number;
        /**
         * @description  审核备注
         */
        auditRemark?: string;
        /**
         * @description  主键ID
         */
        id: number;
      }
      /**
       * @name 收支计划审核
       * @param data
       */
      export function audit(data: AuditParams) {
        return request.put<ResponseResult<object>>(
          "/finance-v100001/incomeExpensePlan/audit",
          data
        );
      }
      /**
       * @name 收支计划删除
       * @param id 收支计划删除
       */
      export function deleteById(id: string | number) {
        return request.delete<ResponseResult<object>>(
          `/finance-v100001/incomeExpensePlan/delete/${id}`
        );
      }
      /**
       * @name 收支计划详情
       * @param id 收支计划详情
       */
      export function info(id: string | number) {
        return request.get<ResponseResult<IncomeExpensePlan>>(
          `/finance-v100001/incomeExpensePlan/info/${id}`
        );
      }
      export interface ListParams {
        /**
         * @description  区域ID
         */
        areaId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区ID
         */
        detailId?: string;
        /**
         * @description  款项原因ID
         */
        feeReasonId?: number;
        /**
         * @description  款项类型ID
         */
        feeTypeId?: number;
        /**
         * @description  首次付款时间(止)
         */
        firstPayDateEnd?: string;
        /**
         * @description  开始时间统计月 格式 2020-01
         */
        firstPayDateMonth?: string;
        /**
         * @description  首次付款时间(起)
         */
        firstPayDateStart?: string;
        /**
         * @description  房源ID
         */
        hosueId?: number;
        /**
         * @description  房源编号
         */
        houseNo?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1-整租 2-合租 3-集中
         */
        houseType?: number;
        /**
         * @description  收入类型 1-收入 2-支出
         */
        inoutType?: number;
        pageNo?: number;
        pageSize?: number;
        /**
         * @description  付款方式ID
         */
        payMethodId?: number;
        /**
         * @description  计划类型(1-房屋 2-其他)
         */
        planType?: number;
        /**
         * @description  关联人身份证号码
         */
        relationIdcard?: string;
        /**
         * @description  关联人
         */
        relationName?: string;
        /**
         * @description  关联人电话
         */
        relationPhone?: string;
        /**
         * @description  关联类型ID
         */
        relationTypeId?: number;
        /**
         * @description  房间编号
         */
        roomNo?: string;
        sortField?: string;
        sortWay?: string;
        /**
         * @description  门店ID
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  租客ID
         */
        tenantsId?: number;
      }
      /**
       * @name 收支计划列表
       * @param params
       */
      export function list(params: ListParams) {
        return request.get<ResponseResult<PageList<IncomeExpensePlan>>>(
          "/finance-v100001/incomeExpensePlan/list",
          { params }
        );
      }
      export interface ReviewParams {
        /**
         * @description  审核状态 2-通过 3-驳回
         */
        audit: number;
        /**
         * @description  审核备注
         */
        auditRemark?: string;
        /**
         * @description  主键ID
         */
        id: number;
      }
      /**
       * @name 收支计划复核
       * @param data
       */
      export function review(data: ReviewParams) {
        return request.put<ResponseResult<object>>(
          "/finance-v100001/incomeExpensePlan/review",
          data
        );
      }
      export interface SaveParams {
        /**
         * @description  计费类型(1-均费 2-自定义)
         */
        billType: number;
        /**
         * @description  款项原因ID
         */
        feeReasonId: number;
        /**
         * @description  款项类型ID
         */
        feeTypeId: number;
        /**
         * @description  首次收款时间
         */
        firstPayDate: string;
        /**
         * @description  房源ID
         */
        houseId?: number;
        /**
         * @description  收入类型 1-收入 2-支出
         */
        inoutType: number;
        /**
         * @description  付款方式ID
         */
        payMethodId: number;
        /**
         * @description  每期金额
         */
        periodFee: number;
        /**
         * @description  计划类型(1-房屋 2-其他)
         */
        planType: number;
        /**
         * @description  关联人
         */
        relationName?: string;
        /**
         * @description  关联类型ID
         */
        relationTypeId?: number;
        /**
         * @description  收支备注
         */
        remark?: string;
        /**
         * @description  门店ID
         */
        storeId?: number;
        /**
         * @description  租客ID
         */
        tenantsId?: number;
        /**
         * @description  总金额
         */
        totalFee: number;
        /**
         * @description  总期数
         */
        totalPeriod: number;
        incomeExpenseList: Array<{
          payDate: string;
          payNum: number;
          periodEnd: string;
          periodStart: string;
          shouldFee: number;
        }>;
      }
      /**
       * @name 收支计划新增
       * @param data
       */
      export function save(data: SaveParams) {
        return request.post<ResponseResult<object>>(
          "/finance-v100001/incomeExpensePlan/save",
          data
        );
      }
      export interface TotalParams {
        /**
         * @description  区域ID
         */
        areaId?: number;
        /**
         * @description  部门id
         */
        deptId?: number;
        /**
         * @description  物业地区ID
         */
        detailId?: string;
        /**
         * @description  款项原因ID
         */
        feeReasonId?: number;
        /**
         * @description  款项类型ID
         */
        feeTypeId?: number;
        /**
         * @description  首次付款时间(止)
         */
        firstPayDateEnd?: string;
        /**
         * @description  开始时间统计月 格式 2020-01
         */
        firstPayDateMonth?: string;
        /**
         * @description  首次付款时间(起)
         */
        firstPayDateStart?: string;
        /**
         * @description  房源ID
         */
        hosueId?: number;
        /**
         * @description  房源编号
         */
        houseNo?: string;
        /**
         * @description  门牌号
         */
        houseNum?: string;
        /**
         * @description  1-整租 2-合租 3-集中
         */
        houseType?: number;
        /**
         * @description  收入类型 1-收入 2-支出
         */
        inoutType?: number;
        /**
         * @description  付款方式ID
         */
        payMethodId?: number;
        /**
         * @description  计划类型(1-房屋 2-其他)
         */
        planType?: number;
        /**
         * @description  关联人身份证号码
         */
        relationIdcard?: string;
        /**
         * @description  关联人
         */
        relationName?: string;
        /**
         * @description  关联人电话
         */
        relationPhone?: string;
        /**
         * @description  关联类型ID
         */
        relationTypeId?: number;
        /**
         * @description  房间编号
         */
        roomNo?: string;
        /**
         * @description  门店ID
         */
        storeId?: number;
        /**
         * @description  门店id多选
         */
        storeIdList?: string;
        /**
         * @description  租客ID
         */
        tenantsId?: number;
      }
      /**
       * @name 收支计划总计
       * @param params
       */
      export function total(params: TotalParams) {
        return request.get<ResponseResult<IncomeExpensePlanTotal>>(
          "/finance-v100001/incomeExpensePlan/total",
          { params }
        );
      }
      export interface UpdateParams {
        /**
         * @description  计费类型(1-均费 2-自定义)
         */
        billType: number;
        /**
         * @description  款项原因ID
         */
        feeReasonId: number;
        /**
         * @description  款项类型ID
         */
        feeTypeId: number;
        /**
         * @description  首次收款时间
         */
        firstPayDate: string;
        /**
         * @description  房源ID
         */
        houseId?: number;
        /**
         * @description  收入类型 1-收入 2-支出
         */
        inoutType: number;
        /**
         * @description  付款方式ID
         */
        payMethodId: number;
        /**
         * @description  每期金额
         */
        periodFee: number;
        /**
         * @description  计划类型(1-房屋 2-其他)
         */
        planType: number;
        /**
         * @description  关联人
         */
        relationName?: string;
        /**
         * @description  关联类型ID
         */
        relationTypeId?: number;
        /**
         * @description  收支备注
         */
        remark?: string;
        /**
         * @description  门店ID
         */
        storeId?: number;
        /**
         * @description  租客ID
         */
        tenantsId?: number;
        /**
         * @description  总金额
         */
        totalFee: number;
        /**
         * @description  总期数
         */
        totalPeriod: number;
        incomeExpenseList: Array<{
          payDate: string;
          payNum: number;
          periodEnd: string;
          periodStart: string;
          shouldFee: number;
        }>;
      }
      /**
       * @name 收支计划修改
       * @param id 收支计划修改
       * @param data
       */
      export function update(id: string | number, data: UpdateParams) {
        return request.put<ResponseResult<object>>(
          `/finance-v100001/incomeExpensePlan/update/${id}`,
          data
        );
      }
    }
    export namespace maintenanceClean {
      /**
       * @name 恢复维修已完成的订单
       * @param id 恢复维修已完成的订单
       */
      export function restore(id: string | number) {
        return request.post<ResponseResult<object>>(
          `/finance-v100001/maintenanceClean/restore/${id}`
        );
      }
    }
    export namespace other {
      export namespace maintenanceClean {
        export interface PayMaintenanceCleanParams {
          /**
           * @description  主键
           */
          id?: number;
          /**
           * @description  前端计算出来的支付金额
           */
          payMoneyShow?: number;
        }
        /**
         * @name 支付保洁费用
         * @param params
         */
        export function payMaintenanceClean(params: PayMaintenanceCleanParams) {
          return request.get<ResponseResult<JHPayResult>>(
            "/finance-v100001/other/maintenanceClean/payMaintenanceClean",
            { params }
          );
        }
        export interface PayMaintenanceCleanV2Params {
          /**
           * @description  主键
           */
          id?: number;
          /**
           * @description  前端计算出来的支付金额
           */
          payMoneyShow?: number;
        }
        /**
         * @name 支付维修费用
         * @param params
         */
        export function payMaintenanceCleanV2(
          params: PayMaintenanceCleanV2Params
        ) {
          return request.get<ResponseResult<JHPayResult>>(
            "/finance-v100001/other/maintenanceClean/payMaintenanceCleanV2",
            { params }
          );
        }
        export interface PayWashParams {
          /**
           * @description  主键
           */
          id?: number;
          /**
           * @description  前端计算出来的支付金额
           */
          payMoneyShow?: number;
        }
        /**
         * @name 支付清洗费用
         * @param params
         */
        export function payWash(params: PayWashParams) {
          return request.get<ResponseResult<JHPayResult>>(
            "/finance-v100001/other/maintenanceClean/payWash",
            { params }
          );
        }
      }
    }
    export namespace noLogin {
      export namespace payRent {
        export interface CallBackParams {
          ACC_TYPE?: string;
          BRANCHID?: string;
          CLIENTIP?: string;
          CURCODE?: string;
          ORDERID?: string;
          PAYMENT?: string;
          POSID?: string;
          SIGN?: string;
          SUCCESS?: string;
          TYPE?: string;
        }
        /**
         * @name 建行支付成功回调
         * @param data
         */
        export function callBack(data: CallBackParams) {
          return request.post<ResponseResult<string>>(
            "/finance-v100001/noLogin/payRent/callBack",
            data
          );
        }
        export interface GetPayRentParams {
          /**
           * @description  城市code
           */
          cityCode?: string;
        }
        /**
         * @name 获取房租详情
         * @param id 获取房租详情
         * @param params
         */
        export function getPayRent(
          id: string | number,
          params: GetPayRentParams
        ) {
          return request.get<ResponseResult<object>>(
            `/finance-v100001/noLogin/payRent/getPayRent/${id}`,
            { params }
          );
        }
        export interface PayRentParams {
          /**
           * @description  定金主键ids
           */
          bargainIds?: string;
          /**
           * @description  城市code
           */
          cityCode?: string;
          /**
           * @description  欠款主键ids
           */
          debtIds?: string;
          /**
           * @description  房租主键
           */
          id?: string;
          /**
           * @description  前端计算出来的支付金额
           */
          payMoneyShow?: number;
        }
        /**
         * @name 分享房租时租客交房租接口
         * @param data
         */
        export function payRent(data: PayRentParams) {
          return request.post<ResponseResult<object>>(
            "/finance-v100001/noLogin/payRent/payRent",
            data
          );
        }
      }
      export namespace out {
        export namespace repair {
          export interface GetInfoParams {
            /**
             * @description  主键
             */
            id?: number;
          }
          /**
           * @name 外部维修详情
           * @param params
           */
          export function getInfo(params: GetInfoParams) {
            return request.get<ResponseResult<Repair>>(
              "/finance-v100001/noLogin/out/repair/getInfo",
              { params }
            );
          }
          export interface PayRepairParams {
            /**
             * @description  小程序code
             */
            code?: string;
            /**
             * @description  主键
             */
            id?: number;
            /**
             * @description  前端计算出来的支付金额
             */
            payMoneyShow?: number;
          }
          /**
           * @name 支付维修费用
           * @param params
           */
          export function payRepair(params: PayRepairParams) {
            return request.get<ResponseResult<JHPayResult>>(
              "/finance-v100001/noLogin/out/repair/payRepair",
              { params }
            );
          }
          export interface PayRepairV2Params {
            /**
             * @description  小程序code
             */
            code?: string;
            /**
             * @description  主键
             */
            id?: number;
            /**
             * @description  前端计算出来的支付金额
             */
            payMoneyShow?: number;
          }
          /**
           * @name 支付保洁费用
           * @param params
           */
          export function payRepairV2(params: PayRepairV2Params) {
            return request.get<ResponseResult<JHPayResult>>(
              "/finance-v100001/noLogin/out/repair/payRepairV2",
              { params }
            );
          }
          export interface PayRepairWashParams {
            /**
             * @description  小程序code
             */
            code?: string;
            /**
             * @description  主键
             */
            id?: number;
            /**
             * @description  前端计算出来的支付金额
             */
            payMoneyShow?: number;
          }
          /**
           * @name 支付保洁费用
           * @param params
           */
          export function payRepairWash(params: PayRepairWashParams) {
            return request.get<ResponseResult<JHPayResult>>(
              "/finance-v100001/noLogin/out/repair/payRepairWash",
              { params }
            );
          }
        }
      }
    }
    export namespace payDetail {
      export interface PayDebtTenantAndHouseParams {
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  前端计算出来的支付金额
         */
        payMoneyShow?: number;
      }
      /**
       * @name 租客/房东 交欠款
       * @param data
       */
      export function payDebtTenantAndHouse(data: PayDebtTenantAndHouseParams) {
        return request.post<ResponseResult<JHPayResult>>(
          "/finance-v100001/payDetail/payDebtTenantAndHouse",
          data
        );
      }
      export interface PayPlanIncomeParams {
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  前端计算出来的支付金额
         */
        payMoneyShow?: number;
      }
      /**
       * @name 租客交其它费
       * @param data
       */
      export function payPlanIncome(data: PayPlanIncomeParams) {
        return request.post<ResponseResult<JHPayResult>>(
          "/finance-v100001/payDetail/payPlanIncome",
          data
        );
      }
      export interface PayRentParams {
        /**
         * @description  定金主键ids
         */
        bargainIds?: string;
        /**
         * @description  欠款主键ids
         */
        debtIds?: string;
        /**
         * @description  房租主键
         */
        id?: string;
        /**
         * @description  前端计算出来的支付金额
         */
        payMoneyShow?: number;
      }
      /**
       * @name 租客交房租
       * @param data
       */
      export function payRent(data: PayRentParams) {
        return request.post<ResponseResult<JHPayResult>>(
          "/finance-v100001/payDetail/payRent",
          data
        );
      }
      export interface GetJhPayStatusParams {
        /**
         * @description  订单号
         */
        orderNo?: string;
      }
      /**
       * @name 主动查询订单状态
       * @param params
       */
      export function getJhPayStatus(params: GetJhPayStatusParams) {
        return request.get<ResponseResult<object>>(
          "/finance-v100001/payDetail/getJhPayStatus",
          { params }
        );
      }
      /**
       * @name updateAll
       */
      export function updateAll() {
        return request.get<ResponseResult<object>>(
          "/finance-v100001/payDetail/updateAll"
        );
      }
    }
    export namespace app {
      export namespace tenantsEnergy {
        export interface MyListParams {
          pageNo?: number;
          pageSize?: number;
          sortField?: string;
          sortWay?: string;
          /**
           * @description  1表示收入  2表示支出
           */
          typeId?: number;
        }
        /**
         * @name 租客：能源记录表列表
         * @param params
         */
        export function myList(params: MyListParams) {
          return request.get<ResponseResult<PageList<TenantsEnergy>>>(
            "/finance-v100001/app/tenantsEnergy/myList",
            { params }
          );
        }
        /**
         * @name 租客：能源统计
         */
        export function myTenantsEnergySum() {
          return request.get<ResponseResult<TenantsEnergySumVo>>(
            "/finance-v100001/app/tenantsEnergy/myTenantsEnergySum"
          );
        }
        export interface PayEnergyParams {
          /**
           * @description  主键
           */
          id?: number;
        }
        /**
         * @name 支付水电气费用
         * @param data
         */
        export function payEnergy(data: PayEnergyParams) {
          return request.post<ResponseResult<JHPayResult>>(
            "/finance-v100001/app/tenantsEnergy/payEnergy",
            data
          );
        }
        export interface SavePreParams {
          /**
           * @description  备注
           */
          remark?: string;
          /**
           * @description  payAmount
           */
          payAmount?: number;
        }
        /**
         * @name 预存费新增
         * @param data
         */
        export function savePre(data: SavePreParams) {
          return request.post<ResponseResult<JHPayResult>>(
            "/finance-v100001/app/tenantsEnergy/savePre",
            data
          );
        }
      }
    }
    export namespace tenantsExit {
      export interface MyTenantsListParams {
        pageNo?: number;
        pageSize?: number;
        sortField?: string;
        sortWay?: string;
      }
      /**
       * @name 租客：我的退房
       * @param params
       */
      export function myTenantsList(params: MyTenantsListParams) {
        return request.get<ResponseResult<PageList<TenantsExit>>>(
          "/finance-v100001/tenantsExit/myTenantsList",
          { params }
        );
      }
      export interface PayTenantsExitParams {
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  前端计算出来的支付金额
         */
        payMoneyShow?: number;
      }
      /**
       * @name 租客退房交还押金
       * @param data
       */
      export function payTenantsExit(data: PayTenantsExitParams) {
        return request.post<ResponseResult<JHPayResult>>(
          "/finance-v100001/tenantsExit/payTenantsExit",
          data
        );
      }
      /**
       * @name 发送管家确认消息
       * @param id 发送管家确认消息
       */
      export function sendHouseKeepConfirm(id: string | number) {
        return request.get<ResponseResult<object>>(
          `/finance-v100001/tenantsExit/sendHouseKeepConfirm/${id}`
        );
      }
      /**
       * @name setStewardConfirm
       */
      export function setStewardConfirm() {
        return request.post<ResponseResult<object>>(
          "/finance-v100001/tenantsExit/setStewardConfirm"
        );
      }
      export interface UpdateConfirmParams {
        /**
         * @description  主键
         */
        id?: number;
        /**
         * @description  管家确认无误：1,无误；2，未确认;3,有误
         */
        stewardConfirm?: number;
        /**
         * @description  管家确认有误备注
         */
        stewardConfirmNotes?: string;
      }
      /**
       * @name 管家确认无误接口
       * @param data
       */
      export function updateConfirm(data: UpdateConfirmParams) {
        return request.post<ResponseResult<object>>(
          "/finance-v100001/tenantsExit/updateConfirm",
          data
        );
      }
    }
  }
}
