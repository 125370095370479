import {
  combineReducers,
  createStore,
  Middleware,
  compose,
  applyMiddleware,
} from "redux";
import thunkMiddleware from "redux-thunk";
import reduxLogger from "redux-logger";

import { systemReducer, ISystemState } from "../reducers/system";

const middlewares: Middleware[] = [thunkMiddleware];

if (process.env.NODE_ENV === "development") {
  middlewares.push(reduxLogger);
}

export const store = createStore(
  combineReducers({
    system: systemReducer,
  }),
  compose(applyMiddleware(...middlewares))
);

export interface IStoreState {
  system: ISystemState;
}
