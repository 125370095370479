import { DataNode } from "antd/lib/tree";
import { TreeDataNode } from "../components";

const permissionsCache = new Map<MenuVo[], string[]>();

export class MenuUtil {
  static mapToTreeData(
    menus: Menu[],
    pid: number,
    hasRoot: boolean = true
  ): DataNode[] {
    const list: Menu[] = [];
    const reduceList: Menu[] = [];

    menus.forEach((item) => {
      if (item.pid === pid) {
        list.push(item);
      } else {
        reduceList.push(item);
      }
    });

    if (pid === 0 && hasRoot) {
      return [
        {
          key: 0,
          title: "所有菜单",
          children: list
            .sort((a, b) => a.sort - b.sort)
            .map((item) => ({
              key: item.id,
              title: item.name,
              sort: item.sort,
              children: MenuUtil.mapToTreeData(reduceList, item.id),
              originData: item,
            }))
            .sort((a, b) => {
              return a.sort - b.sort;
            }),
        },
      ];
    }

    return list
      .sort((a, b) => a.sort - b.sort)
      .map((item) => ({
        key: item.id,
        title: item.name,
        children: MenuUtil.mapToTreeData(reduceList, item.id),
        sort: item.sort,
        originData: item,
      }));
  }
  static mapToTree(
    menus: Menu[],
    pid: number,
    parent?: TreeDataNode<number, Menu>
  ): TreeDataNode<number, Menu>[] {
    const list: Menu[] = [];
    const reduceList: Menu[] = [];

    menus.forEach((item) => {
      if (item.pid === pid) {
        list.push(item);
      } else {
        reduceList.push(item);
      }
    });

    return list
      .sort((a, b) => a.sort - b.sort)
      .map((item) => {
        const value: TreeDataNode<number, Menu> = {
          key: item.id,
          title: item.name,
          originData: item,
          parent,
        };
        value.children = MenuUtil.mapToTree(reduceList, item.id, value);
        return value;
      });
  }
  static menuVoToList(
    menuVo: MenuVo[],
    list: Omit<MenuVo, "childMenuVoList">[] = []
  ) {
    menuVo.forEach((item) => {
      const { childMenuVoList, ...rest } = item;
      list.push(rest);
      if (Array.isArray(childMenuVoList)) {
        MenuUtil.menuVoToList(childMenuVoList, list);
      }
    });
    return list;
  }

  static permissions(menuVoList: MenuVo[]): string[] {
    const cacheList = permissionsCache.get(menuVoList);
    if (cacheList) {
      return cacheList;
    }
    const allList: string[] = [];
    function find(list: MenuVo[]) {
      list.forEach((it) => {
        if (it.beforeUrl) {
          allList.push(...it.beforeUrl.split(","));
        }

        if (it.childMenuVoList) {
          find(it.childMenuVoList);
        }
      });
    }
    find(menuVoList);
    permissionsCache.set(menuVoList, allList);
    return allList;
  }
}
