import { AxiosResponse } from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

type RequestFn<P, R> = (
  params: P
) => Promise<AxiosResponse<ResponseResult<R[]>>>;

export function useList<R, P = any>(fn: RequestFn<P, R>, params?: P) {
  const [list, setList] = useState<R[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(false);
  const cache = useRef({
    list,
    loading,
    isInit,
  });
  useEffect(() => {
    cache.current.isInit = isInit;
    cache.current.loading = loading;
    cache.current.list = list;
  }, [list, loading, isInit]);

  const getList = useCallback(async () => {
    try {
      if (!params || cache.current.loading) {
        return;
      }
      setLoading(true);
      const res = await fn(params);
      if (!cache.current.isInit) {
        setIsInit(true);
      }
      setList(res.data.data);
    } catch (e) {}
    setLoading(false);
  }, [fn, params]);

  useEffect(() => {
    getList().catch((e) => e);
  }, [getList]);

  return useMemo(
    () => ({ list, isInit, getList, loading }),
    [list, isInit, getList, loading]
  );
}
