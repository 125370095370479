import { AxiosResponse } from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export type RequestFn<P, R> = (
  params: P
) => Promise<AxiosResponse<ResponseResult<PageList<R>>>>;

export function usePageList<R, P extends FetchPageParams = FetchPageParams>(
  fn: RequestFn<P, R>,
  params?: P
) {
  const [list, setList] = useState<R[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState(1);

  const cache = useRef({
    list,
    loading,
    total,
  });

  useEffect(() => {
    cache.current.total = total;
    cache.current.list = list;
    cache.current.loading = loading;
  }, [list, loading, total]);

  const getList = useCallback(
    async (query?: P) => {
      try {
        setLoading(true);
        const res = await fn({
          ...params,
          ...query,
        } as P);
        const { list, totalCount } = res.data.data;
        if (Array.isArray(list)) {
          setList(list);
        }
        setTotal(totalCount);
      } catch (e) {}
      setLoading(false);
    },
    [fn, params]
  );

  useEffect(() => {
    getList().catch((e) => e);
  }, [getList]);

  return useMemo(
    () => ({
      list,
      total,
      loading,
      getList,
    }),
    [list, total, loading, getList]
  );
}
