import { useSelector } from "react-redux";
import { IStoreState } from "../store";
import { useMemo } from "react";
import { MenuUtil } from "../utils";

export function useIsPermission(permissions: string[]): boolean {
  const user = useSelector<IStoreState, NullLike<UserLoginData>>(
    (state) => state.system.user
  );
  return useMemo(() => {
    if (user && permissions.length) {
      const permissionList = MenuUtil.permissions(user.menuVoList);
      return permissions.every((permission) => {
        return permissionList.includes(permission);
      });
    }
    return false;
  }, [permissions, user]);
}
