import React, { useEffect, useState } from "react";
import { Cascader, CascaderProps } from "antd";
import { useList } from "../../../hooks";
import { CascaderOptionType } from "antd/lib/cascader";
import { API_SYS } from "../../../api";

const defaultParams = {};

export function StoreGroupCascader(props: StoreGroupCascaderProps) {
  const store = useList(API_SYS.v100001.store.getUserSelect, defaultParams);
  const [storeList, setStoreList] = useState<CascaderOptionType[]>([]);

  useEffect(() => {
    setStoreList(() =>
      store.list.map((item) => ({
        value: item.id,
        label: item.name,
        isLeaf: false
      }))
    );
  }, [store]);

  return (
    <Cascader
      loadData={(selectedOptions) => {
        if (Array.isArray(selectedOptions)) {
          const [option] = selectedOptions;
          option.loading = true;
          API_SYS.v100001.storeGroup
            .getSelectAll({
              storeIds: option.value + ""
            })
            .then((res) => {
              option.loading = false;
              option.children = res.data.data.map((item) => ({
                value: item.id,
                label: item.name,
                isLeaf: true
              }));
              setStoreList([...storeList]);
            })
            .catch((err) => {
              option.loading = false;
              setStoreList([...storeList]);
            });
        }
      }}
      options={storeList}
      {...props}
    />
  );
}

export interface StoreGroupCascaderProps
  extends Omit<CascaderProps, "options"> {}
