export enum NoticeType {
  // 微信租客公告
  wechat = 1,
  // 房东公告
  house_user = 2,
  // 公司公告
  company = 3,
}

export function getNoticeTypeName(type: NoticeType) {
  switch (type) {
    case NoticeType.company:
      return "公司公告";
    case NoticeType.house_user:
      return "房东公告";
    case NoticeType.wechat:
      return "微信租客公告";
  }
}
