import { DataNode } from "antd/lib/tree";
import { isDevMode } from "./index";

const isDev = isDevMode();

export class CompanyDicdataUtil {
  /**
   * 格式化为tree结构
   * @param dicdatas
   * @param pid
   */
  static formatToTree(
    dicdatas: CompanyDicdata[],
    pid: number = 0
  ): Array<DataNode & { originData: CompanyDicdata }> {
    const list: CompanyDicdata[] = [];
    const reduceList: CompanyDicdata[] = [];

    dicdatas.forEach((item) => {
      if (item.pid === pid) {
        list.push(item);
      } else {
        reduceList.push(item);
      }
    });

    return list
      .sort((a, b) => a.sorting - b.sorting)
      .map((item) => ({
        key: item.id,
        title: isDev ? item.name + " - " + item.id : item.name,
        children: CompanyDicdataUtil.formatToTree(reduceList, item.id),
        originData: item
      }));
  }
}
