import MD5 from "crypto-js/md5";

/**
 * 本地存储管理
 */
export class WebStorageUtil {
    static getItem(key: string): string | null {
        return localStorage.getItem(MD5(key).toString());
    }

    static setItem(key: string, value:string) {
        return localStorage.setItem(MD5(key).toString(), value);
    }

    static clear() {
        return localStorage.clear();
    }

    static removeItem(key: string) {
        return localStorage.removeItem(MD5(key).toString());
    }
}