import { request } from "../utils/request";
export namespace API_HOUSE {
    export namespace v100001 {
        export namespace bankcardChange {
            export interface AuditParams {
                /**
                 * @description  是否审核 1已审核  2未通过  3未审核
                 */
                isAudit?: number;
                /**
                 * @description  不通过原因
                 */
                rejectReason?: string;
            }
            /**
             * @name 业主银行卡变更记录审核
             * @param id 业主银行卡变更记录审核
             * @param data
             */
            export function audit(id: string | number, data: AuditParams) { return request.post<ResponseResult<object>>(`/house-v100001/bankcardChange/audit/${id}`, data); }
            /**
             * @name 业主银行卡变更记录作废
             * @param id 业主银行卡变更记录作废
             */
            export function cancel(id: string | number) { return request.post<ResponseResult<object>>(`/house-v100001/bankcardChange/cancel/${id}`); }
            /**
             * @name 业主银行卡变更记录详情
             * @param id 业主银行卡变更记录详情
             */
            export function info(id: string | number) { return request.get<ResponseResult<HBankcardChange>>(`/house-v100001/bankcardChange/info/${id}`); }
            export interface PageParams {
                /**
                 * @description  区域id
                 */
                areaId?: number;
                /**
                 * @description  创建时间结束
                 */
                auditTimeEnd?: string;
                /**
                 * @description  创建时间开始
                 */
                auditTimeStart?: string;
                /**
                 * @description  创建时间结束
                 */
                createTimeEnd?: string;
                /**
                 * @description  创建时间开始
                 */
                createTimeStart?: string;
                /**
                 * @description  部门（大区）id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  房源id
                 */
                houseId?: number;
                /**
                 * @description  门牌号
                 */
                houseNum?: string;
                /**
                 * @description  是否审核 1已审核  2未通过  3未审核
                 */
                isAudit?: number;
                /**
                 * @description  是否作废：1,已作废，0正常
                 */
                isCancel?: number;
                pageNo?: number;
                pageSize?: number;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
            }
            /**
             * @name 业主银行卡变更记录列表
             * @param params
             */
            export function page(params: PageParams) { return request.get<ResponseResult<PageList<HBankcardChange>>>("/house-v100001/bankcardChange/page", { params }); }
            /**
             * @name 业主银行卡变更记录删除
             * @param id 业主银行卡变更记录删除
             */
            export function remove(id: string | number) { return request.delete<ResponseResult<object>>(`/house-v100001/bankcardChange/remove/${id}`); }
            export interface SaveParams {
                /**
                 * @description  银行卡照片
                 */
                bankCardImg: string;
                /**
                 * @description  变更说明
                 */
                changeRemark: string;
                /**
                 * @description  房源id
                 */
                houseId: number;
                /**
                 * @description  新银行账号
                 */
                newBankAccount: string;
                /**
                 * @description  新银行开户行
                 */
                newBankAddr: string;
                /**
                 * @description  新银行收款人姓名
                 */
                newBankName?: string;
                /**
                 * @description  新银行开户行地址
                 */
                newBankOpenAddr: string;
            }
            /**
             * @name 业主银行卡变更记录新增
             * @param data
             */
            export function save(data: SaveParams) { return request.post<ResponseResult<object>>("/house-v100001/bankcardChange/save", data); }
            export interface SignParams {
                /**
                 * @description  ownerSign
                 */
                ownerSign?: string;
            }
            /**
             * @name 业主银行卡变更记录签字
             * @param id 业主银行卡变更记录签字
             * @param data
             */
            export function sign(id: string | number, data: SignParams) { return request.post<ResponseResult<object>>(`/house-v100001/bankcardChange/sign/${id}`, data); }
            export interface UpdateParams {
                /**
                 * @description  银行卡照片
                 */
                bankCardImg: string;
                /**
                 * @description  变更说明
                 */
                changeRemark: string;
                /**
                 * @description  房源id
                 */
                houseId: number;
                /**
                 * @description  新银行账号
                 */
                newBankAccount: string;
                /**
                 * @description  新银行开户行
                 */
                newBankAddr: string;
                /**
                 * @description  新银行收款人姓名
                 */
                newBankName?: string;
                /**
                 * @description  新银行开户行地址
                 */
                newBankOpenAddr: string;
            }
            /**
             * @name 业主银行卡变更记录修改
             * @param id 业主银行卡变更记录修改
             * @param data
             */
            export function update(id: string | number, data: UpdateParams) { return request.post<ResponseResult<object>>(`/house-v100001/bankcardChange/update/${id}`, data); }
        }
        export namespace bargain {
            export interface AuditParams {
                /**
                 * @description  审核状态
                 */
                audit?: number;
                /**
                 * @description  审核备注
                 */
                auditRemark?: string;
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 定金退还审批
             * @param data
             */
            export function audit(data: AuditParams) { return request.put<ResponseResult<object>>("/house-v100001/bargain/audit", data); }
            export interface BargainFailureParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 定金记录定金失效操作
             * @param data
             */
            export function bargainFailure(data: BargainFailureParams) { return request.post<ResponseResult<object>>("/house-v100001/bargain/bargainFailure", data); }
            export interface DeleteByIdParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 定金记录表删除
             * @param params
             */
            export function deleteById(params: DeleteByIdParams) { return request.delete<ResponseResult<object>>("/house-v100001/bargain/deleteById", { params }); }
            export interface GenQrcodeParams {
                /**
                 * @description  合同记录ID
                 */
                id: number;
                /**
                 * @description  访问路径
                 */
                url: string;
            }
            /**
             * @name 生成二维码
             * @param params
             */
            export function genQrcode(params: GenQrcodeParams) { return request.get("/house-v100001/bargain/genQrcode", { params }); }
            /**
             * @name 定金记录表详情
             * @param id 定金记录表详情
             */
            export function info(id: string | number) { return request.get<ResponseResult<Bargain>>(`/house-v100001/bargain/info/${id}`); }
            export interface ListParams {
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  房源id
                 */
                houseId?: number;
                /**
                 * @description  门牌号、（集中几层或者几栋）
                 */
                houseNum?: string;
                /**
                 * @description  1整租 2合租 3集中
                 */
                houseType?: number;
                /**
                 * @description  租客身份证
                 */
                idCard?: string;
                /**
                 * @description  租客姓名
                 */
                name?: string;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  租客付款方式id
                 */
                payTypeId?: number;
                /**
                 * @description  租客电话
                 */
                phone?: number;
                /**
                 * @description  报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  房间id
                 */
                roomId?: number;
                /**
                 * @description  房间号 (合租)/集中 门牌号
                 */
                roomNo?: string;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  状态：1未签约，2已签约，3已退  4失效
                 */
                statusId?: number;
                /**
                 * @description  状态：1未签约，2已签约，3已退  4失效
                 */
                statusShow?: string;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
                /**
                 * @description  定金有效期
                 */
                validityEndTime?: string;
            }
            /**
             * @name 定金记录表列表
             * @param params
             */
            export function list(params: ListParams) { return request.get<ResponseResult<PageList<Bargain>>>("/house-v100001/bargain/list", { params }); }
            export interface RefundParams {
                /**
                 * @description  银行账号
                 */
                bankAccount?: string;
                /**
                 * @description  银行开户行
                 */
                bankAddr?: string;
                /**
                 * @description  银行收款人姓名
                 */
                bankName?: string;
                /**
                 * @description  银行开户行地址
                 */
                bankOpenAddr?: string;
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  退还金额
                 */
                refundAmount?: number;
                /**
                 * @description  退还原因
                 */
                refundReason?: string;
            }
            /**
             * @name 定金记录退还操作
             * @param data
             */
            export function refund(data: RefundParams) { return request.post<ResponseResult<object>>("/house-v100001/bargain/refund", data); }
            export interface RefundListParams {
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  房源id
                 */
                houseId?: number;
                /**
                 * @description  门牌号、（集中几层或者几栋）
                 */
                houseNum?: string;
                /**
                 * @description  1整租 2合租 3集中
                 */
                houseType?: number;
                /**
                 * @description  租客身份证
                 */
                idCard?: string;
                /**
                 * @description  租客姓名
                 */
                name?: string;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  租客付款方式id
                 */
                payTypeId?: number;
                /**
                 * @description  租客电话
                 */
                phone?: number;
                /**
                 * @description  报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  房间id
                 */
                roomId?: number;
                /**
                 * @description  房间号 (合租)/集中 门牌号
                 */
                roomNo?: string;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  状态：1未签约，2已签约，3已退  4失效
                 */
                statusId?: number;
                /**
                 * @description  状态：1未签约，2已签约，3已退  4失效
                 */
                statusShow?: string;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
                /**
                 * @description  定金有效期
                 */
                validityEndTime?: string;
            }
            /**
             * @name 定金退还审批记录表列表
             * @param params
             */
            export function refundList(params: RefundListParams) { return request.get<ResponseResult<PageList<Bargain>>>("/house-v100001/bargain/refundList", { params }); }
            export interface SaveParams {
                /**
                 * @description  审核状态 1-已审核 2-未通过 3-未审核
                 */
                audit?: number;
                /**
                 * @description  审核备注
                 */
                auditRemark?: string;
                /**
                 * @description  定金金额
                 */
                bargainAmount: number;
                /**
                 * @description  业务人员名称
                 */
                businessId: number;
                /**
                 * @description  证件类型字典(601)
                 */
                certificateTypeId?: number;
                /**
                 * @description  收款方式id
                 */
                collectionId: number;
                /**
                 * @description  押金
                 */
                depositAmount?: number;
                /**
                 * @description  租客身份证
                 */
                idCard?: string;
                /**
                 * @description  租赁天
                 */
                leaseDay?: number;
                /**
                 * @description  租赁结束时间
                 */
                leaseEndTime: string;
                /**
                 * @description  租赁月
                 */
                leaseMonth?: number;
                /**
                 * @description  租赁开始时间
                 */
                leaseStartTime: string;
                /**
                 * @description  租赁年
                 */
                leaseYear?: number;
                /**
                 * @description  租客姓名
                 */
                name?: string;
                /**
                 * @description  租客付款方式id
                 */
                payTypeId: number;
                /**
                 * @description  租客电话
                 */
                phone: number;
                /**
                 * @description  定金备注
                 */
                remark?: string;
                /**
                 * @description  房间id
                 */
                roomId: number;
                /**
                 * @description  房租
                 */
                tenantsAmount: number;
                /**
                 * @description  定金有效期天数(结束时间用这个加)
                 */
                validityDay?: number;
                /**
                 * @description  定金有效期
                 */
                validityEndTime: string;
            }
            /**
             * @name 定金记录表新增
             * @param data
             */
            export function save(data: SaveParams) { return request.post<ResponseResult<object>>("/house-v100001/bargain/save", data); }
            export interface SendSmsParams {
                /**
                 * @description  id
                 */
                id: number;
                /**
                 * @description  访问路径
                 */
                url: string;
            }
            /**
             * @name 发送定金短信
             * @param data
             */
            export function sendSms(data: SendSmsParams) { return request.post<ResponseResult<object>>("/house-v100001/bargain/sendSms", data); }
            export interface TransferBargainParams {
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  新房间id
                 */
                roomId?: number;
            }
            /**
             * @name 定金转移功能:必须查询房间是未租的
             * @param data
             */
            export function transferBargain(data: TransferBargainParams) { return request.put<ResponseResult<object>>("/house-v100001/bargain/transferBargain", data); }
            export interface UpdateParams {
                /**
                 * @description  审核状态 1-已审核 2-未通过 3-未审核
                 */
                audit?: number;
                /**
                 * @description  审核备注
                 */
                auditRemark?: string;
                /**
                 * @description  定金金额
                 */
                bargainAmount: number;
                /**
                 * @description  业务人员名称
                 */
                businessId: number;
                /**
                 * @description  证件类型字典(601)
                 */
                certificateTypeId?: number;
                /**
                 * @description  收款方式id
                 */
                collectionId: number;
                /**
                 * @description  押金
                 */
                depositAmount?: number;
                /**
                 * @description  租客身份证
                 */
                idCard?: string;
                /**
                 * @description  租赁天
                 */
                leaseDay?: number;
                /**
                 * @description  租赁结束时间
                 */
                leaseEndTime: string;
                /**
                 * @description  租赁月
                 */
                leaseMonth?: number;
                /**
                 * @description  租赁开始时间
                 */
                leaseStartTime: string;
                /**
                 * @description  租赁年
                 */
                leaseYear?: number;
                /**
                 * @description  租客姓名
                 */
                name?: string;
                /**
                 * @description  租客付款方式id
                 */
                payTypeId: number;
                /**
                 * @description  租客电话
                 */
                phone: number;
                /**
                 * @description  定金备注
                 */
                remark?: string;
                /**
                 * @description  房间id
                 */
                roomId: number;
                /**
                 * @description  房租
                 */
                tenantsAmount: number;
                /**
                 * @description  定金有效期天数(结束时间用这个加)
                 */
                validityDay?: number;
                /**
                 * @description  定金有效期
                 */
                validityEndTime: string;
            }
            /**
             * @name 定金记录表修改
             * @param id 定金记录表修改
             * @param data
             */
            export function update(id: string | number, data: UpdateParams) { return request.put<ResponseResult<object>>(`/house-v100001/bargain/update/${id}`, data); }
            export interface UpdateConfirmParams {
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  管家确认无误：1,无误；2，未确认;3,有误
                 */
                stewardConfirm?: number;
                /**
                 * @description  管家确认有误备注
                 */
                stewardConfirmNotes?: string;
            }
            /**
             * @name 管家确认无误接口
             * @param data
             */
            export function updateConfirm(data: UpdateConfirmParams) { return request.post<ResponseResult<object>>("/house-v100001/bargain/updateConfirm", data); }
            export interface GetBargainContractContentParams {
                /**
                 * @description  id
                 */
                id?: number;
            }
            /**
             * @name 获取定金合同内容
             * @param params
             */
            export function getBargainContractContent(params: GetBargainContractContentParams) { return request.get<ResponseResult<Bargain>>("/house-v100001/bargain/getBargainContractContent", { params }); }
            export interface GetBargainContractUrlParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 获取定金合同下载路径
             * @param params
             */
            export function getBargainContractUrl(params: GetBargainContractUrlParams) { return request.get<ResponseResult<object>>("/house-v100001/bargain/getBargainContractUrl", { params }); }
            /**
             * @name 模板代码
             */
            export function getCodeList() { return request.get<ResponseResult<Array<SelectStrVo>>>("/house-v100001/bargain/getCodeList"); }
            export interface SignatureParams {
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  signatureUserImg
                 */
                signatureUserImg?: string;
            }
            /**
             * @name 定金合同签字
             * @param data
             */
            export function signature(data: SignatureParams) { return request.put<ResponseResult<object>>("/house-v100001/bargain/signature", data); }
            export namespace list {
                export interface DeductionParams {
                    pageNo?: number;
                    pageSize?: number;
                    /**
                     * @description  房间ID
                     */
                    roomId?: number;
                    sortField?: string;
                    sortWay?: string;
                }
                /**
                 * @name 抵扣定金列表 只能传房间ID  ,分页参数不加前端要报错，加了后端不用就行 
                 * @param params
                 */
                export function deduction(params: DeductionParams) { return request.get<ResponseResult<PageList<Bargain>>>("/house-v100001/bargain/list/deduction", { params }); }
            }
        }
        export namespace noLogin {
            export namespace carouselChart {
                /**
                 * @name 轮播图列表
                 */
                export function list() { return request.get<ResponseResult<Array<CarouselChart>>>("/house-v100001/noLogin/carouselChart/list"); }
            }
            export namespace DJLCS {
                export interface CSParams {
                    /**
                     * @description  主键集合
                     */
                    ids?: string;
                }
                /**
                 * @name CS
                 * @param data
                 */
                export function cS(data: CSParams) { return request.post<ResponseResult<object>>("/house-v100001/noLogin/DJLCS/CS", data); }
            }
            export namespace externalDisplayRoom {
                /**
                 * @name 外部展示房间详情页
                 * @param id 外部展示房间详情页
                 */
                export function info(id: string | number) { return request.get<ResponseResult<ExternalDisplayRoom>>(`/house-v100001/noLogin/externalDisplayRoom/info/${id}`); }
                export interface PageParams {
                    /**
                     * @description  区id(通过物业地址获取)
                     */
                    areaId?: number;
                    /**
                     * @description  物业地址id
                     */
                    detailId?: string;
                    dtzmId?: number;
                    /**
                     * @description  当前经度
                     */
                    newLat?: string;
                    /**
                     * @description  当前纬度
                     */
                    newLng?: string;
                    pageNo?: number;
                    pageSize?: number;
                    /**
                     * @description  价格区间
                     */
                    pricingAmount?: string;
                    sortField?: string;
                    sortWay?: string;
                    /**
                     * @description  管家id
                     */
                    stewardId?: number;
                    /**
                     * @description  门店id
                     */
                    storeId?: number;
                    /**
                     * @description  门店id多选
                     */
                    storeIdList?: string;
                }
                /**
                 * @name 外部展示房间列表
                 * @param params
                 */
                export function page(params: PageParams) { return request.get<ResponseResult<PageList<ExternalDisplayRoom>>>("/house-v100001/noLogin/externalDisplayRoom/page", { params }); }
                /**
                 * @name updatePricing
                 */
                export function updatePricing() { return request.post<ResponseResult<object>>("/house-v100001/noLogin/externalDisplayRoom/updatePricing"); }
            }
        }
        export namespace carouselChartWeb {
            /**
             * @name 轮播图删除接口
             * @param id 轮播图删除接口
             */
            export function deleteById(id: string | number) { return request.delete<ResponseResult<object>>(`/house-v100001/carouselChartWeb/delete/${id}`); }
            /**
             * @name 轮播图详情页
             * @param id 轮播图详情页
             */
            export function info(id: string | number) { return request.get<ResponseResult<CarouselChart>>(`/house-v100001/carouselChartWeb/info/${id}`); }
            export interface PageParams {
                pageNo?: number;
                pageSize?: number;
                sortField?: string;
                sortWay?: string;
            }
            /**
             * @name 轮播图列表页
             * @param params
             */
            export function page(params: PageParams) { return request.get<ResponseResult<PageList<CarouselChart>>>("/house-v100001/carouselChartWeb/page", { params }); }
            export interface SaveParams {
                /**
                 * @description  图片路径
                 */
                imgUrl?: string;
            }
            /**
             * @name 轮播图添加接口
             * @param data
             */
            export function save(data: SaveParams) { return request.post<ResponseResult<object>>("/house-v100001/carouselChartWeb/save", data); }
            export interface ShowParams {
                /**
                 * @description  上架状态：0,上架；1下架
                 */
                showStatus?: number;
            }
            /**
             * @name 轮播图上下架
             * @param id 轮播图上下架
             * @param data
             */
            export function show(id: string | number, data: ShowParams) { return request.post<ResponseResult<object>>(`/house-v100001/carouselChartWeb/show/${id}`, data); }
            export interface UpdateParams {
                /**
                 * @description  图片路径
                 */
                imgUrl?: string;
            }
            /**
             * @name 轮播图修改接口
             * @param id 轮播图修改接口
             * @param data
             */
            export function update(id: string | number, data: UpdateParams) { return request.post<ResponseResult<object>>(`/house-v100001/carouselChartWeb/update/${id}`, data); }
        }
        export namespace cleanGrade {
            export interface ListParams {
                /**
                 * @description  保洁类型：1，为内部保洁，2：为外部保洁
                 */
                cleaType: number;
            }
            /**
             * @name /查询档次列表
             * @param data
             */
            export function list(data: ListParams) { return request.post<ResponseResult<object>>("/house-v100001/cleanGrade/list", data); }
            export interface ListAllParams {
                pageNo?: number;
                pageSize?: number;
                sortField?: string;
                sortWay?: string;
            }
            /**
             * @name /查询档次列表
             * @param data
             */
            export function listAll(data: ListAllParams) { return request.post<ResponseResult<PageList<CleanGrade>>>("/house-v100001/cleanGrade/listAll", data); }
            export interface SaveParams {
                /**
                 * @description  保洁类型（1，为内部保洁，2：为外部保洁）
                 */
                cleaType: number;
                /**
                 * @description  档次名称
                 */
                name: string;
                /**
                 * @description  保洁单价（每小时）
                 */
                unitPrice: number;
            }
            /**
             * @name /添加档次
             * @param data
             */
            export function save(data: SaveParams) { return request.post<ResponseResult<object>>("/house-v100001/cleanGrade/save", data); }
        }
        export namespace cleaner {
            /**
             * @name delete
             * @param id delete
             */
            export function deleteById(id: string | number) { return request.post<ResponseResult<object>>(`/house-v100001/cleaner/delete/${id}`); }
            /**
             * @name 查看保洁人员详情
             * @param id 查看保洁人员详情
             */
            export function info(id: string | number) { return request.get<ResponseResult<Cleaner>>(`/house-v100001/cleaner/info/${id}`); }
            export interface ListParams {
                /**
                 * @description  开始时间
                 */
                beginTime?: string;
                /**
                 * @description  内部档次id
                 */
                cleanGradeId?: number;
                /**
                 * @description  人员状态：1，为启用 2，为不启用 
                 */
                cleanerStatus?: number;
                /**
                 * @description  结束时间
                 */
                endTime?: string;
                /**
                 * @description  外部档次id
                 */
                outGradeId?: number;
                pageNo?: number;
                pageSize?: number;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  员工所在门店id 
                 */
                storeId?: number;
            }
            /**
             * @name 获取保洁人员列表
             * @param params
             */
            export function list(params: ListParams) { return request.get<ResponseResult<PageList<Cleaner>>>("/house-v100001/cleaner/list", { params }); }
            export interface ListOneParams {
                /**
                 * @description  开始时间
                 */
                beginTime?: string;
                /**
                 * @description  内部档次id
                 */
                cleanGradeId?: number;
                /**
                 * @description  人员状态：1，为启用 2，为不启用 
                 */
                cleanerStatus?: number;
                /**
                 * @description  结束时间
                 */
                endTime?: string;
                /**
                 * @description  外部档次id
                 */
                outGradeId?: number;
                /**
                 * @description  员工所在门店id 
                 */
                storeId?: number;
            }
            /**
             * @name 获取保洁人员列表
             * @param params
             */
            export function listOne(params: ListOneParams) { return request.get<ResponseResult<Array<Cleaner>>>("/house-v100001/cleaner/listOne", { params }); }
            export interface ListReportParams {
                /**
                 * @description  开始时间
                 */
                beginTime?: string;
                /**
                 * @description  内部档次id
                 */
                cleanGradeId?: number;
                /**
                 * @description  人员状态：1，为启用 2，为不启用 
                 */
                cleanerStatus?: number;
                /**
                 * @description  结束时间
                 */
                endTime?: string;
                /**
                 * @description  外部档次id
                 */
                outGradeId?: number;
                pageNo?: number;
                pageSize?: number;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  员工所在门店id 
                 */
                storeId?: number;
            }
            /**
             * @name 获取保洁人员工资报表
             * @param params
             */
            export function listReport(params: ListReportParams) { return request.get<ResponseResult<PageList<保洁人员输出类>>>("/house-v100001/cleaner/listReport", { params }); }
            export interface SaveParams {
                /**
                 * @description  档次id
                 */
                cleanGradeId?: number;
                /**
                 * @description  保洁单价（每小时）
                 */
                hourlyWage?: number;
                /**
                 * @description  总时长
                 */
                totalDuration?: number;
                /**
                 * @description  总工资
                 */
                totalWages?: number;
                /**
                 * @description  人员userId
                 */
                userId?: number;
            }
            /**
             * @name 添加保洁人员
             * @param data
             */
            export function save(data: SaveParams) { return request.post<ResponseResult<object>>("/house-v100001/cleaner/save", data); }
            /**
             * @name 查询用户表里面的保洁人员添加到保洁人员表里面
             */
            export function saveAll() { return request.post<ResponseResult<object>>("/house-v100001/cleaner/saveAll"); }
            export interface UpdateParams {
                /**
                 * @description  内部档次id
                 */
                cleanGradeId?: number;
                /**
                 * @description  内部档次名称
                 */
                cleanGradeName?: string;
                /**
                 * @description  人员状态：1，为启用 2，为不启用
                 */
                cleanerStatus?: number;
                /**
                 * @description  保洁单价（每小时）
                 */
                hourlyWage?: number;
                /**
                 * @description  外部档次id
                 */
                outGradeId?: number;
                /**
                 * @description  外部档次名称
                 */
                outGradeName?: string;
            }
            /**
             * @name 修改保洁人员
             * @param id 修改保洁人员
             * @param data
             */
            export function update(id: string | number, data: UpdateParams) { return request.post<ResponseResult<object>>(`/house-v100001/cleaner/update/${id}`, data); }
        }
        export namespace contractPlatformAccount {
            export interface EnableAccountParams {
                /**
                 * @description  公司名称
                 */
                companyName: string;
                /**
                 * @description  工商号
                 */
                icNum: string;
                /**
                 * @description  法人身份证
                 */
                idCard: string;
                /**
                 * @description  法人联系电话
                 */
                mobile: string;
                /**
                 * @description  法人姓名
                 */
                name: string;
            }
            /**
             * @name 启用上上签账号
             * @param data
             */
            export function enableAccount(data: EnableAccountParams) { return request.post<ResponseResult<object>>("/house-v100001/contractPlatformAccount/enableAccount", data); }
            /**
             * @name 生成合同章
             */
            export function generateContractSeal() { return request.put<ResponseResult<object>>("/house-v100001/contractPlatformAccount/generateContractSeal"); }
            /**
             * @name 下载合同章
             */
            export function getContractSeal() { return request.get("/house-v100001/contractPlatformAccount/getContractSeal"); }
            /**
             * @name 合同三方平台账号详情
             */
            export function info() { return request.get<ResponseResult<ContractPlatformAccount>>("/house-v100001/contractPlatformAccount/info"); }
            export interface UploadContractSealParams {
                /**
                 * @description  contractChapter
                 */
                contractChapter?: string;
            }
            /**
             * @name 上传合同章
             * @param data
             */
            export function uploadContractSeal(data: UploadContractSealParams) { return request.put<ResponseResult<object>>("/house-v100001/contractPlatformAccount/uploadContractSeal", data); }
        }
        export namespace hcontractRecord {
            export interface CopyDownloadUrlParams {
                /**
                 * @description  id
                 */
                id?: number;
            }
            /**
             * @name 电子合同，小程序获取下载合同地址，通过复制或者分享给别人
             * @param params
             */
            export function copyDownloadUrl(params: CopyDownloadUrlParams) { return request.get<ResponseResult<object>>("/house-v100001/hcontractRecord/copyDownloadUrl", { params }); }
            /**
             * @name 合同记录删除
             * @param id 合同记录删除
             */
            export function deleteById(id: string | number) { return request.delete<ResponseResult<object>>(`/house-v100001/hcontractRecord/delete/${id}`); }
            /**
             * @name 合同记录回收站删除
             * @param id 合同记录回收站删除
             */
            export function deleteFinal(id: string | number) { return request.delete<ResponseResult<object>>(`/house-v100001/hcontractRecord/deleteFinal/${id}`); }
            export interface DownloadParams {
                /**
                 * @description  id
                 */
                id?: number;
            }
            /**
             * @name 下载电子合同
             * @param params
             */
            export function download(params: DownloadParams) { return request.get("/house-v100001/hcontractRecord/download", { params }); }
            /**
             * @name 生成二维码
             * @param id 生成二维码
             */
            export function genQrcode(id: string | number) { return request.get(`/house-v100001/hcontractRecord/genQrcode/${id}`); }
            /**
             * @name  获取合同URL  用于分享签合同
             * @param id  获取合同URL  用于分享签合同
             */
            export function getUrl(id: string | number) { return request.post<ResponseResult<object>>(`/house-v100001/hcontractRecord/getUrl/${id}`); }
            /**
             * @name 合同记录详情
             * @param id 合同记录详情
             */
            export function info(id: string | number) { return request.get<ResponseResult<ContractRecord>>(`/house-v100001/hcontractRecord/info/${id}`); }
            export interface ListParams {
                /**
                 * @description  合同性质id
                 */
                contractNatureId?: number;
                /**
                 * @description  合同编号
                 */
                contractNo?: string;
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  邮件发送状态 0-未发送 1-已发送
                 */
                emailState?: number;
                /**
                 * @description  房源ID
                 */
                houseId?: number;
                /**
                 * @description  门牌号、（集中几层或者几栋）
                 */
                houseNum?: string;
                /**
                 * @description  1整租 2合租 3集中
                 */
                houseType?: number;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  关联人
                 */
                relationName?: string;
                /**
                 * @description  关联类型ID 12房东  13租客
                 */
                relationTypeId?: number;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  状态 1-正常 2-删除
                 */
                state?: number;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
                /**
                 * @description  租客ID
                 */
                tenantsId?: number;
                /**
                 * @description  合同类型 1-电子 2-纸质
                 */
                type?: number;
            }
            /**
             * @name 合同记录列表
             * @param params
             */
            export function list(params: ListParams) { return request.get<ResponseResult<PageList<ContractRecord>>>("/house-v100001/hcontractRecord/list", { params }); }
            /**
             * @name 预览合同
             * @param id 预览合同
             */
            export function preview(id: string | number) { return request.get<ResponseResult<object>>(`/house-v100001/hcontractRecord/preview/${id}`); }
            export interface ReductionParams {
                /**
                 * @description  id
                 */
                id?: number;
            }
            /**
             * @name 合同还原
             * @param data
             */
            export function reduction(data: ReductionParams) { return request.put<ResponseResult<object>>("/house-v100001/hcontractRecord/reduction", data); }
            export interface SendEmailParams {
                /**
                 * @description  邮箱
                 */
                email?: string;
            }
            /**
             * @name 发送邮件
             * @param id 发送邮件
             * @param data
             */
            export function sendEmail(id: string | number, data: SendEmailParams) { return request.post<ResponseResult<object>>(`/house-v100001/hcontractRecord/sendEmail/${id}`, data); }
            /**
             * @name 发送合同短信
             * @param id 发送合同短信
             */
            export function sendSms(id: string | number) { return request.post<ResponseResult<object>>(`/house-v100001/hcontractRecord/sendSms/${id}`); }
        }
        export namespace contractTemplate {
            /**
             * @name 模板代码
             */
            export function code() { return request.get<ResponseResult<Array<ContractTemplateCodeVo>>>("/house-v100001/contractTemplate/code"); }
            /**
             * @name 合同模板删除
             * @param id 合同模板删除
             */
            export function deleteById(id: string | number) { return request.delete<ResponseResult<object>>(`/house-v100001/contractTemplate/delete/${id}`); }
            export interface DownloadParams {
                /**
                 * @description  业务ID 房源ID | 租客ID
                 */
                busId?: number;
                /**
                 * @description  模板ID
                 */
                templateId?: number;
            }
            /**
             * @name 下载合同
             * @param params
             */
            export function download(params: DownloadParams) { return request.get("/house-v100001/contractTemplate/download", { params }); }
            export interface GenContractParams {
                /**
                 * @description  业务ID 房源ID | 租客ID
                 */
                busId?: number;
                /**
                 * @description  模板ID
                 */
                templateId?: number;
            }
            /**
             * @name 生成电子合同
             * @param data
             */
            export function genContract(data: GenContractParams) { return request.post<ResponseResult<ContractTemplate>>("/house-v100001/contractTemplate/genContract", data); }
            /**
             * @name 合同模板详情
             * @param id 合同模板详情
             */
            export function info(id: string | number) { return request.get<ResponseResult<ContractTemplateVo>>(`/house-v100001/contractTemplate/info/${id}`); }
            export interface ListParams {
                /**
                 * @description  1-整租 2-合租 3-集中
                 */
                houseType?: number;
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  模板名称
                 */
                name?: string;
                pageNo?: number;
                pageSize?: number;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  门店ID
                 */
                storeId?: string;
                /**
                 * @description  合同类型 1-房东 2-租客
                 */
                type?: number;
            }
            /**
             * @name 合同模板列表
             * @param params
             */
            export function list(params: ListParams) { return request.get<ResponseResult<PageList<ContractTemplate>>>("/house-v100001/contractTemplate/list", { params }); }
            export interface PreviewParams {
                /**
                 * @description  模板ID
                 */
                templateId?: number;
            }
            /**
             * @name 预览合同
             * @param params
             */
            export function preview(params: PreviewParams) { return request.get("/house-v100001/contractTemplate/preview", { params }); }
            export interface SaveParams {
                /**
                 * @description  模板内容
                 */
                content?: string;
                /**
                 * @description  模板内容json
                 */
                contentJson?: string;
                /**
                 * @description  1-整租 2-合租 3-集中
                 */
                houseType: number;
                /**
                 * @description  模板名称
                 */
                name?: string;
                /**
                 * @description  备注
                 */
                remark?: string;
                /**
                 * @description  门店ID 多个逗号分割
                 */
                storeId?: string;
                /**
                 * @description  合同类型 1-房东 2-租客
                 */
                type: number;
                bottomLeft: Array<{
                    code: number;
                    name: string;
                    remarks: string;
                    val: string;
                }>;
                bottomRight: Array<{
                    code: number;
                    name: string;
                    remarks: string;
                    val: string;
                }>;
            }
            /**
             * @name 合同模板新增
             * @param data
             */
            export function save(data: SaveParams) { return request.post<ResponseResult<object>>("/house-v100001/contractTemplate/save", data); }
            export interface SelectParams {
                /**
                 * @description  1-整租 2-合租 3-集中
                 */
                houseType?: number;
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  模板名称
                 */
                name?: string;
                /**
                 * @description  门店ID
                 */
                storeId?: string;
                /**
                 * @description  合同类型 1-房东 2-租客
                 */
                type?: number;
            }
            /**
             * @name 合同模板下拉
             * @param params
             */
            export function select(params: SelectParams) { return request.get<ResponseResult<Array<ContractTemplate>>>("/house-v100001/contractTemplate/select", { params }); }
            export interface UpdateParams {
                /**
                 * @description  模板内容
                 */
                content?: string;
                /**
                 * @description  模板内容json
                 */
                contentJson?: string;
                /**
                 * @description  1-整租 2-合租 3-集中
                 */
                houseType: number;
                /**
                 * @description  模板名称
                 */
                name?: string;
                /**
                 * @description  备注
                 */
                remark?: string;
                /**
                 * @description  门店ID 多个逗号分割
                 */
                storeId?: string;
                /**
                 * @description  合同类型 1-房东 2-租客
                 */
                type: number;
                bottomLeft: Array<{
                    code: number;
                    name: string;
                    remarks: string;
                    val: string;
                }>;
                bottomRight: Array<{
                    code: number;
                    name: string;
                    remarks: string;
                    val: string;
                }>;
            }
            /**
             * @name 合同模板修改
             * @param id 合同模板修改
             * @param data
             */
            export function update(id: string | number, data: UpdateParams) { return request.put<ResponseResult<object>>(`/house-v100001/contractTemplate/update/${id}`, data); }
            export namespace Default {
                export interface ContractParams {
                    /**
                     * @description  房屋类型 1-整租 2-合租 3-集中
                     */
                    houseType?: number;
                    /**
                     * @description  合同类型 1-房东 2-租客
                     */
                    type?: number;
                }
                /**
                 * @name 默认合同模板
                 * @param params
                 */
                export function contract(params: ContractParams) { return request.get<ResponseResult<ContractTemplateVo>>("/house-v100001/contractTemplate/default/contract", { params }); }
            }
        }
        export namespace decorate {
            export interface DeleteByIdParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 房源装修记录表删除
             * @param params
             */
            export function deleteById(params: DeleteByIdParams) { return request.delete<ResponseResult<object>>("/house-v100001/decorate/deleteById", { params }); }
            /**
             * @name 房源装修记录表详情
             * @param id 房源装修记录表详情
             */
            export function info(id: string | number) { return request.get<ResponseResult<Decorate>>(`/house-v100001/decorate/info/${id}`); }
            export interface ListParams {
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  房源id
                 */
                houseId?: number;
                /**
                 * @description  门牌号、（集中几层或者几栋）
                 */
                houseNum?: string;
                /**
                 * @description  1整租 2合租 3集中
                 */
                houseType?: number;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  房间id（集中传房间id）
                 */
                roomId?: number;
                /**
                 * @description  房间号 (合租)/集中 门牌号
                 */
                roomNo?: string;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
                /**
                 * @description  工人姓名
                 */
                workerName?: string;
            }
            /**
             * @name 房源装修记录表列表
             * @param params
             */
            export function list(params: ListParams) { return request.get<ResponseResult<PageList<Decorate>>>("/house-v100001/decorate/list", { params }); }
            export interface SaveParams {
                /**
                 * @description  1,添加主材;2,添加辅材;3,添加家具家电
                 */
                addItem?: string;
                /**
                 * @description  添加项目金额
                 */
                addItemAmount?: number;
                /**
                 * @description  装修类型id(整租，合租)
                 */
                decorateId: number;
                /**
                 * @description  房源id
                 */
                houseId?: number;
                /**
                 * @description  装修进展图片
                 */
                imgs?: string;
                /**
                 * @description  备注
                 */
                remark?: string;
                /**
                 * @description  房间id（集中传房间id，整租与合租不传）
                 */
                roomId?: number;
                /**
                 * @description  工钱
                 */
                workerAmount: number;
                /**
                 * @description  工人姓名
                 */
                workerName?: string;
            }
            /**
             * @name 设置装修类型
             * @param data
             */
            export function save(data: SaveParams) { return request.post<ResponseResult<House>>("/house-v100001/decorate/save", data); }
            export interface UpdateFinishAllParams {
                /**
                 * @description  房源id
                 */
                houseId?: number;
                /**
                 * @description  房间id
                 */
                roomId?: number;
            }
            /**
             * @name 所有装修完成
             * @param data
             */
            export function updateFinishAll(data: UpdateFinishAllParams) { return request.post<ResponseResult<object>>("/house-v100001/decorate/updateFinishAll", data); }
            export interface UpdateFinishByIdParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 单条装修完成
             * @param data
             */
            export function updateFinishById(data: UpdateFinishByIdParams) { return request.post<ResponseResult<object>>("/house-v100001/decorate/updateFinishById", data); }
        }
        export namespace delay {
            /**
             * @name 延期删除
             * @param id 延期删除
             */
            export function deleteById(id: string | number) { return request.delete<ResponseResult<object>>(`/house-v100001/delay/delete/${id}`); }
            export interface HouseDelayParams {
                /**
                 * @description  金额
                 */
                amount: number;
                /**
                 * @description  结束时间
                 */
                endTime: string;
                /**
                 * @description  租赁天
                 */
                leaseDay?: number;
                /**
                 * @description  租赁月
                 */
                leaseMonth?: number;
                /**
                 * @description  租客或者房东id
                 */
                otherId: number;
                /**
                 * @description  缴费日期 1表示提前，2表示延后 3固定日期
                 */
                payType: number;
                /**
                 * @description  缴费日期 提前或者延后多少天
                 */
                payTypeDay: number;
                /**
                 * @description  付款方式id
                 */
                payTypeId: number;
                /**
                 * @description  开始时间
                 */
                startTime: string;
            }
            /**
             * @name 房东延期
             * @param data
             */
            export function houseDelay(data: HouseDelayParams) { return request.post<ResponseResult<object>>("/house-v100001/delay/houseDelay", data); }
            export interface ListParams {
                /**
                 * @description  创建时间结束
                 */
                crateTimeEnd?: string;
                /**
                 * @description  创建时间开始
                 */
                crateTimeStart?: string;
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  租赁结束时间结束
                 */
                endTimeEnd?: string;
                /**
                 * @description  租赁结束时间开始
                 */
                endTimeStart?: string;
                /**
                 * @description  门牌号、（集中几层或者几栋）
                 */
                houseNum?: string;
                /**
                 * @description  1整租 2合租 3集中
                 */
                houseType?: number;
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  姓名
                 */
                name?: number;
                /**
                 * @description  租客或者房东id
                 */
                otherId?: number;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  缴费日期 1表示提前，2表示延后 3固定日期
                 */
                payType?: number;
                /**
                 * @description  缴费日期 提前或者延后多少天
                 */
                payTypeDay?: number;
                /**
                 * @description  付款方式id
                 */
                payTypeId?: number;
                /**
                 * @description  电话
                 */
                phone?: number;
                /**
                 * @description  12 房东，13表示租客
                 */
                relationTypeId?: number;
                /**
                 * @description  房间号 (合租)/集中 门牌号
                 */
                roomNo?: string;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  租赁开始时间结束
                 */
                startTimeEnd?: string;
                /**
                 * @description  租赁开始时间开始
                 */
                startTimeStart?: string;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
            }
            /**
             * @name 延期记录表列表
             * @param params
             */
            export function list(params: ListParams) { return request.get<ResponseResult<PageList<Delay>>>("/house-v100001/delay/list", { params }); }
            export interface TenantDelayParams {
                /**
                 * @description  金额
                 */
                amount: number;
                /**
                 * @description  结束时间
                 */
                endTime: string;
                /**
                 * @description  租赁天
                 */
                leaseDay?: number;
                /**
                 * @description  租赁月
                 */
                leaseMonth?: number;
                /**
                 * @description  租客或者房东id
                 */
                otherId: number;
                /**
                 * @description  缴费日期 1表示提前，2表示延后 3固定日期
                 */
                payType: number;
                /**
                 * @description  缴费日期 提前或者延后多少天
                 */
                payTypeDay: number;
                /**
                 * @description  付款方式id
                 */
                payTypeId: number;
                /**
                 * @description  开始时间
                 */
                startTime: string;
            }
            /**
             * @name 租客延期
             * @param data
             */
            export function tenantDelay(data: TenantDelayParams) { return request.post<ResponseResult<object>>("/house-v100001/delay/tenantDelay", data); }
        }
        export namespace deliveryCat {
            /**
             * @name 交割单分类删除
             * @param id 交割单分类删除
             */
            export function deleteById(id: string | number) { return request.delete<ResponseResult<object>>(`/house-v100001/deliveryCat/delete/${id}`); }
            /**
             * @name 交割单分类详情
             * @param id 交割单分类详情
             */
            export function info(id: string | number) { return request.get<ResponseResult<DeliveryCat>>(`/house-v100001/deliveryCat/info/${id}`); }
            /**
             * @name 交割单分类列表
             */
            export function list() { return request.get<ResponseResult<Array<DeliveryCat>>>("/house-v100001/deliveryCat/list"); }
            export interface SaveParams {
                /**
                 * @description  是否为默认选项1默认 2不默认
                 */
                isDefault: number;
                /**
                 * @description  模板名称
                 */
                name: string;
                /**
                 * @description  父分类ID 为0 为顶级分类
                 */
                pid: number;
            }
            /**
             * @name 交割单分类新增 或添加物品
             * @param data
             */
            export function save(data: SaveParams) { return request.post<ResponseResult<object>>("/house-v100001/deliveryCat/save", data); }
            export interface SelectParams {
                /**
                 * @description  父分类ID 0 查询顶级分类
                 */
                pid?: number;
                /**
                 * @description  isDefault
                 */
                isDefault?: number;
            }
            /**
             * @name 交割单分类下拉框
             * @param params
             */
            export function select(params: SelectParams) { return request.get<ResponseResult<Array<DeliveryCat>>>("/house-v100001/deliveryCat/select", { params }); }
            export interface UpdateParams {
                /**
                 * @description  是否为默认选项1默认 2不默认
                 */
                isDefault: number;
                /**
                 * @description  模板名称
                 */
                name: string;
                /**
                 * @description  父分类ID 为0 为顶级分类
                 */
                pid: number;
            }
            /**
             * @name 交割单分类修改
             * @param id 交割单分类修改
             * @param data
             */
            export function update(id: string | number, data: UpdateParams) { return request.put<ResponseResult<object>>(`/house-v100001/deliveryCat/update/${id}`, data); }
        }
        export namespace deliveryOrder {
            export interface DeleteByIdParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 交割单删除
             * @param params
             */
            export function deleteById(params: DeleteByIdParams) { return request.delete<ResponseResult<object>>("/house-v100001/deliveryOrder/deleteById", { params }); }
            export interface DeleteListParams {
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  房源ID
                 */
                houseId?: number;
                /**
                 * @description  门牌号）
                 */
                houseNum?: string;
                /**
                 * @description  1整租 2合租 3集中
                 */
                houseType?: number;
                /**
                 * @description  是否为默认选项1默认 2不默认
                 */
                isDefault?: number;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  类型：12租客 13房东 ，如果是房东必须传houseId,如果是租客，有2种情况,传roomId与租客id
                 */
                relationTypeId?: number;
                /**
                 * @description  房间ID
                 */
                roomId?: number;
                /**
                 * @description  房间号
                 */
                roomNo?: string;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  租客id
                 */
                tenantsId?: number;
            }
            /**
             * @name 交割单列表：回收站
             * @param params
             */
            export function deleteList(params: DeleteListParams) { return request.get<ResponseResult<PageList<DeliveryOrder>>>("/house-v100001/deliveryOrder/deleteList", { params }); }
            /**
             * @name 交割单废弃
             * @param id 交割单废弃
             */
            export function discard(id: string | number) { return request.post<ResponseResult<object>>(`/house-v100001/deliveryOrder/discard/${id}`); }
            export interface GetPdfUrlParams {
                /**
                 * @description  邮件地址
                 */
                email?: string;
                /**
                 * @description  交割单ID
                 */
                id?: number;
                /**
                 * @description  类型1表示查看pdf,2表示发送邮件
                 */
                type?: number;
            }
            /**
             * @name 交割单发送邮件或者查看
             * @param data
             */
            export function getPdfUrl(data: GetPdfUrlParams) { return request.post<ResponseResult<object>>("/house-v100001/deliveryOrder/getPdfUrl", data); }
            /**
             * @name 交割单详情
             * @param id 交割单详情
             */
            export function info(id: string | number) { return request.get<ResponseResult<DeliveryOrderVo>>(`/house-v100001/deliveryOrder/info/${id}`); }
            export interface ListParams {
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  房源ID
                 */
                houseId?: number;
                /**
                 * @description  门牌号）
                 */
                houseNum?: string;
                /**
                 * @description  1整租 2合租 3集中
                 */
                houseType?: number;
                /**
                 * @description  是否为默认选项1默认 2不默认
                 */
                isDefault?: number;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  类型：12租客 13房东 ，如果是房东必须传houseId,如果是租客，有2种情况,传roomId与租客id
                 */
                relationTypeId?: number;
                /**
                 * @description  房间ID
                 */
                roomId?: number;
                /**
                 * @description  房间号
                 */
                roomNo?: string;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  租客id
                 */
                tenantsId?: number;
            }
            /**
             * @name 交割单列表:正常使用列表
             * @param params
             */
            export function list(params: ListParams) { return request.get<ResponseResult<PageList<DeliveryOrder>>>("/house-v100001/deliveryOrder/list", { params }); }
            export interface ManagerListParams {
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  房源ID
                 */
                houseId?: number;
                /**
                 * @description  门牌号）
                 */
                houseNum?: string;
                /**
                 * @description  1整租 2合租 3集中
                 */
                houseType?: number;
                /**
                 * @description  是否为默认选项1默认 2不默认
                 */
                isDefault?: number;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  类型：12租客 13房东 ，如果是房东必须传houseId,如果是租客，有2种情况,传roomId与租客id
                 */
                relationTypeId?: number;
                /**
                 * @description  房间ID
                 */
                roomId?: number;
                /**
                 * @description  房间号
                 */
                roomNo?: string;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  租客id
                 */
                tenantsId?: number;
            }
            /**
             * @name 交割单列表:管理端使用:必传参数：relationTypeId 
             * @param params
             */
            export function managerList(params: ManagerListParams) { return request.get<ResponseResult<PageList<DeliveryOrder>>>("/house-v100001/deliveryOrder/managerList", { params }); }
            export interface ReductionParams {
                /**
                 * @description  id
                 */
                id?: number;
            }
            /**
             * @name 交割单还原
             * @param data
             */
            export function reduction(data: ReductionParams) { return request.post<ResponseResult<object>>("/house-v100001/deliveryOrder/reduction", data); }
            export interface SaveParams {
                /**
                 * @description  凭证照片 逗号分割
                 */
                certificateImage?: string;
                /**
                 * @description  物品清单json  [{catId:1,catName:'家具',itemList:[{catId:2,catName:'床',count:1,status:1,exteriorStatus:1}]}]   
                 */
                deliverItem?: string;
                /**
                 * @description  电
                 */
                electricity?: number;
                /**
                 * @description  气
                 */
                gas?: number;
                /**
                 * @description  房源ID 房东交割单与 roomId互斥
                 */
                houseId?: number;
                /**
                 * @description  身份证照片 逗号分割
                 */
                idcardImage?: string;
                /**
                 * @description  备注
                 */
                remark?: string;
                /**
                 * @description  房间ID 租客交割单 与 houseId互斥
                 */
                roomId?: number;
                /**
                 * @description  房间照片 逗号分割
                 */
                roomImage: string;
                /**
                 * @description  视频 逗号分割
                 */
                roomVideo?: string;
                /**
                 * @description  卫生 1-非常干净 2-干净 3-不干净
                 */
                sanitation: number;
                /**
                 * @description  签字图片
                 */
                userSignature?: string;
                /**
                 * @description  水
                 */
                water?: number;
            }
            /**
             * @name 交割单新增
             * @param data
             */
            export function save(data: SaveParams) { return request.post<ResponseResult<object>>("/house-v100001/deliveryOrder/save", data); }
            export interface SignatureUserParams {
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  签名图片url
                 */
                userSignature?: string;
            }
            /**
             * @name 交割单签字,或重签 ，租客与房东端 需要 判断，如果已签字，那不能在签了
             * @param data
             */
            export function signatureUser(data: SignatureUserParams) { return request.post<ResponseResult<object>>("/house-v100001/deliveryOrder/signatureUser", data); }
            export interface UpdateParams {
                /**
                 * @description  凭证照片 逗号分割
                 */
                certificateImage?: string;
                /**
                 * @description  物品清单json  [{catId:1,catName:'家具',itemList:[{catId:2,catName:'床',count:1,status:1,exteriorStatus:1}]}]   
                 */
                deliverItem?: string;
                /**
                 * @description  电
                 */
                electricity?: number;
                /**
                 * @description  气
                 */
                gas?: number;
                /**
                 * @description  房源ID 房东交割单与 roomId互斥
                 */
                houseId?: number;
                /**
                 * @description  身份证照片 逗号分割
                 */
                idcardImage?: string;
                /**
                 * @description  备注
                 */
                remark?: string;
                /**
                 * @description  房间ID 租客交割单 与 houseId互斥
                 */
                roomId?: number;
                /**
                 * @description  房间照片 逗号分割
                 */
                roomImage: string;
                /**
                 * @description  视频 逗号分割
                 */
                roomVideo?: string;
                /**
                 * @description  卫生 1-非常干净 2-干净 3-不干净
                 */
                sanitation: number;
                /**
                 * @description  签字图片
                 */
                userSignature?: string;
                /**
                 * @description  水
                 */
                water?: number;
            }
            /**
             * @name 交割单修改
             * @param id 交割单修改
             * @param data
             */
            export function update(id: string | number, data: UpdateParams) { return request.put<ResponseResult<object>>(`/house-v100001/deliveryOrder/update/${id}`, data); }
        }
        export namespace externalDisplayRoomWeb {
            /**
             * @name 外部展示管理页面-详情页
             * @param id 外部展示管理页面-详情页
             */
            export function info(id: string | number) { return request.get<ResponseResult<ExternalDisplayRoom>>(`/house-v100001/externalDisplayRoomWeb/info/${id}`); }
            export interface PageParams {
                /**
                 * @description  区id(通过物业地址获取)
                 */
                areaId?: number;
                /**
                 * @description  物业地址id
                 */
                detailId?: string;
                dtzmId?: number;
                /**
                 * @description  当前经度
                 */
                newLat?: string;
                /**
                 * @description  当前纬度
                 */
                newLng?: string;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  价格区间
                 */
                pricingAmount?: string;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  管家id
                 */
                stewardId?: number;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
            }
            /**
             * @name 外部展示管理页面
             * @param params
             */
            export function page(params: PageParams) { return request.get<ResponseResult<PageList<ExternalDisplayRoom>>>("/house-v100001/externalDisplayRoomWeb/page", { params }); }
            export interface SaveParams {
                /**
                 * @description  面积
                 */
                acreage?: string;
                /**
                 * @description  1 有空调，0无空调
                 */
                conditioner?: number;
                /**
                 * @description  封面图
                 */
                coverImg?: string;
                /**
                 * @description  物业地址id
                 */
                detailId?: string;
                /**
                 * @description  物业地址名称
                 */
                detailName?: string;
                /**
                 * @description  几厅
                 */
                hall?: number;
                /**
                 * @description  户型图
                 */
                houseTypeImg?: string;
                /**
                 * @description  室内图
                 */
                indoorImg?: string;
                /**
                 * @description  室内图
                 */
                outdoorImg?: string;
                /**
                 * @description  定价金额
                 */
                pricingAmount?: number;
                /**
                 * @description  几室
                 */
                room?: number;
                /**
                 * @description  房间id
                 */
                roomId: number;
                /**
                 * @description  房间标题
                 */
                roomTitle?: string;
                /**
                 * @description  视频地址
                 */
                videoUrl?: string;
                /**
                 * @description  几卫
                 */
                who?: number;
            }
            /**
             * @name 外部展示房间表添加接口
             * @param data
             */
            export function save(data: SaveParams) { return request.post<ResponseResult<object>>("/house-v100001/externalDisplayRoomWeb/save", data); }
            export interface ShowParams {
                /**
                 * @description  id
                 */
                id?: number;
                /**
                 * @description  展示状态：1，外网展示。2不展示
                 */
                showStatus?: number;
            }
            /**
             * @name 外部展示上下架接口
             * @param data
             */
            export function show(data: ShowParams) { return request.post<ResponseResult<object>>("/house-v100001/externalDisplayRoomWeb/show", data); }
            export interface UpdateParams {
                /**
                 * @description  面积
                 */
                acreage?: string;
                /**
                 * @description  1 有空调，0无空调
                 */
                conditioner?: number;
                /**
                 * @description  封面图
                 */
                coverImg?: string;
                /**
                 * @description  物业地址id
                 */
                detailId?: string;
                /**
                 * @description  物业地址名称
                 */
                detailName?: string;
                /**
                 * @description  几厅
                 */
                hall?: number;
                /**
                 * @description  户型图
                 */
                houseTypeImg?: string;
                /**
                 * @description  室内图
                 */
                indoorImg?: string;
                /**
                 * @description  室内图
                 */
                outdoorImg?: string;
                /**
                 * @description  定价金额
                 */
                pricingAmount?: number;
                /**
                 * @description  几室
                 */
                room?: number;
                /**
                 * @description  房间id
                 */
                roomId: number;
                /**
                 * @description  房间标题
                 */
                roomTitle?: string;
                /**
                 * @description  视频地址
                 */
                videoUrl?: string;
                /**
                 * @description  几卫
                 */
                who?: number;
            }
            /**
             * @name 外部展示房间表修改接口
             * @param id 外部展示房间表修改接口
             * @param data
             */
            export function update(id: string | number, data: UpdateParams) { return request.post<ResponseResult<object>>(`/house-v100001/externalDisplayRoomWeb/update/${id}`, data); }
        }
        export namespace followUp {
            export interface DealFollowParams {
                /**
                 * @description  处理结果
                 */
                dealDesc?: string;
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 跟进记录添加处理结果
             * @param data
             */
            export function dealFollow(data: DealFollowParams) { return request.post<ResponseResult<object>>("/house-v100001/followUp/dealFollow", data); }
            export interface DeleteByIdParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 跟进记录删除
             * @param params
             */
            export function deleteById(params: DeleteByIdParams) { return request.delete<ResponseResult<object>>("/house-v100001/followUp/deleteById", { params }); }
            /**
             * @name 跟进记录详情
             * @param id 跟进记录详情
             */
            export function info(id: string | number) { return request.get<ResponseResult<FollowUp>>(`/house-v100001/followUp/info/${id}`); }
            export interface ListParams {
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  房源id 
                 */
                houseId?: number;
                /**
                 * @description  门牌号、（集中几层或者几栋）
                 */
                houseNum?: string;
                /**
                 * @description  1整租 2合租 3集中
                 */
                houseType?: number;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  房间id 
                 */
                roomId?: number;
                /**
                 * @description  房间号 (合租)/集中 门牌号
                 */
                roomNo?: string;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  物品来源id
                 */
                sourceId?: number;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
            }
            /**
             * @name 跟进记录列表
             * @param params
             */
            export function list(params: ListParams) { return request.get<ResponseResult<PageList<FollowUp>>>("/house-v100001/followUp/list", { params }); }
            export interface SaveParams {
                /**
                 * @description  房源id 
                 */
                houseId?: number;
                /**
                 * @description  1表示房东，2表示房间
                 */
                identityId: number;
                /**
                 * @description  图片
                 */
                imgs?: string;
                /**
                 * @description  备注
                 */
                remark?: string;
                /**
                 * @description  房间id
                 */
                roomId?: number;
                /**
                 * @description  录音或者视频
                 */
                tape?: string;
                /**
                 * @description  跟进事项(字典)
                 */
                typeId: number;
            }
            /**
             * @name 跟进记录新增
             * @param data
             */
            export function save(data: SaveParams) { return request.post<ResponseResult<object>>("/house-v100001/followUp/save", data); }
        }
        export namespace goodsIncrease {
            export interface DeleteByIdParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 房源物品增配记录表删除
             * @param params
             */
            export function deleteById(params: DeleteByIdParams) { return request.delete<ResponseResult<object>>("/house-v100001/goodsIncrease/deleteById", { params }); }
            /**
             * @name 房源物品增配记录表详情
             * @param id 房源物品增配记录表详情
             */
            export function info(id: string | number) { return request.get<ResponseResult<GoodsIncrease>>(`/house-v100001/goodsIncrease/info/${id}`); }
            export interface ListParams {
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  房源id(整租与合租传房源id)
                 */
                houseId?: number;
                /**
                 * @description  门牌号、（集中几层或者几栋）
                 */
                houseNum?: string;
                /**
                 * @description  1整租 2合租 3集中
                 */
                houseType?: number;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  房间id(集中传房间id)
                 */
                roomId?: number;
                /**
                 * @description  房间号 (合租)/集中 门牌号
                 */
                roomNo?: string;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  物品来源id
                 */
                sourceId?: number;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
            }
            /**
             * @name 房源物品增配记录表列表
             * @param params
             */
            export function list(params: ListParams) { return request.get<ResponseResult<PageList<GoodsIncrease>>>("/house-v100001/goodsIncrease/list", { params }); }
            export interface SaveParams {
                /**
                 * @description  总价
                 */
                amount: number;
                /**
                 * @description  物品图片
                 */
                goodsImg?: string;
                /**
                 * @description  物品去向(搬出时才填)
                 */
                goodsWhere?: string;
                /**
                 * @description  房源id(整租与合租传房源id)
                 */
                houseId?: number;
                /**
                 * @description  1 表示公司拥有，2表示房东，3租客
                 */
                identityId: number;
                /**
                 * @description  物品名称
                 */
                name?: string;
                /**
                 * @description  数量
                 */
                num: number;
                /**
                 * @description  备注
                 */
                remark?: string;
                /**
                 * @description  房间id，合租与集中(房间id)
                 */
                roomId?: number;
                /**
                 * @description  物品来源id
                 */
                sourceId: number;
                /**
                 * @description  1新增，2搬出
                 */
                typeId: number;
            }
            /**
             * @name 房源物品增配记录表新增 说明：物品整租是挂在房源上面的 合租可以挂房源，也可以挂房间
             * @param data
             */
            export function save(data: SaveParams) { return request.post<ResponseResult<object>>("/house-v100001/goodsIncrease/save", data); }
        }
        export namespace houseChild {
            export interface AuditStewardParams {
                /**
                 * @description  审批备注
                 */
                auditRemark?: string;
                /**
                 * @description  类型：类型：1为管家，2为第二管家
                 */
                changeType?: number;
                /**
                 * @description  主键id
                 */
                id?: number;
                /**
                 * @description  审核状态 1-已审核 2-未通过 3-未审核
                 */
                isAudit?: number;
            }
            /**
             * @name 管家更换审批
             * @param data
             */
            export function auditSteward(data: AuditStewardParams) { return request.post<ResponseResult<House>>("/house-v100001/houseChild/auditSteward", data); }
            export interface AuditStewardAllParams {
                /**
                 * @description  主键id
                 */
                ids?: string;
                /**
                 * @description  审核状态 1-已审核 2-未通过 3-未审核
                 */
                isAudit?: number;
            }
            /**
             * @name 管家更换批量审批
             * @param data
             */
            export function auditStewardAll(data: AuditStewardAllParams) { return request.post<ResponseResult<House>>("/house-v100001/houseChild/auditStewardAll", data); }
            export interface ConversionHouseTypeParams {
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  type 1整租转合租，2合租转整租
                 */
                type?: number;
            }
            /**
             * @name 整租转合租，合租转整租
             * @param data
             */
            export function conversionHouseType(data: ConversionHouseTypeParams) { return request.post<ResponseResult<object>>("/house-v100001/houseChild/conversionHouseType", data); }
            export interface DeleteStewardParams {
                /**
                 * @description  id
                 */
                id?: number;
            }
            /**
             * @name 删除管家
             * @param params
             */
            export function deleteSteward(params: DeleteStewardParams) { return request.delete<ResponseResult<House>>("/house-v100001/houseChild/deleteSteward", { params }); }
            export interface FreezeHouseParams {
                /**
                 * @description  冻结原因
                 */
                freezeReason?: string;
                /**
                 * @description  冻结时间：前端不管
                 */
                freezeTime?: string;
                /**
                 * @description  禁止操作房东续约 1:禁止  2不禁止
                 */
                isHouseContract?: number;
                /**
                 * @description  禁止操作应支房租 1:禁止  2不禁止
                 */
                isOptHouseRent?: number;
                /**
                 * @description  id
                 */
                id?: number;
            }
            /**
             * @name 冻结房源
             * @param data
             */
            export function freezeHouse(data: FreezeHouseParams) { return request.post<ResponseResult<House>>("/house-v100001/houseChild/freezeHouse", data); }
            export interface GetDetailNameSelectParams {
                /**
                 * @description  查询字段
                 */
                detailLike?: string;
                /**
                 * @description  storeId
                 */
                storeId?: number;
            }
            /**
             * @name 房源物业下拉框 本地检索
             * @param params
             */
            export function getDetailNameSelect(params: GetDetailNameSelectParams) { return request.get<ResponseResult<Array<SelectStrVo>>>("/house-v100001/houseChild/getDetailNameSelect", { params }); }
            /**
             * @name 房源子表详情
             * @param id 房源子表详情
             */
            export function infoChild(id: string | number) { return request.get<ResponseResult<HouseChild>>(`/house-v100001/houseChild/infoChild/${id}`); }
            export interface SaveImgParams {
                /**
                 * @description  合同图片
                 */
                contractImg?: string;
                /**
                 * @description  房源图片（9张）
                 */
                houseImg?: string;
                /**
                 * @description  房源视频 （前端看下能不能限制最大多少）
                 */
                houseVideo?: string;
                /**
                 * @description  主键
                 */
                id: number;
                /**
                 * @description  证件图片
                 */
                idCardImg?: string;
                /**
                 * @description  其它图片
                 */
                otherImg?: string;
            }
            /**
             * @name 房源添加图片
             * @param data
             */
            export function saveImg(data: SaveImgParams) { return request.post<ResponseResult<House>>("/house-v100001/houseChild/saveImg", data); }
            export interface SelectParams {
                /**
                 * @description  区域id
                 */
                areaId?: number;
                /**
                 * @description  审核时间结束
                 */
                auditTimeEnd?: string;
                /**
                 * @description  审核时间开始
                 */
                auditTimeStart?: string;
                /**
                 * @description  业务人员
                 */
                businessId?: number;
                /**
                 * @description  客源人
                 */
                businessId2?: number;
                /**
                 * @description  美好家园：1同步成功 2未同步  3同步失败
                 */
                ccbStatus?: number;
                /**
                 * @description  合同性质id
                 */
                contractId?: number;
                /**
                 * @description  合同签名 1-未签名 2-已签名
                 */
                contractRecordIsSignature?: number;
                /**
                 * @description  创建时间结束
                 */
                createTimeEnd?: string;
                /**
                 * @description  创建时间开始
                 */
                createTimeStart?: string;
                /**
                 * @description  装修类型id(整租，合租)
                 */
                decorateId?: number;
                /**
                 * @description  交割单签名 1-未签名 2-已签名
                 */
                deliveryOrderIsSignature?: number;
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  托管结束开始时间
                 */
                endTimeEnd?: string;
                /**
                 * @description  托管结束开始时间
                 */
                endTimeStart?: string;
                /**
                 * @description  导出报表结束时间（导出管家半年内巡房数据使用）
                 */
                exportTimeEnd?: string;
                /**
                 * @description  导出报表开始时间（导出管家半年内巡房数据使用）
                 */
                exportTimeStart?: string;
                /**
                 * @description  几厅
                 */
                hall?: number;
                /**
                 * @description  房东创建时间结束
                 */
                houseCreateTimeEnd?: string;
                /**
                 * @description  房东创建时间开始
                 */
                houseCreateTimeStart?: string;
                /**
                 * @description  房东姓名
                 */
                houseName?: string;
                /**
                 * @description  房源编号
                 */
                houseNo?: string;
                /**
                 * @description  门牌号
                 */
                houseNum?: string;
                /**
                 * @description  手机号
                 */
                housePhone?: string;
                /**
                 * @description  费用结算单签名 1-未签名 2-已签名
                 */
                houseSettlementIsSignature?: number;
                /**
                 * @description  类型：1整租,2合租，3集中
                 */
                houseType?: number;
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  身份证
                 */
                idCard?: string;
                /**
                 * @description  租后是否审核 1已审核  2未通过  3未审核
                 */
                isAfterAudit?: number;
                /**
                 * @description  租前是否审核 1已审核  2未通过  3未审核
                 */
                isBeforeAudit?: number;
                /**
                 * @description  宽带是否安装:1未安装 2已安装 3已到期
                 */
                isBroadband?: number;
                /**
                 * @description  是否到期 1未到期  2已到期   
                 */
                isEndTime?: number;
                /**
                 * @description  是否提交了退房申请(1,已提交；2未提交)
                 */
                isExit?: number;
                /**
                 * @description  家财险是否购买:1未购买 2已购买 3已到期
                 */
                isInsurance?: number;
                /**
                 * @description  只看自己的
                 */
                isMy?: number;
                /**
                 * @description  是否租完 1已租完 2未租完
                 */
                isRentOut?: number;
                /**
                 * @description  缴费方式名称
                 */
                payCostTypeId?: number;
                /**
                 * @description  推送58的状态：1 已推送 ，2 未推送
                 */
                pushStatus?: string;
                /**
                 * @description  录入报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  报表查询时间(托管结束) 房东合同到期报表
                 */
                reportTimeEnd?: string;
                /**
                 * @description  报表查询时间(托管开始) 收房业绩使用
                 */
                reportTimeStart?: string;
                /**
                 * @description  几室
                 */
                room?: number;
                /**
                 * @description  托管开始结束时间
                 */
                startTimeEnd?: string;
                /**
                 * @description  托管开始时间
                 */
                startTimeStart?: string;
                /**
                 * @description  状态：1正常 2待退房 3冻结
                 */
                status?: number;
                /**
                 * @description  管家id
                 */
                stewardId?: number;
                /**
                 * @description  管家id
                 */
                stewardId2?: number;
                /**
                 * @description  员工分组id
                 */
                storeGroupId?: number;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
                /**
                 * @description  1租前  2租后
                 */
                type?: number;
                /**
                 * @description  几卫
                 */
                who?: number;
            }
            /**
             * @name 房源下拉框
             * @param params
             */
            export function select(params: SelectParams) { return request.get<ResponseResult<Array<House>>>("/house-v100001/houseChild/select", { params }); }
            export interface SetAllStewardParams {
                /**
                 * @description  类型：1为管家，2为第二管家
                 */
                changeType: number;
                /**
                 * @description  新管家id不能为空
                 */
                newStewardId: number;
                /**
                 * @description  原管家id不能为空
                 */
                oldStewardId: number;
            }
            /**
             * @name 替换所有房源下面的设置管家
             * @param data
             */
            export function setAllSteward(data: SetAllStewardParams) { return request.post<ResponseResult<House>>("/house-v100001/houseChild/setAllSteward", data); }
            export interface SetAreaStewardParams {
                /**
                 * @description  类型：1为管家，2为第二管家
                 */
                changeType?: number;
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  用户id
                 */
                stewardId?: number;
            }
            /**
             * @name 设置本小区管家
             * @param data
             */
            export function setAreaSteward(data: SetAreaStewardParams) { return request.post<ResponseResult<House>>("/house-v100001/houseChild/setAreaSteward", data); }
            export interface SetStewardParams {
                /**
                 * @description  类型：1为管家，2为第二管家
                 */
                changeType?: number;
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  用户id
                 */
                stewardId?: number;
            }
            /**
             * @name 设置管家
             * @param data
             */
            export function setSteward(data: SetStewardParams) { return request.post<ResponseResult<House>>("/house-v100001/houseChild/setSteward", data); }
            export interface UnFreezeHouseParams {
                /**
                 * @description  id
                 */
                id?: number;
            }
            /**
             * @name 解冻房源
             * @param data
             */
            export function unFreezeHouse(data: UnFreezeHouseParams) { return request.post<ResponseResult<House>>("/house-v100001/houseChild/unFreezeHouse", data); }
            export interface UpdateOtherInfoParams {
                /**
                 * @description  面积
                 */
                acreage?: string;
                /**
                 * @description  紧急联系人
                 */
                emergencyContact?: string;
                /**
                 * @description  紧急联系人电话
                 */
                emergencyContactPhone?: string;
                /**
                 * @description  房产证号码
                 */
                houseCardNo?: string;
                /**
                 * @description  房东其它信息字段json  [{name:电卡号,val:511001111},{name:物业电话,val:13896046012}]  
                 */
                houseOtherJson?: string;
                /**
                 * @description  房产证地址
                 */
                housePropertyAddr?: string;
                /**
                 * @description  主键
                 */
                id: number;
                /**
                 * @description  维修方案id
                 */
                maintenancePlanId?: number;
                /**
                 * @description  备注
                 */
                remark?: string;
            }
            /**
             * @name 房源修改其它信息
             * @param data
             */
            export function updateOtherInfo(data: UpdateOtherInfoParams) { return request.put<ResponseResult<UpdateMessage>>("/house-v100001/houseChild/updateOtherInfo", data); }
            /**
             * @name updateSteward
             */
            export function updateSteward() { return request.post("/house-v100001/houseChild/updateSteward"); }
        }
        export namespace houseConfig {
            export interface AuditConfigSetParams {
                /**
                 * @description  1店长  2所有人有的权限人
                 */
                auditUser?: number;
                /**
                 * @description  字典id
                 */
                configTypeId: number;
                /**
                 * @description  房屋类型 1-整租 2-合租 3-集中 写死 
                 */
                houseType: number;
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  1启用审核  2不启用
                 */
                isAudit: number;
            }
            /**
             * @name 审核设置保存
             * @param data
             */
            export function auditConfigSet(data: AuditConfigSetParams) { return request.post<ResponseResult<object>>("/house-v100001/houseConfig/auditConfigSet", data); }
            export interface AuthIdCardNameSetParams {
                /**
                 * @description  1表示开启  2表示不开启）
                 */
                configJson?: string;
                /**
                 * @description  房屋类型 1-整租 2-合租 3-集中 写死 
                 */
                houseType: string;
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 实名认证开启设置
             * @param data
             */
            export function authIdCardNameSet(data: AuthIdCardNameSetParams) { return request.post<ResponseResult<object>>("/house-v100001/houseConfig/authIdCardNameSet", data); }
            export interface BargainTemplateSetParams {
                /**
                 * @description  定金模版内容不能为空
                 */
                content?: string;
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 定金模版设置
             * @param data
             */
            export function bargainTemplateSet(data: BargainTemplateSetParams) { return request.post<ResponseResult<object>>("/house-v100001/houseConfig/bargainTemplateSet", data); }
            export interface ContractSetParams {
                /**
                 * @description  合同参数：1 表示pdf  2 表示word （单选框）
                 */
                configJson?: string;
                /**
                 * @description  房屋类型 1-整租 2-合租 3-集中 写死 
                 */
                houseType: number;
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 合同下载设置
             * @param data
             */
            export function contractSet(data: ContractSetParams) { return request.post<ResponseResult<object>>("/house-v100001/houseConfig/contractSet", data); }
            export interface DepositSetParams {
                /**
                 * @description  默认定金有效期天数  默认几天过期
                 */
                defaultDay?: number;
                /**
                 * @description  房屋类型 1-整租 2-合租 3-集中 写死 
                 */
                houseType: string;
                /**
                 * @description  主键
                 */
                id?: number;
                isDefaultDay?: number;
                /**
                 * @description  超过有效期自动取消定金  1表示勾选  2表示不勾选，如果为空表示不勾选
                 */
                isValidity?: number;
            }
            /**
             * @name 定金设置
             * @param data
             */
            export function depositSet(data: DepositSetParams) { return request.post<ResponseResult<object>>("/house-v100001/houseConfig/depositSet", data); }
            export interface GetHouseConfigInfoParams {
                /**
                 * @description  配置类型ID  必须传
                 */
                configTypeId?: number;
                /**
                 * @description  房屋类型 1-整租 2-合租 3-集中 必须传
                 */
                houseType?: number;
                /**
                 * @description  关联类型 1-房东 2-租客
                 */
                relationType?: number;
            }
            /**
             * @name 设置表详情
             * @param params
             */
            export function getHouseConfigInfo(params: GetHouseConfigInfoParams) { return request.get<ResponseResult<HouseConfig>>("/house-v100001/houseConfig/getHouseConfigInfo", { params }); }
            export interface GetHouseConfigJsonParams {
                /**
                 * @description  配置类型ID  必须传
                 */
                configTypeId?: number;
                /**
                 * @description  房屋类型 1-整租 2-合租 3-集中 必须传
                 */
                houseType?: number;
                /**
                 * @description  关联类型 1-房东 2-租客
                 */
                relationType?: number;
            }
            /**
             * @name 设置表详情(格式json)
             * @param params
             */
            export function getHouseConfigJson(params: GetHouseConfigJsonParams) { return request.get<ResponseResult<HouseConfigVo>>("/house-v100001/houseConfig/getHouseConfigJson", { params }); }
            export interface HouseRentBeforeDaySetParams {
                /**
                 * @description  提前天数(负数表示延后)
                 */
                beforeDay: number;
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  单选框 1表示缴费时间  2表示有效期开始时间
                 */
                typeTime: number;
            }
            /**
             * @name 崔租提前天数设置
             * @param data
             */
            export function houseRentBeforeDaySet(data: HouseRentBeforeDaySetParams) { return request.post<ResponseResult<object>>("/house-v100001/houseConfig/houseRentBeforeDaySet", data); }
            export interface LateFeeSetParams {
                /**
                 * @description  房屋类型 1-整租 2-合租 3-集中 写死 
                 */
                houseType: number;
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  类型： 1 表示固定金额  2 表示百分比
                 */
                type?: number;
                /**
                 * @description  滞纳金值
                 */
                val?: number;
            }
            /**
             * @name 滞纳金设置
             * @param data
             */
            export function lateFeeSet(data: LateFeeSetParams) { return request.post<ResponseResult<object>>("/house-v100001/houseConfig/lateFeeSet", data); }
            export interface LeaseEndTimeSetParams {
                /**
                 * @description  与租客签合同的到期时间不能超过房东合同到期时间 下拉值： 不限  1天  15天 1个月 2个月 3月 4个月 5个月 6个月 
                 */
                afterHouseTime?: string;
                /**
                 * @description  与租客签合同的到期时间不能在房东合同到期时间前 下拉值： 不限  1天  15天  1个月 2个月 3个月 4个月 5个月 6个月 
                 */
                beforeHouseTime?: string;
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 租赁期限设置
             * @param data
             */
            export function leaseEndTimeSet(data: LeaseEndTimeSetParams) { return request.post<ResponseResult<object>>("/house-v100001/houseConfig/leaseEndTimeSet", data); }
            export interface OfficialSealUpLoadImgParams {
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  公章图片
                 */
                img?: string;
            }
            /**
             * @name 公章上传设置
             * @param data
             */
            export function officialSealUpLoadImg(data: OfficialSealUpLoadImgParams) { return request.post<ResponseResult<object>>("/house-v100001/houseConfig/officialSealUpLoadImg", data); }
            export interface PayTypeSetParams {
                /**
                 * @description  缴费提前，延后，固守天数 ，天数设置，前端使用自己定义json
                 */
                configJson?: string;
                /**
                 * @description  房屋类型 1-整租 2-合租 3-集中 写死 
                 */
                houseType: string;
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  关联类型 1-房东 2-租客
                 */
                relationType: number;
            }
            /**
             * @name 保存房源租客缴费天数设置
             * @param data
             */
            export function payTypeSet(data: PayTypeSetParams) { return request.post<ResponseResult<object>>("/house-v100001/houseConfig/payTypeSet", data); }
            export interface PreAmountSetParams {
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  预存费： 1 表示显示  2 表示不显示
                 */
                preAmountStatus?: number;
            }
            /**
             * @name 预存费设置
             * @param data
             */
            export function preAmountSet(data: PreAmountSetParams) { return request.post<ResponseResult<object>>("/house-v100001/houseConfig/preAmountSet", data); }
            export interface PriceSetParams {
                /**
                 * @description  房屋类型 1-整租 2-合租 3-集中 写死 
                 */
                houseType: string;
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  低于押金出租，不能录入信息  1表示勾选  2表示不勾选，如果为空表示不勾选
                 */
                isDeposit?: number;
                /**
                 * @description  低于定价出租，不能录入信息  1表示勾选  2表示不勾选，如果为空表示不勾选
                 */
                isRent?: number;
            }
            /**
             * @name 出租定价设置
             * @param data
             */
            export function priceSet(data: PriceSetParams) { return request.post<ResponseResult<object>>("/house-v100001/houseConfig/priceSet", data); }
            export interface ServiceChargeSetParams {
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  手续费比例，输入小数）
                 */
                serviceChargeRate?: number;
                /**
                 * @description  1表示公寓承担  2表示租客承担）
                 */
                type?: number;
            }
            /**
             * @name 在线设置手续费设置
             * @param data
             */
            export function serviceChargeSet(data: ServiceChargeSetParams) { return request.post<ResponseResult<object>>("/house-v100001/houseConfig/serviceChargeSet", data); }
            export interface SmsStatusParams {
                /**
                 * @description  缴费提前，延后，固守天数 ，天数设置，前端使用自己定义json
                 */
                configJson?: string;
                /**
                 * @description  房屋类型 1-整租 2-合租 3-集中 写死 
                 */
                houseType: string;
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  关联类型 1-房东 2-租客
                 */
                relationType: number;
            }
            /**
             * @name 催租短信开关
             * @param data
             */
            export function smsStatus(data: SmsStatusParams) { return request.post<ResponseResult<object>>("/house-v100001/houseConfig/smsStatus", data); }
        }
        export namespace houseContract {
            export interface DeleteByIdParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 房东续约记录表删除
             * @param params
             */
            export function deleteById(params: DeleteByIdParams) { return request.delete<ResponseResult<object>>("/house-v100001/houseContract/deleteById", { params }); }
            export interface HouseContractReductionParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 房东续约还原,前端需要提示是否确定还原，还原会删除当前账单,需要配置权限
             * @param data
             */
            export function houseContractReduction(data: HouseContractReductionParams) { return request.post<ResponseResult<object>>("/house-v100001/houseContract/houseContractReduction", data); }
            /**
             * @name 房东续约记录表详情
             * @param id 房东续约记录表详情
             */
            export function info(id: string | number) { return request.get<ResponseResult<HouseContract>>(`/house-v100001/houseContract/info/${id}`); }
            export interface ListParams {
                /**
                 * @description  业务员id
                 */
                businessId?: number;
                /**
                 * @description  合同签字 1-未签字 2-已签字
                 */
                contractRecordSignStatus?: number;
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  房源id
                 */
                houseId?: number;
                /**
                 * @description  门牌号、（集中几层或者几栋）
                 */
                houseNum?: string;
                /**
                 * @description  1整租 2合租 3集中
                 */
                houseType?: number;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  合同开始时间报表查询时间
                 */
                reportTimeStart?: string;
                /**
                 * @description  房间号 (合租)/集中 门牌号
                 */
                roomNo?: string;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  物品来源id
                 */
                sourceId?: number;
                /**
                 * @description  租赁开始时间结束
                 */
                startTimeEnd?: string;
                /**
                 * @description  租赁开始时间开始
                 */
                startTimeStart?: string;
                /**
                 * @description  管家id
                 */
                stewardId?: number;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
            }
            /**
             * @name 房东续约记录表列表
             * @param params
             */
            export function list(params: ListParams) { return request.get<ResponseResult<PageList<HouseContract>>>("/house-v100001/houseContract/list", { params }); }
            export interface SaveParams {
                /**
                 * @description  业务人员id
                 */
                businessId: number;
                /**
                 * @description  房东押金
                 */
                depositAmount: number;
                /**
                 * @description  托管结束时间
                 */
                endTime: string;
                /**
                 * @description  免租列表
                 */
                freeList?: string;
                /**
                 * @description  收房价
                 */
                houseAmount: number;
                /**
                 * @description  托管天
                 */
                houseDay?: number;
                /**
                 * @description  房源id
                 */
                houseId: number;
                /**
                 * @description  托管月
                 */
                houseMonth?: number;
                /**
                 * @description  托管年限
                 */
                houseYear?: number;
                /**
                 * @description  递增约定
                 */
                increaseId: number;
                /**
                 * @description  1百分比 2固定金额
                 */
                increaseType?: number;
                /**
                 * @description  维修方案id
                 */
                maintenancePlanId: number;
                /**
                 * @description  缴费日期 1表示提前，2表示延后 3固定日期
                 */
                payType: number;
                /**
                 * @description  缴费日期 提前或者延后多少天
                 */
                payTypeDay: number;
                /**
                 * @description  缴费方式名称
                 */
                payTypeId: number;
                /**
                 * @description  免租期多少天
                 */
                periodDay?: number;
                /**
                 * @description  免租期id
                 */
                periodId: number;
                /**
                 * @description  免租期多少个月
                 */
                periodMonth?: number;
                /**
                 * @description  备注
                 */
                remark?: string;
                /**
                 * @description  托管开始时间
                 */
                startTime: string;
                /**
                 * @description  管家id
                 */
                stewardId?: number;
                /**
                 * @description  第二管家id
                 */
                stewardId2?: number;
                increaseJson: Array<{
                    amount: number;
                    endTime: string;
                    startTime: string;
                    val: number;
                    year: number;
                }>;
                payOtherJson: Array<{
                    code: number;
                    name: string;
                    remarks: string;
                    val: string;
                    val1: string;
                }>;
                reductionOtherJson: Array<{
                    code: number;
                    name: string;
                    remarks: string;
                    val: string;
                    val1: string;
                }>;
            }
            /**
             * @name 房东续约记录表新增
             * @param data
             */
            export function save(data: SaveParams) { return request.post<ResponseResult<House>>("/house-v100001/houseContract/save", data); }
            /**
             * @name setSteward
             */
            export function setSteward() { return request.post("/house-v100001/houseContract/setSteward"); }
        }
        export namespace house {
            /**
             * @name 发送业主租后审核前确认实体类详情
             * @param id 发送业主租后审核前确认实体类详情
             */
            export function isAfterAuditInfo(id: string | number) { return request.get<ResponseResult<IsAfterAuditInfoVo>>(`/house-v100001/house/IsAfterAuditInfo/${id}`); }
            export interface ExportHouseParams {
                /**
                 * @description  区域id
                 */
                areaId?: number;
                /**
                 * @description  审核时间结束
                 */
                auditTimeEnd?: string;
                /**
                 * @description  审核时间开始
                 */
                auditTimeStart?: string;
                /**
                 * @description  业务人员
                 */
                businessId?: number;
                /**
                 * @description  客源人
                 */
                businessId2?: number;
                /**
                 * @description  美好家园：1同步成功 2未同步  3同步失败
                 */
                ccbStatus?: number;
                /**
                 * @description  合同性质id
                 */
                contractId?: number;
                /**
                 * @description  合同签名 1-未签名 2-已签名
                 */
                contractRecordIsSignature?: number;
                /**
                 * @description  创建时间结束
                 */
                createTimeEnd?: string;
                /**
                 * @description  创建时间开始
                 */
                createTimeStart?: string;
                /**
                 * @description  装修类型id(整租，合租)
                 */
                decorateId?: number;
                /**
                 * @description  交割单签名 1-未签名 2-已签名
                 */
                deliveryOrderIsSignature?: number;
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  托管结束开始时间
                 */
                endTimeEnd?: string;
                /**
                 * @description  托管结束开始时间
                 */
                endTimeStart?: string;
                /**
                 * @description  导出报表结束时间（导出管家半年内巡房数据使用）
                 */
                exportTimeEnd?: string;
                /**
                 * @description  导出报表开始时间（导出管家半年内巡房数据使用）
                 */
                exportTimeStart?: string;
                /**
                 * @description  几厅
                 */
                hall?: number;
                /**
                 * @description  房东创建时间结束
                 */
                houseCreateTimeEnd?: string;
                /**
                 * @description  房东创建时间开始
                 */
                houseCreateTimeStart?: string;
                /**
                 * @description  房东姓名
                 */
                houseName?: string;
                /**
                 * @description  房源编号
                 */
                houseNo?: string;
                /**
                 * @description  门牌号
                 */
                houseNum?: string;
                /**
                 * @description  手机号
                 */
                housePhone?: string;
                /**
                 * @description  费用结算单签名 1-未签名 2-已签名
                 */
                houseSettlementIsSignature?: number;
                /**
                 * @description  类型：1整租,2合租，3集中
                 */
                houseType?: number;
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  身份证
                 */
                idCard?: string;
                /**
                 * @description  租后是否审核 1已审核  2未通过  3未审核
                 */
                isAfterAudit?: number;
                /**
                 * @description  租前是否审核 1已审核  2未通过  3未审核
                 */
                isBeforeAudit?: number;
                /**
                 * @description  宽带是否安装:1未安装 2已安装 3已到期
                 */
                isBroadband?: number;
                /**
                 * @description  是否到期 1未到期  2已到期   
                 */
                isEndTime?: number;
                /**
                 * @description  是否提交了退房申请(1,已提交；2未提交)
                 */
                isExit?: number;
                /**
                 * @description  家财险是否购买:1未购买 2已购买 3已到期
                 */
                isInsurance?: number;
                /**
                 * @description  只看自己的
                 */
                isMy?: number;
                /**
                 * @description  是否租完 1已租完 2未租完
                 */
                isRentOut?: number;
                /**
                 * @description  缴费方式名称
                 */
                payCostTypeId?: number;
                /**
                 * @description  推送58的状态：1 已推送 ，2 未推送
                 */
                pushStatus?: string;
                /**
                 * @description  录入报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  报表查询时间(托管结束) 房东合同到期报表
                 */
                reportTimeEnd?: string;
                /**
                 * @description  报表查询时间(托管开始) 收房业绩使用
                 */
                reportTimeStart?: string;
                /**
                 * @description  几室
                 */
                room?: number;
                /**
                 * @description  托管开始结束时间
                 */
                startTimeEnd?: string;
                /**
                 * @description  托管开始时间
                 */
                startTimeStart?: string;
                /**
                 * @description  状态：1正常 2待退房 3冻结
                 */
                status?: number;
                /**
                 * @description  管家id
                 */
                stewardId?: number;
                /**
                 * @description  管家id
                 */
                stewardId2?: number;
                /**
                 * @description  员工分组id
                 */
                storeGroupId?: number;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
                /**
                 * @description  1租前  2租后
                 */
                type?: number;
                /**
                 * @description  几卫
                 */
                who?: number;
            }
            /**
             * @name 导出房源
             * @param params
             */
            export function exportHouse(params: ExportHouseParams) { return request.get("/house-v100001/house/exportHouse", { params }); }
            export interface GetHouseAndExitInfoParams {
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  1或者不传表示房源详情 2表示退房详情，id传退房id
                 */
                type?: number;
            }
            /**
             * @name 房源表详情（新）包含退房查询
             * @param params
             */
            export function getHouseAndExitInfo(params: GetHouseAndExitInfoParams) { return request.get<ResponseResult<HouseVo>>("/house-v100001/house/getHouseAndExitInfo", { params }); }
            export interface HouseSumParams {
                /**
                 * @description  区域id
                 */
                areaId?: number;
                /**
                 * @description  审核时间结束
                 */
                auditTimeEnd?: string;
                /**
                 * @description  审核时间开始
                 */
                auditTimeStart?: string;
                /**
                 * @description  业务人员
                 */
                businessId?: number;
                /**
                 * @description  客源人
                 */
                businessId2?: number;
                /**
                 * @description  美好家园：1同步成功 2未同步  3同步失败
                 */
                ccbStatus?: number;
                /**
                 * @description  合同性质id
                 */
                contractId?: number;
                /**
                 * @description  合同签名 1-未签名 2-已签名
                 */
                contractRecordIsSignature?: number;
                /**
                 * @description  创建时间结束
                 */
                createTimeEnd?: string;
                /**
                 * @description  创建时间开始
                 */
                createTimeStart?: string;
                /**
                 * @description  装修类型id(整租，合租)
                 */
                decorateId?: number;
                /**
                 * @description  交割单签名 1-未签名 2-已签名
                 */
                deliveryOrderIsSignature?: number;
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  托管结束开始时间
                 */
                endTimeEnd?: string;
                /**
                 * @description  托管结束开始时间
                 */
                endTimeStart?: string;
                /**
                 * @description  导出报表结束时间（导出管家半年内巡房数据使用）
                 */
                exportTimeEnd?: string;
                /**
                 * @description  导出报表开始时间（导出管家半年内巡房数据使用）
                 */
                exportTimeStart?: string;
                /**
                 * @description  几厅
                 */
                hall?: number;
                /**
                 * @description  房东创建时间结束
                 */
                houseCreateTimeEnd?: string;
                /**
                 * @description  房东创建时间开始
                 */
                houseCreateTimeStart?: string;
                /**
                 * @description  房东姓名
                 */
                houseName?: string;
                /**
                 * @description  房源编号
                 */
                houseNo?: string;
                /**
                 * @description  门牌号
                 */
                houseNum?: string;
                /**
                 * @description  手机号
                 */
                housePhone?: string;
                /**
                 * @description  费用结算单签名 1-未签名 2-已签名
                 */
                houseSettlementIsSignature?: number;
                /**
                 * @description  类型：1整租,2合租，3集中
                 */
                houseType?: number;
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  身份证
                 */
                idCard?: string;
                /**
                 * @description  租后是否审核 1已审核  2未通过  3未审核
                 */
                isAfterAudit?: number;
                /**
                 * @description  租前是否审核 1已审核  2未通过  3未审核
                 */
                isBeforeAudit?: number;
                /**
                 * @description  宽带是否安装:1未安装 2已安装 3已到期
                 */
                isBroadband?: number;
                /**
                 * @description  是否到期 1未到期  2已到期   
                 */
                isEndTime?: number;
                /**
                 * @description  是否提交了退房申请(1,已提交；2未提交)
                 */
                isExit?: number;
                /**
                 * @description  家财险是否购买:1未购买 2已购买 3已到期
                 */
                isInsurance?: number;
                /**
                 * @description  只看自己的
                 */
                isMy?: number;
                /**
                 * @description  是否租完 1已租完 2未租完
                 */
                isRentOut?: number;
                /**
                 * @description  缴费方式名称
                 */
                payCostTypeId?: number;
                /**
                 * @description  推送58的状态：1 已推送 ，2 未推送
                 */
                pushStatus?: string;
                /**
                 * @description  录入报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  报表查询时间(托管结束) 房东合同到期报表
                 */
                reportTimeEnd?: string;
                /**
                 * @description  报表查询时间(托管开始) 收房业绩使用
                 */
                reportTimeStart?: string;
                /**
                 * @description  几室
                 */
                room?: number;
                /**
                 * @description  托管开始结束时间
                 */
                startTimeEnd?: string;
                /**
                 * @description  托管开始时间
                 */
                startTimeStart?: string;
                /**
                 * @description  状态：1正常 2待退房 3冻结
                 */
                status?: number;
                /**
                 * @description  管家id
                 */
                stewardId?: number;
                /**
                 * @description  管家id
                 */
                stewardId2?: number;
                /**
                 * @description  员工分组id
                 */
                storeGroupId?: number;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
                /**
                 * @description  1租前  2租后
                 */
                type?: number;
                /**
                 * @description  几卫
                 */
                who?: number;
            }
            /**
             * @name 房源列表统计数据
             * @param params
             */
            export function houseSum(params: HouseSumParams) { return request.get<ResponseResult<房源列表统计类>>("/house-v100001/house/houseSum", { params }); }
            /**
             * @name 房源表详情
             * @param id 房源表详情
             */
            export function info(id: string | number) { return request.get<ResponseResult<HouseVo>>(`/house-v100001/house/info/${id}`); }
            export interface ListParams {
                /**
                 * @description  区域id
                 */
                areaId?: number;
                /**
                 * @description  审核时间结束
                 */
                auditTimeEnd?: string;
                /**
                 * @description  审核时间开始
                 */
                auditTimeStart?: string;
                /**
                 * @description  业务人员
                 */
                businessId?: number;
                /**
                 * @description  客源人
                 */
                businessId2?: number;
                /**
                 * @description  美好家园：1同步成功 2未同步  3同步失败
                 */
                ccbStatus?: number;
                /**
                 * @description  合同性质id
                 */
                contractId?: number;
                /**
                 * @description  合同签名 1-未签名 2-已签名
                 */
                contractRecordIsSignature?: number;
                /**
                 * @description  创建时间结束
                 */
                createTimeEnd?: string;
                /**
                 * @description  创建时间开始
                 */
                createTimeStart?: string;
                /**
                 * @description  装修类型id(整租，合租)
                 */
                decorateId?: number;
                /**
                 * @description  交割单签名 1-未签名 2-已签名
                 */
                deliveryOrderIsSignature?: number;
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  托管结束开始时间
                 */
                endTimeEnd?: string;
                /**
                 * @description  托管结束开始时间
                 */
                endTimeStart?: string;
                /**
                 * @description  导出报表结束时间（导出管家半年内巡房数据使用）
                 */
                exportTimeEnd?: string;
                /**
                 * @description  导出报表开始时间（导出管家半年内巡房数据使用）
                 */
                exportTimeStart?: string;
                /**
                 * @description  几厅
                 */
                hall?: number;
                /**
                 * @description  房东创建时间结束
                 */
                houseCreateTimeEnd?: string;
                /**
                 * @description  房东创建时间开始
                 */
                houseCreateTimeStart?: string;
                /**
                 * @description  房东姓名
                 */
                houseName?: string;
                /**
                 * @description  房源编号
                 */
                houseNo?: string;
                /**
                 * @description  门牌号
                 */
                houseNum?: string;
                /**
                 * @description  手机号
                 */
                housePhone?: string;
                /**
                 * @description  费用结算单签名 1-未签名 2-已签名
                 */
                houseSettlementIsSignature?: number;
                /**
                 * @description  类型：1整租,2合租，3集中
                 */
                houseType?: number;
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  身份证
                 */
                idCard?: string;
                /**
                 * @description  租后是否审核 1已审核  2未通过  3未审核
                 */
                isAfterAudit?: number;
                /**
                 * @description  租前是否审核 1已审核  2未通过  3未审核
                 */
                isBeforeAudit?: number;
                /**
                 * @description  宽带是否安装:1未安装 2已安装 3已到期
                 */
                isBroadband?: number;
                /**
                 * @description  是否到期 1未到期  2已到期   
                 */
                isEndTime?: number;
                /**
                 * @description  是否提交了退房申请(1,已提交；2未提交)
                 */
                isExit?: number;
                /**
                 * @description  家财险是否购买:1未购买 2已购买 3已到期
                 */
                isInsurance?: number;
                /**
                 * @description  只看自己的
                 */
                isMy?: number;
                /**
                 * @description  是否租完 1已租完 2未租完
                 */
                isRentOut?: number;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  缴费方式名称
                 */
                payCostTypeId?: number;
                /**
                 * @description  推送58的状态：1 已推送 ，2 未推送
                 */
                pushStatus?: string;
                /**
                 * @description  录入报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  报表查询时间(托管结束) 房东合同到期报表
                 */
                reportTimeEnd?: string;
                /**
                 * @description  报表查询时间(托管开始) 收房业绩使用
                 */
                reportTimeStart?: string;
                /**
                 * @description  几室
                 */
                room?: number;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  托管开始结束时间
                 */
                startTimeEnd?: string;
                /**
                 * @description  托管开始时间
                 */
                startTimeStart?: string;
                /**
                 * @description  状态：1正常 2待退房 3冻结
                 */
                status?: number;
                /**
                 * @description  管家id
                 */
                stewardId?: number;
                /**
                 * @description  管家id
                 */
                stewardId2?: number;
                /**
                 * @description  员工分组id
                 */
                storeGroupId?: number;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
                /**
                 * @description  1租前  2租后
                 */
                type?: number;
                /**
                 * @description  几卫
                 */
                who?: number;
            }
            /**
             * @name 房源表列表
             * @param params
             */
            export function list(params: ListParams) { return request.get<ResponseResult<PageList<House>>>("/house-v100001/house/list", { params }); }
            export interface SaveParams {
                /**
                 * @description  面积
                 */
                acreage?: string;
                /**
                 * @description  区id（通过物业获取）
                 */
                areaId: number;
                /**
                 * @description  银行账号
                 */
                bankAccount?: string;
                /**
                 * @description  银行开户行
                 */
                bankAddr?: string;
                /**
                 * @description  银行收款人姓名
                 */
                bankName?: string;
                /**
                 * @description  银行开户地址
                 */
                bankOpenAddr?: string;
                /**
                 * @description  座栋(修改时回显用) 随便填
                 */
                building?: string;
                /**
                 * @description  业务人员id
                 */
                businessId: number;
                /**
                 * @description  业务员2
                 */
                businessId2: number;
                /**
                 * @description  证件类型字典(601)
                 */
                certificateTypeId?: number;
                /**
                 * @description  合作人姓名
                 */
                collaboratorName?: string;
                /**
                 * @description  合作人电话
                 */
                collaboratorPhone?: string;
                /**
                 * @description  合同性质id
                 */
                contractId: number;
                /**
                 * @description  房东押金
                 */
                depositAmount: number;
                /**
                 * @description  物业地区id
                 */
                detailId: string;
                /**
                 * @description  物业地区名称
                 */
                detailName?: string;
                /**
                 * @description  到家了员工编号
                 */
                djlNo?: string;
                /**
                 * @description  门牌(修改时回显用) 只能2位数字
                 */
                door: string;
                /**
                 * @description  紧急联系人
                 */
                emergencyContact?: string;
                /**
                 * @description  紧急联系人电话
                 */
                emergencyContactPhone?: string;
                /**
                 * @description  托管结束时间 = 托管开始时间加托管年限
                 */
                endTime: string;
                /**
                 * @description  楼层(修改时回显用) 只能2位数字 如果是三位，只能输入一个-
                 */
                floor: string;
                /**
                 * @description  免租列表
                 */
                freeList?: string;
                /**
                 * @description  几厅
                 */
                hall: number;
                /**
                 * @description  收房价
                 */
                houseAmount: number;
                /**
                 * @description  房产证号码
                 */
                houseCardNo?: string;
                /**
                 * @description  托管天
                 */
                houseDay?: number;
                /**
                 * @description  托管月
                 */
                houseMonth?: number;
                /**
                 * @description  房东姓名
                 */
                houseName?: string;
                /**
                 * @description  房东其它信息字段json  [{name:电卡号,val:511001111},{name:物业电话,val:13896046012}]  
                 */
                houseOtherJson?: string;
                /**
                 * @description  手机号
                 */
                housePhone?: string;
                /**
                 * @description  房产证地址
                 */
                housePropertyAddr?: string;
                /**
                 * @description  类型：1整租,2合租，3集中    前端写死，在添加的时候
                 */
                houseType: number;
                /**
                 * @description  托管年限 
                 */
                houseYear?: number;
                /**
                 * @description  身份证
                 */
                idCard?: string;
                /**
                 * @description  证件图片
                 */
                idCardImg?: string;
                /**
                 * @description  递增约定  1表示有递增  2表示无递增
                 */
                increaseId: number;
                /**
                 * @description  递增约定json [{year:1,val:递增值,startTime:2020-2-5,endTime:2020-6-4,amount:5000 }] 
                 */
                increaseJson?: string;
                /**
                 * @description  1百分比 2固定金额
                 */
                increaseType?: number;
                /**
                 * @description  不规则付费列表
                 */
                irregularList?: string;
                /**
                 * @description  房源经度
                 */
                lat?: string;
                /**
                 * @description  房源纬度
                 */
                lng?: string;
                /**
                 * @description  维修方案id
                 */
                maintenancePlanId: number;
                /**
                 * @description  向房东支付json [{name:物业费, val:1、不付费 2、随房租支付 3、一次性付清, val1:100}]
                 */
                payOtherJson?: string;
                /**
                 * @description  缴费日期 1表示提前，2表示延后 3固定日期 (如果选择30，31号，如果这个月最多30号，那么就固定到30，反正没有就向前取一天)
                 */
                payType: number;
                /**
                 * @description  缴费日期 提前或者延后多少天
                 */
                payTypeDay: number;
                /**
                 * @description  缴费方式名称(字典下拉框) value值取remark
                 */
                payTypeId: number;
                /**
                 * @description  免租期多少天
                 */
                periodDay?: number;
                /**
                 * @description  免租期id
                 */
                periodId: number;
                /**
                 * @description  免租期多少个月
                 */
                periodMonth?: number;
                /**
                 * @description  小区名称（输入框）默认获取物业地址具体地址
                 */
                plotName?: string;
                /**
                 * @description  扣减房东json  [{name:水费, val:1、不付费 2、首次房租扣除 3、第二次房租扣除, val1:100}]
                 */
                reductionOtherJson?: string;
                /**
                 * @description  备注
                 */
                remark?: string;
                /**
                 * @description  几室
                 */
                room: number;
                /**
                 * @description  托管开始时间
                 */
                startTime: string;
                /**
                 * @description  管家id 必填
                 */
                stewardId?: number;
                /**
                 * @description  管家id2 必填
                 */
                stewardId2?: number;
                /**
                 * @description  员工分组id
                 */
                storeGroupId?: number;
                /**
                 * @description  门店id
                 */
                storeId: number;
                /**
                 * @description  房源类型id(146,清水房;147,精装房;148,简装房)
                 */
                typeId: number;
                /**
                 * @description  单元(修改时回显用) 随便填
                 */
                unit?: string;
                /**
                 * @description  几卫
                 */
                who: number;
            }
            /**
             * @name 房源表新增
             * @param data
             */
            export function save(data: SaveParams) { return request.post<ResponseResult<House>>("/house-v100001/house/save", data); }
            /**
             * @name saveLouPan
             */
            export function saveLouPan() { return request.post("/house-v100001/house/saveLouPan"); }
            /**
             * @name 发送租后审核
             * @param id 发送租后审核
             */
            export function sendIsAfterAudit(id: string | number) { return request.post<ResponseResult<object>>(`/house-v100001/house/sendIsAfterAudit/${id}`); }
            /**
             * @name upadateOtherStatus
             */
            export function upadateOtherStatus() { return request.post<ResponseResult<object>>("/house-v100001/house/upadateOtherStatus"); }
            /**
             * @name upadateTenantsOtherStatus
             */
            export function upadateTenantsOtherStatus() { return request.post<ResponseResult<object>>("/house-v100001/house/upadateTenantsOtherStatus"); }
            export interface UpdateParams {
                /**
                 * @description  面积
                 */
                acreage?: string;
                /**
                 * @description  区id（通过物业获取）
                 */
                areaId: number;
                /**
                 * @description  银行账号
                 */
                bankAccount?: string;
                /**
                 * @description  银行开户行
                 */
                bankAddr?: string;
                /**
                 * @description  银行收款人姓名
                 */
                bankName?: string;
                /**
                 * @description  银行开户地址
                 */
                bankOpenAddr?: string;
                /**
                 * @description  座栋(修改时回显用) 随便填
                 */
                building?: string;
                /**
                 * @description  业务人员id
                 */
                businessId: number;
                /**
                 * @description  业务员2
                 */
                businessId2: number;
                /**
                 * @description  证件类型字典(601)
                 */
                certificateTypeId?: number;
                /**
                 * @description  合作人姓名
                 */
                collaboratorName?: string;
                /**
                 * @description  合作人电话
                 */
                collaboratorPhone?: string;
                /**
                 * @description  合同性质id
                 */
                contractId: number;
                /**
                 * @description  房东押金
                 */
                depositAmount: number;
                /**
                 * @description  物业地区id
                 */
                detailId: string;
                /**
                 * @description  物业地区名称
                 */
                detailName?: string;
                /**
                 * @description  到家了员工编号
                 */
                djlNo?: string;
                /**
                 * @description  门牌(修改时回显用) 只能2位数字
                 */
                door: string;
                /**
                 * @description  紧急联系人
                 */
                emergencyContact?: string;
                /**
                 * @description  紧急联系人电话
                 */
                emergencyContactPhone?: string;
                /**
                 * @description  托管结束时间 = 托管开始时间加托管年限
                 */
                endTime: string;
                /**
                 * @description  楼层(修改时回显用) 只能2位数字 如果是三位，只能输入一个-
                 */
                floor: string;
                /**
                 * @description  免租列表
                 */
                freeList?: string;
                /**
                 * @description  几厅
                 */
                hall: number;
                /**
                 * @description  收房价
                 */
                houseAmount: number;
                /**
                 * @description  房产证号码
                 */
                houseCardNo?: string;
                /**
                 * @description  托管天
                 */
                houseDay?: number;
                /**
                 * @description  托管月
                 */
                houseMonth?: number;
                /**
                 * @description  房东姓名
                 */
                houseName?: string;
                /**
                 * @description  房东其它信息字段json  [{name:电卡号,val:511001111},{name:物业电话,val:13896046012}]  
                 */
                houseOtherJson?: string;
                /**
                 * @description  手机号
                 */
                housePhone?: string;
                /**
                 * @description  房产证地址
                 */
                housePropertyAddr?: string;
                /**
                 * @description  类型：1整租,2合租，3集中    前端写死，在添加的时候
                 */
                houseType: number;
                /**
                 * @description  托管年限 
                 */
                houseYear?: number;
                /**
                 * @description  身份证
                 */
                idCard?: string;
                /**
                 * @description  证件图片
                 */
                idCardImg?: string;
                /**
                 * @description  递增约定  1表示有递增  2表示无递增
                 */
                increaseId: number;
                /**
                 * @description  递增约定json [{year:1,val:递增值,startTime:2020-2-5,endTime:2020-6-4,amount:5000 }] 
                 */
                increaseJson?: string;
                /**
                 * @description  1百分比 2固定金额
                 */
                increaseType?: number;
                /**
                 * @description  不规则付费列表
                 */
                irregularList?: string;
                /**
                 * @description  房源经度
                 */
                lat?: string;
                /**
                 * @description  房源纬度
                 */
                lng?: string;
                /**
                 * @description  维修方案id
                 */
                maintenancePlanId: number;
                /**
                 * @description  向房东支付json [{name:物业费, val:1、不付费 2、随房租支付 3、一次性付清, val1:100}]
                 */
                payOtherJson?: string;
                /**
                 * @description  缴费日期 1表示提前，2表示延后 3固定日期 (如果选择30，31号，如果这个月最多30号，那么就固定到30，反正没有就向前取一天)
                 */
                payType: number;
                /**
                 * @description  缴费日期 提前或者延后多少天
                 */
                payTypeDay: number;
                /**
                 * @description  缴费方式名称(字典下拉框) value值取remark
                 */
                payTypeId: number;
                /**
                 * @description  免租期多少天
                 */
                periodDay?: number;
                /**
                 * @description  免租期id
                 */
                periodId: number;
                /**
                 * @description  免租期多少个月
                 */
                periodMonth?: number;
                /**
                 * @description  小区名称（输入框）默认获取物业地址具体地址
                 */
                plotName?: string;
                /**
                 * @description  扣减房东json  [{name:水费, val:1、不付费 2、首次房租扣除 3、第二次房租扣除, val1:100}]
                 */
                reductionOtherJson?: string;
                /**
                 * @description  备注
                 */
                remark?: string;
                /**
                 * @description  几室
                 */
                room: number;
                /**
                 * @description  托管开始时间
                 */
                startTime: string;
                /**
                 * @description  管家id 必填
                 */
                stewardId?: number;
                /**
                 * @description  管家id2 必填
                 */
                stewardId2?: number;
                /**
                 * @description  员工分组id
                 */
                storeGroupId?: number;
                /**
                 * @description  门店id
                 */
                storeId: number;
                /**
                 * @description  房源类型id(146,清水房;147,精装房;148,简装房)
                 */
                typeId: number;
                /**
                 * @description  单元(修改时回显用) 随便填
                 */
                unit?: string;
                /**
                 * @description  几卫
                 */
                who: number;
            }
            /**
             * @name 房源表修改：1表示提示用户是否确定修改，2表示只需要弹出提示信息然后返回详情
             * @param id 房源表修改：1表示提示用户是否确定修改，2表示只需要弹出提示信息然后返回详情
             * @param data
             */
            export function update(id: string | number, data: UpdateParams) { return request.put<ResponseResult<UpdateMessage>>(`/house-v100001/house/update/${id}`, data); }
        }
        export namespace houseExit {
            export interface DeleteByIdParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 房源退房记录删除
             * @param params
             */
            export function deleteById(params: DeleteByIdParams) { return request.delete<ResponseResult<object>>("/house-v100001/houseExit/deleteById", { params }); }
            /**
             * @name 房源退房记录详情
             * @param id 房源退房记录详情
             */
            export function info(id: string | number) { return request.get<ResponseResult<HouseExitVo>>(`/house-v100001/houseExit/info/${id}`); }
            export interface ListParams {
                /**
                 * @description  审核时间结束
                 */
                auditTimeEnd?: string;
                /**
                 * @description  审核时间开始
                 */
                auditTimeStart?: string;
                /**
                 * @description  合同性质id
                 */
                contractId?: number;
                /**
                 * @description  创建时间结束
                 */
                crateTimeEnd?: string;
                /**
                 * @description  创建时间开始
                 */
                crateTimeStart?: string;
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  租赁结束时间结束
                 */
                endTimeEnd?: string;
                /**
                 * @description  租赁结束时间开始
                 */
                endTimeStart?: string;
                /**
                 * @description  1 正常退房 2没有签成 3房东违约 4公司违约
                 */
                exitId?: number;
                /**
                 * @description  退房时间结束
                 */
                exitTimeEnd?: string;
                /**
                 * @description  退房时间开始
                 */
                exitTimeStart?: string;
                /**
                 * @description  房源id
                 */
                houseId?: number;
                /**
                 * @description  门牌号、（集中几层或者几栋）
                 */
                houseNum?: string;
                /**
                 * @description  1整租 2合租 3集中
                 */
                houseType?: number;
                /**
                 * @description  是否审核 1已审核  2驳回  3未审核
                 */
                isAudit?: number;
                /**
                 * @description  姓名
                 */
                name?: string;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  支付状态 1未退  2已退部分  3已退完
                 */
                payStatus?: number;
                /**
                 * @description  电话
                 */
                phone?: string;
                /**
                 * @description  报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  房间号 (合租)/集中 门牌号
                 */
                roomNo?: string;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  租赁开始时间结束
                 */
                startTimeEnd?: string;
                /**
                 * @description  租赁开始时间开始
                 */
                startTimeStart?: string;
                /**
                 * @description  管家确认无误：1,无误；2，未确认;3,有误
                 */
                stewardConfirm?: number;
                /**
                 * @description  管家id
                 */
                stewardId?: number;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
            }
            /**
             * @name 房源退房记录列表
             * @param params
             */
            export function list(params: ListParams) { return request.get<ResponseResult<PageList<HouseExit>>>("/house-v100001/houseExit/list", { params }); }
            export interface ReductionParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 房源退房还原
             * @param data
             */
            export function reduction(data: ReductionParams) { return request.post<ResponseResult<object>>("/house-v100001/houseExit/reduction", data); }
            export interface ReductionPayStatusParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 还原退款状态未退
             * @param data
             */
            export function reductionPayStatus(data: ReductionPayStatusParams) { return request.post<ResponseResult<object>>("/house-v100001/houseExit/reductionPayStatus", data); }
            export interface SaveParams {
                /**
                 * @description  公司赔偿金额(类型为公司违约时有)
                 */
                companyReparations?: number;
                /**
                 * @description  扣除金额
                 */
                deductionSunAmount: number;
                /**
                 * @description  应退押金
                 */
                depositAmount: number;
                /**
                 * @description  电滞纳金
                 */
                electricityLateAmount?: number;
                /**
                 * @description  电单价
                 */
                electricityPrice?: number;
                /**
                 * @description  电总价
                 */
                electricitySumAmount?: number;
                /**
                 * @description  电本次底数
                 */
                electricityThisNum?: number;
                /**
                 * @description  电上次底数
                 */
                electricityUpNum?: number;
                /**
                 * @description  应退能源费
                 */
                energyAmount: number;
                /**
                 * @description  退房性质Id
                 */
                exitId: number;
                /**
                 * @description  退房时间(前端默认为今天)
                 */
                exitTime?: string;
                /**
                 * @description  气滞纳金
                 */
                gasLateAmount?: number;
                /**
                 * @description  气单价
                 */
                gasPrice?: number;
                /**
                 * @description  气总价
                 */
                gasSumAmount?: number;
                /**
                 * @description  气本次底数
                 */
                gasThisNum?: number;
                /**
                 * @description  气上次底数
                 */
                gasUpNum?: number;
                /**
                 * @description  房源id 
                 */
                houseId: number;
                /**
                 * @description  房东赔偿金额 (类型为房东违约时有)
                 */
                houseReparations?: number;
                /**
                 * @description  其它应退
                 */
                otherExitAmount?: number;
                /**
                 * @description  物管费欠费天
                 */
                propertyDay?: number;
                /**
                 * @description  物管费滞纳金
                 */
                propertyLateAmount?: number;
                /**
                 * @description  物管费欠费月
                 */
                propertyMonth?: number;
                /**
                 * @description  物管费单价
                 */
                propertyPrice?: number;
                /**
                 * @description  物管费欠费金额
                 */
                propertySumAmount?: number;
                /**
                 * @description  物管费截至日期
                 */
                propertyTime?: string;
                /**
                 * @description  实际退款金额
                 */
                refundAmount: number;
                /**
                 * @description  退房备注
                 */
                remarks?: string;
                /**
                 * @description  卖给房东物品
                 */
                sellHouseGoods?: number;
                /**
                 * @description  应退金额
                 */
                shouldBackAmount: number;
                /**
                 * @description  剩余房租
                 */
                tenantsAmount?: number;
                /**
                 * @description  水滞纳金
                 */
                waterLateAmount?: number;
                /**
                 * @description  水单价
                 */
                waterPrice?: number;
                /**
                 * @description  水总价
                 */
                waterSumAmount?: number;
                /**
                 * @description  水本次底数
                 */
                waterThisNum?: number;
                /**
                 * @description  水上次底数
                 */
                waterUpNum?: number;
                otherDeductionJson: Array<{
                    code: number;
                    name: string;
                    remarks: string;
                    val: string;
                }>;
                otherJson: Array<{
                    code: number;
                    name: string;
                    remarks: string;
                    val: string;
                }>;
            }
            /**
             * @name 房源退房记录新增
             * @param data
             */
            export function save(data: SaveParams) { return request.post<ResponseResult<object>>("/house-v100001/houseExit/save", data); }
            export interface UpdateParams {
                /**
                 * @description  公司赔偿金额(类型为公司违约时有)
                 */
                companyReparations?: number;
                /**
                 * @description  扣除金额
                 */
                deductionSunAmount: number;
                /**
                 * @description  应退押金
                 */
                depositAmount: number;
                /**
                 * @description  电滞纳金
                 */
                electricityLateAmount?: number;
                /**
                 * @description  电单价
                 */
                electricityPrice?: number;
                /**
                 * @description  电总价
                 */
                electricitySumAmount?: number;
                /**
                 * @description  电本次底数
                 */
                electricityThisNum?: number;
                /**
                 * @description  电上次底数
                 */
                electricityUpNum?: number;
                /**
                 * @description  应退能源费
                 */
                energyAmount: number;
                /**
                 * @description  退房性质Id
                 */
                exitId: number;
                /**
                 * @description  退房时间(前端默认为今天)
                 */
                exitTime?: string;
                /**
                 * @description  气滞纳金
                 */
                gasLateAmount?: number;
                /**
                 * @description  气单价
                 */
                gasPrice?: number;
                /**
                 * @description  气总价
                 */
                gasSumAmount?: number;
                /**
                 * @description  气本次底数
                 */
                gasThisNum?: number;
                /**
                 * @description  气上次底数
                 */
                gasUpNum?: number;
                /**
                 * @description  房源id 
                 */
                houseId: number;
                /**
                 * @description  房东赔偿金额 (类型为房东违约时有)
                 */
                houseReparations?: number;
                /**
                 * @description  其它应退
                 */
                otherExitAmount?: number;
                /**
                 * @description  物管费欠费天
                 */
                propertyDay?: number;
                /**
                 * @description  物管费滞纳金
                 */
                propertyLateAmount?: number;
                /**
                 * @description  物管费欠费月
                 */
                propertyMonth?: number;
                /**
                 * @description  物管费单价
                 */
                propertyPrice?: number;
                /**
                 * @description  物管费欠费金额
                 */
                propertySumAmount?: number;
                /**
                 * @description  物管费截至日期
                 */
                propertyTime?: string;
                /**
                 * @description  实际退款金额
                 */
                refundAmount: number;
                /**
                 * @description  退房备注
                 */
                remarks?: string;
                /**
                 * @description  卖给房东物品
                 */
                sellHouseGoods?: number;
                /**
                 * @description  应退金额
                 */
                shouldBackAmount: number;
                /**
                 * @description  剩余房租
                 */
                tenantsAmount?: number;
                /**
                 * @description  水滞纳金
                 */
                waterLateAmount?: number;
                /**
                 * @description  水单价
                 */
                waterPrice?: number;
                /**
                 * @description  水总价
                 */
                waterSumAmount?: number;
                /**
                 * @description  水本次底数
                 */
                waterThisNum?: number;
                /**
                 * @description  水上次底数
                 */
                waterUpNum?: number;
                otherDeductionJson: Array<{
                    code: number;
                    name: string;
                    remarks: string;
                    val: string;
                }>;
                otherJson: Array<{
                    code: number;
                    name: string;
                    remarks: string;
                    val: string;
                }>;
            }
            /**
             * @name 房源退房记录修改
             * @param id 房源退房记录修改
             * @param data
             */
            export function update(id: string | number, data: UpdateParams) { return request.put<ResponseResult<object>>(`/house-v100001/houseExit/update/${id}`, data); }
        }
        export namespace houseHis {
            /**
             * @name 房源历史表详情
             * @param id 房源历史表详情
             */
            export function info(id: string | number) { return request.get<ResponseResult<HouseHis>>(`/house-v100001/houseHis/info/${id}`); }
            export interface ListParams {
                /**
                 * @description  区id(通过物业地址获取)
                 */
                areaId?: number;
                /**
                 * @description  业务人员id
                 */
                businessId?: number;
                /**
                 * @description  协助人员2
                 */
                businessId2?: number;
                /**
                 * @description  合同性质id
                 */
                contractId?: number;
                /**
                 * @description  合同签字 1-未签字 2-已签字
                 */
                contractRecordSignStatus?: number;
                /**
                 * @description  签字时间结束
                 */
                contractRecordSignTimeEnd?: string;
                /**
                 * @description  签字时间开始
                 */
                contractRecordSignTimeStart?: string;
                /**
                 * @description  创建时间结束
                 */
                createTimeEnd?: string;
                /**
                 * @description  创建时间开始
                 */
                createTimeStart?: string;
                /**
                 * @description  交割单签 1已签字 2废弃 3待签字
                 */
                deliveryOrderSignStatus?: number;
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  托管结束开始时间
                 */
                endTimeEnd?: string;
                /**
                 * @description  托管结束开始时间
                 */
                endTimeStart?: string;
                /**
                 * @description  几厅
                 */
                hall?: number;
                /**
                 * @description  房东姓名
                 */
                houseName?: string;
                /**
                 * @description  门牌号、（集中几层或者几栋）
                 */
                houseNum?: string;
                /**
                 * @description  手机号
                 */
                housePhone?: string;
                /**
                 * @description  类型：1整租,2合租，3集中
                 */
                houseType?: number;
                /**
                 * @description  是否已上传证件图片:2未上传
                 */
                idCardImgStatus?: number;
                /**
                 * @description  租后是否审核 1已审核  2未通过  3未审核
                 */
                isAfterAudit?: number;
                /**
                 * @description  租前是否审核 1已审核  2未通过  3未审核
                 */
                isBeforeAudit?: number;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  缴费方式间隔(月)
                 */
                payTypeId?: number;
                /**
                 * @description  报表录入时间查询
                 */
                reportTime?: string;
                /**
                 * @description  报表查询时间(托管结束) 房东合同到期报表
                 */
                reportTimeEnd?: string;
                /**
                 * @description  报表查询时间(托管开始) 收房业绩使用
                 */
                reportTimeStart?: string;
                /**
                 * @description  几室
                 */
                room?: number;
                /**
                 * @description  结算单签字状态：1已签字，2未签字
                 */
                settlementSignStatus?: number;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  托管开始结束时间
                 */
                startTimeEnd?: string;
                /**
                 * @description  托管开始时间
                 */
                startTimeStart?: string;
                /**
                 * @description  1新签 2续约 3退房
                 */
                statusHisId?: number;
                /**
                 * @description  管家id
                 */
                stewardId?: number;
                /**
                 * @description  第二管家id
                 */
                stewardId2?: number;
                /**
                 * @description  员工分组id
                 */
                storeGroupId?: number;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
                /**
                 * @description  房源类型id
                 */
                typeId?: number;
                /**
                 * @description  几卫
                 */
                who?: number;
            }
            /**
             * @name 房源历史表列表
             * @param params
             */
            export function list(params: ListParams) { return request.get<ResponseResult<PageList<HouseHis>>>("/house-v100001/houseHis/list", { params }); }
        }
        export namespace app {
            export namespace maintenanceClean {
                export interface ListParams {
                    pageNo?: number;
                    pageSize?: number;
                    /**
                     * @description  报表查询时间
                     */
                    reportTime?: number;
                    sortField?: string;
                    sortWay?: string;
                    /**
                     * @description  1未处理，2已处理
                     */
                    status?: number;
                    /**
                     * @description  1表示维修 2保洁
                     */
                    typeId?: number;
                    /**
                     * @description  TABLE选项卡：1全部维修/保洁，2房间维修/保洁，3我的维修/保洁
                     */
                    typeTable?: number;
                }
                /**
                 * @name TABLE选项卡：1全部维修/保洁，2房间维修/保洁，3我的维修/保洁
                 * @param params
                 */
                export function list(params: ListParams) { return request.get<ResponseResult<PageList<MaintenanceClean>>>("/house-v100001/app/maintenanceClean/list", { params }); }
                export interface SaveMaintenanceCleanParams {
                    /**
                     * @description  保洁档次Id
                     */
                    cleanGradeId?: number;
                    /**
                     * @description  处理人id
                     */
                    dealId?: number;
                    /**
                     * @description  事件类型id(下拉字典) 维修与保洁不同的字典
                     */
                    eventId?: number;
                    /**
                     * @description  期望时间(选择天，小时，分与秒不要传00)
                     */
                    expectTime: string;
                    /**
                     * @description  报修前图片
                     */
                    oldImg?: string;
                    /**
                     * @description  内容
                     */
                    remark?: string;
                    /**
                     * @description  清洗类目ids
                     */
                    repairCategoryIds?: string;
                    /**
                     * @description  客户电话
                     */
                    tenantsPhone?: string;
                    /**
                     * @description  1表示维修 2保洁
                     */
                    typeId: number;
                    /**
                     * @description  保洁单价（每小时）
                     */
                    unitPrice?: number;
                    /**
                     * @description  员工工时
                     */
                    workHour?: number;
                }
                /**
                 * @name 维修保洁新增
                 * @param data
                 */
                export function saveMaintenanceClean(data: SaveMaintenanceCleanParams) { return request.post<ResponseResult<MaintenanceClean>>("/house-v100001/app/maintenanceClean/saveMaintenanceClean", data); }
                export interface SaveWashParams {
                    /**
                     * @description  保洁档次Id
                     */
                    cleanGradeId?: number;
                    /**
                     * @description  处理人id
                     */
                    dealId?: number;
                    /**
                     * @description  事件类型id(下拉字典) 维修与保洁不同的字典
                     */
                    eventId?: number;
                    /**
                     * @description  期望时间(选择天，小时，分与秒不要传00)
                     */
                    expectTime: string;
                    /**
                     * @description  报修前图片
                     */
                    oldImg?: string;
                    /**
                     * @description  内容
                     */
                    remark?: string;
                    /**
                     * @description  清洗类目ids
                     */
                    repairCategoryIds?: string;
                    /**
                     * @description  客户电话
                     */
                    tenantsPhone?: string;
                    /**
                     * @description  1表示维修 2保洁
                     */
                    typeId: number;
                    /**
                     * @description  保洁单价（每小时）
                     */
                    unitPrice?: number;
                    /**
                     * @description  员工工时
                     */
                    workHour?: number;
                }
                /**
                 * @name 清洗新增
                 * @param data
                 */
                export function saveWash(data: SaveWashParams) { return request.post<ResponseResult<MaintenanceClean>>("/house-v100001/app/maintenanceClean/saveWash", data); }
                export interface ScoreParams {
                    /**
                     * @description  主键
                     */
                    id: number;
                    /**
                     * @description  星评
                     */
                    score: number;
                    /**
                     * @description  评价说明
                     */
                    scoreDesc?: string;
                    /**
                     * @description  是否私下收费：1是，2否 默认否
                     */
                    visitIsCharge: number;
                    /**
                     * @description  是否按时间完成：1是，2否  默认是
                     */
                    visitOnTime: number;
                }
                /**
                 * @name 租客：评价维修保洁
                 * @param data
                 */
                export function score(data: ScoreParams) { return request.post<ResponseResult<object>>("/house-v100001/app/maintenanceClean/score", data); }
                export interface VisitParams {
                    /**
                     * @description  主键
                     */
                    id: number;
                    /**
                     * @description  回访结果：pid:689 默认已处理
                     */
                    visitDealStatusId: number;
                    /**
                     * @description  是否私下收费：1是，2否 默认否
                     */
                    visitIsCharge: number;
                    /**
                     * @description  是否按时间完成：1是，2否  默认是
                     */
                    visitOnTime: number;
                    /**
                     * @description  回访备注
                     */
                    visitRemark?: string;
                    /**
                     * @description  服务星评：1特别差,2差，3一般，4好，5非常好
                     */
                    visitServiceId: number;
                }
                /**
                 * @name 员工回访功能，租客评价
                 * @param data
                 */
                export function visit(data: VisitParams) { return request.post<ResponseResult<object>>("/house-v100001/app/maintenanceClean/visit", data); }
            }
        }
        export namespace maintenanceClean {
            export interface CleanerUpdateParams {
                /**
                 * @description  保洁人员是否处理完成订单：1.为是，2为不是
                 */
                cleanType?: number;
                /**
                 * @description  处理说明
                 */
                dealDesc?: string;
                /**
                 * @description  主键
                 */
                id: number;
                /**
                 * @description  处理后图片
                 */
                newImg?: string;
                /**
                 * @description  员工工时
                 */
                workHour?: number;
            }
            /**
             * @name 保洁人员保洁完成添加信息
             * @param data
             */
            export function cleanerUpdate(data: CleanerUpdateParams) { return request.post<ResponseResult<object>>("/house-v100001/maintenanceClean/CleanerUpdate", data); }
            export interface OtherSuspendListParams {
                /**
                 * @description  维修id
                 */
                otherId?: number;
                pageNo?: number;
                pageSize?: number;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  维修类型id：1，为内部维修。2，为外部维修
                 */
                typeId?: number;
            }
            /**
             * @name 暂停列表
             * @param params
             */
            export function otherSuspendList(params: OtherSuspendListParams) { return request.get<ResponseResult<PageList<OtherSuspend>>>("/house-v100001/maintenanceClean/OtherSuspendList", { params }); }
            export interface AuditParams {
                /**
                 * @description  审核说明
                 */
                auditDesc?: string;
                /**
                 * @description  处理人id
                 */
                dealId?: number;
                /**
                 * @description  主键
                 */
                id: number;
                /**
                 * @description  1表示通过，2未通过   3未审核（微信报修才有）
                 */
                isAudit: number;
                /**
                 * @description  责任归属(租客，房东，公司)
                 */
                liabilityId?: number;
            }
            /**
             * @name 维修保洁审核
             * @param data
             */
            export function audit(data: AuditParams) { return request.post<ResponseResult<object>>("/house-v100001/maintenanceClean/audit", data); }
            export interface DealParams {
                /**
                 * @description  材料费金额(非公司)
                 */
                dealAmount?: number;
                /**
                 * @description  非公司材料提成
                 */
                dealCommission?: number;
                /**
                 * @description  处理说明
                 */
                dealDesc?: string;
                /**
                 * @description  主键
                 */
                id: number;
                /**
                 * @description  维修材料(非公司)
                 */
                material?: string;
                /**
                 * @description  处理后图片
                 */
                newImg?: string;
                /**
                 * @description  本单提成
                 */
                orderCommission?: number;
                maintenanceDealMaterialDtoList: Array<{
                    commission: number;
                    id: number;
                    name: string;
                    num: number;
                    price: number;
                    sumAmount: number;
                }>;
            }
            /**
             * @name 维修人反馈处理结果
             * @param data
             */
            export function deal(data: DealParams) { return request.post<ResponseResult<object>>("/house-v100001/maintenanceClean/deal", data); }
            export interface DealCleanParams {
                /**
                 * @description  公司承担费用
                 */
                companyAmount?: number;
                /**
                 * @description  处理说明
                 */
                dealDesc?: string;
                /**
                 * @description  主键
                 */
                id: number;
                /**
                 * @description  处理后图片
                 */
                newImg?: string;
                /**
                 * @description  业主承担金额
                 */
                ownerAmount?: number;
                /**
                 * @description  房东是否生成欠款(1，生成；2，不生成)
                 */
                roomStatus?: string;
                /**
                 * @description  租客承担费用
                 */
                tenantsAmount?: number;
                /**
                 * @description  租客是否生成欠款(1，生成；2，不生成)
                 */
                tenantsStatus?: string;
                /**
                 * @description  员工工时
                 */
                workHour?: number;
            }
            /**
             * @name 保洁反馈处理结果
             * @param data
             */
            export function dealClean(data: DealCleanParams) { return request.post<ResponseResult<object>>("/house-v100001/maintenanceClean/dealClean", data); }
            export interface DealWashParams {
                /**
                 * @description  公司承担费用
                 */
                companyAmount?: number;
                /**
                 * @description  处理说明
                 */
                dealDesc?: string;
                /**
                 * @description  主键
                 */
                id: number;
                /**
                 * @description  处理后图片
                 */
                newImg?: string;
                /**
                 * @description  业主承担金额
                 */
                ownerAmount?: number;
                /**
                 * @description  房东是否生成欠款(1，生成；2，不生成)
                 */
                roomStatus?: string;
                /**
                 * @description  租客承担费用
                 */
                tenantsAmount?: number;
                /**
                 * @description  租客是否生成欠款(1，生成；2，不生成)
                 */
                tenantsStatus?: string;
                /**
                 * @description  员工工时
                 */
                workHour?: number;
            }
            /**
             * @name 清洗反馈处理结果
             * @param data
             */
            export function dealWash(data: DealWashParams) { return request.post<ResponseResult<object>>("/house-v100001/maintenanceClean/dealWash", data); }
            export interface DeleteByIdParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 维修保洁删除
             * @param params
             */
            export function deleteById(params: DeleteByIdParams) { return request.delete<ResponseResult<object>>("/house-v100001/maintenanceClean/deleteById", { params }); }
            export interface ExportExcelParams {
                /**
                 * @description  保洁人员是否处理完成订单：1.为是，2为不是
                 */
                cleanType?: number;
                /**
                 * @description  保洁人员完成保洁时间
                 */
                cleanerDealTimeEnd?: string;
                /**
                 * @description  保洁人员完成保洁时间
                 */
                cleanerDealTimeStart?: string;
                /**
                 * @description  创建开始结束时间
                 */
                createTimeEnd?: string;
                /**
                 * @description  创建开始时间
                 */
                createTimeStart?: string;
                /**
                 * @description  处理人id
                 */
                dealId?: number;
                /**
                 * @description  处理时间开始时间
                 */
                dealTimeEnd?: string;
                /**
                 * @description  处理时间开始时间
                 */
                dealTimeStart?: string;
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  期望时间，前传今天，明天 这种要自己处理今天0点 结束为23点，或者自己选
                 */
                expectTimeEnd?: string;
                /**
                 * @description  期望时间，前传今天，明天，或者自己选
                 */
                expectTimeStart?: string;
                /**
                 * @description  房源id(列表使用) 
                 */
                houseId?: number;
                /**
                 * @description  房源编号
                 */
                houseNo?: string;
                /**
                 * @description  门牌号
                 */
                houseNum?: string;
                /**
                 * @description  1表示通过，2未通过   3未审核（微信报修才有）
                 */
                isAudit?: number;
                /**
                 * @description  是否查询自己的 1表示我的，2取消）
                 */
                isMy?: number;
                /**
                 * @description  1表示微信报修，2表示公司派单
                 */
                isWeixin?: number;
                /**
                 * @description  来源(1：租客业主端，2：管理端)
                 */
                orderSource?: number;
                /**
                 * @description  支付状态:1为未支付，2为已支付
                 */
                payStatus?: string;
                /**
                 * @description  报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  房间id(列表使用) 
                 */
                roomId?: number;
                /**
                 * @description  房间号(合租)
                 */
                roomNo?: string;
                /**
                 * @description  星评
                 */
                score?: number;
                /**
                 * @description  1未处理，2已处理 3处理中
                 */
                status?: number;
                /**
                 * @description  管家id
                 */
                stewardId?: number;
                /**
                 * @description  第二管家id
                 */
                stewardId2?: number;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
                /**
                 * @description  客户名称
                 */
                tenantsName?: string;
                /**
                 * @description  客户电话
                 */
                tenantsPhone?: string;
                /**
                 * @description  1表示维修 2保洁  3清洗
                 */
                typeId?: number;
                /**
                 * @description  核实状态：1，未核实；2，已核实
                 */
                verifyStatus?: number;
                /**
                 * @description  回访结果：pid:689
                 */
                visitDealStatusId?: number;
                /**
                 * @description  是否私下收费：1是，2否
                 */
                visitIsCharge?: number;
                /**
                 * @description  是否按时间完：1是，2否
                 */
                visitOnTime?: number;
                /**
                 * @description  服务星评：1特别差,2差，3一般，4好，5非常好
                 */
                visitServiceId?: number;
                /**
                 * @description  回访状态：1未回访 2已回访
                 */
                visitStatus?: number;
                /**
                 * @description  回访时间结束
                 */
                visitTimeEndTime?: string;
                /**
                 * @description  回访时间开始
                 */
                visitTimeStartTime?: string;
                /**
                 * @description  回访人
                 */
                visitUserId?: number;
            }
            /**
             * @name 维修保洁报表导出
             * @param params
             */
            export function exportExcel(params: ExportExcelParams) { return request.get("/house-v100001/maintenanceClean/exportExcel", { params }); }
            /**
             * @name 维修保洁详情
             * @param id 维修保洁详情
             */
            export function info(id: string | number) { return request.get<ResponseResult<MaintenanceClean>>(`/house-v100001/maintenanceClean/info/${id}`); }
            export interface ListParams {
                /**
                 * @description  保洁人员是否处理完成订单：1.为是，2为不是
                 */
                cleanType?: number;
                /**
                 * @description  保洁人员完成保洁时间
                 */
                cleanerDealTimeEnd?: string;
                /**
                 * @description  保洁人员完成保洁时间
                 */
                cleanerDealTimeStart?: string;
                /**
                 * @description  创建开始结束时间
                 */
                createTimeEnd?: string;
                /**
                 * @description  创建开始时间
                 */
                createTimeStart?: string;
                /**
                 * @description  处理人id
                 */
                dealId?: number;
                /**
                 * @description  处理时间开始时间
                 */
                dealTimeEnd?: string;
                /**
                 * @description  处理时间开始时间
                 */
                dealTimeStart?: string;
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  期望时间，前传今天，明天 这种要自己处理今天0点 结束为23点，或者自己选
                 */
                expectTimeEnd?: string;
                /**
                 * @description  期望时间，前传今天，明天，或者自己选
                 */
                expectTimeStart?: string;
                /**
                 * @description  房源id(列表使用) 
                 */
                houseId?: number;
                /**
                 * @description  房源编号
                 */
                houseNo?: string;
                /**
                 * @description  门牌号
                 */
                houseNum?: string;
                /**
                 * @description  1表示通过，2未通过   3未审核（微信报修才有）
                 */
                isAudit?: number;
                /**
                 * @description  是否查询自己的 1表示我的，2取消）
                 */
                isMy?: number;
                /**
                 * @description  1表示微信报修，2表示公司派单
                 */
                isWeixin?: number;
                /**
                 * @description  来源(1：租客业主端，2：管理端)
                 */
                orderSource?: number;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  支付状态:1为未支付，2为已支付
                 */
                payStatus?: string;
                /**
                 * @description  报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  房间id(列表使用) 
                 */
                roomId?: number;
                /**
                 * @description  房间号(合租)
                 */
                roomNo?: string;
                /**
                 * @description  星评
                 */
                score?: number;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  1未处理，2已处理 3处理中
                 */
                status?: number;
                /**
                 * @description  管家id
                 */
                stewardId?: number;
                /**
                 * @description  第二管家id
                 */
                stewardId2?: number;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
                /**
                 * @description  客户名称
                 */
                tenantsName?: string;
                /**
                 * @description  客户电话
                 */
                tenantsPhone?: string;
                /**
                 * @description  1表示维修 2保洁  3清洗
                 */
                typeId?: number;
                /**
                 * @description  核实状态：1，未核实；2，已核实
                 */
                verifyStatus?: number;
                /**
                 * @description  回访结果：pid:689
                 */
                visitDealStatusId?: number;
                /**
                 * @description  是否私下收费：1是，2否
                 */
                visitIsCharge?: number;
                /**
                 * @description  是否按时间完：1是，2否
                 */
                visitOnTime?: number;
                /**
                 * @description  服务星评：1特别差,2差，3一般，4好，5非常好
                 */
                visitServiceId?: number;
                /**
                 * @description  回访状态：1未回访 2已回访
                 */
                visitStatus?: number;
                /**
                 * @description  回访时间结束
                 */
                visitTimeEndTime?: string;
                /**
                 * @description  回访时间开始
                 */
                visitTimeStartTime?: string;
                /**
                 * @description  回访人
                 */
                visitUserId?: number;
            }
            /**
             * @name 维修保洁列表
             * @param params
             */
            export function list(params: ListParams) { return request.get<ResponseResult<PageList<MaintenanceClean>>>("/house-v100001/maintenanceClean/list", { params }); }
            export interface SaveCleanParams {
                /**
                 * @description  保洁档次Id
                 */
                cleanGradeId?: number;
                /**
                 * @description  保洁间隔天(定时保洁才显示的)
                 */
                cleanIntervalDay?: number;
                /**
                 * @description  保洁次数(下拉选，最多选到24次) （定时保洁才显示的）
                 */
                cleanNum?: number;
                /**
                 * @description  处理人id
                 */
                dealId?: number;
                /**
                 * @description  事件类型id(下拉字典) 维修与保洁不同的字典
                 */
                eventId?: number;
                /**
                 * @description  期望时间(选择天，小时，分与秒不要传00)
                 */
                expectTime: string;
                /**
                 * @description  房源id 
                 */
                houseId: number;
                /**
                 * @description  责任归属(1房东 ，2租客，3公司)
                 */
                liabilityId: number;
                /**
                 * @description  报修前图片
                 */
                oldImg?: string;
                /**
                 * @description  内容
                 */
                remark?: string;
                /**
                 * @description  清洗类目ids
                 */
                repairCategoryIds?: string;
                /**
                 * @description  房间id (如果选中房间添加维修与保洁就需要传) 
                 */
                roomId?: number;
                /**
                 * @description  客户电话
                 */
                tenantsPhone?: string;
                /**
                 * @description  保洁单价（每小时）
                 */
                unitPrice?: number;
                /**
                 * @description  员工工时
                 */
                workHour?: number;
            }
            /**
             * @name 保洁新增
             * @param data
             */
            export function saveClean(data: SaveCleanParams) { return request.post<ResponseResult<MaintenanceClean>>("/house-v100001/maintenanceClean/saveClean", data); }
            export interface SaveFollowParams {
                /**
                 * @description  维修表主键
                 */
                id: number;
                /**
                 * @description  备注
                 */
                remark: string;
                /**
                 * @description  状态：3正在处理 4暂停  5维修中(默认这个)
                 */
                statusId?: number;
                /**
                 * @description  预计暂停结束时间
                 */
                stopEndDate?: string;
            }
            /**
             * @name 跟进记录表新增
             * @param data
             */
            export function saveFollow(data: SaveFollowParams) { return request.post<ResponseResult<object>>("/house-v100001/maintenanceClean/saveFollow", data); }
            export interface SaveMaintenanceParams {
                /**
                 * @description  保洁档次Id
                 */
                cleanGradeId?: number;
                /**
                 * @description  保洁间隔天(定时保洁才显示的)
                 */
                cleanIntervalDay?: number;
                /**
                 * @description  保洁次数(下拉选，最多选到24次) （定时保洁才显示的）
                 */
                cleanNum?: number;
                /**
                 * @description  处理人id
                 */
                dealId?: number;
                /**
                 * @description  事件类型id(下拉字典) 维修与保洁不同的字典
                 */
                eventId?: number;
                /**
                 * @description  期望时间(选择天，小时，分与秒不要传00)
                 */
                expectTime: string;
                /**
                 * @description  房源id 
                 */
                houseId: number;
                /**
                 * @description  责任归属(1房东 ，2租客，3公司)
                 */
                liabilityId: number;
                /**
                 * @description  报修前图片
                 */
                oldImg?: string;
                /**
                 * @description  内容
                 */
                remark?: string;
                /**
                 * @description  清洗类目ids
                 */
                repairCategoryIds?: string;
                /**
                 * @description  房间id (如果选中房间添加维修与保洁就需要传) 
                 */
                roomId?: number;
                /**
                 * @description  客户电话
                 */
                tenantsPhone?: string;
                /**
                 * @description  保洁单价（每小时）
                 */
                unitPrice?: number;
                /**
                 * @description  员工工时
                 */
                workHour?: number;
            }
            /**
             * @name 维修新增
             * @param data
             */
            export function saveMaintenance(data: SaveMaintenanceParams) { return request.post<ResponseResult<object>>("/house-v100001/maintenanceClean/saveMaintenance", data); }
            export interface SaveWashParams {
                /**
                 * @description  保洁档次Id
                 */
                cleanGradeId?: number;
                /**
                 * @description  保洁间隔天(定时保洁才显示的)
                 */
                cleanIntervalDay?: number;
                /**
                 * @description  保洁次数(下拉选，最多选到24次) （定时保洁才显示的）
                 */
                cleanNum?: number;
                /**
                 * @description  处理人id
                 */
                dealId?: number;
                /**
                 * @description  事件类型id(下拉字典) 维修与保洁不同的字典
                 */
                eventId?: number;
                /**
                 * @description  期望时间(选择天，小时，分与秒不要传00)
                 */
                expectTime: string;
                /**
                 * @description  房源id 
                 */
                houseId: number;
                /**
                 * @description  责任归属(1房东 ，2租客，3公司)
                 */
                liabilityId: number;
                /**
                 * @description  报修前图片
                 */
                oldImg?: string;
                /**
                 * @description  内容
                 */
                remark?: string;
                /**
                 * @description  清洗类目ids
                 */
                repairCategoryIds?: string;
                /**
                 * @description  房间id (如果选中房间添加维修与保洁就需要传) 
                 */
                roomId?: number;
                /**
                 * @description  客户电话
                 */
                tenantsPhone?: string;
                /**
                 * @description  保洁单价（每小时）
                 */
                unitPrice?: number;
                /**
                 * @description  员工工时
                 */
                workHour?: number;
            }
            /**
             * @name 清洗新增
             * @param data
             */
            export function saveWash(data: SaveWashParams) { return request.post<ResponseResult<MaintenanceClean>>("/house-v100001/maintenanceClean/saveWash", data); }
            /**
             * @name 批量修改处理时间
             */
            export function setDealMinutes() { return request.post<ResponseResult<object>>("/house-v100001/maintenanceClean/setDealMinutes"); }
            export interface StartMaintenanceParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 小程序管理端：维修人开始处理
             * @param data
             */
            export function startMaintenance(data: StartMaintenanceParams) { return request.post<ResponseResult<object>>("/house-v100001/maintenanceClean/startMaintenance", data); }
            export interface UpdateDealParams {
                /**
                 * @description  处理人
                 */
                dealId?: number;
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 客户后台：维修保洁 更换处理人
             * @param data
             */
            export function updateDeal(data: UpdateDealParams) { return request.post<ResponseResult<object>>("/house-v100001/maintenanceClean/updateDeal", data); }
            export interface UpdateMaintenanceParams {
                /**
                 * @description  保洁档次Id
                 */
                cleanGradeId?: number;
                /**
                 * @description  处理人id
                 */
                dealId: number;
                /**
                 * @description  事件类型id(下拉字典) 维修与保洁不同的字典
                 */
                eventId?: number;
                /**
                 * @description  期望时间(选择天，小时，分与秒不要传00)
                 */
                expectTime: string;
                /**
                 * @description  房源id 
                 */
                houseId: number;
                /**
                 * @description  责任归属(1房东 ，2租客，3公司)
                 */
                liabilityId: number;
                /**
                 * @description  报修前图片
                 */
                oldImg?: string;
                /**
                 * @description  内容
                 */
                remark?: string;
                /**
                 * @description  清洗类目ids
                 */
                repairCategoryIds?: string;
                /**
                 * @description  房间id (如果选中房间添加维修与保洁就需要传) 
                 */
                roomId?: number;
                /**
                 * @description  客户电话
                 */
                tenantsPhone?: string;
                /**
                 * @description  保洁单价（每小时）
                 */
                unitPrice?: number;
                /**
                 * @description  员工工时
                 */
                workHour?: number;
            }
            /**
             * @name 维修保洁修改
             * @param id 维修保洁修改
             * @param data
             */
            export function updateMaintenance(id: string | number, data: UpdateMaintenanceParams) { return request.post<ResponseResult<object>>(`/house-v100001/maintenanceClean/updateMaintenance/${id}`, data); }
            export interface UpdatePayStatusParams {
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  支付状态
                 */
                payStatus?: string;
            }
            /**
             * @name 支付状态修改
             * @param data
             */
            export function updatePayStatus(data: UpdatePayStatusParams) { return request.post<ResponseResult<object>>("/house-v100001/maintenanceClean/updatePayStatus", data); }
            export interface UpdatePayStatusListParams {
                /**
                 * @description  主键集合
                 */
                ids?: string;
                /**
                 * @description  支付状态
                 */
                payStatus?: string;
            }
            /**
             * @name 批量支付状态修改
             * @param data
             */
            export function updatePayStatusList(data: UpdatePayStatusListParams) { return request.post<ResponseResult<object>>("/house-v100001/maintenanceClean/updatePayStatusList", data); }
            export interface VerifyMaintenanceAmountParams {
                /**
                 * @description  核实费用备注
                 */
                checkAmountRemark?: string;
                /**
                 * @description  公司承担费用
                 */
                companyAmount?: number;
                /**
                 * @description  公司付费原因id:pid:741
                 */
                companyWhyId?: number;
                /**
                 * @description  主键
                 */
                id: number;
                /**
                 * @description  业主承担金额
                 */
                ownerAmount?: number;
                /**
                 * @description  业主付费原因id:pid:742
                 */
                ownerWhyId?: number;
                /**
                 * @description  房东是否生成欠款(1，生成；2，不生成)
                 */
                roomStatus?: string;
                /**
                 * @description  租客承担费用
                 */
                tenantsAmount?: number;
                /**
                 * @description  租客是否生成欠款(1，生成；2，不生成)
                 */
                tenantsStatus?: string;
                /**
                 * @description  租客付费原因id:pid:743
                 */
                tenantsWhyId?: number;
            }
            /**
             * @name 店长核实维修费用
             * @param data
             */
            export function verifyMaintenanceAmount(data: VerifyMaintenanceAmountParams) { return request.post<ResponseResult<object>>("/house-v100001/maintenanceClean/verifyMaintenanceAmount", data); }
        }
        export namespace otherChangeRoom {
            /**
             * @name 换房记录删除
             * @param id 换房记录删除
             */
            export function deleteById(id: string | number) { return request.delete<ResponseResult<object>>(`/house-v100001/otherChangeRoom/delete/${id}`); }
            /**
             * @name 换房记录详情
             * @param id 换房记录详情
             */
            export function info(id: string | number) { return request.get<ResponseResult<OtherChangeRoom>>(`/house-v100001/otherChangeRoom/info/${id}`); }
            export interface ListParams {
                /**
                 * @description  创建人主键
                 */
                createId?: number;
                /**
                 * @description  创建时间结束
                 */
                createTenantsTimeEnd?: string;
                /**
                 * @description  创建时间开始
                 */
                createTenantsTimeStart?: string;
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  租赁结束时间结束
                 */
                leaseEndTimeEnd?: string;
                /**
                 * @description  租赁结束时间开始
                 */
                leaseEndTimeStart?: string;
                /**
                 * @description  租赁开始时间结束
                 */
                leaseStartTimeEnd?: string;
                /**
                 * @description  租赁开始时间开始
                 */
                leaseStartTimeStart?: string;
                /**
                 * @description  租客姓名
                 */
                name?: string;
                /**
                 * @description  新物业地区id
                 */
                newDetailId?: string;
                /**
                 * @description  新门牌号、（集中几层或者几栋）
                 */
                newHouseNum?: string;
                /**
                 * @description  新房间id 
                 */
                newRoomId?: number;
                /**
                 * @description  原物业地区id
                 */
                oldDetailId?: string;
                /**
                 * @description  原门牌号、（集中几层或者几栋）
                 */
                oldHouseNum?: string;
                /**
                 * @description  原门店id 
                 */
                oldStoreId?: number;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  联系电话
                 */
                phone?: string;
                /**
                 * @description  报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  报表查询时间(租赁结束)
                 */
                reportTimeEnd?: string;
                /**
                 * @description  报表查询时间(租赁开始) 
                 */
                reportTimeStart?: string;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  管家id
                 */
                stewardId?: number;
                /**
                 * @description  新门店id
                 */
                storeId?: number;
            }
            /**
             * @name 换房记录列表
             * @param params
             */
            export function list(params: ListParams) { return request.get<ResponseResult<PageList<OtherChangeRoom>>>("/house-v100001/otherChangeRoom/list", { params }); }
            export interface ReductionParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 租客换房还原
             * @param data
             */
            export function reduction(data: ReductionParams) { return request.post<ResponseResult<object>>("/house-v100001/otherChangeRoom/reduction", data); }
        }
        export namespace pricing {
            /**
             * @name 房源定价记录表详情
             * @param id 房源定价记录表详情
             */
            export function info(id: string | number) { return request.get<ResponseResult<Pricing>>(`/house-v100001/pricing/info/${id}`); }
            export interface ListParams {
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  房源id
                 */
                houseId?: number;
                /**
                 * @description  门牌号、（集中几层或者几栋）
                 */
                houseNum?: string;
                /**
                 * @description  1整租 2合租 3集中
                 */
                houseType?: number;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  房间id
                 */
                roomId?: number;
                /**
                 * @description  房间号 (合租)/集中 门牌号
                 */
                roomNo?: string;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  物品来源id
                 */
                sourceId?: number;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
            }
            /**
             * @name 房源定价记录表列表
             * @param params
             */
            export function list(params: ListParams) { return request.get<ResponseResult<PageList<Pricing>>>("/house-v100001/pricing/list", { params }); }
            export interface SaveParams {
                /**
                 * @description  押金定价
                 */
                depositAmount?: number;
                /**
                 * @description  最高定价
                 */
                pricingMaxAmount?: number;
                /**
                 * @description  最低定价
                 */
                pricingMinAmount: number;
                /**
                 * @description  备注
                 */
                remark?: string;
                /**
                 * @description  房间id
                 */
                roomId: number;
            }
            /**
             * @name 房源定价记录表新增
             * @param data
             */
            export function save(data: SaveParams) { return request.post<ResponseResult<object>>("/house-v100001/pricing/save", data); }
        }
        export namespace repairCategory {
            /**
             * @name 清洗类目删除
             * @param id 清洗类目删除
             */
            export function deleteById(id: string | number) { return request.post<ResponseResult<object>>(`/house-v100001/repairCategory/delete/${id}`); }
            /**
             * @name 清洗类目表下拉框
             */
            export function list() { return request.get<ResponseResult<Array<RepairCategory>>>("/house-v100001/repairCategory/list"); }
            export interface PageParams {
                /**
                 * @description  名称
                 */
                name?: string;
                pageNo?: number;
                pageSize?: number;
                sortField?: string;
                sortWay?: string;
            }
            /**
             * @name 清洗类目表列表
             * @param params
             */
            export function page(params: PageParams) { return request.get<ResponseResult<PageList<RepairCategory>>>("/house-v100001/repairCategory/page", { params }); }
            export interface SaveParams {
                /**
                 * @description  成本价
                 */
                costPrice?: number;
                /**
                 * @description  名称
                 */
                name: string;
                /**
                 * @description  外部销售价
                 */
                outPrice?: number;
            }
            /**
             * @name 清洗类目表添加
             * @param data
             */
            export function save(data: SaveParams) { return request.post<ResponseResult<object>>("/house-v100001/repairCategory/save", data); }
            export interface UpdateParams {
                /**
                 * @description  成本价
                 */
                costPrice?: number;
                /**
                 * @description  名称
                 */
                name: string;
                /**
                 * @description  外部销售价
                 */
                outPrice?: number;
            }
            /**
             * @name 清洗类目修改
             * @param id 清洗类目修改
             * @param data
             */
            export function update(id: string | number, data: UpdateParams) { return request.post<ResponseResult<object>>(`/house-v100001/repairCategory/update/${id}`, data); }
        }
        export namespace roomExit {
            export interface DeleteByIdParams {
                /**
                 * @description  主键
                 */
                ids?: number;
            }
            /**
             * @name 房间删除表删除
             * @param params
             */
            export function deleteById(params: DeleteByIdParams) { return request.delete<ResponseResult<object>>("/house-v100001/roomExit/delete", { params }); }
            /**
             * @name 房间删除表详情
             * @param id 房间删除表详情
             */
            export function info(id: string | number) { return request.get<ResponseResult<RoomExit>>(`/house-v100001/roomExit/info/${id}`); }
            export interface ListParams {
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  户型/房型id
                 */
                doorModelId?: number;
                /**
                 * @description  房源编号
                 */
                houseNo?: string;
                /**
                 * @description  门牌号
                 */
                houseNum?: string;
                /**
                 * @description  1整租 2合租 3集中
                 */
                houseType?: number;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  房间号/门牌号
                 */
                roomNo?: string;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  门店id
                 */
                storeId?: number;
            }
            /**
             * @name 房间删除表列表
             * @param params
             */
            export function list(params: ListParams) { return request.get<ResponseResult<PageList<RoomExit>>>("/house-v100001/roomExit/list", { params }); }
        }
        export namespace roomTenants {
            export interface DeleteByIdParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 房间表删除（合租与集中才有）
             * @param params
             */
            export function deleteById(params: DeleteByIdParams) { return request.delete<ResponseResult<object>>("/house-v100001/roomTenants/deleteById", { params }); }
            export interface ExportRoomParams {
                /**
                 * @description  区id
                 */
                areaId?: number;
                /**
                 * @description  审核时间结束
                 */
                auditTimeEnd?: string;
                /**
                 * @description  审核时间开始
                 */
                auditTimeStart?: string;
                /**
                 * @description  业务人员
                 */
                businessId?: number;
                /**
                 * @description  客源人
                 */
                businessId2?: number;
                /**
                 * @description  美好家园：1同步成功 2未同步  3同步失败
                 */
                ccbStatus?: number;
                /**
                 * @description  渠道来源id
                 */
                channelId?: number;
                /**
                 * @description  1 有空调，0无空调
                 */
                conditioner?: number;
                /**
                 * @description  合同性质id
                 */
                contractId?: number;
                /**
                 * @description  合同签名 1-未签名 2-已签名
                 */
                contractRecordIsSignature?: number;
                /**
                 * @description  创建时间结束
                 */
                createTenantsTimeEnd?: string;
                /**
                 * @description  创建时间开始
                 */
                createTenantsTimeStart?: string;
                /**
                 * @description  装修状态（集中）
                 */
                decorateId?: number;
                /**
                 * @description  交割单签名 1-未签名 2-已签名
                 */
                deliveryOrderIsSignature?: number;
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  户型/房型id  主，次卧（合租才有）
                 */
                doorModelId?: number;
                /**
                 * @description  几厅（集中才有）
                 */
                hall?: number;
                /**
                 * @description  房源id
                 */
                houseId?: number;
                /**
                 * @description  门牌号
                 */
                houseNum?: string;
                /**
                 * @description  第一次房租支付状态 1-已付 2-未付
                 */
                houseRentOne?: number;
                /**
                 * @description  费用结算单签名 1-未签名 2-已签名
                 */
                houseSettlementIsSignature?: number;
                /**
                 * @description  类型：1整租,2合租，3集中
                 */
                houseType?: number;
                /**
                 * @description  租后是否审核 1已审核  2未通过  3未审核
                 */
                isAfterAudit?: number;
                /**
                 * @description  租前是否审核 1已审核  2未通过  3未审核
                 */
                isBeforeAudit?: number;
                /**
                 * @description  租客是否到期 1未到期  2已到期  
                 */
                isEndTime?: number;
                /**
                 * @description  是否提交了退房申请(1,已提交；2未提交)
                 */
                isExit?: number;
                /**
                 * @description  只看自己的
                 */
                isMy?: number;
                /**
                 * @description  租赁结束时间结束
                 */
                leaseEndTimeEnd?: string;
                /**
                 * @description  租赁结束时间开始
                 */
                leaseEndTimeStart?: string;
                /**
                 * @description  租赁开始时间结束
                 */
                leaseStartTimeEnd?: string;
                /**
                 * @description  租赁开始时间开始
                 */
                leaseStartTimeStart?: string;
                /**
                 * @description  租客姓名
                 */
                name?: string;
                /**
                 * @description  在线找房上传状态：1，已上传；2未上传
                 */
                outShowStatus?: number;
                /**
                 * @description  租客付款方式id
                 */
                payTypeId?: number;
                /**
                 * @description  联系电话
                 */
                phone?: string;
                /**
                 * @description  联系电话2
                 */
                phone2?: string;
                /**
                 * @description  空置时间搜索条件
                 */
                rentOutEndTimeId?: number;
                /**
                 * @description  录入报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  报表查询时间(租赁结束) 出房业绩使用
                 */
                reportTimeEnd?: string;
                /**
                 * @description  报表查询时间(租赁开始) 收房业绩使用
                 */
                reportTimeStart?: string;
                /**
                 * @description  几室（集中才有）
                 */
                room?: number;
                /**
                 * @description  房间号/门牌号
                 */
                roomNo?: string;
                /**
                 * @description  状态：1正常   3冻结
                 */
                status?: number;
                /**
                 * @description  出租状态（系统字典）
                 */
                statusId?: number;
                /**
                 * @description  管家id
                 */
                stewardId?: number;
                /**
                 * @description  管家id2
                 */
                stewardId2?: number;
                /**
                 * @description  员工分组id
                 */
                storeGroupId?: number;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
                /**
                 * @description  1租前  2租后
                 */
                type?: number;
                /**
                 * @description  几卫（集中才有）（合租0表示无，1表示有）
                 */
                who?: number;
            }
            /**
             * @name 导出房间
             * @param params
             */
            export function exportRoom(params: ExportRoomParams) { return request.get("/house-v100001/roomTenants/exportRoom", { params }); }
            export interface FreezeRoomTenantsParams {
                /**
                 * @description  冻结原因
                 */
                freezeReason?: string;
                /**
                 * @description  冻结时间：前端不管
                 */
                freezeTime?: string;
                /**
                 * @description  禁止登记租客、添加定金 1:禁止  2不禁止
                 */
                isAddTenants?: number;
                /**
                 * @description  禁止租客转租、租客换房、租客延期、租客续约 1:禁止  2不禁止
                 */
                isTenantsContract?: number;
                /**
                 * @description  id
                 */
                id?: number;
            }
            /**
             * @name 冻结房间
             * @param data
             */
            export function freezeRoomTenants(data: FreezeRoomTenantsParams) { return request.post<ResponseResult<House>>("/house-v100001/roomTenants/freezeRoomTenants", data); }
            export interface GetRoomInfoAndExitInfoParams {
                /**
                 * @description  房间id
                 */
                id?: number;
                /**
                 * @description  1或者不传表示房间详情 2表示退房详情，id传退房id
                 */
                type?: number;
            }
            /**
             * @name 房间表详情（包含退房逻辑查询）
             * @param params
             */
            export function getRoomInfoAndExitInfo(params: GetRoomInfoAndExitInfoParams) { return request.get<ResponseResult<TenantsVo>>("/house-v100001/roomTenants/getRoomInfoAndExitInfo", { params }); }
            export interface HouseAndTenantsExcelParams {
                /**
                 * @description  物业地址id
                 */
                detailId?: string;
            }
            /**
             * @name 租客和业主信息导出表
             * @param params
             */
            export function houseAndTenantsExcel(params: HouseAndTenantsExcelParams) { return request.get("/house-v100001/roomTenants/houseAndTenantsExcel", { params }); }
            /**
             * @name 房间表详情
             * @param id 房间表详情
             */
            export function info(id: string | number) { return request.get<ResponseResult<TenantsVo>>(`/house-v100001/roomTenants/info/${id}`); }
            export interface ListParams {
                /**
                 * @description  区id
                 */
                areaId?: number;
                /**
                 * @description  审核时间结束
                 */
                auditTimeEnd?: string;
                /**
                 * @description  审核时间开始
                 */
                auditTimeStart?: string;
                /**
                 * @description  业务人员
                 */
                businessId?: number;
                /**
                 * @description  客源人
                 */
                businessId2?: number;
                /**
                 * @description  美好家园：1同步成功 2未同步  3同步失败
                 */
                ccbStatus?: number;
                /**
                 * @description  渠道来源id
                 */
                channelId?: number;
                /**
                 * @description  1 有空调，0无空调
                 */
                conditioner?: number;
                /**
                 * @description  合同性质id
                 */
                contractId?: number;
                /**
                 * @description  合同签名 1-未签名 2-已签名
                 */
                contractRecordIsSignature?: number;
                /**
                 * @description  创建时间结束
                 */
                createTenantsTimeEnd?: string;
                /**
                 * @description  创建时间开始
                 */
                createTenantsTimeStart?: string;
                /**
                 * @description  装修状态（集中）
                 */
                decorateId?: number;
                /**
                 * @description  交割单签名 1-未签名 2-已签名
                 */
                deliveryOrderIsSignature?: number;
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  户型/房型id  主，次卧（合租才有）
                 */
                doorModelId?: number;
                /**
                 * @description  几厅（集中才有）
                 */
                hall?: number;
                /**
                 * @description  房源id
                 */
                houseId?: number;
                /**
                 * @description  门牌号
                 */
                houseNum?: string;
                /**
                 * @description  第一次房租支付状态 1-已付 2-未付
                 */
                houseRentOne?: number;
                /**
                 * @description  费用结算单签名 1-未签名 2-已签名
                 */
                houseSettlementIsSignature?: number;
                /**
                 * @description  类型：1整租,2合租，3集中
                 */
                houseType?: number;
                /**
                 * @description  租后是否审核 1已审核  2未通过  3未审核
                 */
                isAfterAudit?: number;
                /**
                 * @description  租前是否审核 1已审核  2未通过  3未审核
                 */
                isBeforeAudit?: number;
                /**
                 * @description  租客是否到期 1未到期  2已到期  
                 */
                isEndTime?: number;
                /**
                 * @description  是否提交了退房申请(1,已提交；2未提交)
                 */
                isExit?: number;
                /**
                 * @description  只看自己的
                 */
                isMy?: number;
                /**
                 * @description  租赁结束时间结束
                 */
                leaseEndTimeEnd?: string;
                /**
                 * @description  租赁结束时间开始
                 */
                leaseEndTimeStart?: string;
                /**
                 * @description  租赁开始时间结束
                 */
                leaseStartTimeEnd?: string;
                /**
                 * @description  租赁开始时间开始
                 */
                leaseStartTimeStart?: string;
                /**
                 * @description  租客姓名
                 */
                name?: string;
                /**
                 * @description  在线找房上传状态：1，已上传；2未上传
                 */
                outShowStatus?: number;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  租客付款方式id
                 */
                payTypeId?: number;
                /**
                 * @description  联系电话
                 */
                phone?: string;
                /**
                 * @description  联系电话2
                 */
                phone2?: string;
                /**
                 * @description  空置时间搜索条件
                 */
                rentOutEndTimeId?: number;
                /**
                 * @description  录入报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  报表查询时间(租赁结束) 出房业绩使用
                 */
                reportTimeEnd?: string;
                /**
                 * @description  报表查询时间(租赁开始) 收房业绩使用
                 */
                reportTimeStart?: string;
                /**
                 * @description  几室（集中才有）
                 */
                room?: number;
                /**
                 * @description  房间号/门牌号
                 */
                roomNo?: string;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  状态：1正常   3冻结
                 */
                status?: number;
                /**
                 * @description  出租状态（系统字典）
                 */
                statusId?: number;
                /**
                 * @description  管家id
                 */
                stewardId?: number;
                /**
                 * @description  管家id2
                 */
                stewardId2?: number;
                /**
                 * @description  员工分组id
                 */
                storeGroupId?: number;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
                /**
                 * @description  1租前  2租后
                 */
                type?: number;
                /**
                 * @description  几卫（集中才有）（合租0表示无，1表示有）
                 */
                who?: number;
            }
            /**
             * @name 房间表列表
             * @param params
             */
            export function list(params: ListParams) { return request.get<ResponseResult<PageList<RoomTenants>>>("/house-v100001/roomTenants/list", { params }); }
            export interface RoomTenantsSumParams {
                /**
                 * @description  区id
                 */
                areaId?: number;
                /**
                 * @description  审核时间结束
                 */
                auditTimeEnd?: string;
                /**
                 * @description  审核时间开始
                 */
                auditTimeStart?: string;
                /**
                 * @description  业务人员
                 */
                businessId?: number;
                /**
                 * @description  客源人
                 */
                businessId2?: number;
                /**
                 * @description  美好家园：1同步成功 2未同步  3同步失败
                 */
                ccbStatus?: number;
                /**
                 * @description  渠道来源id
                 */
                channelId?: number;
                /**
                 * @description  1 有空调，0无空调
                 */
                conditioner?: number;
                /**
                 * @description  合同性质id
                 */
                contractId?: number;
                /**
                 * @description  合同签名 1-未签名 2-已签名
                 */
                contractRecordIsSignature?: number;
                /**
                 * @description  创建时间结束
                 */
                createTenantsTimeEnd?: string;
                /**
                 * @description  创建时间开始
                 */
                createTenantsTimeStart?: string;
                /**
                 * @description  装修状态（集中）
                 */
                decorateId?: number;
                /**
                 * @description  交割单签名 1-未签名 2-已签名
                 */
                deliveryOrderIsSignature?: number;
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  户型/房型id  主，次卧（合租才有）
                 */
                doorModelId?: number;
                /**
                 * @description  几厅（集中才有）
                 */
                hall?: number;
                /**
                 * @description  房源id
                 */
                houseId?: number;
                /**
                 * @description  门牌号
                 */
                houseNum?: string;
                /**
                 * @description  第一次房租支付状态 1-已付 2-未付
                 */
                houseRentOne?: number;
                /**
                 * @description  费用结算单签名 1-未签名 2-已签名
                 */
                houseSettlementIsSignature?: number;
                /**
                 * @description  类型：1整租,2合租，3集中
                 */
                houseType?: number;
                /**
                 * @description  租后是否审核 1已审核  2未通过  3未审核
                 */
                isAfterAudit?: number;
                /**
                 * @description  租前是否审核 1已审核  2未通过  3未审核
                 */
                isBeforeAudit?: number;
                /**
                 * @description  租客是否到期 1未到期  2已到期  
                 */
                isEndTime?: number;
                /**
                 * @description  是否提交了退房申请(1,已提交；2未提交)
                 */
                isExit?: number;
                /**
                 * @description  只看自己的
                 */
                isMy?: number;
                /**
                 * @description  租赁结束时间结束
                 */
                leaseEndTimeEnd?: string;
                /**
                 * @description  租赁结束时间开始
                 */
                leaseEndTimeStart?: string;
                /**
                 * @description  租赁开始时间结束
                 */
                leaseStartTimeEnd?: string;
                /**
                 * @description  租赁开始时间开始
                 */
                leaseStartTimeStart?: string;
                /**
                 * @description  租客姓名
                 */
                name?: string;
                /**
                 * @description  在线找房上传状态：1，已上传；2未上传
                 */
                outShowStatus?: number;
                /**
                 * @description  租客付款方式id
                 */
                payTypeId?: number;
                /**
                 * @description  联系电话
                 */
                phone?: string;
                /**
                 * @description  联系电话2
                 */
                phone2?: string;
                /**
                 * @description  空置时间搜索条件
                 */
                rentOutEndTimeId?: number;
                /**
                 * @description  录入报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  报表查询时间(租赁结束) 出房业绩使用
                 */
                reportTimeEnd?: string;
                /**
                 * @description  报表查询时间(租赁开始) 收房业绩使用
                 */
                reportTimeStart?: string;
                /**
                 * @description  几室（集中才有）
                 */
                room?: number;
                /**
                 * @description  房间号/门牌号
                 */
                roomNo?: string;
                /**
                 * @description  状态：1正常   3冻结
                 */
                status?: number;
                /**
                 * @description  出租状态（系统字典）
                 */
                statusId?: number;
                /**
                 * @description  管家id
                 */
                stewardId?: number;
                /**
                 * @description  管家id2
                 */
                stewardId2?: number;
                /**
                 * @description  员工分组id
                 */
                storeGroupId?: number;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
                /**
                 * @description  1租前  2租后
                 */
                type?: number;
                /**
                 * @description  几卫（集中才有）（合租0表示无，1表示有）
                 */
                who?: number;
            }
            /**
             * @name 房间列表已租统计数据
             * @param params
             */
            export function roomTenantsSum(params: RoomTenantsSumParams) { return request.get<ResponseResult<房间列表数据统计类>>("/house-v100001/roomTenants/roomTenantsSum", { params }); }
            export interface SaveImgParams {
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  房间图片
                 */
                roomImg?: string;
                /**
                 * @description  房间视频
                 */
                roomVideo?: string;
            }
            /**
             * @name 房间添加图片
             * @param data
             */
            export function saveImg(data: SaveImgParams) { return request.post<ResponseResult<object>>("/house-v100001/roomTenants/saveImg", data); }
            export interface SaveOrUpdateBatchRoomListParams {
                /**
                 * @description  房源id
                 */
                houseId: number;
                roomSaveDtoList: Array<{
                    acreage: string;
                    conditioner: number;
                    doorModelId: number;
                    hall: number;
                    id: number;
                    pricingMaxAmount: number;
                    pricingMinAmount: number;
                    remarks: string;
                    room: number;
                    roomConfig: string;
                    roomNo: string;
                    who: number;
                }>;
            }
            /**
             * @name 房间表新增(只有合租与集中才有)
             * @param data
             */
            export function saveOrUpdateBatchRoomList(data: SaveOrUpdateBatchRoomListParams) { return request.post<ResponseResult<object>>("/house-v100001/roomTenants/saveOrUpdateBatchRoomList", data); }
            export interface SelectParams {
                /**
                 * @description  区id
                 */
                areaId?: number;
                /**
                 * @description  审核时间结束
                 */
                auditTimeEnd?: string;
                /**
                 * @description  审核时间开始
                 */
                auditTimeStart?: string;
                /**
                 * @description  业务人员
                 */
                businessId?: number;
                /**
                 * @description  客源人
                 */
                businessId2?: number;
                /**
                 * @description  美好家园：1同步成功 2未同步  3同步失败
                 */
                ccbStatus?: number;
                /**
                 * @description  渠道来源id
                 */
                channelId?: number;
                /**
                 * @description  1 有空调，0无空调
                 */
                conditioner?: number;
                /**
                 * @description  合同性质id
                 */
                contractId?: number;
                /**
                 * @description  合同签名 1-未签名 2-已签名
                 */
                contractRecordIsSignature?: number;
                /**
                 * @description  创建时间结束
                 */
                createTenantsTimeEnd?: string;
                /**
                 * @description  创建时间开始
                 */
                createTenantsTimeStart?: string;
                /**
                 * @description  装修状态（集中）
                 */
                decorateId?: number;
                /**
                 * @description  交割单签名 1-未签名 2-已签名
                 */
                deliveryOrderIsSignature?: number;
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  户型/房型id  主，次卧（合租才有）
                 */
                doorModelId?: number;
                /**
                 * @description  几厅（集中才有）
                 */
                hall?: number;
                /**
                 * @description  房源id
                 */
                houseId?: number;
                /**
                 * @description  门牌号
                 */
                houseNum?: string;
                /**
                 * @description  第一次房租支付状态 1-已付 2-未付
                 */
                houseRentOne?: number;
                /**
                 * @description  费用结算单签名 1-未签名 2-已签名
                 */
                houseSettlementIsSignature?: number;
                /**
                 * @description  类型：1整租,2合租，3集中
                 */
                houseType?: number;
                /**
                 * @description  租后是否审核 1已审核  2未通过  3未审核
                 */
                isAfterAudit?: number;
                /**
                 * @description  租前是否审核 1已审核  2未通过  3未审核
                 */
                isBeforeAudit?: number;
                /**
                 * @description  租客是否到期 1未到期  2已到期  
                 */
                isEndTime?: number;
                /**
                 * @description  是否提交了退房申请(1,已提交；2未提交)
                 */
                isExit?: number;
                /**
                 * @description  只看自己的
                 */
                isMy?: number;
                /**
                 * @description  租赁结束时间结束
                 */
                leaseEndTimeEnd?: string;
                /**
                 * @description  租赁结束时间开始
                 */
                leaseEndTimeStart?: string;
                /**
                 * @description  租赁开始时间结束
                 */
                leaseStartTimeEnd?: string;
                /**
                 * @description  租赁开始时间开始
                 */
                leaseStartTimeStart?: string;
                /**
                 * @description  租客姓名
                 */
                name?: string;
                /**
                 * @description  在线找房上传状态：1，已上传；2未上传
                 */
                outShowStatus?: number;
                /**
                 * @description  租客付款方式id
                 */
                payTypeId?: number;
                /**
                 * @description  联系电话
                 */
                phone?: string;
                /**
                 * @description  联系电话2
                 */
                phone2?: string;
                /**
                 * @description  空置时间搜索条件
                 */
                rentOutEndTimeId?: number;
                /**
                 * @description  录入报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  报表查询时间(租赁结束) 出房业绩使用
                 */
                reportTimeEnd?: string;
                /**
                 * @description  报表查询时间(租赁开始) 收房业绩使用
                 */
                reportTimeStart?: string;
                /**
                 * @description  几室（集中才有）
                 */
                room?: number;
                /**
                 * @description  房间号/门牌号
                 */
                roomNo?: string;
                /**
                 * @description  状态：1正常   3冻结
                 */
                status?: number;
                /**
                 * @description  出租状态（系统字典）
                 */
                statusId?: number;
                /**
                 * @description  管家id
                 */
                stewardId?: number;
                /**
                 * @description  管家id2
                 */
                stewardId2?: number;
                /**
                 * @description  员工分组id
                 */
                storeGroupId?: number;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
                /**
                 * @description  1租前  2租后
                 */
                type?: number;
                /**
                 * @description  几卫（集中才有）（合租0表示无，1表示有）
                 */
                who?: number;
            }
            /**
             * @name 房间下拉框
             * @param params
             */
            export function select(params: SelectParams) { return request.get<ResponseResult<Array<RoomTenants>>>("/house-v100001/roomTenants/select", { params }); }
            /**
             * @name setIsEffective
             */
            export function setIsEffective() { return request.post<ResponseResult<object>>("/house-v100001/roomTenants/setIsEffective"); }
            export interface SetRoomConfigParams {
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  房间配置字符串，以逗号隔开
                 */
                roomConfig?: string;
            }
            /**
             * @name 修改房间配置
             * @param data
             */
            export function setRoomConfig(data: SetRoomConfigParams) { return request.post<ResponseResult<House>>("/house-v100001/roomTenants/setRoomConfig", data); }
            export interface UnFreezeRoomTenantsParams {
                /**
                 * @description  id
                 */
                id?: number;
            }
            /**
             * @name 解冻方间
             * @param data
             */
            export function unFreezeRoomTenants(data: UnFreezeRoomTenantsParams) { return request.post<ResponseResult<House>>("/house-v100001/roomTenants/unFreezeRoomTenants", data); }
        }
        export namespace sublet {
            export interface DeleteByIdParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 转租记录删除
             * @param params
             */
            export function deleteById(params: DeleteByIdParams) { return request.delete<ResponseResult<object>>("/house-v100001/sublet/deleteById", { params }); }
            /**
             * @name 转租记录详情
             * @param id 转租记录详情
             */
            export function info(id: string | number) { return request.get<ResponseResult<Sublet>>(`/house-v100001/sublet/info/${id}`); }
            export interface InfoByExitIdParams {
                /**
                 * @description  退房id
                 */
                exitId?: number;
            }
            /**
             * @name 转租记录通过退房id获取
             * @param params
             */
            export function infoByExitId(params: InfoByExitIdParams) { return request.get<ResponseResult<Sublet>>("/house-v100001/sublet/infoByExitId", { params }); }
            export interface ListParams {
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  门牌号、（集中几层或者几栋）
                 */
                houseNum?: string;
                /**
                 * @description  新租客姓名
                 */
                newName?: string;
                /**
                 * @description  新租客电话
                 */
                newPhone?: string;
                /**
                 * @description  原租客姓名
                 */
                oldName?: string;
                /**
                 * @description  原租客联系电话
                 */
                oldPhone?: string;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  房间id 
                 */
                roomId?: number;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
                /**
                 * @description  转租性质 id Pid:293
                 */
                subletId?: number;
            }
            /**
             * @name 转租记录列表
             * @param params
             */
            export function list(params: ListParams) { return request.get<ResponseResult<PageList<Sublet>>>("/house-v100001/sublet/list", { params }); }
            export interface ReductionParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 租客转租还原
             * @param data
             */
            export function reduction(data: ReductionParams) { return request.post<ResponseResult<object>>("/house-v100001/sublet/reduction", data); }
        }
        export namespace tenants {
            /**
             * @name 租客详情(返回租客)
             * @param id 租客详情(返回租客)
             */
            export function getTenants(id: string | number) { return request.get<ResponseResult<TenantsVo>>(`/house-v100001/tenants/getTenants/${id}`); }
            export interface SaveParams {
                /**
                 * @description  身份证地址
                 */
                addr?: string;
                /**
                 * @description  业务人员
                 */
                businessId: number;
                /**
                 * @description  业务人员2
                 */
                businessId2: number;
                /**
                 * @description  证件类型字典(601)
                 */
                certificateTypeId: number;
                /**
                 * @description  渠道来源id
                 */
                channelId?: number;
                /**
                 * @description  合作人姓名
                 */
                collaboratorName?: string;
                /**
                 * @description  合作人电话
                 */
                collaboratorPhone?: string;
                /**
                 * @description  合同性质id
                 */
                contractId: number;
                /**
                 * @description  押金金额
                 */
                depositAmount: number;
                /**
                 * @description  到家了员工编号
                 */
                djlNo?: string;
                /**
                 * @description  紧急联系人
                 */
                emergencyContact?: string;
                /**
                 * @description  紧急联系人电话
                 */
                emergencyContactPhone?: string;
                /**
                 * @description  身份证
                 */
                idCard?: string;
                /**
                 * @description  证件图片
                 */
                idCardImg?: string;
                /**
                 * @description  入住性质id
                 */
                inNatureId?: number;
                /**
                 * @description  递增约定id
                 */
                increaseId: number;
                /**
                 * @description  1百分比 2固定金额
                 */
                increaseType?: number;
                /**
                 * @description  不规则付款json，付款试为不规则付款这个字段有值  [{index:1,payInterval:1,tenantsAmount:200, periodStart:2022-02-12,periodEnd:2022-03-11} ]
                 */
                irregularList?: string;
                /**
                 * @description  是否需要重新生成（修改时才传） 1需要重新生成  2不需要
                 */
                isUpdate?: number;
                /**
                 * @description  租赁天
                 */
                leaseDay: number;
                /**
                 * @description  租赁结束时间
                 */
                leaseEndTime: string;
                /**
                 * @description  租赁月
                 */
                leaseMonth: number;
                /**
                 * @description  租赁开始时间
                 */
                leaseStartTime: string;
                /**
                 * @description  租赁年
                 */
                leaseYear?: number;
                /**
                 * @description  租客姓名
                 */
                name?: string;
                /**
                 * @description  缴费日期 1表示提前，2表示延后 3固定日期
                 */
                payType: number;
                /**
                 * @description  缴费日期 提前或者延后多少天  
                 */
                payTypeDay: number;
                /**
                 * @description  付款方式id 字典 value值取remark
                 */
                payTypeId: number;
                /**
                 * @description  联系电话
                 */
                phone?: string;
                /**
                 * @description  联系电话2
                 */
                phone2?: string;
                /**
                 * @description  备注
                 */
                remarks?: string;
                /**
                 * @description  房间id
                 */
                roomTenantsId: number;
                /**
                 * @description  服务费
                 */
                serviceCharge: number;
                /**
                 * @description  租金
                 */
                tenantsAmount: number;
                increaseJson: Array<{
                    amount: number;
                    endTime: string;
                    startTime: string;
                    val: number;
                    year: number;
                }>;
                payOtherJson: Array<{
                    code: number;
                    name: string;
                    remarks: string;
                    val: string;
                    val1: string;
                }>;
                tenantsCohabitJson: Array<{
                    idCard: string;
                    name: string;
                    phone: number;
                }>;
                tenantsOtherJson: Array<{
                    code: number;
                    name: string;
                    remarks: string;
                    val: string;
                }>;
            }
            /**
             * @name 租客新增
             * @param data
             */
            export function save(data: SaveParams) { return request.post<ResponseResult<RoomTenants>>("/house-v100001/tenants/save", data); }
            export interface SaveImgParams {
                /**
                 * @description  合同图片
                 */
                contractImg?: string;
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  身份证图片
                 */
                idCardImg?: string;
                /**
                 * @description  其它图片
                 */
                otherImg?: string;
            }
            /**
             * @name 租客添加图片
             * @param data
             */
            export function saveImg(data: SaveImgParams) { return request.post<ResponseResult<object>>("/house-v100001/tenants/saveImg", data); }
            export interface UpdateParams {
                /**
                 * @description  身份证地址
                 */
                addr?: string;
                /**
                 * @description  业务人员
                 */
                businessId: number;
                /**
                 * @description  业务人员2
                 */
                businessId2: number;
                /**
                 * @description  证件类型字典(601)
                 */
                certificateTypeId: number;
                /**
                 * @description  渠道来源id
                 */
                channelId?: number;
                /**
                 * @description  合作人姓名
                 */
                collaboratorName?: string;
                /**
                 * @description  合作人电话
                 */
                collaboratorPhone?: string;
                /**
                 * @description  合同性质id
                 */
                contractId: number;
                /**
                 * @description  押金金额
                 */
                depositAmount: number;
                /**
                 * @description  到家了员工编号
                 */
                djlNo?: string;
                /**
                 * @description  紧急联系人
                 */
                emergencyContact?: string;
                /**
                 * @description  紧急联系人电话
                 */
                emergencyContactPhone?: string;
                /**
                 * @description  身份证
                 */
                idCard?: string;
                /**
                 * @description  证件图片
                 */
                idCardImg?: string;
                /**
                 * @description  入住性质id
                 */
                inNatureId?: number;
                /**
                 * @description  递增约定id
                 */
                increaseId: number;
                /**
                 * @description  1百分比 2固定金额
                 */
                increaseType?: number;
                /**
                 * @description  不规则付款json，付款试为不规则付款这个字段有值  [{index:1,payInterval:1,tenantsAmount:200, periodStart:2022-02-12,periodEnd:2022-03-11} ]
                 */
                irregularList?: string;
                /**
                 * @description  是否需要重新生成（修改时才传） 1需要重新生成  2不需要
                 */
                isUpdate?: number;
                /**
                 * @description  租赁天
                 */
                leaseDay: number;
                /**
                 * @description  租赁结束时间
                 */
                leaseEndTime: string;
                /**
                 * @description  租赁月
                 */
                leaseMonth: number;
                /**
                 * @description  租赁开始时间
                 */
                leaseStartTime: string;
                /**
                 * @description  租赁年
                 */
                leaseYear?: number;
                /**
                 * @description  租客姓名
                 */
                name?: string;
                /**
                 * @description  缴费日期 1表示提前，2表示延后 3固定日期
                 */
                payType: number;
                /**
                 * @description  缴费日期 提前或者延后多少天  
                 */
                payTypeDay: number;
                /**
                 * @description  付款方式id 字典 value值取remark
                 */
                payTypeId: number;
                /**
                 * @description  联系电话
                 */
                phone?: string;
                /**
                 * @description  联系电话2
                 */
                phone2?: string;
                /**
                 * @description  备注
                 */
                remarks?: string;
                /**
                 * @description  房间id
                 */
                roomTenantsId: number;
                /**
                 * @description  服务费
                 */
                serviceCharge: number;
                /**
                 * @description  租金
                 */
                tenantsAmount: number;
                increaseJson: Array<{
                    amount: number;
                    endTime: string;
                    startTime: string;
                    val: number;
                    year: number;
                }>;
                payOtherJson: Array<{
                    code: number;
                    name: string;
                    remarks: string;
                    val: string;
                    val1: string;
                }>;
                tenantsCohabitJson: Array<{
                    idCard: string;
                    name: string;
                    phone: number;
                }>;
                tenantsOtherJson: Array<{
                    code: number;
                    name: string;
                    remarks: string;
                    val: string;
                }>;
            }
            /**
             * @name 租客修改
             * @param id 租客修改
             * @param data
             */
            export function update(id: string | number, data: UpdateParams) { return request.put<ResponseResult<UpdateMessage>>(`/house-v100001/tenants/update/${id}`, data); }
            export interface UpdateOtherInfoParams {
                /**
                 * @description  紧急联系人
                 */
                emergencyContact?: string;
                /**
                 * @description  紧急联系人电话
                 */
                emergencyContactPhone?: string;
                /**
                 * @description  备注
                 */
                remarks?: string;
                /**
                 * @description  租客id
                 */
                tenantsId: number;
                tenantsOtherJson: Array<{
                    code: number;
                    name: string;
                    remarks: string;
                    val: string;
                }>;
            }
            /**
             * @name 租客修改其它信息
             * @param data
             */
            export function updateOtherInfo(data: UpdateOtherInfoParams) { return request.put<ResponseResult<UpdateMessage>>("/house-v100001/tenants/updateOtherInfo", data); }
        }
        export namespace tenantsCohabit {
            export interface ListParams {
                pageNo?: number;
                pageSize?: number;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  租客表id
                 */
                tenantsId?: number;
            }
            /**
             * @name 租客同住人(手机号记录表)列表
             * @param params
             */
            export function list(params: ListParams) { return request.get<ResponseResult<PageList<TenantsCohabit>>>("/house-v100001/tenantsCohabit/list", { params }); }
        }
        export namespace tenantsContract {
            /**
             * @name 租客续约记录表删除
             * @param id 租客续约记录表删除
             */
            export function deleteById(id: string | number) { return request.delete<ResponseResult<object>>(`/house-v100001/tenantsContract/delete/${id}`); }
            export interface GetTenantsVoParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 租客续约记录表详情返回续约前租客信息
             * @param params
             */
            export function getTenantsVo(params: GetTenantsVoParams) { return request.get<ResponseResult<TenantsVo>>("/house-v100001/tenantsContract/getTenantsVo", { params }); }
            /**
             * @name 租客续约记录表详情
             * @param id 租客续约记录表详情
             */
            export function info(id: string | number) { return request.get<ResponseResult<TenantsContract>>(`/house-v100001/tenantsContract/info/${id}`); }
            export interface ListParams {
                /**
                 * @description  续约业务人员id
                 */
                businessId?: number;
                /**
                 * @description  合同签字 1-未签字 2-已签字
                 */
                contractRecordSignStatus?: number;
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  门牌号、（集中几层或者几栋）
                 */
                houseNum?: string;
                /**
                 * @description  1整租 2合租 3集中
                 */
                houseType?: number;
                /**
                 * @description  租赁结束时间结束
                 */
                leaseEndTimeEnd?: string;
                /**
                 * @description  租赁结束时间开始
                 */
                leaseEndTimeStart?: string;
                /**
                 * @description  租赁开始时间结束
                 */
                leaseStartTimeEnd?: string;
                /**
                 * @description  租赁开始时间开始
                 */
                leaseStartTimeStart?: string;
                /**
                 * @description  续约前租赁结束时间开始
                 */
                oldLeaseEndTimeEnd?: string;
                /**
                 * @description  续约前租赁结束时间开始
                 */
                oldLeaseEndTimeStart?: string;
                /**
                 * @description  续约前租赁结束时间结束
                 */
                oldLeaseStartTimeEnd?: string;
                /**
                 * @description  续约前租赁开始时间开始
                 */
                oldLeaseStartTimeStart?: string;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  租客付款方式id
                 */
                payTypeId?: number;
                /**
                 * @description  录入报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  报表续约合同开始时间
                 */
                reportTimeStart?: string;
                /**
                 * @description  房间号 (合租)/集中 门牌号
                 */
                roomNo?: string;
                /**
                 * @description  房间id
                 */
                roomTenantsId?: number;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  管家id
                 */
                stewardId?: number;
                /**
                 * @description  管家2id
                 */
                stewardId2?: number;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
                /**
                 * @description  租客id
                 */
                tenantsId?: number;
            }
            /**
             * @name 租客续约记录表列表
             * @param params
             */
            export function list(params: ListParams) { return request.get<ResponseResult<PageList<TenantsContract>>>("/house-v100001/tenantsContract/list", { params }); }
            export interface SaveParams {
                /**
                 * @description  续约业务人员id
                 */
                businessId: number;
                /**
                 * @description  押金金额
                 */
                depositAmount: number;
                /**
                 * @description  递增约定id
                 */
                increaseId: number;
                /**
                 * @description  1百分比 2固定金额
                 */
                increaseType?: number;
                /**
                 * @description  不规则付款json，付款试为不规则付款这个字段有值
                 */
                irregularList?: string;
                /**
                 * @description  租赁天
                 */
                leaseDay?: number;
                /**
                 * @description  租赁结束时间
                 */
                leaseEndTime: string;
                /**
                 * @description  租赁月
                 */
                leaseMonth?: number;
                /**
                 * @description  租赁开始时间
                 */
                leaseStartTime: string;
                /**
                 * @description  租赁年
                 */
                leaseYear?: number;
                /**
                 * @description  缴费日期 1表示提前，2表示延后 3固定日期
                 */
                payType: number;
                /**
                 * @description  缴费日期 提前或者延后多少天  
                 */
                payTypeDay: number;
                /**
                 * @description  付款方式id 字典 value值取remark
                 */
                payTypeId: number;
                /**
                 * @description  备注
                 */
                remarks?: string;
                /**
                 * @description  租金
                 */
                tenantsAmount: number;
                /**
                 * @description  租客id(退房时清空)
                 */
                tenantsId: number;
                increaseJson: Array<{
                    amount: number;
                    endTime: string;
                    startTime: string;
                    val: number;
                    year: number;
                }>;
                payOtherJson: Array<{
                    code: number;
                    name: string;
                    remarks: string;
                    val: string;
                    val1: string;
                }>;
                tenantsCohabitJson: Array<{
                    idCard: string;
                    name: string;
                    phone: number;
                }>;
            }
            /**
             * @name 租客续约记录表新增
             * @param data
             */
            export function save(data: SaveParams) { return request.post<ResponseResult<object>>("/house-v100001/tenantsContract/save", data); }
            /**
             * @name setSteward
             */
            export function setSteward() { return request.post("/house-v100001/tenantsContract/setSteward"); }
            export interface TenantsContractReductionParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 租客续约还原,前端需要提示是否确定还原，还原会删除当前账单,需要配置权限
             * @param data
             */
            export function tenantsContractReduction(data: TenantsContractReductionParams) { return request.post<ResponseResult<object>>("/house-v100001/tenantsContract/tenantsContractReduction", data); }
        }
        export namespace tenantsEnergy {
            export interface DeleteByIdParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 能源记录表删除
             * @param params
             */
            export function deleteById(params: DeleteByIdParams) { return request.delete<ResponseResult<object>>("/house-v100001/tenantsEnergy/deleteById", { params }); }
            /**
             * @name 能源记录表详情
             * @param id 能源记录表详情
             */
            export function info(id: string | number) { return request.get<ResponseResult<TenantsEnergy>>(`/house-v100001/tenantsEnergy/info/${id}`); }
            export interface ListParams {
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  门牌号、（集中几层或者几栋）
                 */
                houseNum?: string;
                /**
                 * @description  1整租 2合租 3集中
                 */
                houseType?: number;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  付款状态 1-未付   3-已还完
                 */
                payStatus?: number;
                /**
                 * @description  报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  房间id
                 */
                roomId?: number;
                /**
                 * @description  房间号 (合租)/集中 门牌号
                 */
                roomNo?: string;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
                /**
                 * @description  租客id
                 */
                tenantsId?: number;
            }
            /**
             * @name 能源记录表列表
             * @param params
             */
            export function list(params: ListParams) { return request.get<ResponseResult<PageList<TenantsEnergy>>>("/house-v100001/tenantsEnergy/list", { params }); }
            export interface SaveEnergyParams {
                /**
                 * @description  止数
                 */
                endNum: number;
                /**
                 * @description  缴费结束时间
                 */
                endTime: string;
                /**
                 * @description  水电气字典id
                 */
                energyId: number;
                /**
                 * @description  能源相关图片
                 */
                img?: string;
                /**
                 * @description  单价
                 */
                price: number;
                /**
                 * @description  应收时间
                 */
                receivableTime: string;
                /**
                 * @description  备注
                 */
                remark?: string;
                /**
                 * @description  起数
                 */
                startNum: number;
                /**
                 * @description  缴费开始时间
                 */
                startTime: string;
                /**
                 * @description  总价
                 */
                sumAmount: number;
                /**
                 * @description  租客id
                 */
                tenantsId: number;
            }
            /**
             * @name 水电费新增
             * @param data
             */
            export function saveEnergy(data: SaveEnergyParams) { return request.post<ResponseResult<object>>("/house-v100001/tenantsEnergy/saveEnergy", data); }
            export interface SavePreParams {
                /**
                 * @description  能源相关图片
                 */
                img?: string;
                /**
                 * @description  备注
                 */
                remark?: string;
                /**
                 * @description  总价
                 */
                sumAmount: number;
                /**
                 * @description  租客id
                 */
                tenantsId: number;
            }
            /**
             * @name 预存费新增
             * @param data
             */
            export function savePre(data: SavePreParams) { return request.post<ResponseResult<object>>("/house-v100001/tenantsEnergy/savePre", data); }
            export interface UpdateEnergyParams {
                /**
                 * @description  止数
                 */
                endNum: number;
                /**
                 * @description  缴费结束时间
                 */
                endTime: string;
                /**
                 * @description  水电气字典id
                 */
                energyId: number;
                /**
                 * @description  能源相关图片
                 */
                img?: string;
                /**
                 * @description  单价
                 */
                price: number;
                /**
                 * @description  应收时间
                 */
                receivableTime: string;
                /**
                 * @description  备注
                 */
                remark?: string;
                /**
                 * @description  起数
                 */
                startNum: number;
                /**
                 * @description  缴费开始时间
                 */
                startTime: string;
                /**
                 * @description  总价
                 */
                sumAmount: number;
                /**
                 * @description  租客id
                 */
                tenantsId: number;
            }
            /**
             * @name 水电费修改
             * @param id 水电费修改
             * @param data
             */
            export function updateEnergy(id: string | number, data: UpdateEnergyParams) { return request.put<ResponseResult<object>>(`/house-v100001/tenantsEnergy/updateEnergy/${id}`, data); }
            export interface UpdatePreParams {
                /**
                 * @description  能源相关图片
                 */
                img?: string;
                /**
                 * @description  备注
                 */
                remark?: string;
                /**
                 * @description  总价
                 */
                sumAmount: number;
                /**
                 * @description  租客id
                 */
                tenantsId: number;
            }
            /**
             * @name 预存费修改
             * @param id 预存费修改
             * @param data
             */
            export function updatePre(id: string | number, data: UpdatePreParams) { return request.put<ResponseResult<object>>(`/house-v100001/tenantsEnergy/updatePre/${id}`, data); }
        }
        export namespace tenantsExit {
            export interface DeleteByIdParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 租客退房记录删除
             * @param params
             */
            export function deleteById(params: DeleteByIdParams) { return request.delete<ResponseResult<object>>("/house-v100001/tenantsExit/deleteById", { params }); }
            export interface GetExitNameParams {
                /**
                 * @description  退房Id
                 */
                exitId?: number;
                /**
                 * @description  租客Id
                 */
                tenantsId?: number;
            }
            /**
             * @name 租客退房记录列表
             * @param params
             */
            export function getExitName(params: GetExitNameParams) { return request.get<ResponseResult<Array<SelectStrVo>>>("/house-v100001/tenantsExit/getExitName", { params }); }
            /**
             * @name 租客退房记录详情
             * @param id 租客退房记录详情
             */
            export function info(id: string | number) { return request.get<ResponseResult<TenantsExit>>(`/house-v100001/tenantsExit/info/${id}`); }
            export interface IsMarkListParams {
                /**
                 * @description  审核时间结束
                 */
                auditTimeEnd?: string;
                /**
                 * @description  审核时间开始
                 */
                auditTimeStart?: string;
                /**
                 * @description  创建时间结束
                 */
                crateTimeEnd?: string;
                /**
                 * @description  创建时间开始
                 */
                crateTimeStart?: string;
                /**
                 * @description  退房人
                 */
                createId?: number;
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  退房性质id 
                 */
                exitId?: number;
                /**
                 * @description  退房时间结束
                 */
                exitTimeEnd?: string;
                /**
                 * @description  退房时间开始
                 */
                exitTimeStart?: string;
                /**
                 * @description  门牌号、（集中几层或者几栋）
                 */
                houseNum?: string;
                /**
                 * @description  1整租 2合租 3集中
                 */
                houseType?: number;
                /**
                 * @description  是否审核 1已审核  2驳回  3未审核
                 */
                isAudit?: number;
                /**
                 * @description  是否核算业绩标记（标记的就不核算，未标记的核算）：0，未标记，1已标记;(标记列表页面默认传1)
                 */
                isMark?: number;
                /**
                 * @description  超期退租标记月份
                 */
                isMark1Month?: string;
                /**
                 * @description  租赁结束时间结束
                 */
                leaseEndTimeEnd?: string;
                /**
                 * @description  租赁结束时间开始
                 */
                leaseEndTimeStart?: string;
                /**
                 * @description  租赁开始时间结束
                 */
                leaseStartTimeEnd?: string;
                /**
                 * @description  租赁开始时间开始
                 */
                leaseStartTimeStart?: string;
                /**
                 * @description  姓名
                 */
                name?: string;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  支付状态 1未退  2已退部分  3已退完
                 */
                payStatus?: number;
                /**
                 * @description  电话
                 */
                phone?: string;
                /**
                 * @description  报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  房间id 
                 */
                roomId?: number;
                /**
                 * @description  房间号 (合租)/集中 门牌号
                 */
                roomNo?: string;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  物品来源id
                 */
                sourceId?: number;
                /**
                 * @description  管家确认无误：1,无误；2，未确认;3,有误
                 */
                stewardConfirm?: number;
                /**
                 * @description  管家id
                 */
                stewardId?: number;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
            }
            /**
             * @name 租客超期退租标记列表
             * @param params
             */
            export function isMarkList(params: IsMarkListParams) { return request.get<ResponseResult<PageList<TenantsExit>>>("/house-v100001/tenantsExit/isMarkList", { params }); }
            export interface IsMarkListExportParams {
                /**
                 * @description  审核时间结束
                 */
                auditTimeEnd?: string;
                /**
                 * @description  审核时间开始
                 */
                auditTimeStart?: string;
                /**
                 * @description  创建时间结束
                 */
                crateTimeEnd?: string;
                /**
                 * @description  创建时间开始
                 */
                crateTimeStart?: string;
                /**
                 * @description  退房人
                 */
                createId?: number;
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  退房性质id 
                 */
                exitId?: number;
                /**
                 * @description  退房时间结束
                 */
                exitTimeEnd?: string;
                /**
                 * @description  退房时间开始
                 */
                exitTimeStart?: string;
                /**
                 * @description  门牌号、（集中几层或者几栋）
                 */
                houseNum?: string;
                /**
                 * @description  1整租 2合租 3集中
                 */
                houseType?: number;
                /**
                 * @description  是否审核 1已审核  2驳回  3未审核
                 */
                isAudit?: number;
                /**
                 * @description  是否核算业绩标记（标记的就不核算，未标记的核算）：0，未标记，1已标记;(标记列表页面默认传1)
                 */
                isMark?: number;
                /**
                 * @description  超期退租标记月份
                 */
                isMark1Month?: string;
                /**
                 * @description  租赁结束时间结束
                 */
                leaseEndTimeEnd?: string;
                /**
                 * @description  租赁结束时间开始
                 */
                leaseEndTimeStart?: string;
                /**
                 * @description  租赁开始时间结束
                 */
                leaseStartTimeEnd?: string;
                /**
                 * @description  租赁开始时间开始
                 */
                leaseStartTimeStart?: string;
                /**
                 * @description  姓名
                 */
                name?: string;
                /**
                 * @description  支付状态 1未退  2已退部分  3已退完
                 */
                payStatus?: number;
                /**
                 * @description  电话
                 */
                phone?: string;
                /**
                 * @description  报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  房间id 
                 */
                roomId?: number;
                /**
                 * @description  房间号 (合租)/集中 门牌号
                 */
                roomNo?: string;
                /**
                 * @description  物品来源id
                 */
                sourceId?: number;
                /**
                 * @description  管家确认无误：1,无误；2，未确认;3,有误
                 */
                stewardConfirm?: number;
                /**
                 * @description  管家id
                 */
                stewardId?: number;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
            }
            /**
             * @name 租客超期退租标记列表导出
             * @param params
             */
            export function isMarkListExport(params: IsMarkListExportParams) { return request.get("/house-v100001/tenantsExit/isMarkListExport", { params }); }
            export interface IsmMarkParams {
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  1，标记；0，取消标记
                 */
                isMark?: number;
                /**
                 * @description  标记备注
                 */
                markRemark?: string;
            }
            /**
             * @name 手动标记租客是否核算业绩
             * @param data
             */
            export function ismMark(data: IsmMarkParams) { return request.post<ResponseResult<object>>("/house-v100001/tenantsExit/ismMark", data); }
            export interface IsmMarkV1Params {
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  1，标记；0，取消标记
                 */
                isMark1?: number;
                /**
                 * @description  租客超期退租标记备注
                 */
                markRemark1?: string;
            }
            /**
             * @name 手动标记租客是否核算业绩
             * @param data
             */
            export function ismMarkV1(data: IsmMarkV1Params) { return request.post<ResponseResult<object>>("/house-v100001/tenantsExit/ismMarkV1", data); }
            export interface ListParams {
                /**
                 * @description  审核时间结束
                 */
                auditTimeEnd?: string;
                /**
                 * @description  审核时间开始
                 */
                auditTimeStart?: string;
                /**
                 * @description  创建时间结束
                 */
                crateTimeEnd?: string;
                /**
                 * @description  创建时间开始
                 */
                crateTimeStart?: string;
                /**
                 * @description  退房人
                 */
                createId?: number;
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地区id
                 */
                detailId?: string;
                /**
                 * @description  退房性质id 
                 */
                exitId?: number;
                /**
                 * @description  退房时间结束
                 */
                exitTimeEnd?: string;
                /**
                 * @description  退房时间开始
                 */
                exitTimeStart?: string;
                /**
                 * @description  门牌号、（集中几层或者几栋）
                 */
                houseNum?: string;
                /**
                 * @description  1整租 2合租 3集中
                 */
                houseType?: number;
                /**
                 * @description  是否审核 1已审核  2驳回  3未审核
                 */
                isAudit?: number;
                /**
                 * @description  是否核算业绩标记（标记的就不核算，未标记的核算）：0，未标记，1已标记;(标记列表页面默认传1)
                 */
                isMark?: number;
                /**
                 * @description  超期退租标记月份
                 */
                isMark1Month?: string;
                /**
                 * @description  租赁结束时间结束
                 */
                leaseEndTimeEnd?: string;
                /**
                 * @description  租赁结束时间开始
                 */
                leaseEndTimeStart?: string;
                /**
                 * @description  租赁开始时间结束
                 */
                leaseStartTimeEnd?: string;
                /**
                 * @description  租赁开始时间开始
                 */
                leaseStartTimeStart?: string;
                /**
                 * @description  姓名
                 */
                name?: string;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  支付状态 1未退  2已退部分  3已退完
                 */
                payStatus?: number;
                /**
                 * @description  电话
                 */
                phone?: string;
                /**
                 * @description  报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  房间id 
                 */
                roomId?: number;
                /**
                 * @description  房间号 (合租)/集中 门牌号
                 */
                roomNo?: string;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  物品来源id
                 */
                sourceId?: number;
                /**
                 * @description  管家确认无误：1,无误；2，未确认;3,有误
                 */
                stewardConfirm?: number;
                /**
                 * @description  管家id
                 */
                stewardId?: number;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
            }
            /**
             * @name 租客退房记录列表
             * @param params
             */
            export function list(params: ListParams) { return request.get<ResponseResult<PageList<TenantsExit>>>("/house-v100001/tenantsExit/list", { params }); }
            export interface ReductionParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 租客退房还原
             * @param data
             */
            export function reduction(data: ReductionParams) { return request.post<ResponseResult<object>>("/house-v100001/tenantsExit/reduction", data); }
            export interface ReductionPayStatusParams {
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 还原退款状态未退
             * @param data
             */
            export function reductionPayStatus(data: ReductionPayStatusParams) { return request.post<ResponseResult<object>>("/house-v100001/tenantsExit/reductionPayStatus", data); }
            export interface SaveParams {
                /**
                 * @description  银行账号
                 */
                bankAccount?: string;
                /**
                 * @description  银行卡姓名
                 */
                bankName?: string;
                /**
                 * @description  开户行
                 */
                bankOpenAccount?: string;
                /**
                 * @description  开户行地址
                 */
                bankOpenAccountAddr?: string;
                /**
                 * @description  扣费：超期房租
                 */
                beyondAmount?: number;
                /**
                 * @description  扣费：超期房租天数
                 */
                beyondDay?: number;
                /**
                 * @description  违约盈利、如果转租就叫 转租手续费 ，换房就叫换房手续费
                 */
                breakContractAmount?: number;
                /**
                 * @description  证件类型字典(601)
                 */
                certificateTypeId?: number;
                /**
                 * @description  换房备注
                 */
                changeRemarks?: string;
                /**
                 * @description  退房押金
                 */
                depositAmount: number;
                /**
                 * @description  电滞纳金
                 */
                electricityLateAmount?: number;
                /**
                 * @description  电单价
                 */
                electricityPrice?: number;
                /**
                 * @description  电总价
                 */
                electricitySumAmount?: number;
                /**
                 * @description  电本次底数
                 */
                electricityThisNum?: number;
                /**
                 * @description  电上次底数
                 */
                electricityUpNum?: number;
                /**
                 * @description  应退能源费
                 */
                energyAmount: number;
                /**
                 * @description  退房性质id 1正常退房  2违约退房  3没有签成 4公司违约  5租客转租   6租客换房
                 */
                exitId: number;
                /**
                 * @description  退房时间(前端默认为今天)
                 */
                exitTime?: string;
                /**
                 * @description  气滞纳金
                 */
                gasLateAmount?: number;
                /**
                 * @description  气单价
                 */
                gasPrice?: number;
                /**
                 * @description  气总价
                 */
                gasSumAmount?: number;
                /**
                 * @description  气本次底数
                 */
                gasThisNum?: number;
                /**
                 * @description  气上次底数
                 */
                gasUpNum?: number;
                /**
                 * @description  剩余房租
                 */
                leftTenantsAmount?: number;
                /**
                 * @description  后端参数：前端不管，新结束时间
                 */
                newEndTime?: string;
                /**
                 * @description  新租客证件号
                 */
                newIdCard?: string;
                /**
                 * @description  新租客身份证地址
                 */
                newIdCardAddr?: string;
                /**
                 * @description  新租客姓名
                 */
                newName?: string;
                /**
                 * @description  新租客电话
                 */
                newPhone?: string;
                /**
                 * @description  新租客电话
                 */
                newPhone2?: string;
                /**
                 * @description  新房间id 
                 */
                newRoomId?: number;
                /**
                 * @description  后端参数：前端不管，新开始时间
                 */
                newStartTime?: string;
                /**
                 * @description  后端参数：前端不管，新租客id
                 */
                newTenantsId?: number;
                /**
                 * @description  其它费
                 */
                otherAmount?: number;
                /**
                 * @description  应扣其它费
                 */
                otherSumAmount: number;
                /**
                 * @description  预存电
                 */
                preElectricityAmount?: number;
                /**
                 * @description  预存气
                 */
                preGasAmount?: number;
                /**
                 * @description  预存物管费
                 */
                prePropertyAmount?: number;
                /**
                 * @description  预存水
                 */
                preWaterAmount?: number;
                /**
                 * @description  物管费欠费天
                 */
                propertyDay?: number;
                /**
                 * @description  物管费滞纳金
                 */
                propertyLateAmount?: number;
                /**
                 * @description  物管费欠费月
                 */
                propertyMonth?: number;
                /**
                 * @description  物管费单价
                 */
                propertyPrice?: number;
                /**
                 * @description  物管费欠费金额
                 */
                propertySumAmount?: number;
                /**
                 * @description  物管费截至日期
                 */
                propertyTime?: string;
                /**
                 * @description  实际退款金额
                 */
                refundAmount: number;
                /**
                 * @description  退房备注
                 */
                remarks?: string;
                /**
                 * @description  应退金额
                 */
                shouldBackAmount: number;
                /**
                 * @description  转租性质id 293
                 */
                subletId?: number;
                /**
                 * @description  转租备注
                 */
                subletRemarks?: string;
                /**
                 * @description  租客id 
                 */
                tenantsId: number;
                /**
                 * @description  类型：1原条件转租 2变条件转租
                 */
                type?: number;
                /**
                 * @description  水滞纳金
                 */
                waterLateAmount?: number;
                /**
                 * @description  水单价
                 */
                waterPrice?: number;
                /**
                 * @description  水总价
                 */
                waterSumAmount?: number;
                /**
                 * @description  水本次底数
                 */
                waterThisNum?: number;
                /**
                 * @description  水上次底数
                 */
                waterUpNum?: number;
                otherDeductionJson: Array<{
                    code: number;
                    name: string;
                    remarks: string;
                    val: string;
                }>;
                otherJson: Array<{
                    code: number;
                    name: string;
                    remarks: string;
                    val: string;
                }>;
            }
            /**
             * @name 租客退房记录新增
             * @param data
             */
            export function save(data: SaveParams) { return request.post<ResponseResult<object>>("/house-v100001/tenantsExit/save", data); }
            export interface SaveImgParams {
                /**
                 * @description  水电气底数图片
                 */
                energyImg?: string;
                /**
                 * @description  退房图片
                 */
                exitImg?: string;
                /**
                 * @description  主键
                 */
                id?: number;
            }
            /**
             * @name 添加退房图片
             * @param data
             */
            export function saveImg(data: SaveImgParams) { return request.post<ResponseResult<House>>("/house-v100001/tenantsExit/saveImg", data); }
            export interface UpdateParams {
                /**
                 * @description  银行账号
                 */
                bankAccount?: string;
                /**
                 * @description  银行卡姓名
                 */
                bankName?: string;
                /**
                 * @description  开户行
                 */
                bankOpenAccount?: string;
                /**
                 * @description  开户行地址
                 */
                bankOpenAccountAddr?: string;
                /**
                 * @description  扣费：超期房租
                 */
                beyondAmount?: number;
                /**
                 * @description  扣费：超期房租天数
                 */
                beyondDay?: number;
                /**
                 * @description  违约盈利、如果转租就叫 转租手续费 ，换房就叫换房手续费
                 */
                breakContractAmount?: number;
                /**
                 * @description  退房押金
                 */
                depositAmount: number;
                /**
                 * @description  电滞纳金
                 */
                electricityLateAmount?: number;
                /**
                 * @description  电单价
                 */
                electricityPrice?: number;
                /**
                 * @description  电总价
                 */
                electricitySumAmount?: number;
                /**
                 * @description  电本次底数
                 */
                electricityThisNum?: number;
                /**
                 * @description  电上次底数
                 */
                electricityUpNum?: number;
                /**
                 * @description  应退能源费
                 */
                energyAmount: number;
                /**
                 * @description  退房性质id 1正常退房  2违约退房  3没有签成 4公司违约  5租客转租   6租客换房
                 */
                exitId: number;
                /**
                 * @description  退房时间(前端默认为今天)
                 */
                exitTime?: string;
                /**
                 * @description  气滞纳金
                 */
                gasLateAmount?: number;
                /**
                 * @description  气单价
                 */
                gasPrice?: number;
                /**
                 * @description  气总价
                 */
                gasSumAmount?: number;
                /**
                 * @description  气本次底数
                 */
                gasThisNum?: number;
                /**
                 * @description  气上次底数
                 */
                gasUpNum?: number;
                /**
                 * @description  剩余房租
                 */
                leftTenantsAmount?: number;
                /**
                 * @description  其它费
                 */
                otherAmount?: number;
                /**
                 * @description  应扣其它费
                 */
                otherSumAmount: number;
                /**
                 * @description  预存电
                 */
                preElectricityAmount?: number;
                /**
                 * @description  预存气
                 */
                preGasAmount?: number;
                /**
                 * @description  预存物管费
                 */
                prePropertyAmount?: number;
                /**
                 * @description  预存水
                 */
                preWaterAmount?: number;
                /**
                 * @description  物管费欠费天
                 */
                propertyDay?: number;
                /**
                 * @description  物管费滞纳金
                 */
                propertyLateAmount?: number;
                /**
                 * @description  物管费欠费月
                 */
                propertyMonth?: number;
                /**
                 * @description  物管费单价
                 */
                propertyPrice?: number;
                /**
                 * @description  物管费欠费金额
                 */
                propertySumAmount?: number;
                /**
                 * @description  物管费截至日期
                 */
                propertyTime?: string;
                /**
                 * @description  实际退款金额
                 */
                refundAmount: number;
                /**
                 * @description  退房备注
                 */
                remarks?: string;
                /**
                 * @description  应退金额
                 */
                shouldBackAmount: number;
                /**
                 * @description  租客id 
                 */
                tenantsId: number;
                /**
                 * @description  水滞纳金
                 */
                waterLateAmount?: number;
                /**
                 * @description  水单价
                 */
                waterPrice?: number;
                /**
                 * @description  水总价
                 */
                waterSumAmount?: number;
                /**
                 * @description  水本次底数
                 */
                waterThisNum?: number;
                /**
                 * @description  水上次底数
                 */
                waterUpNum?: number;
                otherDeductionJson: Array<{
                    code: number;
                    name: string;
                    remarks: string;
                    val: string;
                }>;
                otherJson: Array<{
                    code: number;
                    name: string;
                    remarks: string;
                    val: string;
                }>;
            }
            /**
             * @name 租客退房记录修改
             * @param id 租客退房记录修改
             * @param data
             */
            export function update(id: string | number, data: UpdateParams) { return request.put<ResponseResult<object>>(`/house-v100001/tenantsExit/update/${id}`, data); }
            export interface UpdateMarkRemarkParams {
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  业绩标记备注
                 */
                markRemark?: string;
            }
            /**
             * @name 业绩标记备注单独修改
             * @param data
             */
            export function updateMarkRemark(data: UpdateMarkRemarkParams) { return request.post<ResponseResult<object>>("/house-v100001/tenantsExit/updateMarkRemark", data); }
            export interface UpdateMarkRemark1Params {
                /**
                 * @description  主键
                 */
                id?: number;
                /**
                 * @description  租客超期退租标记备注
                 */
                markRemark1?: string;
            }
            /**
             * @name 超期退租备注单独修改
             * @param data
             */
            export function updateMarkRemark1(data: UpdateMarkRemark1Params) { return request.post<ResponseResult<object>>("/house-v100001/tenantsExit/updateMarkRemark1", data); }
        }
        export namespace tenantsHis {
            /**
             * @name 租房历史表详情
             * @param id 租房历史表详情
             */
            export function info(id: string | number) { return request.get<ResponseResult<TenantsHis>>(`/house-v100001/tenantsHis/info/${id}`); }
            export interface ListParams {
                /**
                 * @description  业务人员
                 */
                businessId?: number;
                /**
                 * @description  客源人
                 */
                businessId2?: number;
                /**
                 * @description  协助人员2
                 */
                businessName2?: string;
                /**
                 * @description  渠道来源id
                 */
                channelId?: number;
                /**
                 * @description  合同性质id
                 */
                contractId?: number;
                /**
                 * @description  合同签字 1-未签字 2-已签字
                 */
                contractRecordSignStatus?: number;
                /**
                 * @description  签字时间结束
                 */
                contractRecordSignTimeEnd?: string;
                /**
                 * @description  签字时间开始
                 */
                contractRecordSignTimeStart?: string;
                /**
                 * @description  创建时间结束
                 */
                createTenantsTimeEnd?: string;
                /**
                 * @description  创建时间开始
                 */
                createTenantsTimeStart?: string;
                /**
                 * @description  交割单签 1已签字 2废弃 3待签字
                 */
                deliveryOrderSignStatus?: number;
                /**
                 * @description  部门id
                 */
                deptId?: number;
                /**
                 * @description  物业地址id
                 */
                detailId?: string;
                /**
                 * @description  户型/房型id
                 */
                doorModelId?: number;
                /**
                 * @description  几厅（集中才有）
                 */
                hall?: number;
                /**
                 * @description  门牌号（集中楼层）
                 */
                houseNum?: string;
                /**
                 * @description  类型：1整租,2合租，3集中
                 */
                houseType?: number;
                /**
                 * @description  是否已上传证件图片:2未上传
                 */
                idCardImgStatus?: number;
                /**
                 * @description  租后是否审核 1已审核  2未通过  3未审核
                 */
                isAfterAudit?: number;
                /**
                 * @description  租前是否审核 1已审核  2未通过  3未审核
                 */
                isBeforeAudit?: number;
                /**
                 * @description  租赁结束时间结束
                 */
                leaseEndTimeEnd?: string;
                /**
                 * @description  租赁结束时间开始
                 */
                leaseEndTimeStart?: string;
                /**
                 * @description  租赁开始时间结束
                 */
                leaseStartTimeEnd?: string;
                /**
                 * @description  租赁开始时间开始
                 */
                leaseStartTimeStart?: string;
                /**
                 * @description  租客姓名
                 */
                name?: string;
                pageNo?: number;
                pageSize?: number;
                /**
                 * @description  租客付款方式id
                 */
                payTypeId?: number;
                /**
                 * @description  联系电话
                 */
                phone?: string;
                /**
                 * @description  录入报表查询时间
                 */
                reportTime?: string;
                /**
                 * @description  报表查询时间(租赁结束) 出房业绩使用
                 */
                reportTimeEnd?: string;
                /**
                 * @description  报表查询时间(租赁开始) 收房业绩使用
                 */
                reportTimeStart?: string;
                /**
                 * @description  几室（集中才有）
                 */
                room?: number;
                /**
                 * @description  房间号/门牌号
                 */
                roomNo?: string;
                /**
                 * @description  结算单签字状态：1已签字，2未签字
                 */
                settlementSignStatus?: number;
                sortField?: string;
                sortWay?: string;
                /**
                 * @description  1新签 2续约 3退房
                 */
                statusHisId?: number;
                /**
                 * @description  管家id
                 */
                stewardId?: number;
                /**
                 * @description  管家id
                 */
                stewardId2?: number;
                /**
                 * @description  分组id
                 */
                storeGroupId?: number;
                /**
                 * @description  门店id
                 */
                storeId?: number;
                /**
                 * @description  门店id多选
                 */
                storeIdList?: string;
                /**
                 * @description  几卫（集中才有）（合租0表示无，1表示有）
                 */
                who?: number;
            }
            /**
             * @name 租房历史表列表
             * @param params
             */
            export function list(params: ListParams) { return request.get<ResponseResult<PageList<TenantsHis>>>("/house-v100001/tenantsHis/list", { params }); }
        }
    }
}
