import { Action } from "redux";
import AES from "crypto-js/aes";
import UTF8 from "crypto-js/enc-utf8";
import { ReduxActionType } from "../../constants";
import { ParsedRoute, request } from "../../utils";
import { WebStorageUtil } from "../../utils/webStorage";

const defaultUser = WebStorageUtil.getItem(
  `USER-INFO-${ReduxActionType.LOGIN}`
);
const token = WebStorageUtil.getItem(`TOKEN-${ReduxActionType.LOGIN}`);

const INIT_STATE: ISystemState = {
  token,
  routeHistory: [],
  user:
    defaultUser && token
      ? JSON.parse(AES.decrypt(defaultUser, token).toString(UTF8))
      : null
};

export function systemReducer(
  state: ISystemState = INIT_STATE,
  action: Actions
) {
  switch (action.type) {
    case ReduxActionType.LOGIN:
      if (action.token) {
        WebStorageUtil.setItem(`TOKEN-${ReduxActionType.LOGIN}`, action.token);
        // WebStorageUtil.setItem(
        //   `USER-${ReduxActionType.LOGIN}`,
        //   JSON.stringify(action.user)
        // );

        WebStorageUtil.setItem(
          `USER-INFO-${ReduxActionType.LOGIN}`,
          AES.encrypt(JSON.stringify(action.user), action.token).toString()
        );
        request.defaults.headers["token"] = action.token;
        // request.defaults.headers["user"] = action.user;
      } else {
        WebStorageUtil.removeItem(`TOKEN-${ReduxActionType.LOGIN}`);
        WebStorageUtil.removeItem(`USER-INFO-${ReduxActionType.LOGIN}`);
        request.defaults.headers["token"] = "";
      }
      return {
        ...state,
        token: action.token,
        user: action.user
      };
    case ReduxActionType.HISTORY:
      return {
        ...state,
        routeHistory: action.history
      };
    default:
      return {
        ...state
      };
  }
}

type Actions = LoginAction | HistoryAction;

export interface LoginAction extends Action<ReduxActionType.LOGIN> {
  token: NullLike;
  user: NullLike<LoginVo>;
}

export interface HistoryAction extends Action<ReduxActionType.HISTORY> {
  history: ParsedRoute[];
}

export interface ISystemState {
  token: NullLike;
  user: NullLike<UserLoginData>;
  routeHistory: ParsedRoute[];
  dict?: Array<any>;
}
