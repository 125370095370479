export enum WarehouseInventoryType {
  // 入库
  warehousing = 1,
  // 出库
  delivery = 2,
  // 丢失
  lose = 3,
  // 损坏
  damage = 4,
}

export function getWarehouseInventoryTypeName(type: WarehouseInventoryType) {
  switch (type) {
    case WarehouseInventoryType.warehousing:
      return "入库";
    case WarehouseInventoryType.delivery:
      return "出库";
    case WarehouseInventoryType.lose:
      return "丢失";
    case WarehouseInventoryType.damage:
      return "损坏";
  }
}
