export enum MenuType {
  "Directory" = 1,
  "Page" = 2,
}

export const menuTypes = [
  {
    label: "目录",
    value: MenuType.Directory,
    color: "#2db7f5",
  },
  {
    label: "页面",
    value: MenuType.Page,
    color: "#87d068",
  },
];
